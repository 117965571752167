import { combineReducers } from 'redux'
import contactReducer from "./contactReducer";
import tagReducer from "./tagReducer";
import virtualNumberReducer from "./virtualNumberReducer";
import dripBatchReducer from "./dripBatchReducer";
import rightsideReducer from "../components/contact/rightSide/redux/rightsideReducer"
import calendarReducer from './calendarReducer';
export default combineReducers({
    contactReducer,tagReducer,virtualNumberReducer,dripBatchReducer,
    rightsideReducer,calendarReducer
})