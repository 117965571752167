import React, { useCallback, useState } from 'react';
import If from 'if-else-react';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import StepProgressBar from './StepProgressBar';
import BottomButtons from './BottomButtons';

const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}


const DealCreate = props => {

    const [doCreateDeal,setDoCreateDeal] = useState(props.state.do_create_deal);
    const [pipelineId,setPipelineId] = useState(props.state.pipeline_id);
    const [stageId,setStageId] = useState(props.state.stage_id);
    const [hasPipelineError,setHasPipelineError] = useState(false);
    const [hasStageError,setHasStageError] = useState(false);

    const saveAndContinue = (e) => {
        e.preventDefault();
        if(doCreateDeal == 1){
            if(pipelineId <= 0 || stageId <= 0) {
                setHasPipelineError(pipelineId == 0);
                setHasStageError(stageId == 0);
                return false;
            }
            props.handleChange("do_create_deal", doCreateDeal);
            props.handleChange("pipeline_id", pipelineId);
            props.handleChange("stage_id", stageId);
            props.nextStep()
        }else{
            props.handleChange("do_create_deal", 0);
            props.handleChange("pipeline_id", 0);
            props.handleChange("stage_id", 0);
            props.nextStep()
        }
    }

    const prevStep = useCallback((e) => {
        e.preventDefault()
        props.prevStep()
    },[]);

    const setDealCreate = (value) => {
        setDoCreateDeal(doCreateDeal == 1 ? 0 : 1);
    }

    const getPipelines = () => {
        if(props.state.pipelines.length > 0){
            return props.state.pipelines.map((pipeline,index)=>{
                return <option value={pipeline.id} key={index}>{pipeline.title}</option>
            })
        }
    }

    const setPipeline = (value) => {
        setPipelineId(value);
        setStageId(0);
    }

    const getStages = () => {
        if(pipelineId != null){
            if (props.state.stages.length > 0) {
                return props.state.stages.filter( data => {
                    return data.pipeline_id == pipelineId
                }).map(item => {
                    return <option value={item.id}>{item.stage}</option>;
                });
            }
        }
    }

    return (
        <>
            <div className="import_content_container">
                <div className="main_content_inner_container white">
                    <div className="main_content_topbar">
                        <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                            <HeaderRight />
                        </div>
                    </div>

                    <div className="divider main_content_container_divider"></div>

                    <div className="main_content_main_section">
                        <StepProgressBar fromCampaign={props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_DEAL_CREATE}/>
                    </div>

                    <div className="content_divider"><div className="content_divider_inner"></div></div>

                    <div className="main_section_title_section new_deal_title">
                        <div>
                            <h6 className="center-align">Create A New Deal</h6>
                        </div>
                        <div>
                            <p className="center-align">When uploading contacts you can also create a new deal (optional)</p>
                        </div>
                    </div>

                    <div className="divider main_content_main_section_divider"></div>

                    <div className="create_deal_content_section">
                        <div className="create_deal_toggle_section">
                            <div className="create_deal_toggle_button_section center-align">
                                <p>Do you want to add these contacts to your deals pipeline?</p>
                                <div className="switch">
                                    <label>
                                        <input type="checkbox" checked={doCreateDeal == 1} onClick={ e => setDealCreate(e.target.value)} name="do_create_deal"/>
                                        <span className="lever"></span>
                                    </label>
                                </div>
                            </div>                            
                        </div>

                        <If condition={doCreateDeal == 1}>
                            <div className="">
                                <div className="select_pipeline_content d-flex mb-4">
                                    <div className="select_numbering_box d-flex align-items-center">
                                        <div class={`number_box number_box_1 ${pipelineId != 0 ? 'number_checked' : ''}`}>
                                            <div className="done_step center-align">
                                                <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M27.4913 3.75459C27.6895 3.94933 27.691 4.26832 27.4945 4.4648L10.4478 21.5115C10.2531 21.7062 9.93745 21.7068 9.74198 21.5127L0.511607 12.3483C0.313919 12.1521 0.314762 11.8321 0.513482 11.6369L3.8493 8.35962C4.04421 8.16814 4.35674 8.16857 4.55111 8.36061L9.74199 13.489C9.93759 13.6822 10.2525 13.6813 10.447 13.4869L23.4525 0.481302C23.6466 0.287259 23.9607 0.285869 24.1565 0.478189L27.4913 3.75459Z" fill="white"/>
                                                </svg>
                                            </div>
                                            <div className="number">1</div>
                                        </div>

                                        <div className="horizontal_line"></div>

                                        <div class={`number_box number_box_2 ${stageId != 0 ? 'number_checked' : ''}`}>
                                            <div className="done_step center-align">
                                                <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M27.4913 3.75459C27.6895 3.94933 27.691 4.26832 27.4945 4.4648L10.4478 21.5115C10.2531 21.7062 9.93745 21.7068 9.74198 21.5127L0.511607 12.3483C0.313919 12.1521 0.314762 11.8321 0.513482 11.6369L3.8493 8.35962C4.04421 8.16814 4.35674 8.16857 4.55111 8.36061L9.74199 13.489C9.93759 13.6822 10.2525 13.6813 10.447 13.4869L23.4525 0.481302C23.6466 0.287259 23.9607 0.285869 24.1565 0.478189L27.4913 3.75459Z" fill="white"/>
                                                </svg>
                                            </div>

                                            <div className="number">2</div>
                                        </div>
                                    </div>
                                    
                                    <div className="select_pipeline_content_box">
                                        <div>
                                            <div className="first_selection">
                                                <p>Select Pipeline</p>
                                                <div className="pipeline_select_option_box">
                                                    <div className="input-field">
                                                        <select id="pipeline" className="d-block" name="pipeline_id" value={pipelineId} onChange={ e => setPipeline(e.target.value)}>
                                                            <option value={0}>Choose a pipeline</option>
                                                            {getPipelines()}
                                                        </select>
                                                    </div>
                                                    <If condition={hasPipelineError}>
                                                        <div className="help-block filled">
                                                            <div className="text-danger">Pipeline is
                                                                required
                                                            </div>
                                                        </div>
                                                    </If>
                                                </div>
                                            </div>

                                            <div class={pipelineId == 0 ? 'second_selection' : 'first_selection'}>
                                                <p>Select Stage</p>
                                                <div className="pipeline_select_option_box">
                                                    <div className="input-field">
                                                        <select id="pipeline2" className="d-block" name="stage_id" value={stageId} onChange={ e => setStageId(e.target.value)}>
                                                            <option value={0}>Choose a stage</option>
                                                            {getStages()}
                                                        </select>
                                                    </div>
                                                    <If condition={hasStageError}>
                                                        <div className="help-block filled">
                                                            <div className="text-danger">Stage is
                                                                required
                                                            </div>
                                                        </div>
                                                    </If>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </If>

                        <BottomButtons 
                            onBackClick={prevStep} 
                            onContinueClick={saveAndContinue}
                            continueButtonText={'Save & Continue'}
                            style={doCreateDeal == 1 ? {bottom:30} : {}}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DealCreate;
