import React, { Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import If from 'if-else-react';
import { emailContactToFriend } from '../../../api/contactApi';
import {Grid } from '@material-ui/core';
import GlobalModal from '../../globals/Modal/GlobalModal';
import NewLoader from '../../common/NewLoader';
import { IconList } from '../../globals/IconList';

class ShareContactModal extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.validator = new SimpleReactValidator();
    }

    getInitialState = () => {
        return {
            message : '',
            email : '',
            conversion: 0,
            invalidEmailError: false,
            emailLengthError: true,
            invalidMessageError: false,
            messageLengthError: false,
            submitting: false
        };
    };

    handleMessageChange = (e) => {
        this.setState({
            message : e.target.value,
        })
        if(e.target.value !== '') {
            if (!(e.target.value.match(/(.*[a-zA-Z0-9])/))) {
                this.setState({
                    invalidMessageError: true,
                    messageLengthError: false
                });
            } else {
                this.setState({
                    invalidMessageError: false
                });
                if(e.target.value.length > 350){
                    this.setState({
                        messageLengthError: true
                    });
                }else{
                    this.setState({
                        messageLengthError: false
                    });
                }
            }

        }else{
            this.setState({
                invalidMessageError : false,
                messageLengthError: false
            });
        }
    }

    handleEmailChange = (e) => {
        this.setState({
            email : e.target.value,
        })

        if(e.target.value !== '') {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!(re.test(String(e.target.value).toLowerCase())) || e.target.value.length > 150 || e.target.value.length ===0) {
                this.setState({
                    invalidEmailError: true,
                    emailLengthError: false
                });
            } else {
                this.setState({
                    invalidEmailError: false
                });
                if(e.target.value.length > 150 || e.target.value.length ===0){
                    this.setState({
                        emailLengthError: true
                    });
                }else{
                    this.setState({
                        emailLengthError: false
                    });
                }
            }

        }else{
            this.setState({
                invalidEmailError : false,
                emailLengthError: true
            });
        }
    }

    handleSubmit = () => {
        if(this.state.invalidEmailError || this.state.emailLengthError || this.state.invalidMessageError || this.state.messageLengthError){
            this.props.showNotificationStart('ERROR','Email not valid')

            return false;
        }
        this.startLoader()

        emailContactToFriend({
            contact_id: this.props.contact_id,
            message : this.state.message,
            email : this.state.email,
            conversion: this.state.conversion
        }).then(res => {
            this.props.showNotificationStart('SUCCESS','Contact Shared')
            this.props.onClose()
            // Utils.showNotification("Success to share contact", 'success');
        });


    }

    closeModal = () => {
        this.setState(this.getInitialState());
        this.props.closeModal();
    };

    startLoader=()=>{
        
        let modalWidth  = document.querySelector('.create-new-list').offsetWidth;
        let modalHeight  = document.querySelector('.create-new-list').offsetHeight;

        document.querySelector(".create-new-list").style.width = modalWidth+'px';
        document.querySelector(".create-new-list").style.height = modalHeight+'px';

        this.setState({submitting:true})
    }

    render() {

        return (
            // <Drawer anchor={'right'} open={true} onClose={this.closeModal}>
            //     <Wrapper>
            //     <Form ref={c => { this.form = c }} onSubmit={this.handleSubmit}>

            <GlobalModal {...this.props} 
            title={ this.props.title}
            buttonText={"Send Contact"}
            buttonIcon={IconList.sendToInboxIcon}
            onSubmit={this.handleSubmit}
            hideFooter={this.state.submitting ?true: false}
            className={'manage-tag-modal create-new-list update share_contact_info_modal global-xs-modal global-small-modal'}>
                    <Grid container>
                        <Grid item xs={12}>
                            {!this.state.submitting &&
                            <div className="form-horizontal">
                                <div className="form-group m-form__group row">
                                    <div className="col-lg-11 mx-auto">
                                        <label htmlFor="exampleTextarea">
                                            Email your contact to your friend
                                        </label>
                                        <textarea onChange={this.handleMessageChange} placeholder="Add a message with the link"
                                                className="form-control m-input m-input--air"
                                                rows="3"></textarea>
                                        <div className="">
                                            <If condition={this.state.messageLengthError}>
                                                <div className="form-error text-danger mt-1">
                                                    Message length should be max 350
                                                </div>
                                            </If>
                                            <If condition={this.state.invalidMessageError}>
                                                <div className="text-error form-error text-danger mt-1">
                                                    Invalid message
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group m-form__group">
                                    <div className="col-lg-11 mx-auto">
                                        <label htmlFor="exampleTextarea">
                                            Email <span style={{color:'red'}}>*</span>
                                        </label>
                                        <div className="input-group">
                                            <input type="email" onChange={this.handleEmailChange} className="form-control" placeholder="Email Address" required />
                                        </div>
                                        <div className="text-error">
                                            <If condition={this.state.emailLengthError}>
                                                <div className="form-error text-danger mt-1">
                                                    Email length should be max 150
                                                </div>
                                            </If>
                                            <If condition={this.state.invalidEmailError}>
                                                <div className="form-error text-danger mt-1">
                                                    Invalid email format [Ex. something@something.com]
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {this.state.submitting &&
                                <NewLoader loading={this.state.submitting} onlyLoader={true} size={30} message={""} />
                            }
                        </Grid>
                    </Grid>
            </GlobalModal>
        );
    }
}

export default ShareContactModal;
