import { sendRequest } from './rootApi';
import Api from "../constants/ApiDripBatch";

export const getDripBatchReportByPaginationApi = async (payload) => {
    return sendRequest("post", Api.getDripBatchReportByPagination, payload);
}

export const submitDripBatchTitleEditApi = async (payload) => {
    return sendRequest("post", Api.submitDripBatchTitleEdit, payload);
}

export const getDripBatchContactsByPaginationApi = async (payload) => {
    return sendRequest("post", Api.getDripBatchContactList, payload);
}

/* drip batch api */
export const deleteDripBatchApi = async (payload) => {
    return sendRequest("post", Api.deleteDripBatch, payload);
}

export const getAllBatchFramesApi = async (payload) => {
    return sendRequest("post", Api.getAllBatchFrames, payload);
}

export const getAllFrameContactsApi = async (payload) => {
    return sendRequest("post", Api.getAllFrameContacts, payload);
}