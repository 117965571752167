/* middleware */
export const GET_DRIP_BATCH_REPORT_PAGINATION = 'GET_DRIP_BATCH_REPORT_PAGINATION'
export const SUBMIT_DRIP_BATCH_REPORT_TITLE_EDIT = 'SUBMIT_DRIP_BATCH_REPORT_TITLE_EDIT'
export const GET_DRIP_BATCH_CONTACT_PAGINATION = 'GET_DRIP_BATCH_CONTACT_PAGINATION'

/* reducer */
export const RELOAD_DRIP_BATCH_REPORT_FLAG = 'RELOAD_DRIP_BATCH_REPORT_FLAG'
export const DRIP_BATCH_REPORT_SEARCH_TEXT = 'DRIP_BATCH_REPORT_SEARCH_TEXT'
export const DRIP_BATCH_REPORT_PAGINATION = 'DRIP_BATCH_REPORT_PAGINATION'
export const DRIP_BATCH_REPORT_EDIT_TITLE = 'DRIP_BATCH_REPORT_EDIT_TITLE'
export const DRIP_REPORT_SHOW_NOTIFICATION = 'DRIP_REPORT_SHOW_NOTIFICATION'