export const UploadFileIcon = () => {
    return (
        <svg height="120px" width="120px" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 498.217 498.217">
                <g>
                    <g>
                        <g>
                            <polygon fill="#010002" points="343.481,292.295 249.108,197.931 154.744,292.295 173.895,311.454 235.558,249.783
                                235.558,433.57 262.659,433.57 262.659,249.791 324.314,311.438"/>
                        </g>
                        <g>
                            <path fill="#010002" d="M393.732,156.044c-4.487,0-9.015,0.293-13.526,0.878c-22.923-33.555-64.663-51.608-105.436-44.106
                                c-19.2-29.873-52.372-48.17-88.13-48.17c-54.063,0-98.526,40.651-104.208,93.812C34.246,168.79,0,210.782,0,260.895
                                c0,57.819,47.04,104.85,104.85,104.85h104.712v-26.873H104.85c-43,0-77.986-34.985-77.986-77.986
                                c0-40.049,30.003-73.385,69.776-77.538l12.022-2.723v-11.144c0-43,34.977-77.977,77.977-77.977
                                c29.466,0,56.087,16.379,69.491,42.748l5.032,9.917l10.681-3.097c35.319-10.234,72.686,5.958,89.943,37.123l4.861,8.763
                                l9.795-2.154c5.731-1.268,11.526-1.91,17.257-1.91c42.821,0,77.644,34.985,77.644,77.977c0,43-34.985,77.986-77.986,77.986
                                H287.93v26.873h105.436c57.81,0,104.85-47.032,104.85-104.85C498.217,203.084,451.347,156.044,393.732,156.044z"/>
                        </g>
                    </g>
                </g>
            </svg>);
}