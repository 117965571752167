import contactMiddleware from './contactMiddleware';
import tagMiddleware from './tagMiddleware';
import { all } from "redux-saga/effects";
import virtualNumberMiddleware from "./virtualNumberMiddleware";
import taskMiddleware from './taskMiddleware';
import dripBatchMiddleware from './dripBatchMiddleware';
import rightsideMiddleware from '../components/contact/rightSide/redux/rightsideMiddleware'
import calendarMiddleware from './calendarMiddleware';
export default function* rootMiddleware() {
    yield all(
        [
            contactMiddleware(),
            tagMiddleware(),
            virtualNumberMiddleware(),
            taskMiddleware(),
            dripBatchMiddleware(),
            rightsideMiddleware(),
            calendarMiddleware(),
        ]
    )
}
