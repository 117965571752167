import React, {useEffect, useRef, useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  InputBase,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  withStyles, Typography, Box, ListItemText
} from '@material-ui/core';
import Styles from "./AddCollaboratorModal.module.css";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import {leadPermission} from "../../../../constants/CoreConstants";
import {receiveBulkContactPermissionRequest} from "../../../../api/contactApi";

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: '4px !important',
      position: 'relative',
      border: '1px solid #ced4da !important',
      fontSize: 16,
      padding: '10px 26px 10px 12px !important',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4
      },
    },
  }))(InputBase);

  const useStyles = makeStyles((theme) => {
    return {
      root: {
        "&.MuiButton-root": {
          backgroundColor: '#008945',
          padding: '10px 20px',
          borderRadius: '4px',
        },
        "&.MuiButton-text": {
          color: "grey"
        },
        "&.MuiButton-contained": {
          color: "white"
        },
        "&.MuiButton-outlined": {
          color: "brown"
        },
        "&.Mui-disabled": {
          backgroundColor: "#00894596",
          cursor: "not-allowed"
        }
      }
    };
  });

const AddCollaboratorModal = ({data, isOpen, onClose}) => {
  const requestedForUserIdRef = useRef(null);
  const [subUserList, setSubUserList] = useState([]);
  const [formData, setFormData] = useState({
    requestedForUserIds: [],
  });
  const [submitting, setSubmitting] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState({
    requestedForUserIds: ''
  });

  const classes = useStyles();

  useEffect(()=>{
      if(data && data.subUserList){
        setSubUserList(data.subUserList);
      }
  },[data.subUserList]);


  const validateForm = () => {
    let cold = 1;
    const errorState = {
      requestedForUserIds: ''
    };

    if (formData.requestedForUserIds.length <= 0){
      errorState.requestedForUserIds = 'You must select a user.';
      if (requestedForUserIdRef){
        requestedForUserIdRef.current.focus();
      }
      cold = 0;
    }

    if (cold === 0){
      setFormErrorMessage({ ...formErrorMessage, ...errorState });
    }

    return cold;
  }
  const handleFormDataChange = (name, value) => {
      setFormData({ ...formData, [name]: value });
      if (formErrorMessage[name]){
        setFormErrorMessage({ ...formErrorMessage, [name]: '' });
      }
  }

  const renderSubUserListOptions = () => {
    const item = [];
    for(let i = 0; i < subUserList.length; i++){
      item.push(<MenuItem className="dropdownhelper-menuitem-class" value={subUserList[i].id}>
        <Checkbox color="primary" checked={formData.requestedForUserIds.indexOf(subUserList[i].id) > -1} />
        <ListItemText primary={subUserList[i].full_name} />
      </MenuItem>);
    }
    return item;
  }

  const renderErrorMessage = (fieldName) => {
    return <FormHelperText error>{formErrorMessage[fieldName]}</FormHelperText>;
  }

  const handleSubmit = async () => {
    if (validateForm()){
      setSubmitting(true);

      if (data.contactIds){
        formData.contactIds = data.contactIds;
      }

      if(data.filter){
        formData.filter = data.filter;
      }

      if(data.userId){
        formData.userId = data.userId;
      }

      formData.requestType = leadPermission.REQUEST_TYPE_ADD_COLLABORATOR;

      receiveBulkContactPermissionRequest(formData)
          .then((res)=>{
            if(res.success){
              onClose();
              window.showNotification("SUCCESS",res.message);
            }else {
              window.showNotification("ERROR",res.message);
            }
          }).catch((err)=>{
        window.showNotification("ERROR","Something went wrong. Please try again later!");
      }).finally(()=>{
        setSubmitting(false);
      });
    }
  };

  const handleClose = () => {
    if(submitting){
      return;
    }
    if(data.callback){
      data.callback({ success:false }, formData);
    }
    onClose();
  }

  return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
              <div className={Styles.headerWrapper}>
                <Typography variant="h5">Request for Add Collaborators.</Typography>
                <Box className={Styles.headerClose} onClick={handleClose}>
                  <CloseIcon />
                </Box>
              </div>

              <div className={Styles.paddingWrp}>
                  <div className={Styles.modalBody}>
                    <FormControl className={Styles.formControl}>
                      <span className={Styles.label}>
                        Please select user(s).
                        <span className={Styles.required}> *</span>
                      </span>

                      <Select
                        labelId="add-collaborator-select-label"
                        id="add-collaborator-select"
                        name="requestedForUserIds"
                        value={formData.requestedForUserIds}
                        displayEmpty
                        onChange={(e)=>{ handleFormDataChange('requestedForUserIds', e.target.value) }}
                        input={<BootstrapInput/>}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        multiple
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select some user</em>;
                          }

                          if (formData.requestedForUserIds.length > 3){
                            return `${formData.requestedForUserIds.slice(0, 3)
                                .map((id) => subUserList.find((option) => option.id === id).full_name)
                                .join(', ')}...`
                          }else {
                            return selected
                                .map((id) =>
                                    subUserList.find((option) => option.id === id).full_name
                                )
                                .join(', ');
                          }
                        }}
                      >
                        {renderSubUserListOptions()}
                      </Select>
                      {renderErrorMessage('requestedForUserIds')}
                    </FormControl>
                  </div>

                  <div className={Styles.modalFooter}>
                    <Button style={{padding: '10px 20px', }} onClick={handleClose} size="large">Cancel</Button>
                    <Button disabled={submitting || (formData.requestedForUserIds === '')} size="large" onClick={handleSubmit} className={classes.root} variant="contained">
                      {submitting ? 'Submitting....' : 'Submit Request'}
                    </Button>
                  </div>
                </div>
            </Paper>
        </Modal>
    );
}

export default AddCollaboratorModal;
