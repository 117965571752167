import React, { useState ,useEffect} from 'react';
import { WithCustomField } from './WithCustomField';

const customTypesArray = {
    1 : 'text',
    2 : 'multiline_text',
    3 : 'number',
    4 : 'date',
    5 : 'checkbox',
    6 : 'select',
    7 : 'radio',
    8 : 'phone',
    9 : 'zip_code',
    10 : 'website',
    11 : 'datetime',
}

const CustomFields =(props)=>{


                //Don't remove necessary if custom types change
    // const [customTypes, setCustomTypes] = useState(null)

    const [customFields,setCustomFields] = useState([]);

    useEffect(() => {
        window.globalCustomFieldWithPersonalized({
            get_custom_data: true,
            get_personalized_data: false,
            field_name: ['*'],
            for_drop_down: false,
            drop_down_field: {
            value_field: 'id',
            title_field: 'title'
            }
            }).then(res => {
            let customFieldDatas = res.data.custom_field;
            setCustomFields(customFieldDatas);
            })
    }, [])


    // render() {

        const fields = ()=>{

            let arrayData = []
            customFields.map((data, index)=> {

                    // let Each = WithCustomField( data, props.contactCustomFields, customTypesArray[data.type_id]);

                    // return <Each contact_id={props.contact_id} key={index} value={props.contactCustomFields}
                    //              field={data}/>

                    arrayData.push( <WithCustomField data={data} contact_id={props.contact_id} key={index} value={props.contactCustomFields}
                             formType={customTypesArray[data.type_id] }/>)
            })

            return arrayData;
        }

        return (
            <React.Fragment>
        <div className="contact-single-details-more-info-tab">
            <div className="contact-single-details-more-info-tab-body">
                <div className="contact-single-details-more-info-header">
                    <span>Custom Fields</span>
                </div>
                <div className="contact-single-details-more-info-body">
                        {/* {props.data !=null && fields()} */}
                        {
                            fields()
                        }
                </div>
            </div>
            </div>
            
            </React.Fragment>
        );
    // }
}

export default CustomFields;
