import React from 'react';

const HeaderRight = (props) =>{
    return (
        <>
            <div>
                <h6>Import {props.extraTitle}</h6>
            </div>
            {/* <div>
                <a href="#" class="main_content_container_button white-text d-flex justify-content-center align-items-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="main_content_container_button_icon">
                        <circle cx="10" cy="10" r="10" fill="white"/>
                        <path d="M14.1557 9.07129C13.8171 8.85645 13.3777 8.71057 12.8969 8.65148C12.754 7.81145 12.3765 7.09344 11.7954 6.56191C11.179 5.99795 10.3639 5.6875 9.5 5.6875C8.74053 5.6875 8.03906 5.92555 7.47252 6.375C7.04259 6.71744 6.70759 7.16435 6.49949 7.67309C5.84057 7.7659 5.27488 7.98697 4.85143 8.31762C4.29434 8.75611 4 9.37336 4 10.1047C4 11.5527 5.20141 12.5625 6.92188 12.5625H9.15625V9.46875H9.84375V12.5625H12.5078C14.0684 12.5625 15 11.8269 15 10.5945C15 9.94893 14.708 9.42213 14.1557 9.07129ZM9.15625 14.011L8.12457 12.9707L7.63881 13.4648L9.5 15.3125L11.3612 13.4648L10.8754 12.9707L9.84375 14.011V12.5625H9.15625V14.011Z" fill="#3C7EF3"/>
                    </svg>
                    <span>Download sample File</span>
                </a>
            </div> */}
        </>
    );
}

const Upload = (props) => {

    const saveAndContinue = (e) => {
        e.preventDefault();
        if(props.contactLimit < props.currentContact){
            props.upgradeStep();
        } else{
            props.nextStep();
        }
    };

    const saveAndContinueList = (e) => {
        e.preventDefault();
        props.nextStepList();
    };

    return (
        <>
        <div className="import_content_container v2">
            <div className="main_content_inner_container white">
                <div className="main_content_topbar">
                    <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                        <HeaderRight extraTitle={props.titleForCampaignUpload ? props.titleForCampaignUpload : ''}/>
                    </div>
                </div>

                <div class="divider main_content_container_divider"></div>

                <div className="main_content_main_section">
                    <div className="main_content_main_section_content">
                        {/*<div className="video-inner">*/}
                            {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/D0a0aNqTehM" frameBorder="0" allow="accelerometer; autoplay; " allowFullScreen></iframe>*/}
                        {/*</div>*/}
                        <div>
                            <h6 class="justify-content-center main_content_main_section_heading center">Import your leads from any CSV file</h6>
                        </div>
                        <div>
                            <p class="center main_content_main_section_details">We'll walk you through matching up your data in the next step</p>
                        </div>
                        <div className="main_content_main_section_button_group">

                            <a href="javascript:void(0)" onClick={saveAndContinue} class="main_content_container_button left white-text d-flex justify-content-center align-items-center">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="main_content_container_button_icon">
                                    <circle cx="10" cy="10" r="10" fill="white"/>
                                    <path d="M13.5513 14.4001H11.7411H11.2537H11.1484V11.7781H11.9425C12.1439 11.7781 12.2629 11.531 12.1439 11.3531L10.5991 9.04497C10.5007 8.8967 10.2971 8.8967 10.1987 9.04497L8.65391 11.3531C8.53491 11.531 8.65162 11.7781 8.8553 11.7781H9.64942V14.4001H9.54414H9.05669H6.95812C5.75665 14.3284 4.80005 13.1151 4.80005 11.8004C4.80005 10.8934 5.25546 10.1027 5.93058 9.67513C5.86879 9.49473 5.83675 9.30198 5.83675 9.09934C5.83675 8.17263 6.53017 7.42385 7.38836 7.42385C7.57373 7.42385 7.75224 7.45845 7.9193 7.52517C8.41591 6.38842 9.48693 5.6001 10.7319 5.6001C12.343 5.60257 13.6703 6.93455 13.8214 8.63228C15.0595 8.8621 16 10.1002 16 11.5014C16 12.9989 14.9199 14.2963 13.5513 14.4001Z" fill="#3C7EF3"/>
                                </svg>									
                                <span>Upload your CSV file</span>
                            </a>
                        
                        
                        
                            <a href="javascript:void(0)" onClick={saveAndContinueList} class="main_content_container_button right white-text d-flex justify-content-center align-items-center">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="main_content_container_button_icon">
                                    <circle cx="10" cy="10" r="10" fill="white"/>
                                    <g clipPath="url(#clip0)">
                                    <path d="M10 11.9312C8.93455 11.9312 8.06885 11.0654 8.06885 9.99996C8.06885 8.93448 8.93455 8.06877 10 8.06877C11.0655 8.06877 11.9312 8.93448 11.9312 9.99996C11.9312 11.0654 11.0655 11.9312 10 11.9312ZM9.85353 10.5727C9.6138 10.5727 9.41402 10.3729 9.41402 10.1331H8.77473C8.78805 10.7325 9.26752 11.212 9.85353 11.212V10.5727Z" fill="#3C7EF3"/>
                                    <path d="M15.8602 10.3995C15.2075 11.2119 12.8768 13.8623 10 13.8623C7.1232 13.8623 4.79245 11.2119 4.13984 10.3995C3.95339 10.1731 3.95339 9.84011 4.13984 9.60037C4.79245 8.78794 7.1232 6.13755 10 6.13755C12.8768 6.13755 15.2075 8.78794 15.8602 9.60037C16.0466 9.82679 16.0466 10.1598 15.8602 10.3995ZM10 7.20304C8.45505 7.20304 7.20311 8.45498 7.20311 9.99993C7.20311 11.5449 8.45505 12.7968 10 12.7968C11.545 12.7968 12.7969 11.5449 12.7969 9.99993C12.7969 8.45498 11.545 7.20304 10 7.20304Z" fill="#3C7EF3"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0">
                                    <rect width="12" height="12" fill="white" transform="matrix(1 0 0 -1 4 16)"/>
                                    </clipPath>
                                    </defs>
                                </svg>																		
                                <span>View past imports</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Upload;
