

import { confirmAlert } from "react-confirm-alert";


const GlobalConfirmAlert = (props)=>{


    confirmAlert({
        title: props.title,
        message: props.message,
        buttons: [
            {
                label: 'Yes',
                onClick: async () => {
                    props.successFunction()
                }
            },
            {
                label: 'No',
                onClick: () => {
                    props.failFunction()
                }
            }
        ]
    })
}

export default GlobalConfirmAlert;