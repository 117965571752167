import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getDialingList = async (payload, url) => {
    return sendRequest("post", url, payload);
}

export const callingSettingSubmit = async (payload) => {
    return sendRequest("post", Api.callingSettingSubmit, payload);
}

export const deleteList = async (payload) => {
    return sendRequest("post", Api.deleteList, payload);
}

export const addContactsToAutoDialingList = async (payload) => {
    return sendRequest("post", Api.addContactsToAutoDialingList, payload);
}