import React, {Fragment, useContext, useEffect} from "react";
import StepProgressBar from "./StepProgressBar";
import MapContact from "./mapContact/MapContact";
import { FILE_IMPORT_STEP } from "../Constants";
import UploadStep from "./UploadStep";
import {DataCleanContext} from "../StateProvider";
import CompleteStep from "./CompleteStep";
import "./CustomOverwrite.css";

const FileUpload = () => {
    const {state, dispatch} = useContext(DataCleanContext);
    const getFileUploadStep = () => {
        switch (state.activeStep){
            case FILE_IMPORT_STEP.STEP_IMPORT_MAPPING:
                return <MapContact/>;
            case FILE_IMPORT_STEP.STEP_UPLOAD:
                return <UploadStep/>;
            case FILE_IMPORT_STEP.STEP_SUCCESS:
                return <CompleteStep/>;
            default:
                return <div>No Step</div>;
        }
    };

    return (
        <Fragment>
            <StepProgressBar currentStep={state.activeStep}/>
            { getFileUploadStep() }
        </Fragment>
    );
}

export default FileUpload;