import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reloadNoteList, toggleNoteModal } from '../../../actions/contactAction';
import { getTimeline, getUserGroup } from '../../../api/contactApi';
import Loading from '../../common/Loading';
import Note from './Note';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from "@material-ui/lab/Skeleton";
import './noteList.css'

const contentType = 16;

const NoteList = (props) => {
    const [notes, setNotes] = useState([]);
    const [componentLoading, setComponentLoading] = useState(true);
    const [allTeamUser, setAllTeamUser] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const [page, setPage] = useState(1)
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        loadData(page);
        getUsers();
    }, [])   // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if(props.reloadStatusNoteList){
            if(!firstLoad){
                setPage(1)
                loadData(1)
                props.reloadNeedForNoteList(false);
            }else{
                setFirstLoad(false)
                props.reloadNeedForNoteList(false);

            }
        }
    }, [props.reloadStatusNoteList]) // eslint-disable-line react-hooks/exhaustive-deps

    

    const loadData = (pageNo) => {
        getTimeline({
            contact_id: props.contactId,
            skip : 0,
            page: pageNo,
            content_type: contentType,
        }).then((response) => {
            setComponentLoading(false);
            let array = [...notes];

            // response.data.data.forEach(element => {
            //     array.push(element);
            // });

            if(pageNo === 1){   
                setFirstLoad(false)             
                setNotes(response.data.data)
            }else{
                setNotes(array.concat(response.data.data));
            }
            if (response.data.next_page_url) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            setScrolling(false);
        })
    }

    const getUsers = () => {
        getUserGroup({
            
        }).then(res => {
            if(res.data.status === undefined) {
                let allTeamUser = res.data;
                let users = [];
                if (res.data) {
                    users = allTeamUser.map((data) => {
                        return {
                            id: data.id,
                            display: data.full_name,
                        };
                    });
                }
                setAllTeamUser(users);
            }else{
                // this.setState({
                //     loading: false
                // })
            }
        });
    }

    const loadMoreData = (e) => {
        if(hasMore && !scrolling && Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)) {
            setScrolling(true);

            setTimeout(() => {
                setPage(page + 1);
                loadData(page);
            }, 500);
        }
    }

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 5; i++) {
            skeletons.push(
            <div
                key={i}
                className={`skeleton_each_note`}
            >
                <div className="skeleton_note_details">
                    <Skeleton variant="rect" width={500} height={80} />
                    <Skeleton
                        className="skeleton_note_edit_icon"
                        variant="circle"
                        width={30}
                        height={30}
                    />
                </div>
            </div>
            );
        }
        return skeletons;
    };

    return (
        <React.Fragment>
            <div id="note_tab" className="note_tab_content col s12 contact__middle__inbox__conversation__tab awesome__scroll_bar" onScroll={(e) => loadMoreData(e)}>
            {!componentLoading && notes.length > 0 &&
            <Button className="accent--bg--color globalAddNoteButton alt" variant="contained" color="primary" onClick={()=>props.toggleNoteModal({status:true})}><AddIcon /> Add Note</Button>
            }    
            {
                    componentLoading ? (
                        <div>
                            {loadingSkeleton()}
                        </div> 
                    ) : (
                        <React.Fragment>
                            {
                                
                                notes.map((note, index) => (
                                    <Note 
                                        loggedOnUser={props.user} 
                                        key={`${note.message} ${index}`} 
                                        create={note} 
                                        message={note} 
                                        users={allTeamUser}
                                    />
                                ))
                            }
                                { notes.length === 0 &&
                                    <div className="notItemsWrap">
                                        <div className="notItemsFound">
                                            <Button className="accent--bg--color globalAddNoteButton" variant="contained" color="primary" onClick={()=>props.toggleNoteModal({status:true})}><AddIcon /> Add Note</Button>
                                            <p>No Notes Available</p>
                                        </div>
                                    </div>
                            }
                            {
                                scrolling && (
                                    <React.Fragment>
                                        <Loading/>
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        reloadStatusNoteList: state.contactReducer.reloadNoteList,
        showNoteModal: state.contactReducer.showNoteModal,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        reloadNeedForNoteList: (params) => dispatch(reloadNoteList(params)),
        toggleNoteModal: (params) => dispatch(toggleNoteModal(params)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NoteList);
