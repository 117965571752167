const BottomButtons = props => {
    return ( 
        <div className="d-flex justify-content-center content_duel_buttons mb-5">
            <div>
                <a href="#!" onClick={e => {e.preventDefault(); props.onBackClick(e)}} className="main_content_container_button white-text d-flex justify-content-center align-items-center back_button back_next_buttons">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="main_content_container_button_icon">
                        <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z" fill="#133159" fillOpacity="0.65"/>
                    </svg>												
                    <span>Back</span>
                </a>
            </div>
            <div>
                <a href="#!" onClick={e => { e.preventDefault(); props.onContinueClick(e)}} className="main_content_container_button white-text d-flex justify-content-center align-items-center back_next_buttons accent--bg--text--color">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="main_content_container_button_icon">
                        <path d="M20 10C20 4.4774 15.5226 0 10 0C4.4774 0 0 4.4774 0 10C0 15.5226 4.4774 20 10 20C15.5226 20 20 15.5226 20 10ZM9.77644 14.3923C9.70471 14.3212 9.64769 14.2366 9.60865 14.1434C9.56961 14.0502 9.54931 13.9502 9.54891 13.8492C9.54851 13.7482 9.56802 13.6481 9.60631 13.5546C9.64461 13.4611 9.70095 13.376 9.77212 13.3043L12.2875 10.7692H5.86538C5.66137 10.7692 5.46571 10.6882 5.32146 10.5439C5.1772 10.3997 5.09615 10.204 5.09615 10C5.09615 9.79599 5.1772 9.60033 5.32146 9.45607C5.46571 9.31181 5.66137 9.23077 5.86538 9.23077H12.2875L9.77212 6.69567C9.70096 6.62389 9.64464 6.53879 9.60638 6.44524C9.56811 6.35169 9.54864 6.25152 9.54909 6.15045C9.54954 6.04937 9.56989 5.94938 9.60898 5.85617C9.64807 5.76296 9.70514 5.67837 9.77692 5.60721C9.84871 5.53606 9.9338 5.47974 10.0274 5.44147C10.1209 5.40321 10.2211 5.38374 10.3221 5.38419C10.4232 5.38463 10.5232 5.40498 10.6164 5.44407C10.7096 5.48317 10.7942 5.54023 10.8654 5.61202L14.6822 9.45817C14.8252 9.60226 14.9054 9.79702 14.9054 10C14.9054 10.203 14.8252 10.3977 14.6822 10.5418L10.8654 14.388C10.7942 14.4599 10.7096 14.517 10.6163 14.5561C10.523 14.5952 10.423 14.6156 10.3218 14.616C10.2207 14.6164 10.1204 14.5968 10.0268 14.5585C9.93327 14.5201 9.84818 14.4636 9.77644 14.3923Z"/>
                    </svg>												
                    <span>{props.continueButtonText}</span>
                </a>
            </div>
        </div>
    );
}
 
export default BottomButtons;