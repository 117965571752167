import React from 'react';
import { Link } from "react-router-dom";
import { IconList } from '../../../constants/IconList';
import Utils from '../../../helpers/Utils';
import BootstrapTooltip from '../../globals/BootstrapTooltip';

 const StageListItem=(props)=>{

    return (
        <li className="link-text-design link__text__design__alt">
            <BootstrapTooltip title={props.title} arrow placement="top">
                <Link to={`/deal/deal-stages?id=${props.stageInfo.stage.pipeline_id}`} target="_blank">
                    <span>
                        {Utils.reduceTextLength(props.title,20)}
                    </span>
                </Link>
            </BootstrapTooltip>

            <span
                className=" d-inline-flex ml-1"
                onClick={()=>props.openStageChangeModal(props.stageInfo, props.stageIndex, props.row, props.index)}
            >
                {IconList.arrowRightIcon}
            </span>
        </li>
    )
}

export default React.memo(StageListItem);