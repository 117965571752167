export const FETCH_ACTIVITY_CALENDAR = 'FETCH_ACTIVITY_CALENDAR';
export const FETCH_ACTIVITY_LIST = 'FETCH_ACTIVITY_LIST';
export const ACTIVITY_CALENDAR = 'ACTIVITY_CALENDAR';
export const ACTIVITY_LIST = 'ACTIVITY_LIST';
export const ACTIVITY_LIST_LOADING = 'ACTIVITY_LIST_LOADING';
export const UPDATE_VIEW_ACTIVE_TAB = 'UPDATE_ACTIVITY_ACTIVE_TAB';
export const UPDATE_FILTER_ACTIVE_TAB = 'UPDATE_FILTER_ACTIVE_TAB';
export const UPDATE_TASK_ACTIVE_TAB = 'UPDATE_TASK_ACTIVE_TAB';
export const SAVE_ACTIVITY_FOLLOWUP = 'SAVE_ACTIVITY_FOLLOWUP';
export const FETCH_TASK_COUNT = 'FETCH_TASK_COUNT';
export const TASK_COUNT = 'TASK_COUNT';
export const CALENDAR_TAB_VIEW = 1;
export const LIST_TAB_VIEW = 2;
export const TASK_ALL=0;
export const TASK_CALL=1;
export const TASK_MEETING=2;
export const TASK_TASK=3;
export const TASK_DEADLINE=4;
export const TASK_EMAIL=5;
export const TASK_FOLLOWUP=6;
export const FETCH_CONTACT_LIST = 'FETCH_CONTACT_LIST';
export const FILTER_PLANNED = 0;
export const FILTER_OVERDUE = 1;
export const FILTER_TODAY = 2;
export const FILTER_TOMORROW = 3;
export const FILTER_THIS_WEEK = 4;
export const FILTER_NEXT_WEEK = 5;

export const FETCH_CALENDAR_WIDGET = 'FETCH_CALENDAR_WIDGET';
export const CALENDAR_WIDGET_LIST = 'CALENDAR_WIDGET_LIST';
export const TARN_ON_CALENDAR_WIDGET = 'CALENDAR_WIDGET_LIST';
export const FETCH_CALENDAR_WIDGET_DETAILS = 'FETCH_CALENDAR_WIDGET_DETAILS';
export const CALENDAR_WIDGET_DETAILS = 'CALENDAR_WIDGET_DETAILS';

export const FETCH_CALENDAR_WIDGET_SCHEDULE_DETAILS = 'FETCH_CALENDAR_WIDGET_DETAILS';
export const CALENDAR_WIDGET_SCHEDULE_DETAILS = 'CALENDAR_WIDGET_SCHEDULE_DETAILS';
export const CALENDAR_WIDGET_SCHEDULES_ARR = 'CALENDAR_WIDGET_SCHEDULES_ARR';
export const CALENDAR_WIDGET_SCHEDULES_START_DATE = 'CALENDAR_WIDGET_SCHEDULES_START_DATE';
export const CALENDAR_WIDGET_SCHEDULES_END_DATE = 'CALENDAR_WIDGET_SCHEDULES_END_DATE';

export const FETCH_CAMPAIGN_LIST = 'FETCH_CAMPAIGN_LIST';
export const FETCH_LEAD_FLOW_LIST = 'FETCH_LEAD_FLOW_LIST';
export const CAMPAIGN_LIST = 'CAMPAIGN_LIST';
export const LEAD_FLOW_LIST = 'LEAD_FLOW_LIST';

export  const UPDATE_CALENDAR_EVENTS_SLOT = 'UPDATE_CALENDAR_EVENTS_SLOT';
export  const REGENERATE_CALENDAR_EVENTS = 'REGENERATE_CALENDAR_EVENTS';

/* for task update */
export  const UPDATE_TASK_UPDATE_FLAG = 'UPDATE_TASK_UPDATE_FLAG';

