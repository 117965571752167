import React, { useEffect, useState } from 'react';
import { getTimelineLogs, getUserGroup } from '../../../api/contactApi';
import Loading from '../../common/Loading';
import TimezoneConversation from '../../globals/TimezoneConversion';
import Utils from '../../../helpers/Utils';
import Skeleton from "@material-ui/lab/Skeleton";
import "./logList.css"

const userTimezone = Utils.getAccountData('userTimezoneRaw');

const LogList = (props) => {
    const [logs, setLogs] = useState([]);
    const [componentLoading, setComponentLoading] = useState(true);
    const [allTeamUser, setAllTeamUser] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const [page, setPage] = useState(1)

    useEffect(() => {
        loadData();
        getUsers();
    }, [])

    const loadData = () => {
        getTimelineLogs({
            contact_id: props.contactId,
            page: page,
        }).then((response) => {
            setComponentLoading(false);
            let array = [...logs];

            // response.data.data.forEach(element => {
            //     array.push(element);
            // });
            setLogs(array.concat(response.data.data));

            if (response.data.next_page_url) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            setScrolling(false);
        })
    }

    const getUsers = () => {
        getUserGroup({
            
        }).then(res => {
            if(res.data.status === undefined) {
                let allTeamUser = res.data;
                let users = [];
                if (res.data) {
                    users = allTeamUser.map((data) => {
                        return {
                            id: data.id,
                            display: data.full_name,
                        };
                    });
                }
                setAllTeamUser(users);
            }else{
                // this.setState({
                //     loading: false
                // })
            }
        });
    }

    const loadMoreData = (e) => {
        if(hasMore && !scrolling && Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)) {
            setScrolling(true);

            setTimeout(() => {
                setPage(page + 1);
                loadData(page);
            }, 500);
        }
    }

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 5; i++) {
            skeletons.push(
            <div
                key={i}
                className={`skeleton_each_log`}
            >
                <div className="skeleton_log_details">
                    <Skeleton variant="rect" width={500} height={60} />
                </div>
            </div>
            );
        }
        return skeletons;
    };

    return (
        <React.Fragment>
            <div id="log_tab" className="log_tab_content col s12 p-0 pb-3 awesome__scroll_bar active" onScroll={(e) => loadMoreData(e)}>
                {
                    componentLoading ? (
                        <div>
                            {loadingSkeleton()}
                        </div> 
                    ) : (
                        <React.Fragment>
                            {
                                logs.map((log, index) => (
                                    <div class="log_tab_single_file d-flex justify-content-between align-items-center py-2 px-4">
                                        <h6 class="log_tab_heading text-dark-blue-65">
                                            {log.message}
                                        </h6>
                                        <p class="log_tabs_time text-light-blue">{TimezoneConversation(log.created_at, 'UTC', userTimezone, 'LLL')}</p>
                                    </div>
                                ))
                            }
                            {
                                scrolling && (
                                    <React.Fragment>
                                        <Loading/>
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </React.Fragment>
    )
}

export default LogList;