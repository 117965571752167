import React,{useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { IconList } from './IconList';
import { DropdownHelper } from './DropdownHelper';

function PopperHelper(props) {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [omitList,setOmitList] = useState([])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        setOmitList(props.omitList)
    }, [props.omitList])

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleChange=(event)=>{
        let index = omitList.indexOf(event);
        let tempData= omitList;
        if(index < 0){
            tempData.push(event)
        }else{
            tempData.splice(index,1)
        }
        setOmitList(tempData)
        if(omitList.includes(event)){
            props.setOmitList(tempData, event, 'pop')
        }else{
            props.setOmitList(tempData, event, 'push')
        }
    }

    return (
        <div className="colums-filter">
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={(props.buttonClassName !== undefined && props.buttonClassName != null) ? props.buttonClassName : ''}
            >
               {(props.buttonColumnIcon !== undefined && props.buttonColumnIcon != null) ? IconList[props.buttonColumnIcon]: ''}&nbsp;
               {(props.buttonText !== undefined && props.buttonText != null) ? props.buttonText: ''}
            </Button>
            <Popper className="colums-filter" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
            placement='bottom'>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'center bottom'}}
                    >
                        <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" >
                    
                      {(props.queryType === undefined || props.queryType ==null) &&
                       props.datalist.map((dataInfo)=>(
                        <MenuItem className={`dropdownhelper-menuitem-class`} onClick={()=>handleChange(dataInfo)}>
                        <FormControlLabel
                        control={
                        <Checkbox checked={omitList.includes(dataInfo) ? false: true} 
                        onChange={()=>handleChange(dataInfo)}
                        />
                    }
                      />
                        {dataInfo}
                        </MenuItem>
                        
                      ))
                      }
                      {(props.queryType !== undefined && props.queryType !=null) && 
                         <MenuItem >
                         <DropdownHelper
                        datalist={props.queryType}
                        noneFieldRequired={false}
                        className=""
                        mapping={{ title: "query" }}
                        menuItemClassName=""
                        selectedValue={props.queryType[0].id}
                    /></MenuItem>
                    
                      }

                  </MenuList>
                </ClickAwayListener>
              </Paper>

                    </Grow>
                )}
            </Popper>
        </div>
    );
}

export default React.memo(PopperHelper)
