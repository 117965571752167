import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import { Save } from "@material-ui/icons";
import "./modal_assign_to_tag.css";
import {getUserTagListNew} from "../../../../api/contactApi";
import useDelayCallback from "../../../../hooks/useDelayCallback";
import {icons} from "./icon";
import {cleanContactsActionsApi} from "../../../../api/cleanDataApi";

const EachTag = (props) => {

    const checkSelect = (id) => {
        return !!props.selectedTags.includes(id);
    }

    const renderSelectBox = (item) => {
        const flag = checkSelect(item.id)

        return (
            <div className="custom-checkbox-wrapper">
                <span className="custom-checkbox-icon" onClick={() => props.selectTag(item.id)} key={`id_${item.id};`}>
                    {flag ? icons.check : icons.unCheck}
                </span>
                <span>{props.data.name}</span>
            </div>
        );
    }

    return (
        <li>
            {renderSelectBox(props.data)}
        </li>
    )
}

const ModalAssignToTagNew = (props) => {
    const [tagInfo, setTagInfo] = useState({
        error: false,
        success: false,
        errorMsg: "",
        successMsg: "",
        allTags: [],
        allTagsToShow: [],
        storedTagList: [],
        submittingFile: false,
        submitEnable: false,
        selectedTags: [],
        submittingContact: false,
        createNew: false,
        searchKey: null,
        new: false,
        contactTags: [],
        newTagName: "",
        saveButtonText: "Assign Tag",
    })

    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [hasMore, setHasMore] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [search, setSearch] = useState("");
    const [list, setList] = useState([]);

    
    useDelayCallback(() => {
        let perPageCountCal = 50;
        if(perPage === 50){
            perPageCountCal = (Math.round((document.body.clientHeight - 300) / 40) + 5)
            setPerPage(perPageCountCal)
        }else{
            perPageCountCal = perPage
        }
        getAllTagsForDropdown(perPageCountCal);  
    }, [search])

    const getAllTagsForDropdown = (page = perPage) => {
        setIsLoading(true)
        getUserTagListNew({
            per_page: page,
            current_page:currentPage,
            search:search
        }).then((response)=>{
            let res = response.data
            if(currentPage === 1){
                if(res.length === 0){
                    setTagInfo({
                        ...tagInfo,
                        createNew:true,
                        newTagName:search
                    })
                }
                setList(res)
            }else{
                let all_data = [...list]
                all_data = [...all_data, ...res]
                setTagInfo({
                    ...tagInfo,
                    createNew:false,
                    newTagName:""
                })
                setList(all_data)
            }

            if(res.length === page){
                setCurrentPage(parseInt((currentPage + 1)))
                setHasMore(true)
            }else{
                setHasMore(false)
            }
            setLoadingMore(false)
            setIsLoading(false)
        }).catch(error => {
            setLoadingMore(false)
            setIsLoading(false)
        })
    }


   const  handleOnScrollLoad = (e) =>{
        if(hasMore && !loadingMore){
            if(Math.round((e.target.scrollTop + e.target.clientHeight), 10) >= Math.round(e.target.scrollHeight, 10) ){
                setLoadingMore(true)
                getAllTagsForDropdown(perPage);
            }
        } 
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (tagInfo.submittingContact){
            return false;
        }

        if ((!tagInfo.createNew && tagInfo.selectedTags.length === 0)){
            window.showNotification("WARNING", "Please select any tag", 5000)
            return false
        }

        const payload = {
            cleanContactIds: props.selectedFileContactIds,
            filterType: props.selectedFileContactIds[0] ? "SEGMENT" : "ALL",
            action: "ASSIGN_TAG",
            tagIds: tagInfo.selectedTags
        };

        if (props.filter){
            if (props.filter.invalidNumbers){
                payload.numberStatus = "INVALID";
                payload.filterType = "SEGMENT";
            }else if (props.filter.validNumbers){
                payload.numberStatus = "VALID";
                payload.filterType = "SEGMENT";
            }

            if (props.filter.invalidEmails){
                payload.emailStatus = "INVALID";
                payload.filterType = "SEGMENT";
            }else if (props.filter.validEmails){
                payload.emailStatus = "VALID";
                payload.filterType = "SEGMENT";
            }
        }

        setIsLoading(true)
        cleanContactsActionsApi(payload).then((res)=>{
            if (res && res.success){
                window.showNotification("success", res.message);
                props.onClose();
            }else {
                window.showNotification("error", "Something went wrong try again later");
            }
        }).finally(()=>{
            setIsLoading(false)
        });
    };

    const toggle = (update = false) => {
        setTagInfo({
            ...tagInfo,
            isOpen: !tagInfo.isOpen
        });
    };

    const handleSelectCheckbox = (value) => {
        let allTags = tagInfo.selectedTags;
        if (allTags.includes(value)) {
            allTags.splice(allTags.indexOf(value), 1)
        } else {
            allTags.push(value)
        }

        setTagInfo({
            ...tagInfo,
            selectedTags: allTags,
            submitEnable: (allTags.length > 0)
        })
    }

    const changeSearch = (e) => {
        let searchKey = e.target.value.toUpperCase().trim();
        setCurrentPage(1);
        setSearch(searchKey)
        setTagInfo({
            ...tagInfo,
            createNew:false
        })
    };

    const runningList = () => {
        let runList = [];
        list.forEach((data, index) => {
            runList.push(<EachTag selectedTags={tagInfo.selectedTags} selectTag={handleSelectCheckbox} data={data} key={index} />)
        })

        return runList
    }

    return (
        <GlobalModal {...props}
            title="Assign Tag"
            buttonText="Add"
            buttonIcon={<Save />}
            onSubmit={handleSubmit}
            hideFooter={tagInfo.createNew ? false : false}
            className="manage-tag-modal modal-assign-campaign global-small-modal">
            <div className="row">
                <div className="col-md-12">
                    <div className="search-bar-top-area2 search-bar-top-area">
                        <form action="">
                            <label htmlFor="" className="mb-2">Type to search/create tag</label>
                            <div className="search_input_wrapper search_input_wrapper__for__relatiive ">
                                <span className="search-icon">
                                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                    </svg>
                                </span>
                                <input type="search" className="ad__custom__input custom-popup-search input-color-black" placeholder="Search tag" onChange={changeSearch} />
                                {
                                    isLoading &&  <span className="assign__tag__search__loader"> <CircularProgress size={16} /></span>
                                }
                               
                            </div>
                        </form>
                    </div>
                    <div className="campaign-search-list">
                        <div className="campaign-search-results">
                             <div className="all__tags__to__show__area m-nav awesome__scroll_bar update__list_item" onScroll={handleOnScrollLoad} >
                                <ul>
                                    {runningList()}
                                </ul>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </GlobalModal>
    )

}
export default ModalAssignToTagNew;
