import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { Component } from "react";
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from "react-redux";
import styled from 'styled-components';
import { reloadNoteList, toggleNoteModal } from "../../../../actions/contactAction";
import { addNote, getAllTeamUsers } from '../../../../api/contactApi';
import GlobalModal from "../../../globals/Modal/GlobalModal";
import defaultStyle from './defaultStyle';
import './note.css';

const mentionStyle = {
    backgroundColor: '#cee4e5',
};


const Wrapper = styled.div`
    margin: 10px;
    max-width: 500px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
`;

class NoteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitingNote: false,
            note: '',
            mentionedUser: [],
            mentionedUserId: [],
            allTeamUser:[{id:1,display:'a'},{id:2,display:'b'}]
        }
    }

    changeNote = (event)=> {
        this.setState({
            note: (event.target.value) ? event.target.value : ''
        })
    };
    handleNoteSubmit = () => {
        this.setState({
            submitingNote: true
        });


        addNote({
            note: this.state.note,
            contact_id: this.props.contact.id,
            origin: 4
        }).then(res=>{
            if(res.data.status === 'success'){
                window.showNotification("SUCCESS","Add Note Successfully")
                this.props.onClose()
                this.props.reloadNeedForNoteList(true)
            }
        })

    };
    loadTeamUserList = () => {
        this.setState({
            loading: true
        });

        getAllTeamUsers({}).then(res=>{
            if(res.data.users){
                let allTeamUser=res.data.users
              let  users = allTeamUser.map((data) => {
                    return {
                        id: data.value,
                        display: data.label,
                    };
                });
                this.setState({
                    allTeamUser: users,
                    loading: false
                })
            }

        })

        // HTTP.post("/user/group/user/list").then(res => {
        //     if(res.data.status === undefined) {
        //         let allTeamUser = res.data;
        //         let users = null;
        //         if (res.data) {
        //             users = allTeamUser.map((data) => {
        //                 return {
        //                     id: data.id,
        //                     display: data.full_name,
        //                 };
        //             });
        //         }
        //         this.setState({
        //             allTeamUser: users,
        //             loading: false
        //         })
        //     }else{
        //         this.setState({
        //             loading: false
        //         })
        //     }
        //
        //
        // });
    };


    handlePersonalize = (event)=>{

        var start = this.myTextInput.selectionStart
        var end = this.myTextInput.selectionEnd
        var text = this.myTextInput.value
        var before = text.substring(0, start)
        var after  = text.substring(end, text.length)
        var newText = (before + event.target.value + after)
        this.myTextInput.value = newText;
        this.myTextInput.selectionStart = this.myTextInput.selectionEnd = start + newText.length
        this.myTextInput.focus();
    }
    componentDidMount(){
        this.loadTeamUserList();
    }
    addMention = (id, display) =>{
        // this code is commented becasue there are no onRemove functionality on React-mention plugin
        // if(this.state.mentionedUserId.indexOf(id) === -1){
        //     let name = display.split(" ");
        //     let joined = this.state.mentionedUser.concat(<div key={id} className="user__pic"><span>{nameInitialFormatter(name[0],name[1])}</span></div>);
        //     this.setState({
        //         mentionedUser: joined,
        //         mentionedUserId: this.state.mentionedUserId.concat(id),
        //     });
        // }
    };
    onRemove = (id, display) =>{

    };
    closeModal=()=>{
        this.props.toggleModal({status:false})
    }

    render(){
        return(
            <React.Fragment>
                {/* <Drawer
                    anchor={'right'}
                    open={this.props.showModal}
                    onClose={()=>this.closeModal()}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Wrapper className={"manage-tag-modal create-collaborate-modal"}> */}

                    <GlobalModal {...this.props}
                    // open={props.open}
                    title={"Note"}
                    buttonText={"Save"}
                    buttonIcon={ <AddIcon/> }
                    onSubmit={()=>this.handleNoteSubmit()}
                    hideFooter={false}
                    // onClose={()=>this.closeModal()}
                    modalClass={'manage-tag-modal create-collaborate-modal update singleContactNoteModal' }>
                        <Grid container>
                            {/* <Grid item xs={12}>
                                <Header className={"p-4"}>
                                    <h5>Note</h5>
                                    <div class="modal-close cross-icon d-flex justify-content-center align-items-center" onClick={()=>this.closeModal()}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1262 17.1368L17.1367 15.125L6.87897 4.86052L4.86845 6.87237L15.1262 17.1368Z" fill="white"></path>
                                            <path d="M6.87898 17.1395L17.1367 6.875L15.1262 4.86315L4.86845 15.1276L6.87898 17.1395Z" fill="white"></path>
                                        </svg>
                                    </div>
                                </Header>
                            </Grid> */}
                            <Grid item xs={12}>
                                {/* <form onSubmit={this.handleNoteSubmit} className="m-form"> */}
                                    <div className="modal-content p-0">
                                        <div className="chat__bottom_wrapper update">
                                            <div className="tiny-mce">
                                                <MentionsInput
                                                    cols={30}
                                                    rows={5}
                                                    className="control-overflow mentionClass"
                                                    // classNames={'mentionClass'}
                                                    value={this.state.note}
                                                    onChange={(e)=>this.changeNote(e)}
                                                    style={defaultStyle}
                                                    markup="@[__display__](__id__) "
                                                    placeholder={" Add notes and mentions (@username)"}
                                                >
                                                    <Mention
                                                        type="user"
                                                        trigger="@"
                                                        data={this.state.allTeamUser}
                                                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                            <div className="user">{highlightedDisplay}</div>
                                                        )}
                                                        onAdd={this.addMention}
                                                        onRemove={this.onRemove}
                                                        style={mentionStyle}
                                                    />
                                                </MentionsInput>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="modal-footer mb-4">
                                        <div className="modal_footer_buttons text-center">
                                            <Button type={'submit'}
                                            className="d-inline-flex align-items-center bg-light-blue white-text p-3 radius-10">
                                                <i className="material-icons text-light-blue white circle p-1 mr-2">
                                                    add
                                                </i>
                                                Add Note
                                            </Button>
                                            <a href="javascript:void(0)" onClick={()=>this.closeModal()}
                                            className="d-inline-flex align-items-center bg-red white-text p-3 radius-10 modal-close ml-3">
                                                <i className="material-icons text-red white circle p-1 mr-2" >
                                                    close
                                                </i>
                                                Close
                                            </a>
                                        </div>
                                    </div> */}
                                {/* </form> */}
                            </Grid>
                        </Grid>
                        </GlobalModal>
                    {/* </Wrapper>
                    
                </Drawer>*/}
            </React.Fragment> 
        )
    }
}

const mapStateToProps = state => {
    return {
        contact: state.contactReducer.contact,
        showModal: state.contactReducer.showNoteModal,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        reloadNeedForNoteList: (params) => dispatch(reloadNoteList(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteComponent);