import { FormControl, Grid, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { Save } from '@material-ui/icons';
import dateFnsFormat from 'date-fns/format';
import moment from 'moment';
import 'moment-timezone';
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import SimpleReactValidator from 'simple-react-validator';
import styled from 'styled-components';
import { DropdownHelper } from '../../../globals/DropdownHelper';
import GlobalModal from '../Helpers/Modal/GlobalModal';
import { getStageList, updateDeal } from '../redux/contactApi';

import './RightSideModalEdit.css'

class RightSideModalEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deal_id : props.edit_deal.id,
            deal:props.edit_deal.deal_value,
            title:props.edit_deal.title,
            edit_deal:props.edit_deal,
            contact: false,
            startDate : props.startDate,
            contact_id : props.contact_id,
            stages: [],
            pipeline: '',
            pipeline_id: 0,
            selectedPipeline: props.edit_deal.stage.pipeline_id,
            pipelines : [],
            percentage: [],
            Allpipeline : [],
            selectedStage : props.edit_deal.stage.id,
            submitForm : false,
            selectedStageTitle: '',
            // estimate_closing_date: props.estimate_closing_date?props.estimate_closing_date:props.startDate,
            estimate_closing_date: null,
            selected_action: null,
        };
        this.validator = new SimpleReactValidator();
    }

    handleSubmit = (event)  => {

        event.preventDefault();

        if(this.state.selectedStage === 0 ||  this.state.selectedStage === ''){
            window.showNotification('ERRROR','No Stage selected')
            return ;
        }

        if( this.validator.allValid() ) {

            this.setState({
                submitForm : true
            })
            var myEstimateClosingDate = moment(this.state.estimate_closing_date);

            if (myEstimateClosingDate.isValid()) {
                myEstimateClosingDate = myEstimateClosingDate.format('YYYY-MM-DD');
            } else {
                myEstimateClosingDate = null;
            }

            updateDeal({
                id : this.state.deal_id,
                closing_date : myEstimateClosingDate,
                contact_id : this.state.contact_id,
                deal : this.state.deal,
                title : this.state.title,
                stage : this.state.selectedStage,
                createNewContact : false,
                stage_id : this.state.deal_id,
                pipeline_id : this.props.edit_deal.stage.pipeline_id,
                old_stage_id : this.props.edit_deal.stage.id,
                status : this.state.selected_action,

            } ).then(res => {
                this.setState({
                    submitForm : false
                })
                if(res.data.status === 'error'){

                } else{
                    window.showNotification('SUCCESS', 'Deal Updated!')
                    this.props.onModalFormSubmit({
                        'update':true,
                        'pipeline_id':this.state.selectedPipeline,
                        'stage_id':this.state.selectedStage,
                        'selectedStageTitle':this.state.selectedStageTitle,
                        'deal_value':this.state.deal,
                        'estimate_closing_date':this.state.estimate_closing_date
                    });
                    this.props.toggle(true);
                }
            });
        }else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    getSelectedContact = (param) => {
        if(param == null){
            this.setState({
                selectContact : false,
                contact: false
            })
        }else{
            this.setState({
                selectContact : true,
                contactId: param.id,
                contact: true
            })
        }
    }

    selectOption = () => {
        var lists = this.state.stages.map(function (item, index) {
            return  <option key={index} value={item['id']} >
                {item['stage']}
            </option>
        }.bind(this));
        return lists;
    }

    toggleRightSideModalEdit = () => {
        this.props.toggle(false);
    }
    handleChangeSelectStage = (e) => {
        this.setState({ stageId: e.target.value });
    }

    handleDayChange = (date) =>  {
        this.setState({ estimate_closing_date: date });
    }

    componentWillReceiveProps(nestProps){
        this.setState({
            isOpen: nestProps.isOpen,
            contact_id: nestProps.contact_id,
            modalShow: "show",
            popOpacity: "pop-opacity"
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    loadContent=()=>{
        getStageList(
            {
                pipeline_id: this.state.selectedPipeline
            })
            .then(
                response  => {
                    var stage = response.data['stages'];
                    var pipeline = response.data['pipeline'];
                    var pipelines = response.data['pipelines'];
                    var Allpipeline = response.data['Allpipeline'];
                    var pipelineId = response.data['pipelineId'];

                    if(pipelineId != this.props.edit_deal.stage.pipeline_id){

                        if(stage[0]){
                            this.setState((st)=>({
                                stages:stage,
                                pipeline: pipeline.title,
                                pipelines: pipelines,
                                Allpipeline : Allpipeline,
                                selectedPipeline : pipelineId,
                                data: true,
                                selectedStage: stage[0].id,
                                selectedStageTitle: stage[0].stage
                            }))
                        }else{
                            this.setState((st)=>({
                                stages:stage,
                                pipeline: pipeline.title,
                                pipelines: pipelines,
                                Allpipeline : Allpipeline,
                                selectedPipeline : pipelineId,
                                data: true,
                                selectedStage: '',
                                selectedStageTitle: ''
                            }))
                        }
                        
                    }else{
                        this.setState((st)=>({
                            stages:stage,
                            pipeline: pipeline.title,
                            pipelines: pipelines,
                            Allpipeline : Allpipeline,
                            selectedPipeline : pipelineId,
                            data: true,
                            selectedStage: this.props.edit_deal.stage.id,
                        }))
                    }
                    
                    // if(this.state.selectedPipeline != this.props.edit_deal.stage.pipeline_id){
                    //     if(stage[0]){
                    //         this.setState({selectedStage: stage[0].id, selectedStageTitle : stage[0].stage})
                    //     }else{
                    //         this.setState({selectedStage: '', selectedStageTitle : ''})
                    //     }
                    // }else{
                    //     this.setState({selectedStage: this.props.edit_deal.stage.id})
                    // }

                    if(stage.length > 0){
                        this.setState((st)=>({
                            dataFound: true
                        }))
                    }else{
                        this.setState((st)=>({
                            dataFound: false
                        }))
                    }
                }
            );
    }

    selectOptionsval = (value) =>{
        if(value != null){
            this.setState({
                selectedPipeline : value
            }, () => {
                this.setState({stages : [], selectedStage : '' })
                this.loadContent()
            })
        }
    }

    selectStageval = (value) =>{

        let selectedStageTitle=''
        for(let j=0;j<this.state.stages.length;j++){
            if(this.state.stages[j].id === value){
                selectedStageTitle = this.state.stages[j].stage
                break;
            }
        }
        this.setState({
            selectedStage : value,
            selectedStageTitle : selectedStageTitle
        });
    }

    componentDidMount(){
        this.loadContent();
        var estimate_closing_date = this.props.edit_deal.estimate_closing_date;
        if(estimate_closing_date){
            estimate_closing_date = new Date(estimate_closing_date);
        }
        this.setState({
            modalShow: "show",
            popOpacity: "pop-opacity",
            estimate_closing_date : estimate_closing_date,
        });
    }

    formatDate = (date, format, locale) =>  {
        return dateFnsFormat(date, format, { locale });
    }

    dealMarkAsWinLose = (contactStageId, status) => {
        let action = (status==this.state.selected_action)?null:status;
        this.setState({selected_action:action});
        /*confirmAlert({
            title: 'Alert!',
            message: status == 2 ? 'Close this deal as win?' : 'Close this deal as lost?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.post(Config.serverURL + '/user/status-change-deals',
                        {
                            stage_id : contactStageId,
                            status : status,
                        })
                        .then(
                            response  => {
                                location.reload();
                            }
                        );
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false;
                    }
                }
            ]
        });*/
    }

    changeSelectedStage = (contactStageId) => {
        this.setState({selectedStage:contactStageId});
    }

    render() {

        this.validator.purgeFields();

        // let pipelineOptions = [];

        // this.state.Allpipeline.forEach((value, index) => {
        //     pipelineOptions.push(<MenuItem key={index} value={value.id}>{value.title}</MenuItem>);
        // });

        // let stageOptions = [];

        // this.state.stages.forEach((value, index) => {
        //     stageOptions.push( <MenuItem key={index} value={value.id} >{value.stage}</MenuItem>);
        // });



        return (
                <GlobalModal {...this.props} 
                    title={"Edit Deal"}
                    buttonText={"Edit Deal"}
                    buttonIcon={<Save/>}
                    onSubmit={this.handleSubmit}
                    hideFooter={false}
                    className={'inbox__madal__top__wrapper global-small-modal' }>

                        <div className="indox__modal__body__container">
                            <div className="d-flex justify-content-between">
                                <div className="flext__col__alt">
                                    <h3 className="inbox__modal_title__alt">Title</h3>
                                    <input type="text" onChange={this.handleChange} placeholder="Title" value={this.state.title} name='title' className="inbox__modal_text__field__alt" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="flext__col__alt__extra">
                                    <h3 className="inbox__modal_title__alt modal__title__margin__top">Select Pipeline</h3>
                                    <FormControl variant="outlined" className="inbox__input__from__control__wr">
                                        {/* <Select
                                            className="inbox__input__from__control"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.selectedPipeline}
                                            onChange={this.selectOptionsval}
                                        >
                                            {pipelineOptions}
                                        </Select> */}
                                        <DropdownHelper
                                            datalist={this.state.Allpipeline}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select Pipeline"
                                            className=""
                                            mapping={{ title: "title" }}
                                            menuItemClassName=""
                                            updateSelectedData={this.selectOptionsval}
                                            selectedValue={this.state.selectedPipeline}
                                            // updateDatatable={updateDatatable}
                                            // dropdownHeader={'campaignChange'}

                                        />
                                    </FormControl>
                                </div>
                                <div className="flext__col__alt__extra">
                                    <h3 className="inbox__modal_title__alt modal__title__margin__top">Select Stage</h3>
                                    <FormControl variant="outlined" className="inbox__input__from__control__wr">
                                        {/* <Select
                                            className="inbox__input__from__control"
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={this.state.selectedStage}
                                            onChange={this.selectStageval}
                                        >
                                            <MenuItem value="">
                                                <em>Select Stage</em>
                                            </MenuItem>
                                            {stageOptions}
                                        </Select> */}
                                        <DropdownHelper
                                            datalist={this.state.stages}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select Stage"
                                            className=""
                                            mapping={{ title: "stage" }}
                                            menuItemClassName=""
                                            updateSelectedData={this.selectStageval}
                                            selectedValue={this.state.selectedStage}
                                            // updateDatatable={updateDatatable}
                                            // dropdownHeader={'campaignChange'}

                                        />
                                    </FormControl>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <div className="flext__col__alt__extra">
                                    <h3 className="inbox__modal_title__alt modal__title__margin__top">Deal Value</h3>
                                    <input onChange={this.handleChange} type={'number'} min={0} placeholder={"Deal value"} value={this.state.deal} name='deal' className="inbox__modal_text__field__alt" />
                                </div>
                                <div className="flext__col__alt__extra">
                                    <h3 className="inbox__modal_title__alt modal__title__margin__top"> Estimate Close Date </h3>
                                    <DayPickerInput
                                        style={{width: '100%'}}
                                        formatDate={this.formatDate}
                                        format={'MM/dd/yyyy'}
                                        name="closeDate"
                                        value={this.state.estimate_closing_date}
                                        onDayChange={this.handleDayChange}
                                        placeholder="MM/DD/YYYY"
                                        selected={null}
                                    />
                                </div>
                            </div>
                        </div>
                </GlobalModal>
        );
    }
}
export default RightSideModalEdit;