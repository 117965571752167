import React, {Component} from 'react';
import {connect} from "react-redux";
import If, { Else } from 'if-else-react';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class SelectContactEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key:this.props.name?this.props.name:'',
            selectValue:''
        };
        this.updateSelectValue = this.updateSelectValue.bind(this)
    }

    componentDidMount(){
        this.updateSelectValue()
    }

    updateSelectValue(){
        if(this.props.data && this.props.data !== undefined && this.props.data.length>0 && this.props.data[0]['id'] && this.props.formType !==undefined && this.props.formType == "add"){
            this.setState({
                selectValue: this.props.data[0]['id']
            })
        }
        if(this.props.data && this.props.data !== undefined && this.props.data.length>0 && this.props.data[0]['id'] && this.props.formType !==undefined && this.props.formType == "edit"){
            this.setState({
                selectValue: this.props.value
            })
        }
        
    }

   fieldValueChange = e =>{
       this.props.onTypeChange(e)
       this.setState({
           selectValue : e.target.value
       })
   }

    updateMode = e =>{
        e.preventDefault()

    }
    render() {
        const {label,name,data,value}=this.props;
        console.log(this.state.selectValue,"data")
        return (
            <React.Fragment>
                <h6>{label}</h6>
                <div className="text-field-item lead__status__select addtional__email__select__type">
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name={name}
                            value={this.state.selectValue}
                            onChange={(e) => this.fieldValueChange(e)}>
                            {
                                data && data.length>0 && data.map((item,index)=>{
                                    return <MenuItem key={index} value={item["id"]}>{item["relation_type"]}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            </React.Fragment>
        );
    }
}

export default SelectContactEmail;