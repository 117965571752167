import Select from "react-select";
import { groupBadgeStyles, groupStyles } from "../../../../constants/CoreConstants";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const ActionNeededTable = (props) => {

    const renderTableRow = () => {

		return props.rows.map((row,index) => {
			return (<tr key={index}>
				<td>												
					<div className="d-flex justify-content-between first_tdata">
						<div className="d-flex align-items-center">
							<div className="imported_map_details">
								{
									row.fields.map((element,index) => {
										if(index === 0) {
											return <h6 key={index}>{element}</h6>
										} else {
											return <p key={index}>{element}</p>
										}
									})
								}
							</div>
						</div>	
						
						<div className="d-flex align-items-start">
							<div className="d-flex justify-content-center align-items-center">
                                <BootstrapTooltip arrow title="Change the dropdown beside to map the field">
                                    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm0 14.546A6.553 6.553 0 0 1 1.455 8 6.553 6.553 0 0 1 8 1.455 6.553 6.553 0 0 1 14.546 8 6.553 6.553 0 0 1 8 14.546Z" fill="#133159"/><path d="M8 3.401a.97.97 0 0 0 0 1.94.97.97 0 0 0 0-1.94ZM7.999 6.783a.727.727 0 0 0-.728.727v4.364a.727.727 0 1 0 1.455 0V7.51a.727.727 0 0 0-.727-.727Z" fill="#133159"/></svg>
                                </BootstrapTooltip>
								<div className="input-field">
									<Select
										key={row.index}
										name="fields"
										value={ { value: "", label: "Do Not Upload" } }
										options={props.selectOptions}
										onChange={(e) => props.onOptionChange(e,index)}
										className="file-import-header"
										formatGroupLabel = { formatGroupLabel}
                                        menuPortalTarget={document.querySelector("body")}
									/>
								</div>
							</div>
						</div>
					</div>
				</td>
				<td></td>
				<td></td>
			</tr>)
		});
	}

    return ( 
        <div className="imported_table mapped_table awesome__scroll_bar">
            <table>
                <thead>
                    <tr>
                        <th>
                            <div className="imported_heading imported_heading_one">
                                <h6>Map Column to Identifier</h6>
                                <div className="heading_icon">
                                    <BootstrapTooltip arrow title="Columns need to be mapped">
                                        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm0 14.546A6.553 6.553 0 0 1 1.455 8 6.553 6.553 0 0 1 8 1.455 6.553 6.553 0 0 1 14.546 8 6.553 6.553 0 0 1 8 14.546Z" fill="#133159"/><path d="M8 3.401a.97.97 0 0 0 0 1.94.97.97 0 0 0 0-1.94ZM7.999 6.783a.727.727 0 0 0-.728.727v4.364a.727.727 0 1 0 1.455 0V7.51a.727.727 0 0 0-.727-.727Z" fill="#133159"/></svg>
                                    </BootstrapTooltip>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="imported_heading imported_heading_two">
                                <h6>Formatting Guidelines</h6>
                            </div>
                        </th>
                        <th>
                            <div className="imported_heading imported_heading_three">
                                <h6>Example</h6>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRow()}
                </tbody>
            </table>
        </div>
     );
}
 
export default ActionNeededTable;