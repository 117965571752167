import React from 'react';
import socketIOClient from "socket.io-client";
import If from 'if-else-react';
import { contactImport } from '../../api/contactApi';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import StepProgressBar from './StepProgressBar';
import Loading from '../common/Loading';
import Utils from '../../helpers/Utils';

const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}

class ProcessLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validationChecked: false,
            newCustomsData: [],
            file:null,
            total : 0,
            current : 0,
            progressBarOpen : false,
            success : 0,
            failed : 0,
            isExistFailed : 0,
            totalUpload : 0,
            validateFailed : 0,
            fileStatus:''
        }

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.loadSocket = this.loadSocket.bind(this);
    }

    fileStatusChange = (data) =>
    {
        this.setState({
            isExistFailed : data.isExistFailed,
            totalUpload : data.totalUpload,
            validateFailed : data.validateFailed,
            current: parseInt(data.isExistFailed) + parseInt(data.totalUpload) +  parseInt(data.validateFailed),
            fileStatus: data.message,
        },() => {
            if(data.message=== "success"){
                this.props.handleChange("isExistFailed", this.state.isExistFailed);
                this.props.handleChange("totalUpload", this.state.totalUpload);
                this.props.handleChange("validateFailed", this.state.validateFailed);
                this.props.nextStep()
            }

        });
    }

    loadSocket = () =>
    {
            const socket = socketIOClient(this.props.nodeServer);
            socket.emit('subscribe-for-upload', this.props.userId);
            socket.on('upload-notification', data => this.fileStatusChange(data.params));

            this.setState({ progressBarOpen : true });
            this.onFormSubmit().then((response)=>{
                this.setState({
                    fileStatus: "File upload finished. Please wait while we process leads from file"
                });
                if(response.data.success) {
                    Utils.showNotification(response.data.message, 'SUCCESS');
                } else {
                    Utils.showNotification(response.data.message, 'ERROR');
                }                    
                this.props.refreshCSVData();
                this.props.setStepShowList();
            }).catch((err) => {
                console.log(err)
            });

    }

    onFormSubmit = () =>
    {
        const {file, newCustomsData,newTags, oldTags, existing_contact_rule,do_create_deal,pipeline_id,stage_id,batch_form,source_id, validate_contacts } = this.props.state;
        const formData = new FormData();
        formData.append('file',file);
        formData.append('existing_contact_rule', existing_contact_rule);
        formData.append('do_create_deal', do_create_deal);
        formData.append('pipeline_id', pipeline_id == 0 ? null : pipeline_id);
        formData.append('stage_id', stage_id == 0 ? null : stage_id);
        formData.append('source_id',source_id);
        if (validate_contacts){
            formData.append('validate_contacts',JSON.stringify(validate_contacts));
        }
        if (typeof this.props.campaignId !== 'undefined' ) {
            if(Object.keys(batch_form).length !== 0){
                batch_form['campaign_id'] = this.props.campaignId;
            }
            formData.append('campaignId',this.props.campaignId);
            formData.append('fromCampaign','yes');
        }

        if (typeof this.props.powerDialerId !== 'undefined' ) {
            if(Object.keys(batch_form).length !== 0){
                batch_form['power_dialer_list_id'] = this.props.powerDialerId;
            }
            formData.append('powerDialerListId',this.props.powerDialerId);
            formData.append('fromPowerDialer','yes');
        }

        formData.append('batch_form',JSON.stringify(batch_form));
        var data = this.props.state.submitData;
        for (const [key, value] of Object.entries(data)) {
            formData.append(value.map, value.data);
        }

        if(oldTags != undefined){
            for (var i = 0; i < oldTags.length; i++) {
                formData.append('oldTags' + '[' + i + ']'+'[id]', oldTags[i]['id']);
                formData.append('oldTags' + '[' + i + ']'+'[name]', oldTags[i]['name']);
            }
        }

        if(newTags != undefined){
            formData.append('newTags', newTags);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return  contactImport(formData,config);
    }

    componentDidMount(){
        this.loadSocket();
    }

    render()
    {
        return (
            <React.Fragment>
                <div className="import_content_container">
                    <div className="main_content_inner_container white">
                        <div className="main_content_topbar">
                            <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                                <HeaderRight />
                            </div>
                        </div>

                        <div class="divider main_content_container_divider"></div>

                        <div class="main_content_main_section">
                            <StepProgressBar fromCampaign={this.props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_SUCCESS}/>
                        </div>

                        <div class="content_divider"><div class="content_divider_inner"></div></div>


                        <div className="m-main_contain_detail_section">
                            <div className="import-uploading-process text-center mt-5">
                                <Loading/>
                                <div className="import-uploading-title pb-3 mt-5">
{/*
                                    <h5>Uploading { (this.state.current <= 0) ? 0 : this.state.current -1   } contact  from { this.props.state.total }</h5>
*/}
                                    <h5>Uploading...</h5>
                                </div>
                                {/*<div className="title">
                                    <h5>Current Status: {this.state.fileStatus}</h5>
                                </div>*/}
                                {/*<div className="import-complete-list d-inline-block pt-3">
                                    <ul >
                                        <li>Added Leads: <span><strong>{ this.state.totalUpload }</strong></span></li>
                                        <li>Updated Leads: <span><strong>{ this.state.isExistFailed}</strong></span></li>
                                        <li>Duplicate Leads: <span><strong> { (parseInt(this.state.validateFailed) <= 0) ? 0 : parseInt(this.state.validateFailed) - 1 }</strong></span></li>
                                    </ul>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


export default ProcessLoader
