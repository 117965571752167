import React, {useState} from 'react';
import {connect} from 'react-redux'
import { useSelector, useDispatch } from 'react-redux';
import ActivityModal from '../../rightSide/task/ActivityModal';
import ActivityListItem from './ActivityListItem';
import ActivityListLoading from './ActivityListLoading';
import ActivityTopPanel from './ActivityTopPanel';
import NotFoundActivity from './NotFoundActivity';

import { updateTaskFlag } from "../../../../actions/calendarAction";
import { fetchTasks } from '../../rightSide/redux/contactAction';


const ActivityList = (props) => {

    const activityCalendar = useSelector(state => state.calendarReducer.activityCalendar);
    const activityLoading = useSelector(state => state.calendarReducer.activityLoading);
    const dispatch = useDispatch();

    /* update activity */
    const [showActivityModal, setShowActivityModal] = useState(false)
    const [editData, setEditData] = useState(null)
    const [editId, setEditId] = useState(null)

    const handleTaskUpdate = (id, data) => {
        setEditId(id);
        setEditData(data);
        setShowActivityModal(true);
    }

    const toggleTaskModal = (updateTask = true) => {
        let modalStatus = showActivityModal;
        setShowActivityModal(!modalStatus);
        if(modalStatus){
            setEditId(null);
            setEditData(null);
            if(updateTask){
                dispatch(updateTaskFlag(true))
            }
        }
    };

    return (
        <div id="activities_tab" className="col s12 white awesome__scroll_bar activities_tab active" style={{display: "block"}}>
            <ActivityTopPanel contactId={props.contactId} setShowActivityModal={setShowActivityModal} setEditData={setEditData}/> 
            <table className="activities_items_table">
                <thead className="bg-gray-light py-1">
                    <tr className="px-1">
                        <td>
                            <label>
                                <input type="checkbox" className="filled-in" />
                            </label>
                        </td>
                        <td>
                            <h5 className="m-0 text-dark-blue">Title</h5>
                        </td>
                        <td>
                            <h5 className="m-0 text-dark-blue">Due Date</h5>
                        </td>
                        <td>
                            <h5 className="m-0 text-dark-blue">Duration</h5>
                        </td>
                        <td>
                            <label>
                                <span className="text-dark-blue">Type</span>
                            </label>
                        </td>
                        
                    </tr>
                </thead>
                <tbody className="awesome__scroll_bar">
                    {
                        activityLoading ? <ActivityListLoading/> :activityCalendar.length === 0 ? <NotFoundActivity/> : 
                        activityCalendar.map((item, i) => {
                            return <ActivityListItem
                                    item={item} key={i}
                                    handleTaskUpdate={handleTaskUpdate}
                                    updateTaskFlag={()=>props.updateTaskFlag(!props.updateTask)}
                                />
                        })
                    }
                </tbody>
            </table>
        
        
            {/* for task edit */}
            {showActivityModal &&
                <ActivityModal
                    openActivityModal={showActivityModal}
                    setOpenActivityModal={toggleTaskModal}
                    showAll={editData !=null ? editData.taskId == 2 ? false : true : true}
                    slotInfo={editData !=null ? editData.taskId == 2 ? {"taskId": 2} : [] : []}
                    fetchTask={() => props.fetchTasks({
                        contact_id : props.contactId
                    })} 
                    people={props.contact}
                    from={"contactDetails-activity-tab"}
                    editData={editData}
                />
            }
        </div>
    );
};
const mapStateToProps = state => {
    return {
        allActivityList: state.rightsideReducer.allActivityList,
        updateTask: state.calendarReducer.updateTask,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTasks: (params,callBack) => dispatch(fetchTasks(params,callBack)),
        updateTaskFlag: (params) => dispatch(updateTaskFlag(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);
