import React, { useCallback, useState } from 'react';
import StepProgressBar from './StepProgressBar';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import BottomButtons from './BottomButtons';


const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}


const ContactImportRule = props => {

    const [existingContactRule,setExistingContactRule] = useState(props.state.existing_contact_rule);

    const saveAndContinue = useCallback((e) => {
        e.preventDefault()
        props.handleChange("existing_contact_rule", existingContactRule);
        props.nextStep()
    },[existingContactRule]);

    const prevStep = useCallback((e) => {
        e.preventDefault();
        props.prevStep();
    },[]);

    const handleChange = (e) => {
        setExistingContactRule(parseInt(e.target.value, 10));
    }

    return (
        <>
            <div className="import_content_container">
                <div className="main_content_inner_container white">
                    <div className="main_content_topbar">
                        <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                            <HeaderRight />
                        </div>
                    </div>
                    
                    <div class="divider main_content_container_divider"></div>

                    <div className="main_content_main_section">
                        <StepProgressBar fromCampaign={props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_IMPORT_RULE}/>
                    </div>
                        
                    <div class="content_divider"><div class="content_divider_inner"></div></div>

                    <div class="main_section_title_section">
                        <div>
                            <h6 class="center-align">Select Duplicate Strategy</h6>
                        </div>
                        <div>
                            <p class="center-align">Choose how you'd like to handle any duplicates.</p>
                        </div>
                    </div>

                    <div class="divider main_content_main_section_divider"></div>
                    
                    <div class="duplicate_rule_content_section">
                        <div class="duplicate_rule_content">
                            <form action="#" class="duplicate_rule_form">
                                <p class="duplicate_rule_content_title">If an existing contact is in the spreadsheet:</p>
                                <p class={`options ${existingContactRule === 1 ? 'duplicate_rule_checked' : ''}`}>
                                    <label>
                                        <input type="checkbox" value={1} name={'existing_contact_rule'} checked={existingContactRule === 1} onChange={handleChange} class="filled-in" />
                                        <span>Update them with the data in the document, but only for fields that are empty (recommended)</span>
                                    </label>
                                </p>
                                <p class={`options ${existingContactRule === 2 ? 'duplicate_rule_checked' : ''}`}>
                                    <label>
                                        <input type="checkbox" value={2} name={'existing_contact_rule'} onChange={handleChange} checked={existingContactRule === 2} class="filled-in"/>
                                        <span>Update them with the data in the spreadsheet, including fields that already have a value</span>
                                    </label>
                                </p>
                                <p class={`options ${existingContactRule === 3 ? 'duplicate_rule_checked' : ''}`}>
                                    <label>
                                        <input type="checkbox" value={3} name={'existing_contact_rule'} onChange={handleChange} checked={existingContactRule === 3} class="filled-in" />
                                        <span>Don't modify them</span>
                                    </label>
                                </p>
                            </form>
                        </div>

                        <BottomButtons 
                            onBackClick={prevStep} 
                            onContinueClick={saveAndContinue}
                            continueButtonText={'Save & Continue'}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactImportRule;
