import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react'
import './GlobalDropdown.css';

export default function GlobalDropdown(props) {
  const [ anchorActionMenu, setAnchorActionMenu ] = useState(null);
  
  let buttonChildren = [];
  
  let position = {};
  
  if(props.placement === undefined || props.placement === 'left'){
    position ={
      anchorOrigin:{
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin:{
        vertical: 'top',
        horizontal: 'right',
      }
    } 
  } else if(props.placement === 'bottom'){
    position ={
      getContentAnchorEl:null, 
      anchorOrigin:{
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin:{
        vertical: 'top',
        horizontal: 'center',
      }
    } 
  } else if(props.placement === 'bottomRight'){
    position ={
      getContentAnchorEl:null, 
      anchorOrigin:{
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin:{
        vertical: 'top',
        horizontal: 'left',
      }
    } 
  }

  const renderActionMenu = () => {

    let items = [];

    if(Array.isArray(props.children)){
      props.children.forEach((elem,index) => {

        const clickHandler = (e) => {
          if(elem.props.onClickHide !== undefined && elem.props.onClickHide) {
            setAnchorActionMenu(null);
          }
          elem.props.onClick(e)
        }
        if(elem.type != undefined && elem.type != null){
          if (elem.type.name === GlobalDropDownItem.name) {
            items.push(
              <MenuItem
                onClick={(e) => clickHandler(e)} 
                key={ ("action-menu-" + index) }>
                <ListItemIcon >
                  <span>
                    { elem.props.icon }
                  </span>
                </ListItemIcon>
                <ListItemText inset>
                  { elem.props.title }
                </ListItemText>
              </MenuItem>
            );
          } else if(elem != undefined){
            buttonChildren.push(elem);
          }
        }
        
      });

      return items;

    } else {
        buttonChildren = props.children;
    }
    return null;
  }

  return (
    <>
      <div>
        <div onClick={ (e) => setAnchorActionMenu(e.currentTarget) }>
          {buttonChildren}
        </div>


        <Menu
          className={`trigger__folderDropdown global__dropdown ${props.className !== undefined ? props.className : ''}`}
          id="action-menu"
          anchorEl={ anchorActionMenu }
          keepMounted
          open={ Boolean(anchorActionMenu) }
          onClose={ () => setAnchorActionMenu(null) }
          {...position}
        >
          { renderActionMenu() }
        </Menu>

      </div>
    </>
  )
}


export const GlobalDropDownButton = (props) => {
  return props.children;
}

export const GlobalDropDownItem = (props) => {
  return props.children;
}
