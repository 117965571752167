import React, { useState, useEffect } from 'react';
import { changeLeadOwner, getSubUserListForContactlist } from './redux/contactApi';
import NewLoader from './Helpers/NewLoader';
import Helper from './Helpers/Helper';
import Utils from './Helpers/Utils';
import { connect } from 'react-redux';
import { getSubUserList, setSubUserListFromAction } from './redux/contactAction';
import { DropdownHelper } from './task/DropdownHelper';
import { MenuItem, Button} from "@material-ui/core";
import NewConfirmAlert from "../../common/new-alert/NewConfirmAlert";
import {receiveBulkContactPermissionRequest, rejectContactRequest} from "../../../api/contactApi";
import {leadPermission} from "../../../constants/CoreConstants";

const LeadOwner = (props) => {
    // constructor() {
    //     super()

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [subUserList, setSubUserList] = useState(null)
    const [ownerInfo, setOwnerInfo] = useState({})
    useEffect(() => {

        if (props.contactDetails != null && props.contactDetails !== undefined && props.contactDetails.user_id !== undefined && props.contactDetails.user_id != null) {
            loadTeamUserList();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if (subUserList){
            let newOwner = subUserList.find((user)=>(user.id === props.contact.user_id));

            if (newOwner){
                setOwnerInfo(newOwner);
            }
        }
    },[props.contact.user_id, subUserList]);

    const loadTeamUserList = () => {

        if (props.subUserList != null) {

            setOwnerInfos(props.subUserList, props.contactDetails.user_id, true)
        } else {
            getSubUserListForContactlist()
                .then(response => {
                    props.setSubUserList(response.data.data)
                    setOwnerInfos(response.data.data, props.contactDetails.user_id, true)

                })
                .catch(error => {
                    console.log('something is wrong' + error)
                });
        }

    };

    const setOwnerInfos = (tempSubUserList, ownerId, firstLoad = false) => {
        if (ownerId === Utils.getAccountData('userId') || Utils.getAccountData('userIsOwner')) {
            setOwnerInfo({ id: ownerId })
            setShow(true)
        } else {
            if (tempSubUserList != null) {
                for (let j = 0; j < tempSubUserList.length; j++) {
                    if (tempSubUserList[j].id === ownerId) {
                        setOwnerInfo({ id: ownerId, full_name: tempSubUserList[j].full_name })
                        break;
                    }
                }
            }
            setShow(true)
        }
        if (firstLoad) {
            setSubUserList(tempSubUserList)
        }
    }

    const handleSubmit = (user_id) => {
        if (user_id != "_claimLead_"){
            if (props.contactDetails.user_id === Utils.getAccountData('userId') || Utils.getAccountData('userIsOwner')) {
                setLoading(true)
                changeLeadOwner({
                    contact_id: props.contact.id,
                    user_id: user_id
                }).then(res => {
                    if (res.status === 200) {
                        try {
                            window.showNotification("SUCCESS", "Lead Owner Successfully Changed");
                            setOwnerInfos(subUserList, user_id)
                        }
                        catch (e) {
                            console.log(e)
                        }
                    } else {
                        try {
                            window.showNotification("ERROR", "Something was wrong, Please reload the page and try again");
                        }
                        catch (e) {
                            console.log(e)
                        }
                    }
                }).finally(() => {
                    setLoading(false)
                });
            }else {
                window.showNotification("ERROR", "You cannot change lead owner");
            }
        }else {
            let message = 'Your are going to send a request to lead/team owner to claim lead';
            if (Utils.getAccountData('userIsOwner')){
                message = 'As a team owner, you can claim lead ownership.';
            }

            NewConfirmAlert({
                onSubmit : () => {
                    receiveBulkContactPermissionRequest({
                        requestType: leadPermission.REQUEST_TYPE_CHANGE_OWNER,
                        contactIds: [props.contactDetails.id],
                        requestedForUserIds: [Utils.getAccountData('userId')],
                        filter: ["no-filter"],
                        from: "claim-lead"
                    })
                        .then((res)=>{
                            if(res.success){
                                window.showNotification("SUCCESS",res.message);
                            }else {
                                window.showNotification("ERROR",res.message);
                            }
                        }).catch((err)=>{
                        window.showNotification("ERROR","Something went wrong. Please try again later!");
                    });
                },
                title: 'Are your sure?',
                description: message,
                cancelText: 'No',
                submitText: 'Yes',
                width: '480px'
            });
        }
    };

    return (
        <React.Fragment>
            <div className="lead-owner">
                <div className="mb-0 row">
                    {
                        loading ? (
                            <NewLoader loading={loading} onlyLoader={true} size={30} message={""} />
                        ) : (
                            <React.Fragment>
                                {
                                    (!show) ? (
                                        <React.Fragment>

                                            <div className="col s12 lead-owner-header">
                                                <div className="lead__owner__title">
                                                    <i className="material-icons">person</i>
                                                    <h5 className="text-dark-blue">Lead Owner</h5>
                                                </div>
                                                <span className="text-light-blue ml-4">{Helper.isEmpty(ownerInfo.id) ? '' : ownerInfo.full_name}</span>
                                            </div>
                                            {/* <div className="col sm6">
                                                    <input type="text" onClick={this.showInputBox} value={this.state.users[this.state.user_id]}/>
                                                </div> */}
                                        </React.Fragment>
                                    ) : (
                                        <div className="col s12 lead-owner-header">
                                            <div className="lead__owner__title">
                                                <i className="material-icons">person</i>
                                                <h5 className="text-dark-blue">Lead Owner</h5>
                                            </div>
                                            {subUserList != null &&
                                                <DropdownHelper
                                                    datalist={subUserList}
                                                    noneFieldRequired={true}
                                                    noneFieldTitle="Update Owner"
                                                    className=""
                                                    mapping={{ title: "full_name", email: "email" }}
                                                    menuItemClassName="new__dropdown_effect"
                                                    updateSelectedData={handleSubmit}
                                                    selectedValue={ownerInfo.id}
                                                    updateDatatable={() => console.log('update owner')}
                                                    dropdownHeader={'ownerChange'}
                                                    parentDivClassName="lead-source-rightside"
                                                    hideExtraOption={props.contactDetails.user_id === Utils.getAccountData('userId')}
                                                    extraOption={
                                                        <MenuItem style={{flexDirection: 'column', alignItems: 'flex-start'}} value={"_claimLead_"}>
                                                            <Button fullWidth  variant='contained' color='primary'>Claim Lead</Button>
                                                        </MenuItem>
                                                    }
                                                />
                                            }
                                        </div>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    );
    // }
}
const mapStateToProps = state => {
    return {
        allContactList: state.rightsideReducer.allContactList,
        userInfo: state.rightsideReducer.userInfo,
        subUserList: state.rightsideReducer.subUserList,
        contact: state.rightsideReducer.contact,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
        getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadOwner);
