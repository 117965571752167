import { Skeleton } from '@material-ui/lab'
import React from 'react'

const listStyle = {
    root : {
        height : "50px",
        backgroundColor : "rgb(250, 250, 250)",
        color : "rgba(0, 0, 0, 0.87)",
        border: "1px solid rgba(60,126,243,.1)",
        borderRadius: "10px",
        marginBottom : "15px",
    }
}

const SingleSkeleton = () => {
    return (
        <div style={listStyle.root}>
            <div className="w-100" height={50}>
                <Skeleton  />
            </div>
        </div>
    )
}

export const RightSideSkeleton = () => {
    return (
        <div className="d-flex flex-column w-100 mt-10">
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
        </div>
    )
}
