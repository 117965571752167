import DialingListTopBar from "./DialingListTopBar";
import {GlobalTable} from "../../globals/GlobalTable";
import {getDialingList} from "../../../api/autoDialerApi";
import {useEffect, useState} from "react";
import {AUTO_DIALER} from "../../../constants/CoreConstants";
import DialingListAction from "./DialingListAction";
import './dialing_list.css'
import Loading from "../../common/Loading";
import ModalAddNewList from "./ModalAddNewList";
import Api from "../../../constants/Api";

const DialingList = props => {
    const [loading, setLoading] = useState(true);
    const [dialingLists, setDialingLists] = useState({});
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [totalRecords, setTotalRecords] = useState(0)
    const [searchKey, setSearchKey] = useState('')

    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [editListData, setEditListData] = useState({});

    useEffect(() => {
        fetchDialingList();
    }, [page, searchKey]);

    const fetchDialingList = () => {
        setLoading(true);
        getDialingList({
            page: page,
            perPage: perPage,
            searchKey: searchKey
        }, Api.getDialingList)
        .then(response => {
            setLoading(false);
            setDialingLists(response.data.data);
            setTotalRecords(response.data.total)
        }).catch(error => {
            setLoading(false);
            console.log(error);
        })
    }

    const columns = [
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '14px', lineHeight: '22px'}}>Name</span>,
            selector: row => row.title,
            sortable: false,
            wrap: true,
            minWidth: '250px',
            maxWidth: '350px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '14px', lineHeight: '22px'}}>Status</span>,
            sortable: false,
            cell: (row, index) => {
                let status = '';
                switch (row.status) {
                    case AUTO_DIALER.STATUS.NOT_STARTED:
                        status = 'not started';
                        break;
                    case AUTO_DIALER.STATUS.RUNNING:
                        status = 'Running';
                        break;
                    case AUTO_DIALER.STATUS.PAUSED:
                        status = 'Paused';
                        break;
                    case AUTO_DIALER.STATUS.COMPLETED:
                        status = 'Completed';
                        break;
                    default:
                        status = 'unknown';
                        break;
                }

                return status;
            },
            width: '200px',
            left: true,
            grow: 4
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '14px', lineHeight: '22px'}}>Total Contact</span>,
            selector: row => row.total_contact,
            sortable: false,
            minWidth:'250px'

        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '14px', lineHeight: '22px'}}>Total Contacted</span>,
            selector: row => row.total_contacted,
            sortable: false,
            minWidth:'250px'

        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '14px', lineHeight: '22px'}}>Action</span>,
            selector: row => <DialingListAction row={row} fetchDialingList={fetchDialingList} editDialingList={() => editDialingList(row)}/>,
            sortable: false,
            minWidth:'250px'
        }
    ];

    const editDialingList = editData => {
        setShowAddNewModal(true);
        setEditListData(editData);
    }

    const onChangePage = (pageNo) => {
        setPage(pageNo)
    }

    return (
        <div className="auto__dialer_main_container">
            <div className="auto__dialing_list">
                <div className='card auto__dialer_wrapper-card'>
                    <div className='card_content auto__dialer_wrapper_content'>
                        <DialingListTopBar showAddNewModal={() => {
                            setEditListData({});
                            setShowAddNewModal(true);
                        }}
                       totalRecords={totalRecords}
                        />
                        <div className="dataTable__wrapper">
                            <div className={'dialer_list_view_container'} style={{padding: '0px 20px 20px 20px'}}>
                                <GlobalTable
                                    table_unique_id={'calling__list'}
                                    columns={columns}
                                    data={dialingLists}
                                    keyField={'id'}
                                    noDataComponent={(
                                        <span style={{padding: 10, color: 'red'}}>No list created yet!</span>
                                    )}
                                    progressPending={loading}
                                    progressComponent={<Loading />}
                                    defaultSortField="title"
                                    selectableRows={false}

                                    pagination={true}
                                    paginationData={{
                                        paginationTotalRows: totalRecords,
                                        paginationPerPage: 10,
                                        currentPage: page,
                                        onChangePage: onChangePage,
                                        paginationComponentOptions: {
                                            noRowsPerPage: true
                                        }
                                    }}
                                    subHeader={false}
                                    noHeader={true}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showAddNewModal && <ModalAddNewList editListData={editListData} isOpen={showAddNewModal} onClose={() => setShowAddNewModal(false)} fetchDialingList={fetchDialingList}/>
            }
        </div>
    );
}

export default DialingList;