import React, { useState, useEffect } from 'react'
import { Button, FormControl } from '@material-ui/core';
import { IconList } from '../../globals/IconList';
import { makeStyles ,useTheme} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      borderRadius:'4px',
      height:'25px',
      cursor: 'pointer',
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
}));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name, personName, theme) {
    try{
        return {
            fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
        };
    }catch($e){
        return null
    }

}

const ColumnFilter = (props) => {


    // Pop Hover Start 
    const classes = useStyles();
    const theme = useTheme();

    const [numberEmailFilter, setNumberEmailFilter] = useState(null);
    const [selectedData,setSelectedData] = useState(['','','','','',''])
    const [multipleSelectedData,setMultipleSelectedData] = useState([[],[]])
    const [multiSelectedDatalabel, setMultiSelectedDataLabel] = useState([[],[]])
    const [filterShow,setFilterShow] = useState(false)
    const [update,setUpdate] = useState(false)
    const [scrolling, setScrolling] = useState(false)

    useEffect(() => {

        if(props.filterName !== 'stage' && props.columnFilterData[props.filterName] === undefined && props.columnFilterData[props.filterName] == null){
            updateDefaultValue();
        }
    }, [props.datalist])

    useEffect(() => {
        if(props.columnFilterData !== undefined && props.columnFilterData != null && 
            props.columnFilterData[props.filterName] !== undefined && props.columnFilterData[props.filterName] != null){
                
                setSelectedData(props.columnFilterData[props.filterName])
                setFilterShow(true)
            }else {
                setFilterShow(false)
    
            }

        if(props.columnFilterMultipleSelectedData !== undefined && props.columnFilterMultipleSelectedData != null && 
            props.columnFilterMultiSelectedDatalabel !== undefined && props.columnFilterMultiSelectedDatalabel != null){

                setMultipleSelectedData(props.columnFilterMultipleSelectedData)
                setMultiSelectedDataLabel(props.columnFilterMultiSelectedDatalabel)
                if(props.columnFilterMultipleSelectedData[0].length > 0 && props.columnFilterMultipleSelectedData[1].length >0){
                    setFilterShow(true)
                }
        }else if(props.filterName === 'tags'){
            setFilterShow(false)
        }

    }, [props.updateTable])


    // useEffect(()=>{
    //     if(props.tagList !== undefined && props.tagList != null){

    //     }

    // },[multipleSelectedData.length])
    
    const updateDefaultValue=()=>{
        let tempSelectedData = ['','','','','',''];

        if(props.defaultValue.length > 0 ){

            for(let i=0; i < props.defaultValue.length; i++){
                tempSelectedData[i] = props.defaultValue[i]
            }

        }
        setSelectedData(tempSelectedData)

    }

    const handleClick = (event) => {
        setNumberEmailFilter(event.currentTarget);
    };

    const handleClose = () => {
        setNumberEmailFilter(null);
    };

    const updateSelectValue=(value, index)=>{

        if(!props.buttonAvailable){

        }
        else if (props.filterName=='stage' && index == 0){

            let tempSelectedData = [...selectedData];
            tempSelectedData[index] = value;
            setSelectedData(tempSelectedData);

            props.updateStageListForColumnFilter(value)

            // console.log(tempSelectedData)
        }
        else{
            let tempSelectedData = [...selectedData];
            if(props.inputTypeSecurity){
                if(props.inputTypeSecurity[index] === 'number'){
                    value = value.replace(/[^0-9]/,'');
                }
            }
            tempSelectedData[index] = value;
            // console.log(tempSelectedData)
            setSelectedData(tempSelectedData);
        }
    }

    const submitData=()=>{

        props.onSubmit(selectedData, props.filterName, multipleSelectedData, multiSelectedDatalabel);
        handleClose()
    }

    const scrollCallback = async (e)=>{

            if(!scrolling && props.tagList.fetch && props.tagList.last_page && props.tagList.current_page < props.tagList.last_page &&
                // Math.round(e.target.scrollTop + e.target.clientHeight, 20) >= Math.round(e.target.scrollHeight, 10)
                Math.round(e.target.scrollHeight, 10) - Math.round(e.target.scrollTop + e.target.clientHeight, 20) < 300 ){
                    setScrolling(true)
                    await props.fetchUrlForScroll();
                    setScrolling(false)
    
            }
    }

    const open = Boolean(numberEmailFilter);
    const id = open ? 'simple-popover' : undefined;

const selectCompomonent=(index)=>{

    return (
        
        <FormControl variant="outlined" key={`select-component${index}`} margin="none" focused="false" className={props.inputArray.length ==3 ? "email__pophover__wr" : "email__pophover__wr select_input_inline"}>
                                                <Select
                                                className="email__pophover"
                                                labelId="demo-simple-select-outlined-label"
                                                value={selectedData[index]}
                                                onChange={e => updateSelectValue(e.target.value, index)}
                                                IconComponent={KeyboardArrowDownIcon}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            >
            
                                                {props.emptyValueShow[index] && 
                                                    <MenuItem value='' className={'dropdownhelper-menuitem-class'}>{props.emptyValueTitle[index]}</MenuItem>

                                                }


                                                {/* {(props.datalist != null && props.datalist[index] !== undefined && props.datalist[index] != null && props.datalist[index].id !== undefined &&
                                                props.datalist[index].id != null) && 
                                                    props.datalist[index].map((query) => {
                                                        return (
                                                     <MenuItem value={query.id} key={props.filterName+query.id}>{query.full_name}</MenuItem>
                                                        )
                                                    })
                                                } */}
                                                {(props.filterName == 'user' && props.subUserList !== undefined && props.subUserList != null &&
                                                props.subUserList.length > 0) && 
                                                
                                                    props.subUserList.map((query) => (
                                                     <MenuItem value={query.id} key={`${props.filterName}${query.id}`}
                                                     className={'dropdownhelper-menuitem-class'}>{query.full_name}</MenuItem>
                                                    )
                                                    )
                                                }

                                                {(props.filterName == 'source' && props.sourceList !== undefined && props.sourceList != null &&
                                                props.sourceList.length > 0) && 
                                                
                                                    props.sourceList.map((query) => (
                                                     <MenuItem value={query.id}  key={`${props.filterName}${query.id}`}
                                                     className={'dropdownhelper-menuitem-class'}>{query.source_title}</MenuItem>
                                                    )
                                                    )
                                                }
                                                {(props.filterName == 'stage' && index == 0 && props.pipelineList !== undefined && props.pipelineList!= null && props.pipelineList.length > 0) && 
                                                
                                                props.pipelineList.map((query) => (
                                                     <MenuItem value={query.id}  key={`${props.filterName}${query.id}`}
                                                     className={'dropdownhelper-menuitem-class'}>{query.title}</MenuItem>
                                                    )
                                                    )
                                                }
                                                {(props.filterName == 'stage' && index == 1 && props.stageListForPipeline !== undefined && props.stageListForPipeline!= null && props.stageListForPipeline.length > 0) && 
                                                
                                                props.stageListForPipeline.map((query) => (
                                                     <MenuItem value={query.id}  key={`${props.filterName}${query.id}`}
                                                     className={'dropdownhelper-menuitem-class'}>{query.stage}</MenuItem>
                                                    )
                                                    )
                                                }
                                                {(props.filterName !== 'stage' && props.datalist!= null && props.datalist[index] !== undefined && props.datalist[index] != null && props.datalist[index].id == undefined) && 
                                                    props.datalist[index].map((query) => (

                                                    <MenuItem value={query}  key={`${props.filterName}${query.id}`}
                                                    className={'dropdownhelper-menuitem-class'}>{query.replaceAll("_"," ")}</MenuItem>

                                                ))                                               
                                                }
                                            </Select>
                                        </FormControl>
                                            
                                            
    )
}

const updateSelectedMultipleValue=(event, index)=>{

    // console.log(value)
    let value = event.target.value;

    if(multiSelectedDatalabel[index].length > 0){
        value = value[multiSelectedDatalabel[index].length]

    }else{
        value = value[0]

    }
    let savedSelectedData = multipleSelectedData;
    let savedMultipleSelectedDataLabel = multiSelectedDatalabel;
    let selectedInputs = savedSelectedData[index]

    let selectedLabels = savedMultipleSelectedDataLabel[index]
    let indexOf = selectedInputs.indexOf(value.id)
    if(indexOf < 0){
        selectedInputs.push(value.id)
        selectedLabels.push(value.name)
    }else{
        selectedInputs.splice(indexOf,1)
        selectedLabels.splice(indexOf,1)

    }

    savedSelectedData[index] = selectedInputs
    savedMultipleSelectedDataLabel[index] = selectedLabels
    setMultipleSelectedData(savedSelectedData)
    setMultiSelectedDataLabel(savedMultipleSelectedDataLabel)
    setUpdate(!update)

}
const multiSelectComponent=(index)=>{

  
    return (
        
        <FormControl variant="outlined" margin="none" focused="false" className={props.inputArray.length ==3 ? "email__pophover__wr" : "email__pophover__wr select_input_inline"} key={`multi-selectcomponent${index}`}>
        <Select
            labelId="multiple-custom-select"
            id="multiple-custom-select"
            multiple
            displayEmpty
            value={multiSelectedDatalabel[index]}
            onChange={(event)=>updateSelectedMultipleValue(event,index)}
            input={<Input id="multiple-custom-select" />}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <em>{props.emptyValueTitle[index]}</em>;
                }
                return(
                    <div className={classes.chips}>
                        {selected.map((value, index) => (
                        <Chip key={`chip${index}`} label={value} className={`${classes.chip}`}/>
                        ))}
                    </div>
                )
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,

                PaperProps: {
                    style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250
                    },
                    onScroll: (e)=>{props.needToLoadViaScroll ? scrollCallback(e) : console.log()}
                }
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem disabled value="" className={'dropdownhelper-menuitem-class'}>
                <em>{props.emptyValueTitle[index]}</em>
            </MenuItem>

            {(update ||!update)&& props.filterName==='tags' && Array.isArray(props.tagList.data) && props.tagList.data.map((each, indexId) => {
                if(!multipleSelectedData[index].includes(each.id)){
                    return(
                <MenuItem key={`tags-${indexId}`} value={each} style={getStyles(each.name, props.tagList, theme)}
                className={'dropdownhelper-menuitem-class'}>
                    {each.name} 
                </MenuItem>
                    )}

                    if(multipleSelectedData[index].includes(each.id)){
                        return(
                    <MenuItem key={`tags-${indexId}`} value={each} style={getStyles(each.name, props.tagList, theme)}
                    className={'dropdownhelper-menuitem-class'}>
                        {each.name} <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-6yvdg3" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HighlightOffTwoToneIcon" tabIndex="-1" title="HighlightOffTwoTone"><path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm4 10.59L14.59 16 12 13.41 9.41 16 8 14.59 10.59 12 8 9.41 9.41 8 12 10.59 14.59 8 16 9.41 13.41 12 16 14.59z" opacity=".3"></path><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>
                    </MenuItem>
                        )}
              
            })}
          </Select>
                                        </FormControl>
                                            
                                            
    )
}

const inputComponent=(index)=>{

    return (
        <div className={props.inputArray.length ==3 ? "contact_list_toggle_input_wr" : "contact_list_toggle_input_wr input_select_inline"} key={`input-component${index}`}>
        <FormControl className="contact_list_toggle_input">
            <Input
                placeholder={props.placeHolders[index]}
                onChange={e => updateSelectValue(e.target.value, index)} 
                value={selectedData[index]} disableUnderline={true}
            />
        </FormControl>
    </div>
    )
}

if(props.active !== undefined && props.active != null && !props.active) {return null;

}else{


    return (
        <React.Fragment>
            {}
            <span onClick={handleClick} className="data-table-svg toggle__icon__email contact-table-column-filter-icon">

                {filterShow && IconList.filterAltFilledSVGMedium} 
                {!filterShow && IconList.filterAltOutlineSVGMedium}
             </span>               

            <Popover
                id={id}
                open={open}
                anchorEl={numberEmailFilter}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography>

                    <div className="email__droupdown">

                        <div className="email__droupdown__content">
                            <h4 className="email__deroupdoan__head"> filter</h4>


                                {/* {props.inputArray.length >0 && */}
                                {/* {selectedData.length > 0 && */}
                                 {props.inputArray.map((inputType, index)=>

                                {
                                    switch (inputType) {

                                        case "select": 
                                            return selectCompomonent(index)
                                            break;
                                            
                                        case "input" :
                                            return inputComponent(index)
                                            break;
                                        case "multiSelect" :
                                            return multiSelectComponent(index)
                                            break;
                                        default: 
                                            return null;
                                    
                                    }

                                })

                                    
                                }
                                
                            {props.buttonAvailable &&
                            <div className="contact_list_toggle">
                                <Button type="submit"
                                    className="contact_list_toggle_btn"
                                    onClick={() => submitData()}
                                >
                                    {props.buttonText !== undefined && props.buttonText != null && props.buttonText !== ''?  props.buttonText : 'submit'}
                                </Button>

                            </div>
                            }

                        </div>

                    </div>
                </Typography>
            </Popover>
        </React.Fragment>
    )
}
}

export default React.memo(ColumnFilter)