import * as ACTION from "../constants/calendarActionTypes";

export const fetchActivityCalendar = (payload, callback) => ({
    type: ACTION.FETCH_ACTIVITY_CALENDAR,
    payload: {payload, callback}
});

export const fetchActivityListData = (payload, callback) => ({
    type: ACTION.FETCH_ACTIVITY_LIST,
    payload: {payload, callback}
});

export const fetchTaskCount = (payload, callback) => ({
    type: ACTION.FETCH_TASK_COUNT,
    payload: {payload, callback}
});

export const updateActivityActiveTab = payload => ({
    type:ACTION.UPDATE_VIEW_ACTIVE_TAB,
    payload: payload
});

export const updateFilterActiveTab = payload => ({
    type:ACTION.UPDATE_FILTER_ACTIVE_TAB,
    payload: payload
});

export const updateActiveTaskId = payload => ({
    type:ACTION.UPDATE_TASK_ACTIVE_TAB,
    payload: payload
});

export const followupActivitySave = (payload, callback) => ({
    type: ACTION.SAVE_ACTIVITY_FOLLOWUP,
    payload: {payload, callback}
});

export const fetchContactsList = (payload, callback) => ({
    type: ACTION.FETCH_CONTACT_LIST,
    payload: {payload, callback}
});

export const updateTaskFlag = payload => ({
    type: ACTION.UPDATE_TASK_UPDATE_FLAG,
    payload: payload
});



