import { IconButton } from "@material-ui/core";
import React, {useEffect, useState} from "react";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Styles from "./LeadRequest.module.css";
import {acceptContactRequest, getContactRequests, rejectContactRequest, runCampaign} from "../../../api/contactApi";
import {leadPermission} from "../../../constants/CoreConstants";
import NewConfirmAlert from "../../common/new-alert/NewConfirmAlert";
import {updateContactColumn} from "./redux/contactAction";
import {connect} from "react-redux";

const LeadRequest = (props) => {
    const [isLeadRequest, setIsLeadRequest] = useState(false);
    const [leadOwnerRequests, setLeadOwnerRequests] = useState([]);
    const [addCollaboratorRequests, setAddCollaboratorRequests] = useState([]);

    useEffect(()=>{
        getContactRequests({ contactId: props.contact.id, ownerId: props.contact.user_id })
            .then((res)=>{
                res = res.data;
                let isShow = false;

                if(res.status == 'success'){
                    if (res.data && res.data.addCollaboratorRequests && res.data.addCollaboratorRequests.length > 0){
                        setAddCollaboratorRequests(res.data.addCollaboratorRequests);
                        isShow = true;
                    }

                    if (res.data && res.data.leadOwnerChangeRequests && res.data.leadOwnerChangeRequests.length > 0){
                        setLeadOwnerRequests(res.data.leadOwnerChangeRequests);
                        isShow = true;
                    }
                }

                setIsLeadRequest(isShow);
            });
    },[]);

    const getSubUserName = (id) => {
        if (props.subUserList != undefined && props.subUserList != null && props.subUserList != '' && Array.isArray(props.subUserList)){
            let subUser = props.subUserList.find((user)=>(user.id === id));
            return (subUser && subUser.full_name) ? subUser.full_name : '';
        }

        return '';
    }

    const handleRejectContactRequest = (contactRequestId, index, requestType) => {
        NewConfirmAlert({
            onSubmit : () => {
                rejectContactRequest({ contactRequestId: contactRequestId })
                    .then((res)=>{
                        res = res.data;
                        let isShow = false;

                        if(res.status == 'success'){
                            window.showNotification("SUCCESS",res.message);

                            if (requestType == leadPermission.REQUEST_TYPE_CHANGE_OWNER){
                                let oldData = [...leadOwnerRequests];
                                oldData.splice(index,1);

                                if (oldData.length > 0){
                                    isShow = true;
                                }
                                setLeadOwnerRequests(oldData);
                            }else if (requestType == leadPermission.REQUEST_TYPE_ADD_COLLABORATOR){
                                let oldData = [...addCollaboratorRequests];
                                oldData.splice(index,1);
                                if (oldData.length > 0){
                                    isShow = true;
                                }
                                setAddCollaboratorRequests(oldData);
                            }

                            setIsLeadRequest(isShow);
                        }else {
                            window.showNotification("ERROR",res.message);
                        }
                    }).catch((err)=>{
                    window.showNotification("ERROR","Something went wrong. Please try again later!");
                });
            },
            title: 'Please confirm your selection below:',
            description: '',
            cancelText: 'Cancel',
            submitText: 'Reject',
            width: '480px'
        });
    }


    const handleAcceptContactRequest = (contactRequest, index, requestType) => {
        NewConfirmAlert({
            onSubmit : () => {
                acceptContactRequest({ contactId: props.contact.id, contactRequestId: contactRequest.id, requestForUserId: contactRequest.request_for_user_id })
                    .then((res)=>{
                        let isShow = false;
                        if(res.success){
                            window.showNotification("SUCCESS",res.message);

                            if (requestType == leadPermission.REQUEST_TYPE_CHANGE_OWNER){
                                let oldData = [...leadOwnerRequests];
                                oldData.splice(index,1);

                                if (oldData.length > 0){
                                    isShow = true;
                                }
                                setLeadOwnerRequests(oldData);
                                props.updateContactColumn({ user_id: contactRequest.request_for_user_id });
                            }
                            setIsLeadRequest(isShow);
                        }else {
                            window.showNotification("ERROR",res.message);
                        }
                    }).catch((err)=>{
                    window.showNotification("ERROR","Something went wrong. Please try again later!");
                });
            },
            title: 'Please confirm your selection below:',
            description: '',
            cancelText: 'Cancel',
            submitText: 'Accept',
            width: '480px'
        });
    }

    if (isLeadRequest){
        return (
            <div className={Styles.leadRequestWrapper}>
                {
                    ((addCollaboratorRequests && addCollaboratorRequests.length > 0) || (leadOwnerRequests && leadOwnerRequests.length > 0)) && (
                        <div className={Styles.requestTitle}>Lead Request</div>
                    )
                }

                {
                    leadOwnerRequests && leadOwnerRequests.length > 0 ? (
                        <div className={Styles.requestContainer}>
                            <div className={Styles.requestSub}>Request for change lead owner</div>

                            {
                                leadOwnerRequests.map((request, index)=>{
                                    return (
                                        <div className={Styles.requestItem}>
                                            <span className={Styles.requestUser}>{getSubUserName(request.request_for_user_id)}</span>

                                            <div className={Styles.actionButton}>
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    aria-label="accept"
                                                    onClick={()=>{
                                                        handleAcceptContactRequest(request, index, leadPermission.REQUEST_TYPE_CHANGE_OWNER)
                                                    }}
                                                >
                                                    <CheckIcon />
                                                </IconButton>

                                                <IconButton
                                                    size="small"
                                                    color="secondary"
                                                    aria-label="reject"
                                                    onClick={()=>{
                                                        handleRejectContactRequest(request.id, index, leadPermission.REQUEST_TYPE_CHANGE_OWNER)
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : ''
                }

                {
                    addCollaboratorRequests && addCollaboratorRequests.length > 0 ? (
                        <div className={Styles.requestContainer}>
                            <div className={Styles.requestSub}>
                                Request for add collaborator
                            </div>

                            {
                                addCollaboratorRequests.map((request, index)=>{
                                    return (
                                        <div className={Styles.requestItem}>
                                            <span className={Styles.requestUser}>{getSubUserName(request.request_for_user_id)}</span>

                                            <div className={Styles.actionButton}>
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    aria-label="accept"
                                                >
                                                    <CheckIcon />
                                                </IconButton>

                                                <IconButton
                                                    size="small"
                                                    color="secondary"
                                                    aria-label="reject"
                                                    onClick={()=>{
                                                        handleRejectContactRequest(request.id, index, leadPermission.REQUEST_TYPE_ADD_COLLABORATOR)
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : ''
                }
            </div>
        );
    }

    return (<div/>);
};

const mapStateToProps = state => {
    return {
        contact: state.rightsideReducer.contact,
        subUserList: state.contactReducer.subUserList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateContactColumn: (params) => dispatch(updateContactColumn(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadRequest);