import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayer = (props) => {

    return (
        <ReactAudioPlayer
            src={props.src}
            autoPlay={false}
            controls
/>
    );
}
export default AudioPlayer;