import CustomPopupMenu from './CustomPopupMenu';
import './userListDropdownStyle.css'

const GlobalUserListDropdown = ({
    className,
    handleSelect,
    userList = [],
    selected = [],
    selectedId = [],
    reorganized = false,
    valueField = '',
    labelField = '',
    textLength=19,
    height='42px',
    placeholder=null
}) => {
    const handleItemChange = (value, remove) => {
        handleSelect(value, remove)
    }

    return(
        <div className={`pypepro_global__custom__field__grouped__wraper ${className ?? ''}`}>
            <CustomPopupMenu 
                list={userList}
                handleClick={(value, remove) => handleItemChange(value, remove)}
                isLoading={false}
                value={selected}
                selectedId={selectedId}
                placeholder={placeholder ?? 'Select user'}
                reorganized={reorganized}
                valueField={valueField}
                labelField={labelField}
                textLength={textLength}
                height={height}
            />
        </div>
    );
}
export default GlobalUserListDropdown;