import {createContext, useReducer} from "react";
import ActionType from "./ActionTypes";
import { FILE_IMPORT_STEP, TAB_CLEAN_LIST } from "./Constants";

export const DataCleanContext = createContext({});

const dataCleanStates = {
    isFileSelected: false,
    activeTab: TAB_CLEAN_LIST.VERIFY,
    activeStep: FILE_IMPORT_STEP.STEP_IMPORT_MAPPING,
    carrierLookupPrices: null,
    data: [],
    validateData: [],
    file: null,
    total :  0,
    current : 0,
    success : 0,
    failed : 0,
    isExistFailed : 0,
    totalUpload : 0,
    validateFailed : 0,
    checkedData : 0,
    maxFileSize: "100M",
    submitData : [],
    carrierInfos: {
        emailVerificationType: null,
        phoneVerificationType: null,
        doNotCall: null
    },
    cleanDataRequestId: null,
    cleanDataRequest: null
};


const dataCleanReducer = (state, action) => {
    switch (action.type){
        case ActionType.UPDATE_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const DataCleanProvider = ({children}) => {
    const [state, dispatch] = useReducer(dataCleanReducer, dataCleanStates);
    return (
        <DataCleanContext.Provider value={{state, dispatch}}>
            {children}
        </DataCleanContext.Provider>
    )
};