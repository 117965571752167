import React, { useState } from "react";
import { IconList } from "../../../globals/IconList";
import ShowMorePipelineInfo from "./ShowMorePipelineInfo";
import PipelineListItem from "../PipelineListItem";
import StageListItem from "../StageListItem";

const ShowSpecificContactPipelines = (props) => {

  let specificPipelines = [];
  const dataColumnInsideColumn = 3;
  
   //Show more Pipelines modal
  const [isContactPipelineModalOpen, setIsContactPipelineModalOpen] = useState(false);

  return (
    <>
      { props.pipelineList && 
        <td className="Campaigns-content">
          <ul>
            {(props.row?.stage && Array.isArray(props.row?.stage)) &&
                // eslint-disable-next-line array-callback-return
                props.row?.stage.map((stageInfo, stageIndex) => {
                  if (stageInfo?.stage && stageInfo?.stage?.stage) {
                    if (specificPipelines.length < dataColumnInsideColumn) {
                      let pipelineTitle = "";
                      let pipeLength = props.pipelineList.length;

                      for(let i=0; i < pipeLength; i++) {
                          if(props.pipelineList[i].id === stageInfo.stage.pipeline_id ){
                              pipelineTitle = props.pipelineList[i].title;
                              specificPipelines.push(props.pipelineList[i].id);
                              break;
                          }
                      }

                      if(pipelineTitle !== "" && props.willShow === "pipeline") {
                        return (
                          <PipelineListItem
                            row={props.row}
                            index={props.index}
                            stageInfo={stageInfo}
                            stageIndex={stageIndex}
                            pipelineTitle={pipelineTitle}
                            openStageChangeModal={props.openStageChangeModal}
                            key={`stageDropdown${stageInfo?.stage?.id}${stageIndex}`}
                          />
                      )} else if(props.willShow === "stage") {
                        return (
                          <StageListItem 
                            row={props.row}
                            index={props.index}
                            stageInfo={stageInfo}
                            stageIndex={stageIndex}
                            title={stageInfo.stage.stage}
                            openStageChangeModal={props.openStageChangeModal}
                            key={"stageDropdown" + stageInfo?.stage?.id + stageInfo?.id}
                          />
                      )} 
                    } else if (specificPipelines.length === dataColumnInsideColumn) {
                      specificPipelines.push(-1);
                      return (
                        <li 
                            key={`${"stageDropdown" +stageInfo?.stage?.id+ stageInfo?.id}`}
                            onClick={() => setIsContactPipelineModalOpen(true)}
                        >
                            <div className="see__more__contact__campaigns">
                                {IconList.seeMore}
                                <span>For More Info</span>
                            </div>
                        </li>
                    )}
                  }
              })}
          </ul>
        </td>
      }
      {isContactPipelineModalOpen && (
          <ShowMorePipelineInfo
            open={isContactPipelineModalOpen}
            close={() => setIsContactPipelineModalOpen(false)}
            allContactPipelines={props.row?.stage}
            pipelineList={props.pipelineList}
            openStageChangeModal={props.openStageChangeModal}
            row={props.row}
            index={props.index}
          />
        )}
    </>
  );
};

export default ShowSpecificContactPipelines;
