import React, { Component,Fragment } from 'react';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import Gallery from 'react-fine-uploader';
import 'react-fine-uploader/gallery/gallery.css';
import If from 'if-else-react';
import ButtonLoader from '../../common/ButtonLoader';
import Api from '../../../constants/Api';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { Button, Grid } from '@material-ui/core';
import { getCookie } from '../../../helpers/Cookie';

const style = {
    zIndex: "10000000"
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
`;

const Wrapper = styled.div`
    margin: 10px;
    max-width: 500px;
`;

const ErroMsg = (props) => {
    return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {props.msg}
        </div>
    )
}


const uploader = new FineUploaderTraditional({
    options: {
        chunking: {
            enabled: false
        },
        request: {
            endpoint: Api.uploadFile
        },
        validation: {
            allowedExtensions: ['jpeg', 'jpg', 'mp3', 'mp4', 'pdf', 'txt', 'csv', 'png', 'gif', 'zip', 'rar', 'doc','css'],
            itemLimit: 100,
            sizeLimit: 50000000,  // 50mb
            stopOnFirstInvalidFile: true
        },
        autoUpload: false,
        messages: {
            typeError: '{file} has an invalid extension. Valid extensions[{extensions}].',
            sizeError: '{file} is too large, maximum file size is {sizeLimit}.',
            tooManyItemsError: 'You are trying to upload {netItems} items. But remaining limit is {itemLimit}.'
        }
    }
});


class ModalFileUploadForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            error: false,
            errorMsg: '',
            submitingFile: false,
            submitEnable: false,
        };
    }

    componentWillReceiveProps(previous) {
        uploader.methods.setItemLimit(100-this.props.count);
        this.setState({
            isOpen: previous.isOpen
        });
    }

    toggle = () => {
        if(this.props.modalToggle){
            this.props.modalToggle();
        }
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    componentDidMount() {

        uploader.on('statusChange', (id, oldStatus, newStatus) => {
            "use strict";
            if (newStatus === 'submitted') {
                this.setState({
                    submitEnable: true
                })
            }

            if (newStatus === 'canceled') {
                this.setState({
                    submitEnable: false
                })
            }
        });


        uploader.on('complete', (id, name, response) => {
            this.setState({
                submitingFile: false,
                submitEnable: false
            }, ()=> {
                
                if(response.error===true){
                    this.setState({
                        error: true,
                        errorMsg: response.html
                    });
                }else{
                    window.showNotification("SUCCESS", response.html)
                    this.props.callBack(response.file);
                }

            });
        });

        uploader.on('error', (id, name, response) => {
            this.setState({
                error: true,
                errorMsg: response
            });

            setTimeout(() => {
                this.setState({
                    error: false,
                    errorMsg: "",
                    submitingFile: false,
                    submitEnable: true,
                });
            }, 5000);
        });

        if(this.state.error===false){
            uploader.on('submit', (id, name, response) => {
                uploader.methods.setParams({
                    contact_id: this.props.defaultParams.contact_id,
                    'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)
                });
            });
        }
    }

    uploadFile = (event) => {
        event.preventDefault();

        this.setState({
            submitingFile: true
        });

        uploader.methods.uploadStoredFiles()
    };


    render() {
        const fileInputChildren = <span> <span className="fa fa-photo"></span>Choose file</span>

        return (
            <Drawer anchor={'right'} open={this.state.isOpen} onClose={this.toggle}>
                <Wrapper>
                <form onSubmit={this.uploadFile} className="m-form">
                    <Grid container>
                        <Grid item xs={12}>
                            <Header>
                                <h5>{ this.props.title}</h5>
                                <div class="modal-close cross-icon d-flex justify-content-center align-items-center" onClick={this.toggle}>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.1262 17.1368L17.1367 15.125L6.87897 4.86052L4.86845 6.87237L15.1262 17.1368Z" fill="white"></path>
                                        <path d="M6.87898 17.1395L17.1367 6.875L15.1262 4.86315L4.86845 15.1276L6.87898 17.1395Z" fill="white"></path>
                                    </svg>
                                </div>
                            </Header>
                        </Grid>
                        <Grid item xs={12}>
                            <If condition={this.state.error}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group m-form__group">
                                            <div className="col-md-12">
                                                <div className="form-group m-form__group">
                                                    <ErroMsg msg={this.state.errorMsg}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </If>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group m-form__group">
                                        <Gallery fileInput-children={ fileInputChildren } uploader={ uploader }/>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="w-100 text-right">
                                <ButtonLoader btnClass={"btn btn-primary mr-2"} btnIcon={'la la-plus'} btnText={"Upload"}
                                            content={this.state.submitEnable}
                                            loading={this.state.submitingFile}/>
                                <Button className="btn btn-default btn-secondary" color="secondary" onClick={this.toggle}>
                                    <i className="la la-times text-light"></i> Close
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
                </Wrapper>
            </Drawer>
        );
    }
}

export default ModalFileUploadForm;
