import React,{useState} from "react";
import { Link } from "react-router-dom";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import UtilsHelper from "../../../helpers/Utils";
import ShowAllCampaignsOfContact from "./ShowAllCampaignsOfContact";
import { IconList } from "../../globals/IconList";
import { CAMPAIGN_STATUS } from "../../../../src/constants/CoreConstants";
import "./show-all-campaigns-of-contact.css";

const ShowSpecificContactCampaigns = (props) => {
  let campaignArray = [];
  const dataColumnInsideColumn = 3;
  
   //Show more camapigns
  const [allCampaignsOfContactModal, setAllCampaignsOfContactModal] = useState(false);
  const [allCampaignsOfContact, setAllCampaignsOfContact] = useState([]);

  // Show More Contacts
  const handleMoreCampaigns = (campaignInfo) => {
    setAllCampaignsOfContactModal(true);
    setAllCampaignsOfContact(campaignInfo);
  };

  return (
    <>
    <td className="Campaigns-content">
      <ul>
        {props.row.subscription && props.row.subscription.length > 0 &&
          props.row.subscription.forEach((subcript) => {
            if (campaignArray.length < dataColumnInsideColumn && subcript.campaign && subcript.campaign.title) {
              if (subcript.campaign.status === CAMPAIGN_STATUS.ACTIVE_CAMPAIGN && subcript.is_unsubscribed === 0) {
                campaignArray.push(
                  <li
                    className="link-text-design campaign-list-in-contact-list"
                    key={"campaigns" + subcript.id}
                  >
                    <BootstrapTooltip
                      title={subcript.campaign.title}
                      arrow
                      placement="top"
                    >
                      <Link to={`/campaign/details/${subcript?.campaign?.id}`} target="_blank">
                        <span>
                          {UtilsHelper.reduceTextLength(
                            subcript.campaign.title,
                            20
                          )}
                        </span>
                      </Link>
                    </BootstrapTooltip>
                    <div className="running-campaigncontact-icon-contactlist"></div>
                  </li>
                );
              } else if((subcript.campaign.status !== CAMPAIGN_STATUS.ACTIVE_CAMPAIGN || subcript.is_unsubscribed !== 0) && props.isShowPausedCampaigns) {
                campaignArray.push(
                  <li
                    className="link-text-design campaign-list-in-contact-list"
                    key={"campaigns" + subcript.id}
                  >
                    <BootstrapTooltip
                      title={subcript.campaign.title}
                      arrow
                      placement="top"
                    >
                      <Link to={`/campaign/details/${subcript?.campaign?.id}`} target="_blank">
                        <span>
                          {UtilsHelper.reduceTextLength(
                            subcript.campaign.title,
                            20
                          )}
                        </span>
                      </Link>
                    </BootstrapTooltip>
                    <div className="pause-campaign-contact-icon-contactlist"></div>
                  </li>
                );
              }
            } else if (campaignArray.length === dataColumnInsideColumn && subcript.campaign && subcript.campaign.title) {
              campaignArray.push(
                <li
                  key={"campaigns" + subcript.id}
                  onClick={() =>
                    handleMoreCampaigns(
                      props.row.subscription
                    )
                  }
                >
                  <div className="see__more__contact__campaigns">
                    {IconList.seeMore}
                    <span>See More</span>
                  </div>
                </li>
              );
            }
          })}
        {campaignArray}
      </ul>
    </td>

    {allCampaignsOfContactModal && (
        <ShowAllCampaignsOfContact
          open={allCampaignsOfContactModal}
          close={() => setAllCampaignsOfContactModal(false)}
          allCampaignsOfContact={allCampaignsOfContact}
          isShowPausedCampaigns={props.isShowPausedCampaigns}
        />
      )}
    </>
  );
};

export default ShowSpecificContactCampaigns;
