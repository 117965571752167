import React, { useCallback, useEffect, useState } from 'react';
import RightSide from './rightSide/RightSide';
//moved to cdn
// import './Contact.css';
// import './ContactResponsive.css';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchContactDetailsNew } from '../../api/contactApi';
import { getAllContacts, setContactDetails, updateMultipleData } from '../../actions/contactAction';
import LeftSide from './leftSide/LeftSide';
import MiddleSide from './middleSide/MiddleSide';
import Utils from './rightSide/Helpers/Utils';
import { useHistory} from "react-router-dom";
import { withTransaction } from '@elastic/apm-rum-react'
import "./ExtraStyle.css";

const Contact = (props) => {
    const { id } = useParams();
    const history = useHistory();

    const [contactId, setContactId] = useState(id);
    const [data, setData] = useState(null);
    const [changeArrowDir, setChangeArrowDir] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    let currentIndex;

    useEffect(() => {
        //setting html title
        props.setContactDetails(null);

        document.title = `Contact | ${Utils.getAccountData('AuthUserAgencyName')}`;
        //setting active sidebar
        if (window.setActiveSidebar) {
            window.setActiveSidebar('contact');
        }

        //Hide left sidebar small screen
        if(window.innerWidth <= 1110){
            window.setCollapsedMenu(true)
        }
        //Show right sidebar at the beginning
        document.querySelector("#main__content").classList.add("right_sidebar_active");

        //scroll to top
        document.getElementById('main__content_wrapper_parent_module').scrollTo({ top: 0, behavior: 'smooth' });

        loadData(id);

        let localData =localStorage.getItem('contactListInfo')

        if(localData){
            try{
                let params = new URLSearchParams(document.location.search);
                currentIndex = parseInt(params.get("currentIndex")); // is the string "Jonathan"
            }catch(e){
                console.log(e);
                currentIndex=null;
            }

            if(currentIndex !== undefined && currentIndex != null){
                try {
                    localData = JSON.parse(localData);
                    let cLength = localData.contactList.length
                    if(localData.from === 'contactListPage' && currentIndex < cLength && localData.contactList[currentIndex].id == id){
                        
                        let tempData = {
                            contactList: localData.contactList,
                            // totalContacts: history.location.state.totalContacts,
                            totalContacts: cLength,
                            currentIndex: currentIndex
                        }
                        props.updateMultipleData({assignedContactListInfo : {...tempData}})
                    }
                }
                catch(e){
                    console.log(e)
                }
            }
        }

            // const intervalTimer = setInterval(()=>{
            //     //For next
            //     if(props.assignedContactListInfo.contactList.length - props.assignedContactListInfo.currentIndex)
            // },500)

    }, []) // eslint-disable-line



    const loadData = (tmpId =null) => {
        fetchContactDetailsNew({
        // fetchContactDetails({
            id: tmpId ?? contactId,
            for_custom_field_update:true
        }).then(response => {
            if (response.data.status === 'success') {

                if(response.data.data !== undefined && response.data.data != null && response.data.data['people'] !== undefined && response.data.data['people'] != null){
                    // alert('asd')
                    setData(response.data.data);
                
                    props.setContactDetails(response.data.data['people']);
                    setLoadingData(false)

                }else{
                    window.showNotification('ERROR',"You don't have access to this contact") 
                    history.push('/contacts');
                }
                

            } else {
                window.showNotification('ERROR',response.data.message) 
                history.push('/contacts');


            }
        }).finally(() => {
        })
    }

    const toggleRightSidebar = useCallback((e) => {
        e.preventDefault();
        document.querySelector("#main__content").classList.toggle("right_sidebar_active");
        setChangeArrowDir(!changeArrowDir)
    },[changeArrowDir])

    const goToContactDetails=(type)=>{

        setLoadingData(true)
        let destId;
        let currentIndex;
        if(type === 'next'){

            currentIndex = props.assignedContactListInfo.currentIndex+1;
            
        }else{
            currentIndex = props.assignedContactListInfo.currentIndex-1

        }
        destId = props.assignedContactListInfo.contactList[currentIndex].id
        history.push({ pathname: `/contacts/${destId}`})

        setContactId(destId)

        let tempData = {
            contactList: props.assignedContactListInfo.contactList,
            totalContacts: props.assignedContactListInfo.totalContacts,
            currentIndex: currentIndex
        }
        props.updateMultipleData({assignedContactListInfo : {...tempData}})
        loadData(destId)

        // history.push({ pathname: `/contacts/${contactId}`, state: { from: 'contactListPage',
        //     contactList: props.assignedContactListInfo.contactList, 
        //     totalContacts: props.assignedContactListInfo.totalContacts ,
        //     currentIndex : props.assignedContactListInfo.currentIndex} })
        // setContactId()
        // loadData()
        // updateMultipleData()
    }
    
    return (
        <React.Fragment>
            <div className="container-fulid single__contact_height updated_single_contact_height">
                <div className="row mt-4 side_content_row mb-0">
                    
                    {/* <div className="col s12"> */}
                            {/* {
                                componentLoading ? (
                                    <div className="left-right-middle-content-body-main" style={centerStyle}>
                                        <Loading/>
                                    </div>
                                ) : ( */}
                                    <React.Fragment>
                                        {
                                            (props.contact != null ) && (
                                                <React.Fragment>
                                                    <LeftSide data={data} contactId={contactId} refreshData={loadData}
                                                    goToContactDetails={goToContactDetails}
                                                    loadingData={loadingData}
                                                    />
                                                        <MiddleSide 
                                                            contactId={contactId}
                                                            loadingData={loadingData}
                                                        />
                                                    <RightSide
                                                        refreshData={loadData}
                                                        data={data}
                                                        contactDetails={data !=null ? data['people'] : null}
                                                        contactId={contactId}
                                                        from={'contact'}
                                                        sourceList={props.storedSourceList}
                                                        loadingData={loadingData}
                                                    />
                                                </React.Fragment>
                                            )
                                        }
                                    </React.Fragment>
                                
                            {/* } */}
                    {/* </div> */}
                </div>
                <div onClick={(e)=>toggleRightSidebar(e)} className="inbox_toggle_btn switch-icon" style={changeArrowDir ? {transform: 'rotate(180deg)'} : {}} >
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.15118 1.57604L12.1408 6.58429L7.15118 11.5925L6.26288 10.6946L9.72788 7.21664H0.800781V5.95193H9.72788L6.26288 2.47398L7.15118 1.57604ZM12.1408 6.58429V12.9078H13.4008V0.260742H12.1408V6.58429Z" fill="white"></path>
                    </svg>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        contact: state.contactReducer.contact,
        storedSourceList: state.contactReducer.sourceList,
		assignedContactListInfo: state.contactReducer.assignedContactListInfo,
		contactListParams: state.contactReducer.contactListParams,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setContactDetails: (params) => dispatch(setContactDetails(params)),
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),
        getAllContactList: (params, callback) => dispatch(getAllContacts(params, callback)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);