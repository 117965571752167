import React, { Component,Fragment } from 'react';
import './Preloader.css'



export default class ButtonLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLoadin: false,
            stateDisable: true,
            stateEnable: false,
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.loading) {
            this.setState({
                stateLoadin: true,
                stateDisable: false,
                stateEnable: false
            });
        } else if (nextProps.content) {
            this.setState({
                stateLoadin: false,
                stateEnable: true,
                stateDisable: false
            });
        }else if (!nextProps.content) {
            this.setState({
                stateLoadin: false,
                stateEnable: false,
                stateDisable: true
            });
        }

    }

    render() {
        let btnClass = (this.props.btnClass) ? this.props.btnClass : "btn m-btn--pill m-btn--custom btn-violate";
        return (
            <Fragment>
                {(this.state.stateLoadin) && (

                    <button disabled type="submit" className={btnClass}>
                        <div className="pre-loader">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>

                )}

                {(this.state.stateDisable) && (
                    <button disabled type="submit" className={btnClass}>
                        {this.props.btnIcon != undefined &&
                            <i className={this.props.btnIcon}></i>
                        }
                        {this.props.btnText}
                    </button>

                )}


                {(this.state.stateEnable) && (
                    <button type="submit" className={btnClass}>
                        {this.props.btnIcon != undefined &&
                            <i className={this.props.btnIcon}></i>
                        }
                        {this.props.btnText}
                    </button>
                )}

            </Fragment>
        );
    }
}


ButtonLoader.defaultProps = {
    btnText: "Submit"
};
