import React from 'react';
import ModalTags from './ModalTags';
import StepProgressBar from './StepProgressBar';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import { Chip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import BottomButtons from './BottomButtons';
import ModalAssignToTagNew from '../contact/contactList/ModalAssignToTagNew';


const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}

const EachTag = (props) => {
    return (
        <>
            <Chip
                avatar={<LocalOfferIcon />}
                label={props.data.name}
                onDelete={props.onClick}
                />
        </>
    )
}

class UserAndTag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen : false,
        };
       this.tagsClick = this.tagsClick.bind(this);
       this.modalToggle = this.modalToggle.bind(this);
    }

    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    prevStep = (e) => {
        e.preventDefault()
        this.props.prevStep()
    }

    tagsClick =()=>{
        this.setState((state) =>({
            isOpen : !state.isOpen
        }));
    }

    modalToggle(){
        this.setState((state) =>({
            isOpen : !state.isOpen
        }));
    }

    tagsRemove = () => {
        this.props.handleChange("newTags");
    }

    tagsRemoveDouble = (row) => {
        let oldtags = this.props.state.oldTags;
        var key = row.name.toUpperCase().trim();

        const oldTagsList = oldtags.filter((data)=> {
            if (data.name.toUpperCase() !== key) {
                return data;
            }
        })

        this.props.handleChange("oldTags", (oldTagsList.length == 0) ? undefined : oldTagsList );
    }

    render() {
        var runningList = null;
        if(this.props.state.oldTags != undefined){
            runningList = this.props.state.oldTags.map((data, index)=> {
                return (<EachTag data={data} key={index} index={index } onClick = { (e) => this.tagsRemoveDouble(data)}/>)
            });
        }

        return (
            <React.Fragment>
                <div className="import_content_container">
                    <div className="main_content_inner_container white">
                        <div className="main_content_topbar">
                            <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                                <HeaderRight />
                            </div>
                        </div>

                        <div class="divider main_content_container_divider"></div>

                        <div className="main_content_main_section">
                            <StepProgressBar fromCampaign={this.props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_ADD_TAG}/>
                        </div>
                            
                        <div class="content_divider"><div class="content_divider_inner"></div></div>

                        <div class="main_section_title_section new_tag_title">
                            <div>
                                <h3 class="center-align">{ this.props.state.total }</h3>
                            </div>
                            <div>
                                <h6 class="center-align">Leads are ready for import!</h6>
                            </div>
                            <div>
                                <p class="center-align">Please review your selections below and click "Import Leads" when you are ready.</p>
                            </div>
                        </div>

                        <div class="divider main_content_main_section_divider"></div>


                        <div className="add_tag_content_section">
                            <div class="add_tag_content_box center">
                                <p>Add tags to these leads (Optional)</p>
                                <div class="add_tag_button">
                                    <a href="javascript:void(0)" onClick={this.tagsClick} class="main_content_container_button d-flex justify-content-center align-items-center">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM13.75 10.4688C13.75 10.5547 13.6797 10.625 13.5938 10.625H10.625V13.5938C10.625 13.6797 10.5547 13.75 10.4688 13.75H9.53125C9.44531 13.75 9.375 13.6797 9.375 13.5938V10.625H6.40625C6.32031 10.625 6.25 10.5547 6.25 10.4688V9.53125C6.25 9.44531 6.32031 9.375 6.40625 9.375H9.375V6.40625C9.375 6.32031 9.44531 6.25 9.53125 6.25H10.4688C10.5547 6.25 10.625 6.32031 10.625 6.40625V9.375H13.5938C13.6797 9.375 13.75 9.44531 13.75 9.53125V10.4688Z" fill="#5D718D"/>
                                        </svg>																				
                                        <span>Add Tag</span>
                                    </a>
                                </div>
                            </div>

                            <div className={(this.props.state.newTags != undefined || this.props.state.oldTags != undefined) ? "tag-link tag-border d-block  m-auto": " tag-link d-inline-block  m-auto"}>
                                <div className="tag-list text-left">
                                {
                                    (this.props.state.newTags != undefined) &&
                                    <div className="tagsList__wrapper">
                                        <h3>New Tag  :</h3> 
                                        <Chip
                                            avatar={<LocalOfferIcon />}
                                            label={this.props.state.newTags}
                                            onDelete={this.tagsRemove}
                                        />
                                    </div>
                                }

                                {
                                    (this.props.state.oldTags != undefined) &&
                                    <div className="tagsList__wrapper">
                                        <h3>Selected Tags:</h3> 
                                        {runningList}
                                    </div>
                                }
                                </div>

                            </div>

                            <BottomButtons 
                                onBackClick={this.prevStep} 
                                onContinueClick={this.saveAndContinue}
                                continueButtonText={'Import Leads'}/>
                        </div>
                    </div>
                </div>
                {/* {
                    this.state.isOpen &&
                    <ModalTags
                        isOpen={ this.state.isOpen }
                        hideModal={() => this.modalToggle()}
                        title={"Add Tags"}
                        handleChange = { this.props.handleChange}
                    />
                } */}

                {
                    this.state.isOpen &&
                    <ModalAssignToTagNew
                        open={this.state.isOpen}
                        title={"Add Tag"}
                        callBack={this.props.handleChange}
                        onClose={() => this.modalToggle()}
                        hideModal={() => this.modalToggle()}
                        from="contact-file-upload"
                    // tagList={tagList}
                    />
                }
            </React.Fragment>
        );
    }
}

export default UserAndTag;
