import React, { Component } from 'react';
import { Radio } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import {connect} from  "react-redux"
import GlobalModal from '../../globals/Modal/GlobalModal';
import { Save, SearchOutlined } from '@material-ui/icons';
import { addContactToList, getContactListForDropdown } from '../../../api/contactApi';
import { storeContactListGroup } from '../../../actions/contactAction';
import NewLoader from '../../common/NewLoader';

const TagNotFound = (props) => {
    return (
        <div>
            <div className="no-results">
                <span className="icon text-danger"><i className="la la-exclamation-triangle"></i></span>
                <span className="text text-danger">Sorry no results found</span>
            </div>
        </div>
    )
}

const EachList = (props) => {

    if((props.data.contact_ids !== undefined && props.data.contact_ids != null && props.data.contact_ids.length > 0) || 
        (props.data.filter_params.operation === "[{\"filter\":{},\"sortField\":\"id\",\"sortOrder\":\"desc\"}]" && 
        props.data.filter_params.columnFilterData  === "{}" && props.data.filter_params.columnFilterData  === "{}")){
        return (

            <li>
                <FormControlLabel
                // eslint-disable-next-line eqeqeq
                    control={<Radio onChange={props.selectList} name="example_2" value={props.data.id} checked={props.data.id == props.selectedList ? true: false}/>} 
                    label={props.data.title}
                />
            </li>
        )
    }else 
        return null
}

class ModalAssignToList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            success: false,
            errorMsg: '',
            successMsg: '',
            allTags: [],
            allListToShow: [],
            storedListGroup:[],
            submitEnable: false,
            selectedList: '',
            submittingContact: false,
            // createNew: false,
            searchKey : null,
            new : false,
            contactTags: [],
            newTagName: '',
        };

    }


    componentDidMount() {
        if(this.props.contactListGroup !== undefined && this.props.contactListGroup != null){



            this.setAllShowList(this.props.contactListGroup)
        }else{
            this.getAllContactList()
        }
    }

    getAllContactList = async () => {
        await getContactListForDropdown()
            .then(response => {
                this.setAllShowList(response.data.data.list)
                this.props.storeContactListGroup(response.data.data.list)
            })
            .catch(error => {
                // alert('something is wrong' + error)
                console.log(error)
            });
    }

    setAllShowList =(initialListGroup)=>{

        if(this.props.contactSavedList !== undefined && this.props.contactSavedList != null && this.props.contactSavedList.length >0){
            this.props.contactSavedList.map((item, index) => (
                initialListGroup = initialListGroup.filter(each => each.id !== item.list_id)
            ))

        }
        this.setState({ allListToShow: initialListGroup, storedListGroup: initialListGroup})

        

    }

    // createNew = (tag) =>{
    //     this.submitTagNew(tag);
    // };


    addToList = () => {
        addContactToList({
            contactIds: this.props.selectedContactIds,
            listId: this.state.selectedList,

        }).then(res => {

            this.setState({
                submittingContact: false
            });

            if (res.data.status === 'error') {
                window.showNotification('ERROR',"validation-error")

                // this.setState({
                //     error: true,
                //     errorMsg: res.data.html
                // });

                // setTimeout(() => {
                //     this.setState({
                //         error: false,
                //         errorMsg: ""
                //     });
                // }, 5000);
            } else if (res.data.status === 'validation-error') {
                // console.log(res.data.html);
                window.showNotification('ERROR',"validation-error")

            } else
            {
                window.showNotification('SUCCESS',"added to List successfully")
                this.props.callBack(false, true)
            }
        });
    };

    handleSubmit = () => {

        if(this.state.selectedList !== ''){
            this.setState({
                submittingContact: true
            });
    
            // if(this.state.createNew){
            //     this.submitTagNew(this.state.newTagName)
    
            // }else{
                this.addToList();
            // }
        }else{
            window.showNotification("ERROR","No List selected")
        }
        
    };

    handelRadio = (e) => {

        // // let alltags = this.state.selectedList;
        // if (e.target.checked) {
        //     // alltags.push(e.target.value)
        //     this.setState({selectedList : e.target.value})
        // } else {
        //     // alltags.splice(alltags.indexOf(e.target.value), 1)
        //     this.setState({selectedList : ''})
        // }

        if(e.target.value === this.state.selectedList){
            this.setState({selectedList:''})
        }else{
            this.setState({selectedList:e.target.value})

        }
    }

    changeSearch = (e) => {
        var searchKey = e.target.value.toUpperCase().trim();

        if(searchKey === ''){
            this.setState({ 
                allListToShow : this.state.storedListGroup,
                searchKey: '',
            })
            return;
        }
        let listData = [];
        listData = this.state.storedListGroup.filter((data)=> {
            if (data.title.toUpperCase().includes(searchKey)) {
                return data;
            }else 
                return null
        });

        // if (listData.length === 0) {
        //     // if(searchKey.length !== 0 && searchKey.length < 30) {
        //         this.setState({
        //             searchKey: searchKey,
        //             newTagName: e.target.value.trim()
        //         });
        //     // }
        // } else {
            this.setState({
                allListToShow: listData,
                searchKey: searchKey,
            })
        // }


    };

    render() {

        var runningList = this.state.allListToShow.map((data, index)=> {
            return (<EachList selectList={this.handelRadio} data={data} key={index} selectedList={this.state.selectedList}/>)
        });

        // if (this.state.createNew) {
            // runningList = (<CreateNewTag createNew={this.createNew} searchKey={this.state.searchKey}/>)
        //     runningList = (<TagNotFound/>)
        // }

        return (

            <GlobalModal {...this.props} 
            title={"Add to List"}
            buttonText={"Add"}
            buttonIcon={<Save/>}
            onSubmit={()=> this.state.submittingContact ? console.log('running ') : this.handleSubmit()}
            hideFooter={false}
            className={'manage-tag-modal modal-assign-campaign update create-new-list global-xs-modal global-small-modal'} >

                {this.state.submittingContact &&
                    <NewLoader loading={this.state.submittingContact} onlyLoader={true} size={26} message={""} />
                }
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="campaign-search-list">
                                                <div className="campaign-search form">
                                                    <div className="form-group m-0">
                                                        <div className="m-input-icon m-input-icon--right assign__tag_search_wrapper">
                                                            <input onChange={this.changeSearch} type="text"
                                                                className="form-control m-input customFormControlField"
                                                                placeholder="Type and search"/>
                                                            <SearchOutlined className="assign__searchIcon" />
                                                            <span className="m-input-icon__icon m-input-icon__icon--right">
                                                                <span>
                                                                    <i className="flaticon-search-1"/>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="campaign-search-results">
                                                    {/* {this.state.createNew &&
                                                    <CreateNewTag createNew={this.createNew} searchKey={this.state.searchKey} />
                                                    } */}
                                                    <ul className="m-nav height200px awesome__scroll_bar update__list_item">
                                                        {(this.state.allListToShow === undefined || this.state.allListToShow == null || !Array.isArray(this.state.allListToShow) || this.state.allListToShow.length === 0 )? <TagNotFound/> : runningList}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </GlobalModal>
        );
    }
}

const mapStateToProps = state => {
    return {
        storedContactListGroup: state.contactReducer.storedContactListGroup
    };
};

const mapDispatchToProps = dispatch => {
    return {
        storeContactListGroup: (params, callback) => dispatch(storeContactListGroup(params, callback)),


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignToList);
