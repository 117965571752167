import * as ACTION from "../constants/calendarActionTypes";

const initialState = {
    activeTabActivityCalendar : ACTION.CALENDAR_TAB_VIEW,
    activeTaskId : ACTION.TASK_ALL,
    activeFilterId : ACTION.FILTER_PLANNED,
    activityCalendar : [],
    activityList : [],
    taskCount : [],
    teamUsers : [],
    activityLoading : true,

    /* for update task */
    updateTask: false
};

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.ACTIVITY_CALENDAR:
            return {...state,
                activityLoading : false,
                activityCalendar: action.payload,
                updateTask: false
            }

        case ACTION.ACTIVITY_LIST:
            return {...state, activityList: action.payload}

        case ACTION.TASK_COUNT:
            return {...state, taskCount: action.payload}

        case ACTION.UPDATE_VIEW_ACTIVE_TAB:
            return {...state, activeTabActivityCalendar: action.payload}

        case ACTION.UPDATE_TASK_ACTIVE_TAB:
            return {...state, activeTaskId: action.payload}

        case ACTION.UPDATE_FILTER_ACTIVE_TAB:
            return {...state,activeFilterId: action.payload}

        case ACTION.ACTIVITY_LIST_LOADING:
            return {...state,activityLoading: action.payload}
            
        case ACTION.UPDATE_TASK_UPDATE_FLAG:
            return {...state, updateTask: action.payload}

        default:
            return state;
    }
}

export default calendarReducer;
