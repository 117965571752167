import React, {Component} from 'react';
import {connect} from "react-redux";
import If, { Else } from 'if-else-react';
import {updateContactInfo, updateContactInfoWithCallback} from "../../../actions/contactAction";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomConfirmAlert from '../../custom-alert/CustomConfirmAlert';

class SelectField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key:this.props.name?this.props.name:'',
            selectValue:''
        };
    }

    fieldValueChange = e =>{
        CustomConfirmAlert({
            handleConfirm : () => {
                let self = this;
                this.setState({key:e.target.name,inputValue:e.target.value},()=>{
                    // this.props.updateContactInfo({
                    //     contactId: this.props.contact.id,
                    //     key: this.state.key,
                    //     value: this.state.inputValue?this.state.inputValue:this.props.value,
                    // })
                    this.props.updateContactInfoWithCallback({
                        contactId: this.props.contact.id,
                        key: this.state.key,
                        value: this.state.inputValue?this.state.inputValue:this.props.value
                    }, (res) => {
                        let response = res.original
                        if(response.success == true){
                            if(this.props.redirect !== undefined){
                                this.props.redirect()
                            }
                        }
                    })
                })
            },
            handleOnClose: () => {
            },
            title: 'Are you sure?',
            description: 'Do you want to deactivate this contact? This contact will be removed from all assigned campaigns !',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: "Yes, Inactive.",
            callConfirmLast: true,
            type_word: 'INACTIVE',
        })
    }

    updateMode = e =>{
        e.preventDefault()

    }
    render() {
        const {label,name,value}=this.props;
        const {selectValue}=this.state;
        return (
            <React.Fragment>
                <h6>{label}</h6>
                <div className="text-field-item lead__status__select">
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name={name}
                            value={selectValue?selectValue:this.props.contact && this.props.contact.status}
                            onChange={(e) => this.fieldValueChange(e)}>
                            <MenuItem className="dropdownhelper-menuitem-class" value={0}>Active</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={1}>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contact: state.contactReducer.contact,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateContactInfo: (params) => dispatch(updateContactInfo(params)),
        updateContactInfoWithCallback: (params, callback) => dispatch(updateContactInfoWithCallback(params, callback))
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (SelectField);