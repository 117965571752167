import React, {Component} from 'react';
import {connect} from "react-redux";
import If from 'if-else-react';
import {updateContactInfo} from "../../../actions/contactAction";
import {updateAditionalEmailPhone} from "../../../api/contactApi"

class TextInputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key:this.props.name?this.props.name:'',
            inputValue:'',
            secondaryKey:'',
            secondaryValue:'',
            isOpen: false,
            isDisabled : true,
            showEditButton:true,
            showUpdateButton:false,
            addField:false,
            removeNewField:false
        };
    }

   editMode = ()=>{
        this.setState({
            isDisabled: false,
            showUpdateButton: true,
            showEditButton: false,

        })
   }

   fieldValueChange = e =>{
        this.setState({key:e.target.name,inputValue:e.target.value})
   }

    updateMode = e =>{
        e.preventDefault()
        this.props.updateContactInfo({
            contactId: this.props.contact.id,
            key: this.state.key,
            value: this.state.inputValue?this.state.inputValue:this.props.value
        })
        this.setState({isDisabled: true, showUpdateButton: false, showEditButton: true})
    }
    addNewField = e =>{
        this.setState({addField:!this.state.addField,removeNewField:!this.state.removeNewField})
    }

    secondaryFieldValueChange = e =>{
        this.setState({secondaryKey:e.target.name,secondaryValue:e.target.value})
    }
    updateAnotherField = (e)=>{
        e.preventDefault()
        if(this.state.secondaryValue!=='' && this.state.secondaryValue!==''){
            updateAditionalEmailPhone({
                contactId: this.props.contact.id,
                key: this.state.secondaryKey,
                value: this.state.secondaryValue
            })
        }
    }

    render() {
        const {isDisabled,showEditButton,showUpdateButton,addField,removeNewField}=this.state;
        const {label,name,value,addNew,editable,multiple}=this.props;
        return (
            <React.Fragment>
                <div className="d-flex align-items-center">
                    <h6>{label}</h6>
                    <If condition={addNew}>
                        <If condition={!removeNewField}><span className="small-round-icon ml-3" onClick={(e)=>this.addNewField(e)}><i className="material-icons">add</i></span></If>
                        <If condition={removeNewField}><span className="small-round-icon ml-3" onClick={(e)=>this.addNewField(e)}><i className="material-icons">remove</i></span></If>
                    </If>
                </div>
                <div className="text-field-item">
                    <input className="text-field text-gray" onKeyUp={this.fieldValueChange} name={name} type="text" disabled={isDisabled} defaultValue={value}/>
                    <If condition={editable}>
                    <If condition={showEditButton}><span className="small-round-icon" onClick={()=>this.editMode()}><i className="material-icons">edit</i></span></If>
                    <If condition={showUpdateButton}><span className="small-round-icon" onClick={(e)=>this.updateMode(e)} ><i className="material-icons">save</i></span></If>
                    </If>
                </div>
                <If condition={multiple && addField}>
                    <div className="text-field-item">
                        <input className="text-field text-gray" onKeyUp={this.secondaryFieldValueChange} name={name} type="text"  />
                        <If condition={editable}>
                            <span className="small-round-icon" onClick={(e)=>this.updateAnotherField(e)} ><i className="material-icons">save</i></span>
                        </If>
                    </div>
                </If>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contact: state.contactReducer.contact,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateContactInfo: (params) => dispatch(updateContactInfo(params))
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (TextInputField);