import React, { useState } from 'react';
import moment from "moment";
import DateTimeSetting from '../globals/DateTimeSetting';
import If from 'if-else-react';
import StepProgressBar from './StepProgressBar';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import BottomButtons from './BottomButtons';
import Utils from '../../helpers/Utils';
import './process-mode.css'

const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}

const TYPE_MINUTE = 1
const TYPE_HOUR = 2
const TYPE_DAY = 3

const QUANTITY_MINUTE_MAX = 20;
const QUANTITY_HOUR_MAX = 200;
const QUANTITY_DAY_MAX = 1000;

const process_type = [
    {id: 1, value: TYPE_MINUTE, label: 'Minute'},
    {id: 1, value: TYPE_HOUR, label: 'Hour'},
    {id: 1, value: TYPE_DAY, label: 'Day'}
]

const ProcessMode = props => {

    const [date,setDate] = useState(props.state.batch_date);
    const [time,setTime] = useState(props.state.batch_time);
    const [batchQuantity,setBatchQuantity] = useState(props.state.batch_quantity);
    const [repeatAfterDays,setRepeatAfterDays] = useState(props.state.batch_repeat_after);
    const [sendOn,setSendOn] = useState(props.state.batch_send_on);
    const [processAllAtOnce,setProcessAllAtOnce] = useState(props.state.batch_mode);

    const [processType, setProcessType] = useState(TYPE_MINUTE)

     /* for quantity limit */
     const [maxQuantity, setMaxQuantity] = useState(QUANTITY_MINUTE_MAX)

    const validateBatchForm = () => {
        if (isNaN(batchQuantity)) {
            Utils.showNotification("Must be a valid quantity","ERROR");
            return false;
        } else if (batchQuantity <= 0) {
            Utils.showNotification("Quantity must be greater than 0","ERROR");
            return false;
        }
        
        // if (isNaN(repeatAfterDays)) {
        //     Utils.showNotification("Must be a valid repeat after days value","ERROR");
        //     return false;
        // } else if (repeatAfterDays <= 0 || repeatAfterDays > 100) {
        //     Utils.showNotification("Repeat after days must be between 1 to 100","ERROR");
        //     return false;
        // }

        if(!Object.values(sendOn).includes(true)) {
            Utils.showNotification("Please select minimum one send on day.","ERROR");
            return false;
        }

        return true;
    }

    const saveAndContinue = (e) => {
        e.preventDefault();
        props.handleChange("batch_mode", processAllAtOnce);
        
        if(processAllAtOnce == 0){
            
            if(!validateBatchForm()){
                return;
            }

            props.handleChange("batch_time",time);
            props.handleChange("batch_date",date);
            props.handleChange("batch_start_on", moment(date).format('YYYY-MM-DD') + ' ' + moment(time).format('HH:m:s'));
            props.handleChange("batch_quantity", batchQuantity);
            props.handleChange("batch_process_type", processType);
            props.handleChange("batch_repeat_after", repeatAfterDays);
            props.handleChange("batch_send_on", sendOn);
            props.handleChange("batch_form", {
                'start_from' : moment(date).format('YYYY-MM-DD') + ' ' + moment(time).format('HH:m:s'),
                'quantity' : batchQuantity,
                'repeat_after_days' : repeatAfterDays,
                'send_on' : sendOn,
                'batch_process_type' : processType
            });
        }else{
            props.handleChange("batch_form", {});
        }

        props.nextStep();

    }

    const prevStep = (e) => {
        e.preventDefault()
        props.prevStep();
    }

    const timeSetup = (value,state) => {
        if(state === 'time') {
            setTime(value);
        } else {
            setDate(value);
        }
    }

    const getSelectDays = () => {
        return Object.keys(sendOn).map((item,i) => 
            (<button 
                className={"btn btn-sm mr-1 "+((sendOn[item] !== true)?"btn-info":"btn-light days-button")}
                onClick={() => setSendOn(Object.assign({}, sendOn, {[item]: !sendOn[item]})) }
                >{item}</button>));
    }

    const getProcessTypes = () => {
        let view = [];
        process_type.forEach((item, index) => {
            view.push(
                <option value={item.value} key={index}>{item.label}</option>
            )
        })
        return view;
    }

    const handleProcessTypeChange = (e) => {
        let value = e.target.value;
        if(value == TYPE_HOUR){
            if(repeatAfterDays > 23){
                setRepeatAfterDays(23)
            }
            if(batchQuantity > QUANTITY_HOUR_MAX){
                setBatchQuantity(QUANTITY_HOUR_MAX)
            }
            setMaxQuantity(QUANTITY_HOUR_MAX)
        }
        else if(value == TYPE_DAY){
            if(repeatAfterDays > 365){
                setRepeatAfterDays(365)
            }
            if(batchQuantity > QUANTITY_DAY_MAX){
                setBatchQuantity(QUANTITY_DAY_MAX)
            }
            setMaxQuantity(QUANTITY_DAY_MAX)
        }else{
            if(repeatAfterDays > 59){
                setRepeatAfterDays(59)
            }
            if(batchQuantity > QUANTITY_MINUTE_MAX){
                setBatchQuantity(QUANTITY_MINUTE_MAX)
            }
            setMaxQuantity(QUANTITY_MINUTE_MAX)
        }
        setProcessType(parseInt(value))
    }

    const handleRepeatTimeChange = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setRepeatAfterDays("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }
            if(processType == TYPE_MINUTE){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 59){
                    setRepeatAfterDays(59)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            else if(processType == TYPE_HOUR){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 23){
                    setRepeatAfterDays(23)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            else if(processType == TYPE_DAY){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 365){
                    setRepeatAfterDays(365)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
        }
    }

    const onFocusOutTime = (e) => {
        if(e.target.value === ""){
            setRepeatAfterDays(1)
        }
    }

    const handleBatchQuantity = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setBatchQuantity("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }
            if(value > maxQuantity){
                setBatchQuantity(maxQuantity)
            }
            else{
                setBatchQuantity(value)
            }
        }
    }

    return (
        <>
            <div className="import_content_container">
                <div className="main_content_inner_container white">
                    <div className="main_content_topbar">
                        <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                            <HeaderRight />
                        </div>
                    </div>

                    <div class="divider main_content_container_divider"></div>

                    <div className="main_content_main_section">
                        <StepProgressBar fromCampaign={props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_PROCESS_MODE}/>
                    </div>

                    <div class="content_divider"><div class="content_divider_inner"></div></div>

                    <div class="main_section_title_section new_deal_title">
                        <div>
                            <h6 class="center-align">Process Mode</h6>
                        </div>
                        <div>
                            <p class="center-align">Choose how you'd like to handle the import process.</p>
                        </div>
                    </div>

                    <div class="divider main_content_main_section_divider"></div>

                    <div className="create_deal_content_section">

                        <div class="create_deal_toggle_section">
                            <div class="create_deal_toggle_button_section center-align">
                            <div class="duplicate_rule_content">
                                <form action="#" class="duplicate_rule_form">
                                    <p class={`${processAllAtOnce === 1 ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" value={1} name={'process_all_at_once'} checked={processAllAtOnce === 1} onChange={() => setProcessAllAtOnce(1)} class="filled-in" />
                                            <span>Process all at once</span>
                                        </label>
                                    </p>
                                    <p class={`${processAllAtOnce === 0 ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" value={0} name={'process_all_at_once'} onChange={() => setProcessAllAtOnce(0)} checked={processAllAtOnce === 0} class="filled-in"/>
                                            <span>Process in drip mode</span>
                                        </label>
                                    </p>
                                </form>
                            </div>

                                <If condition={processAllAtOnce == 0}>
                                    <div className="form-group m-form__group row process__drip_mode ">
                                        <div className="col-md-8">
                                            <label htmlFor="start_on" className="form-control-label label__borer__bottom__wr__v2">
                                                Start On <span className="text-danger">*</span>
                                            </label>
                                            <DateTimeSetting timeSettingValue={timeSetup} time={time} date={date}/>
                                        </div>

                                        <div className="row d-flex batch__process__container__v2">
                                            <div className="batch__process__container__v2__single col-md-6 text-left">
                                                <label htmlFor="batch_quantity" className="form-control-label common__label__v3"> Process Type </label>
                                                <select name="process_type" className="d-block" value={processType} onChange={handleProcessTypeChange}>
                                                    {getProcessTypes()}
                                                </select>
                                            </div>

                                            <div className="batch__process__container__v2__single col-md-6 text-left">
                                                <label htmlFor="batch_quantity" className="form-control-label common__label__v3"> Batch Quantity <span className="text-danger">*</span> </label>
                                                <input className="form-control global__form_control" id="batch_quantity" name="batch_quantity" value={batchQuantity} onChange={handleBatchQuantity}/>
                                                <span><small>Max quantity limit: {maxQuantity}</small></span>
                                            </div>

                                            <div className="batch__process__container__v2__single col-md-6 text-left">
                                                <label htmlFor="repeat_after_days" className="form-control-label common__label__v3"> Repeat After ({processType === TYPE_MINUTE ? "Minutes" : (processType === TYPE_HOUR ? "Hours" : "Days")}) <span className="text-danger">*</span> </label>
                                                <input className="form-control global__form_control" id="repeat_after_days" name="repeat_after_days" value={repeatAfterDays} onChange={handleRepeatTimeChange} onBlur={onFocusOutTime} />
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <label className="form-control-label">
                                                Assign on campaign <span className="text-danger">*</span>
                                            </label>
                                            <div className="sendOnButtons">
                                                    {getSelectDays()}
                                                </div>
                                        </div>
                                    </div>
                                </If>
                            </div>  
                        </div>

                        <BottomButtons 
                            onBackClick={prevStep} 
                            onContinueClick={saveAndContinue}
                            continueButtonText={'Save & Continue'}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProcessMode;
