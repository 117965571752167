import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


class File extends Component {
    constructor() {
        super()
        this.state = {
            checked: false
        }
    }

    deleteFile = (e)=>{
        confirmAlert({ 
            title: 'Confirm to delete',
            message: 'Are you sure to delete this file.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.props.deleteFile(this.props.data.id, this.props.contactId, this.props.data.file_name, this.props.index);
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    };

    imagePopup = (e) => {
        e.preventDefault();
    };


    render() {


        return (
            <div className="files_tab_single_file d-flex justify-content-between align-items-center p-2">
                <h6 className="text-gray">{this.props.data.file_original_name}</h6>
                {/* <p className="files_tabs_time text-light-blue">April 28th 2021 12:51 pm</p> */}
                <div className="d-flex">
                    <a target="_blank" download className="image-popup-container" href={this.props.data.url ? this.props.data.url : process.env.REACT_APP_S3_SERVER_URL + "contact/"+ this.props.contactId + "/" +this.props.data.file_name} rel="noreferrer">
                        <svg className="mr-4" width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.59624 20.5269C7.59644 20.5271 7.59663 20.5272 7.59683 20.5274C7.6209 20.5514 7.64624 20.5742 7.67256 20.5959C7.68462 20.6058 7.69736 20.6144 7.70976 20.6237C7.72455 20.6348 7.73906 20.6462 7.75444 20.6565C7.76928 20.6665 7.78466 20.6751 7.79989 20.6841C7.81386 20.6925 7.82748 20.7012 7.84183 20.7089C7.85736 20.7172 7.87332 20.7242 7.88919 20.7317C7.90413 20.7388 7.91888 20.7462 7.93421 20.7526C7.94959 20.7589 7.96531 20.7641 7.98093 20.7697C7.99739 20.7756 8.0136 20.7819 8.03039 20.787C8.04602 20.7917 8.06189 20.7952 8.07771 20.7991C8.09484 20.8035 8.11178 20.8082 8.12926 20.8117C8.14757 20.8153 8.16603 20.8175 8.18444 20.8201C8.19962 20.8223 8.21461 20.8252 8.22999 20.8267C8.29835 20.8334 8.36724 20.8334 8.43559 20.8267C8.45097 20.8252 8.46596 20.8223 8.48115 20.8201C8.49955 20.8175 8.51801 20.8153 8.53632 20.8117C8.5538 20.8082 8.57074 20.8035 8.58788 20.7991C8.60365 20.7952 8.61956 20.7917 8.63519 20.787C8.65198 20.7819 8.66824 20.7756 8.68465 20.7697C8.70027 20.7641 8.71599 20.7589 8.73137 20.7526C8.7467 20.7462 8.76145 20.7388 8.77639 20.7317C8.79226 20.7242 8.80822 20.7172 8.82375 20.7089C8.8381 20.7012 8.85173 20.6924 8.86569 20.6841C8.88092 20.6751 8.8963 20.6665 8.91115 20.6565C8.92653 20.6462 8.94103 20.6348 8.95582 20.6237C8.96822 20.6144 8.98097 20.6058 8.99303 20.5959C9.01934 20.5742 9.04468 20.5515 9.06875 20.5274C9.06895 20.5272 9.06914 20.5271 9.06934 20.5269L16.3605 13.2357C16.7673 12.8289 16.7673 12.1694 16.3605 11.7627C15.9538 11.3559 15.2942 11.3559 14.8875 11.7627L9.37435 17.2757V1.04158C9.37435 0.466327 8.90802 0 8.33277 0C7.75751 0 7.29118 0.466327 7.29118 1.04158V17.2757L1.77812 11.7627C1.37136 11.3559 0.711831 11.3559 0.30507 11.7627C-0.10169 12.1694 -0.10169 12.8289 0.30507 13.2357L7.59624 20.5269Z" fill="#133159"></path>
                            <path d="M15.6239 22.917H1.04158C0.466327 22.917 0 23.3833 0 23.9586C0 24.5338 0.466327 25.0002 1.04158 25.0002H15.624C16.1992 25.0002 16.6656 24.5339 16.6656 23.9586C16.6656 23.3834 16.1992 22.917 15.6239 22.917Z" fill="#133159"></path>
                        </svg>
                    </a>
                    
                    <svg className="cursor-pointer" width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.deleteFile}>
                        <path d="M2.69033 23.7944C2.71854 24.4682 3.27302 25 3.94734 25H15.6925C16.3669 25 16.9213 24.4682 16.9495 23.7944L17.7883 6.08789H1.85156L2.69033 23.7944ZM12.4768 10.4859C12.4768 10.2037 12.7056 9.97491 12.9879 9.97491H13.8052C14.0873 9.97491 14.3162 10.2037 14.3162 10.4859V20.602C14.3162 20.8842 14.0875 21.113 13.8052 21.113H12.9879C12.7057 21.113 12.4768 20.8843 12.4768 20.602V10.4859ZM8.9003 10.4859C8.9003 10.2037 9.12907 9.97491 9.41127 9.97491H10.2286C10.5107 9.97491 10.7396 10.2037 10.7396 10.4859V20.602C10.7396 20.8842 10.5109 21.113 10.2286 21.113H9.41127C9.12913 21.113 8.9003 20.8843 8.9003 20.602V10.4859ZM5.32363 10.4859C5.32363 10.2037 5.5524 9.97491 5.8346 9.97491H6.65201C6.93414 9.97491 7.16298 10.2037 7.16298 10.4859V20.602C7.16298 20.8842 6.93421 21.113 6.65201 21.113H5.8346C5.55246 21.113 5.32363 20.8843 5.32363 20.602V10.4859Z" fill="#EB5757"></path>
                        <path d="M18.3433 1.28786H12.9248V0.263471C12.9248 0.117996 12.8069 0 12.6614 0H6.97142C6.82595 0 6.70801 0.117996 6.70801 0.263471V1.2878H1.2895C0.853437 1.2878 0.5 1.6413 0.5 2.07735V4.55766H19.1328V2.07742C19.1328 1.64136 18.7794 1.28786 18.3433 1.28786Z" fill="#EB5757"></path>
                    </svg>

                </div>
            </div>
        );
    }
}

export default File;
