import React, {useContext, useEffect} from "react";
import Styles from "./DataClean.module.css";
import Verify from "./Verify";
import FileUpload from "./fileUpload";
import {DataCleanContext, DataCleanProvider} from "./StateProvider";
import { TAB_CLEAN_LIST, TABS } from "./Constants";
import ActionTypes from "./ActionTypes";
import FileReport from "./report/FileReport";
import FileReportDetails from "./report/FileReportDetails";
import FileReportDetailsSingle from "./report/FileReportDetailsSingle";

const App = () => {
    const {state, dispatch} = useContext(DataCleanContext);

    useEffect(() => {
        if (window.setActiveSidebar) {
            window.setActiveSidebar("clean-data");
        }
    }, []);

    const GetTab = () => {
        switch (state.activeTab){
            case TAB_CLEAN_LIST.VERIFY:
                return <Verify/>;
            case TAB_CLEAN_LIST.REPORTS:
                return <FileReport/>;
            case TAB_CLEAN_LIST.REPORTS_DETAILS:
                return <FileReportDetails/>;
            case TAB_CLEAN_LIST.REPORTS_SINGLE:
                return <FileReportDetailsSingle/>;
            default:
                return <div>Invalid Tab. Please reload the page</div>;
        }
    };

    return (
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <div className={Styles.pageHeaderLeft}>
                    <div className={Styles.pageTab}>
                        {
                            state.isFileSelected ?
                             <h2 className={Styles.importTabHeader}>Import CSV file for data cleaning</h2> :
                                TABS.map((tab)=>(
                                    <div className={`${Styles.pageTabItem} ${tab.code.includes(state.activeTab)? Styles.activeTab : ""}`} onClick={()=>{
                                        if (state.activeTab === TAB_CLEAN_LIST.REPORTS_DETAILS && tab.code[0] === TAB_CLEAN_LIST.REPORTS){
                                            dispatch({
                                                type: ActionTypes.UPDATE_STATE,
                                                payload: {
                                                    activeTab: TAB_CLEAN_LIST.REPORTS_DETAILS
                                                }
                                            });
                                        }else {
                                            dispatch({
                                                type: ActionTypes.UPDATE_STATE,
                                                payload: {
                                                    activeTab: tab.code[0]
                                                }
                                            });
                                        }
                                    }}>
                                        {tab.label}
                                    </div>
                                ))
                        }
                    </div>
                </div>
            </div>
            <div className={Styles.pageBody}>
                {
                    state.isFileSelected ?
                        <FileUpload/> :
                        <GetTab/>
                }
            </div>
        </div>
    );
}

const AppWrapper = () => {
    return (
        <DataCleanProvider>
            <App/>
        </DataCleanProvider>
    )
}

export default AppWrapper;