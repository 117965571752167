import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneIcon from "@material-ui/icons/Phone";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { confirmAlert } from 'react-confirm-alert';
import {deleteList} from '../../../api/autoDialerApi'
import {AUTO_DIALER} from "../../../constants/CoreConstants";
import GlobalConfirmAlert from "../../globals/GlobalConfirmAlert";

const DialingListAction = props => {

    const listDelete = listId => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'You will not be able to recover this data. Do you want to remove this dialing list?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        deleteList({
                            listId: listId
                        }).then(response => {
                            if (response.data.status) {
                                window.showNotification('SUCCESS', response.data.message);
                                props.fetchDialingList();
                            } else {
                                window.showNotification('ERROR', response.data.message);
                            }
                        }).catch(error => {
                            console.error(error);
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }



    const initiateAutoDialer = () => {
        if (props.row.total_contact === 0) {
            window.showNotification('ERROR', 'Oops! No contact in the list. Please add contact first!');
        }

        if(props.row.status !== AUTO_DIALER.STATUS.COMPLETED){

            GlobalConfirmAlert({title:'Are you sure?',message:'you will starting call this whole list ?',
            successFunction: ()=>{window.initiateAutoDialer ? window.initiateAutoDialer(props.row) : console.log('not proceed')}
            , failFunction: ()=>{return;}})
            
        }else{
            GlobalConfirmAlert({title:'Are you sure?',message:'you already completed calling this list. Do you want to call the full list again ?',
            successFunction: successFunction, failFunction: ()=>{return;}})
        }
        
    }

    const successFunction=()=>{
        if (window.initiateAutoDialer) {
            window.initiateAutoDialer(props.row);
        }
    }

    return (
        <div className="option__icons">
            {/* {props.row.status !== AUTO_DIALER.STATUS.COMPLETED && */}
                <BootstrapTooltip title="Start Dialing" arrow placement="top">
                    <PhoneIcon onClick={()=> initiateAutoDialer()} />
                </BootstrapTooltip>
            {/* } */}
            <BootstrapTooltip title="Edit" arrow placement="top">
            <EditIcon onClick={()=> props.editDialingList()} />
            </BootstrapTooltip>
            
            <BootstrapTooltip title="Delete" arrow placement="top">
                <DeleteIcon onClick={()=> listDelete(props.row.id)} />
            </BootstrapTooltip>
        </div>
    );
}

export default DialingListAction;