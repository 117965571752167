import React, {Component} from 'react';
import {connect} from "react-redux";
import ShareContactModal from './ShareContactModal';
import Helper from '../../globals/Helper';
import Utils from "../../../helpers/Utils";
class AvatarComponet extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isOpen: false,
            firstNameInvalidError : false,
            firstNameLengthError : false,
            lastNameInvalidError : false,
            lastNameLengthError : false,
            showShareContactModal: false,
            dncStatus: "NOT_VERIFIED"
        };   
    }

    componentDidMount() {
        let contacts = this.props.contacts
        if(contacts.contact_additional_informations && contacts.contact_additional_informations.dnc_status){
            this.setState({dncStatus: contacts.contact_additional_informations.dnc_status})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let contacts = this.props.contacts;
        if(contacts.contact_additional_informations && contacts.contact_additional_informations.dnc_status
            && contacts.contact_additional_informations.dnc_status !== this.state.dncStatus){
                this.setState({dncStatus: contacts.contact_additional_informations.dnc_status})
        }
    }


    render() {
        const {contacts}=this.props;
        return (
            <React.Fragment>
                <div className="user__profile__wr position-relative">
                    <div className="user__profile__avater">
                        <span className="user__profile__h2">{Helper.getIconTextFromContact(contacts)}</span>
                        <span className="edit_share_icon edit_share_icon-alt">
                            <i className="material-icons text-light-blue" style={{cursor: 'pointer'}} onClick = {() => {
                                this.setState({
                                    showShareContactModal : true
                                })
                            }}>
                                share
                            </i>
                        </span>
                    </div>
                </div>
                
                <div className="user_profile_name d-flex justify-content-center align-items-center fullNameUser">
                    {contacts!=null &&
                    <h5 className="overwrite-h5">{!Helper.isEmpty(contacts.first_name) ? contacts.first_name  + ' ' : ''}{!Helper.isEmpty(contacts.last_name) ? contacts.last_name : ''}</h5>
                    }
                    {Utils.getAccountData("carrierLookup") && this.state.dncStatus === "DO_NOT_CALL" &&

                        <div className="dncStatusChip">DNC</div>
                    }
                </div>
                {this.state.showShareContactModal &&
                    <ShareContactModal
                        title={'Share contact information'}
                        isOpen={this.state.showShareContactModal}
                        open={this.state.showShareContactModal}
                        contact_id={contacts.id}
                        closeModal = {() => {
                            this.setState({
                                showShareContactModal : false
                            })
                        }}
                        onClose={()=>this.setState({
                            showShareContactModal : false
                        })}
                        showNotificationStart={this.props.showNotificationStart}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.contactReducer.contact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (AvatarComponet);