
const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`

const ApiDripBatch = {
    getDripBatchReportByPagination: `${rootUrl}/batches-list`,
    submitDripBatchTitleEdit: `${rootUrl}/batch-title-edit-form-submit`,
    getDripBatchContactList: `${rootUrl}/batch-added-contact-list`,
    deleteDripBatch: `${rootUrl}/delete-drip-batch`,
    getAllBatchFrames: `${rootUrl}/get-batch-frames`,
    getAllFrameContacts: `${rootUrl}/get-frame-contacts`
}
export default ApiDripBatch;