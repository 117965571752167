import { Paper, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getCustomFields } from "../../../api/contactApi";
import { IconList } from "../../globals/IconList";
import { TabPanel, useImportListPaperStyle, useImportListTabsStyle, useImportListTabStyle } from "./ImportContactStyles";
import MapcontactHelper from "./MapcontactHelper.js";
import MappedTable from "./MappedTable";
import ActionNeededTable from "./ActionNeededTable";
// import ModalImport from './ModalImport';


const MapContact = (props) => {

	const [value, setValue] = useState(0);
	const [mappedRows,setMappedRows] = useState([]);
	const [actionNeededRows,setActionNeededRows] = useState([]);
	const [selectOptions,setSelectOptions] = useState([]);
	const [selectedFields,setSelectedFields] = useState({});
	const [submitData,setSubmitData] = useState({});
	// const [isOpen,setIsOpen] = useState(false);
	// const [newCustomData,setNewCustomData] = useState(null);

	const tabClasses = useImportListTabStyle();
	const tabsClasses = useImportListTabsStyle();
	const paperClasses = useImportListPaperStyle();

	useEffect(() => {
		getCustomFields({}).then(res => {
			let customFields = [];
			res.data.data.forEach((row) => {
				customFields.push({
					label : row.title,
					value : "customFields_"+row.id,
				});
			});
			setSelectOptions(MapcontactHelper.getSelectOptions(customFields));
		});
	},[]);

	useEffect(() => {
		//Just rotating the data array
		if(props.data && props.data.length > 0) {
			let mappedData = MapcontactHelper.mapData(props.data);
			setSelectedFields(mappedData.selectedRows);
			setTimeout(() => {
				setMappedRows(mappedData.mappedRows)
			}, 1000);
			setActionNeededRows(mappedData.actionNeededRows);
			setSubmitData(mappedData.submitData);
		}
	},[props.data]);

	useEffect(() => {
		props.callBack(submitData);
	},[submitData])

	const handleActionNeededOptionChange = (e,index) => {
		const actionNeedArr = [...actionNeededRows];
		const actionNeedValue = actionNeedArr[index];
		actionNeedArr.splice(index, 1);
		setActionNeededRows(actionNeedArr);
		setMappedRows([...mappedRows,actionNeedValue]);
		setSelectedFields({...selectedFields,[actionNeedValue.index] : e});

		let splitData = e.value.split("_");
		if(splitData[0] == "customFields"){
			setSubmitData({...submitData,[actionNeedValue.index] : {map:'custom['+actionNeedValue.index+']',data:e.value.replace('customFields_', '')}});
		} else {
			setSubmitData({...submitData,[actionNeedValue.index] : {map:'data['+actionNeedValue.index+']',data:e.value}});
		}
		
	}

	const handleMappedOptionChange = (e,index) => {
		
		if (e.value == '') {
			const mappedRowsArr = [...mappedRows];
			const mappedValue = mappedRowsArr[index];
			mappedRowsArr.splice(index, 1);
			setMappedRows(mappedRowsArr);
			setActionNeededRows([...actionNeededRows,mappedValue]);
			
			const selectedFieldsObj = {...selectedFields};
			delete selectedFieldsObj[mappedValue.index];
			setSelectedFields(selectedFieldsObj);
			setSubmitData({...submitData,[mappedValue.index] : {map:'data['+mappedValue.index+']',data:""}});
		} else {
			setSelectedFields({...selectedFields,[mappedRows[index].index] : e});

			let splitData = e.value.split("_");
			if(splitData[0] == "customFields"){
				setSubmitData({...submitData,[mappedRows[index].index] : {map:'custom['+mappedRows[index].index+']',data:e.value.replace('customFields_', '')}});
			} else {
				setSubmitData({...submitData,[mappedRows[index].index] : {map:'data['+mappedRows[index].index+']',data:e.value.replace('customFields_', '')}});
			}
		}
	}

	// const onChangeCustomData = (customData) =>{
	// 	let submitDataObj = submitData;
    //     setSubmitData({...submitData,
	// 		[customData.index] : {
	// 			newCustomsData : {
	// 				'value' : "newCustomFields_"+customData.type,
	// 				'label' : submitDataObj[customData.index].label,
	// 				'index' : customData.index,
	// 				'type' : customData.type
	// 			}
	// 		}
	// 	});
    //     setIsOpen(false);
    // }

	return ( 
		<div class="main_contain_detail_tab_section white">
			<Paper square classes={paperClasses}>
				<Tabs
					value={value}
					classes={tabsClasses}
					indicatorColor="primary"
					textColor="primary"
					onChange={(e,value) => setValue(value)}
					aria-label="disabled tabs example"
					className="contact__import_tab"
				>
					<Tab classes={tabClasses} icon={IconList.contactImportTab1} label={`Mapped (${mappedRows.length})`}/>
					<Tab classes={tabClasses} icon={IconList.contactImportTab2} label={`Action Needed (${actionNeededRows.length})`}/>

				</Tabs>
			</Paper>
			<div>
				<TabPanel value={value} index={0}>
					<div id="tab1">
						<MappedTable
							rows={mappedRows}
							selectOptions={selectOptions}
							onOptionChange={handleMappedOptionChange}
							selectedFields={selectedFields}
						/>
						<UploadAndNext onSubmit={props.onSubmit}/>
					</div>
				</TabPanel>

				<TabPanel value={value} index={1}>
					<div id="tab2">
						<ActionNeededTable 
							rows={actionNeededRows}
							selectOptions={selectOptions}
							onOptionChange={handleActionNeededOptionChange}/>
						<UploadAndNext onSubmit={props.onSubmit}/>
					</div>
				</TabPanel>
				{/* {
					isOpen &&
					<ModalImport
						isOpen={ isOpen }
						hideModal={() => setIsOpen(!isOpen)}
						customsData = { newCustomData }
						getCustomData = { onChangeCustomData}
					/>
				} */}
			</div>
		</div>
	);
}

const UploadAndNext = ({ onSubmit }) => {
	return (
		<div class="d-flex justify-content-center content_duel_buttons mb-5">
			<div>
				<a onClick={e => { e.preventDefault(); onSubmit(e) }} href="#!" class="main_content_container_button white-text d-flex justify-content-center align-items-center back_next_buttons accent--bg--text--color">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="main_content_container_button_icon">
						<path d="M20 10C20 4.4774 15.5226 0 10 0C4.4774 0 0 4.4774 0 10C0 15.5226 4.4774 20 10 20C15.5226 20 20 15.5226 20 10ZM9.77644 14.3923C9.70471 14.3212 9.64769 14.2366 9.60865 14.1434C9.56961 14.0502 9.54931 13.9502 9.54891 13.8492C9.54851 13.7482 9.56802 13.6481 9.60631 13.5546C9.64461 13.4611 9.70095 13.376 9.77212 13.3043L12.2875 10.7692H5.86538C5.66137 10.7692 5.46571 10.6882 5.32146 10.5439C5.1772 10.3997 5.09615 10.204 5.09615 10C5.09615 9.79599 5.1772 9.60033 5.32146 9.45607C5.46571 9.31181 5.66137 9.23077 5.86538 9.23077H12.2875L9.77212 6.69567C9.70096 6.62389 9.64464 6.53879 9.60638 6.44524C9.56811 6.35169 9.54864 6.25152 9.54909 6.15045C9.54954 6.04937 9.56989 5.94938 9.60898 5.85617C9.64807 5.76296 9.70514 5.67837 9.77692 5.60721C9.84871 5.53606 9.9338 5.47974 10.0274 5.44147C10.1209 5.40321 10.2211 5.38374 10.3221 5.38419C10.4232 5.38463 10.5232 5.40498 10.6164 5.44407C10.7096 5.48317 10.7942 5.54023 10.8654 5.61202L14.6822 9.45817C14.8252 9.60226 14.9054 9.79702 14.9054 10C14.9054 10.203 14.8252 10.3977 14.6822 10.5418L10.8654 14.388C10.7942 14.4599 10.7096 14.517 10.6163 14.5561C10.523 14.5952 10.423 14.6156 10.3218 14.616C10.2207 14.6164 10.1204 14.5968 10.0268 14.5585C9.93327 14.5201 9.84818 14.4636 9.77644 14.3923Z"/>
					</svg>												
					<span>Upload & Next</span>
				</a>
			</div>
		</div>
	);
}
 
export default MapContact;
