import React, { Component } from 'react';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {connect} from  "react-redux"
import GlobalModal from '../../globals/Modal/GlobalModal';
import {Save} from '@material-ui/icons';
import './modal_assign_to_tag.css';
import { addTagsV2 ,getUserTagListNew} from '../../../api/contactApi';
import { getUserTagList, setUserTagList} from '../../../actions/contactAction';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    checkboxWrapper: {
        "& .MuiButtonBase-root": {
            padding: "9px !important"
        }
    },
});

const EachTag = (props) => {
    const classes = useStyles();
    return (

        <li>
            <FormControlLabel
                className={classes.checkboxWrapper}
                control={<Checkbox onChange={props.selectTag} name="example_2" value={props.data.id} checked={props.checked}/>}
                label={props.data.name}
            />
        </li>
    )
}

class ModalAssignToTag extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            success: false,
            errorMsg: '',
            successMsg: '',
            allTags: [],
            allTagsToShow: [],
            storedTagList:[],
            submitingFile: false,
            submitEnable: false,
            selectedTags: [],
            submitingContact: false,
            createNew: false,
            searchKey : null,
            new : false,
            contactTags: null,
            newTagName: '',
            saveButtonText: "Assign Tag",
            perPage:50,
            currentPage:1,
            isLoadMore:false
        };

    }


    // componentDidMount() {
    //     if(this.props.tagList !== undefined && this.props.tagList != null){
    //         this.setAllTagsToShowList(this.props.tagList)
    //     }else{
    //         this.getAllTagsForDropdown()
    //     }
    // }

    componentDidMount() {
        if(this.props.allContactTags && Array.isArray(this.props.allContactTags)){
            let array = this.props.allContactTags.map((item, index) =>{
                return item.tag_id
            })
            this.setState({contactTags : array})
        }
            this.getAllTagsForDropdown();
            
    }

    getAllTagsForDropdown = () => {
            getUserTagListNew({per_page:this.state.perPage,current_page:this.state.currentPage}).then((response)=>{
                this.setState({ allTagsToShow: response.data})

                if(response.data.length == this.state.perPage){
                    this.setState({
                        currentPage : this.state.currentPage+1,
                        isLoadMore:true
                    })
                }else{
                    this.setState((prevState)=>({
                        ...prevState,
                        isLoadMore:false
                    }))
                }

            })
        
    }

    // setAllTagsToShowList =(initialTagList)=>{

    //     if(this.props.contactTags && this.props.contactTags.length >0){
    //         this.props.contactTags.map((item, index) => (
    //             initialTagList = initialTagList.filter(each => each.id != item.tag_id)  // eslint-disable-line eqeqeq
    //         ))
    //     }

    //     let newState = [...this.state.allTagsToShow,...initialTagList];
    //     this.setState({ allTagsToShow: newState, storedTagList: newState})
    // }

    // getAllTagsForDropdown = () => {

    //     if(this.props.userTagList != null){
    //         this.setAllTagsToShowList(this.props.userTagList)
    //     }else{
    //         this.props.fetchUserTagList({}, (callback)=>{

    //             this.setAllTagsToShowList(callback)

    //         })   
    //     }     
    // }

    // setAllTagsToShowList =(initialTagList)=>{

    //     if(this.props.contactTags !== undefined && this.props.contactTags != null && this.props.contactTags.length >0){
    //         this.props.contactTags.map((item, index) => (
    //             initialTagList = initialTagList.filter(each => each.id != item.tag_id)  // eslint-disable-line eqeqeq
    //         ))
    //     }
    //     this.setState({ allTagsToShow: initialTagList, storedTagList: initialTagList})
    // }

    handleOnScrollLoad = (e) =>{
        
        if(this.state.isLoadMore && this.state.currentPage &&
            Math.round(e.target.scrollTop + e.target.clientHeight, 20) >= Math.round(e.target.scrollHeight, 10)){
            this.getAllTagsForDropdown();

        } 
    }

    createNew = (tag) =>{
        this.submitTagNew(tag);
    };


    submitTag = (tagName) => {
        addTagsV2({
            contact_id: this.props.contact_id,
            tags: tagName,
            new : 0,
            assign: true

        }).then(res => {

            this.setState({
                submitingContact: false
            });

            if (res.data.status === 'error') {
                this.setState({
                    error: true,
                    errorMsg: res.data.html
                });

                setTimeout(() => {
                    this.setState({
                        error: false,
                        errorMsg: ""
                    });
                }, 5000);
            } else if (res.data.status === 'validation-error') {
                // console.log(res.data.html);
            } else if (res.data.status === 'success') {
                this.props.callBack(true, true, res.data.data);
            } else
            {
                this.toggle(true);
            }
        });
    };

    submitTagNew = (tagName) => {

        addTagsV2({
            contact_id: this.props.contact_id,
            tags: tagName,
            new : this.state.new,
            assign: false

        }).then(res => {

            this.setState({
                submitingContact: false
            });


            if (res.data.status === 'error') {
                this.setState({
                    error: true,
                    errorMsg: res.data.html
                });

                setTimeout(() => {
                    this.setState({
                        error: false,
                        errorMsg: ""
                    });
                }, 5000);
            } else if (res.data.status === 'validation-error') {
                // console.log(res.data.html);
            }else if(res.data.status === 'success'){
                this.props.callBack(true, true, res.data.data);
            } else {
                this.setState({
                    createNew: false,
                    searchKey: null
                });
                //this.toggle();
                // this.loadAllTags();
            }
        });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            submitingContact: true
        });

        if(this.state.createNew){
            this.submitTagNew(this.state.newTagName)

        }else{
            this.submitTag(this.state.selectedTags);
        }
    };

    toggle = (update =false) => {
        this.setState({
            isOpen: !this.state.isOpen
        });
        if(update){
            this.props.callBack(update);
        }else{
            this.props.callBack();
        }
    };

    handelRadio = (e) => {
        let alltags = this.state.selectedTags;
        
        if (e.target.checked) {
            alltags.push(e.target.value)
        } else {
            alltags.splice(alltags.indexOf(e.target.value), 1)
        }

        this.setState({
            selectedTags: alltags,
            submitEnable: (alltags.length > 0)
        })
    }

    changeSearch = (e) => {
        let searchKey = e.target.value.toUpperCase().trim();
        let isFound = false ;


        if(searchKey === ''){
            this.setState({ 
                allTagsToShow : this.state.storedTagList,
                searchKey: '',
                createNew: false,
                saveButtonText: 'Assign Tag',
            })
            return;
        }

        this.getAllTagsForDropdown();

        let tagData = [];



        // tagData = this.state.storedTagList.filter((data) => {
        //     if (data.name === searchKey) {
        //         isFound = true;
        //     }
        //     if (data.name.toUpperCase().includes(searchKey)) {
        //         return data;
        //     }
        // });


        if (tagData.length === 0) {
            if(searchKey.length !== 0 && searchKey.length < 30) {
                this.setState({
                    createNew: !isFound,
                    new: true,
                    allTagsToShow: [],
                    searchKey: searchKey.replace(new RegExp(" ", 'g'), '-'),
                    saveButtonText: 'Create & Assign Tag',
                    newTagName: e.target.value.trim()
                });
            }
        } else {
            this.setState({
                allTagsToShow: tagData,
                searchKey: searchKey,
                createNew: false,
                saveButtonText: 'Assign Tag',
                newTagName: searchKey.replace(new RegExp(" ", 'g'), '-'),
            })
        }
    };

    render() {

        var runningList = 
            this.state.allTagsToShow.map((data, index)=> {
           if(this.state.contactTags && this.state.contactTags.includes(data.id)){
               return null;
           }
           return (<EachTag selectTag={this.handelRadio} data={data} key={index} />)
           });
       
        return (

            <GlobalModal {...this.props} 
            title={"Assign Tag"}
            buttonText={this.state.saveButtonText}
            buttonIcon={<Save/>}
            onSubmit={this.handleSubmit}
            hideFooter={this.state.createNew ? false : false}
            className={'manage-tag-modal modal-assign-campaign global-small-modal'} >
                <div className="row">
                    <div className="col-md-12">
                        <div className="search-bar-top-area2 search-bar-top-area">
                            <form action="">
                                <label htmlFor="" className="mb-2">Type to search/create tag</label>
                                <div className="search_input_wrapper">
                                    <span className="search-icon">
                                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                        </svg>
                                    </span>
                                    <input type="search" className="ad__custom__input custom-popup-search" placeholder="Search or Create ..." onChange={this.changeSearch}/>
                                </div>
                            </form>
                        </div>
                        <div className="campaign-search-list">
                            <div className="campaign-search-results">
                                {this.state.createNew &&
                                <div className="not_found_tag">
                                    <button className="create_new_tag" onClick={(e) => this.handleSubmit(e)}>Create and assign with this <strong>{this.state.searchKey}</strong></button>
                                    <p>No tags created with <b>{this.state.searchKey}</b></p>
                                </div>
                                }
                                <div className="all__tags__to__show__area m-nav awesome__scroll_bar update__list_item" onScroll={this.handleOnScrollLoad}>
                                    <ul>
                                        {(this.state.allTagsToShow && Array.isArray(this.state.allTagsToShow) && this.state.allTagsToShow.length === 0 )? '' : runningList}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GlobalModal>
        );
    }
}

const mapStateToProps = state => {
    return {
        userTagList: state.contactReducer.userTagList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
        storeUserTagList: (params, callback) => dispatch(setUserTagList(params))

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignToTag);
