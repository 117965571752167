import React, { Component } from 'react';
import { useState } from 'react';
// import ModalComponent from '../../Calendar/ModalComponent.js';
import {confirmAlert} from "react-confirm-alert";
import CustomConfirmAlert from '../../../common/custom-alert/CustomConfirmAlert';
import TimezoneConversation from '../Helpers/TimezoneConversion';
import Utils from '../Helpers/Utils';

const Task =(props)=>{
    const [checked, setChecked] = useState(false)

    const checkDisabled = (forEdit = false) => {
        let disable = true,
            assigned_to = props.data.assigned_to != null ? parseInt(props.data.assigned_to) : null,
            task_user_id = parseInt(props.data.user_id),
            contact_owner = parseInt(props.people.user_id),
            user_id = parseInt(Utils.getAccountData('userId'));

        if(props.makeDone!= null){
            if(task_user_id === user_id){
                disable = false
            }
            else if(assigned_to === user_id && !forEdit){
                disable = false
            }
            else if(contact_owner === user_id && !forEdit){
                disable = false
            }
        }
        return disable;
    }

    const modalToggle = (e, disable)=> {
        if(disable){
            return;
        }
        if(props.handleUpdate !== undefined){
            props.handleUpdate(props.data.id, props.data)
        }
    };

    const switchType = (e, disable = false)=>{
        if(props.makeDone == null){
            return
        }
        if(disable){
            setChecked(false)
            e.target.checked = false
            /* show alert message */
            CustomConfirmAlert({
                handleConfirm : () => {},
                handleOnClose: () => {},
                title: 'Oops !!!',
                description: 'You can not add or update activity to member user lead.',
                icon: 'cancel',
                closeButtonText: "Got It !",
                minHeight: '260px',
                showConfirmButton: false
            })
            return
        }
        setChecked(prevState=> !prevState)
        if(props.makeDone != null){
            props.makeDone(e, props.index)
        }
    };

    const renderCheck = () => {
        const disable = checkDisabled()
                
        if(props.makeDone == null){
            return (
                <div className="m-widget2__checkbox">
                    <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                        <input onChange={(e) => switchType(e,disable)} checked={"checked"} type="checkbox" defaultValue={props.data.id}  name="check-1"/>
                        <span style={disable ? {opacity: 0.5, cursor: 'not-allowed'} : {}} />
                    </label>
                </div>
            );
        }
        return (
            <div className="m-widget2__checkbox">
                <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                    <input onChange={(e) => switchType(e,disable)} checked={checked ? "checked" : null} type="checkbox" defaultValue={props.data.id}  name="check-1"/>
                    <span style={disable ? {opacity: 0.5, cursor: 'not-allowed'} : {}}/>
                </label>
            </div>
        );
    }
    const renderTitle = () => {
        const disable = checkDisabled(true)
        return (
            <span className="m-widget2__text task cursor-pointer" style={{color: '#039be5'}} onClick={(e) => modalToggle(e, disable)}>
                <i className="fa fa-share"/>
                {props.data.task_title}
            </span>
        );
    }
    const renderCreatedBy = () => {
        let title = ""
        if((props.data.user !== undefined && props.data.user != null && props.data.user.full_name !== undefined)){
            title = props.data.user.full_name
        }
        else{
            title = Utils.getAccountData('fullName')
        }

        return (
            <span className="m-widget2__user-name">
                <a href="#!" className="m-widget2__link m--font-success" onClick={e=>e.preventDefault()}>
                    <i className="fa fa-user m--font-success"/>By {title}
                </a>
            </span>
        );
    }

    let isDone = (props.data.status === 2) ? "m-widget2__item m-widget2__item--primary task-list d-flex done" :
        "m-widget2__item m-widget2__item--primary task-list d-flex";

    return (
        <div className={isDone}>
            {/* {props.makeDone != null &&
            <div className="m-widget2__checkbox">
                <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                    <input onChange={switchType} checked={checked ? "checked" : null} type="checkbox" defaultValue={props.data.id}  name="check-1"/>
                    <span/>
                </label>
            </div>
            }

            {props.makeDone == null &&
            <div className="m-widget2__checkbox">
                <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                    <input onChange={switchType} checked={"checked"} type="checkbox" defaultValue={props.data.id}  name="check-1"/>
                    <span/>
                </label>
            </div>
            } */}

            {renderCheck()}

            <div /* onClick={this.modalToggle} */ className="m-widget2__desc info">
                    {renderTitle()}
                    <span className="m-widget2__text m--font-info">
                        <i className="fa fa-clock-o m--font-info"/>
                        {TimezoneConversation(props.data.date + " " + props.data.time,'UTC',Utils.getAccountData('userTimezoneRaw') , "MM/DD/YYYY hh:mm A")}
                    </span>
                    {renderCreatedBy()}
            </div>
        </div>
    );
}
export default Task;