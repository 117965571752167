import { contactAddCustoms, contactAddress, contactDetails, contactInformation } from "../../../constants/CoreConstants";

const mapData = (data) => {
    let rowsArr = [];
    let maxRow = 5;
    data.forEach((rowData,rowIndex) => {
        if(rowIndex >= maxRow) {
            return;
        }
        if(rowIndex == 0) {
            rowData.forEach((eachData,dataIndex) => {
                rowsArr.push({
                    index : dataIndex,
                    fields : [eachData]
                });
            })
        } else {
            rowData.forEach((eachData,dataIndex) => {
                if(rowsArr[dataIndex] !== undefined && rowsArr[dataIndex] != null){
                    rowsArr[dataIndex].fields.push(eachData);
                }
            })
        }
    });

    let mappedData = getMappedData(rowsArr);

    return {
        mappedRows : mappedData.mappedRows,
        actionNeededRows : mappedData.actionNeededRows,
        selectedRows : mappedData.selectedRows,
        submitData : mappedData.submitData
    };
}

const getSelectOptions = (customFields) => {
    return [
        { value: '', label: 'Do Not Upload' },
        {
            label: 'Information',
            options: contactInformation,
        },
        {
            label: 'Details',
            options: contactDetails,
        },
        {
            label: 'Address',
            options: contactAddress,
        },
        {
            label: 'Custom Fields',
            options: customFields,
        },
        // {
        //     label: 'Add Custom Fields',
        //     options: contactAddCustoms,
        // },
    ];
}

const getMappedData = (allRows) => {
    
    let mappedRows = [];
    let actionNeededRows = [];
    let submitData = {};
    let selectedRows = {};

    allRows.forEach(row => {
        let has = false;
        for(let data of row.fields) {
            if (isPhoneNumber(data)){
                mappedRows.push(row);
                selectedRows[row.index] = contactInformation[1];
                has = true;
                break;
            } else if(isEmail(data)) {
                mappedRows.push(row);
                selectedRows[row.index] = contactInformation[0];
                has = true;
                break;
            } else if(isZip(data)) {
                mappedRows.push(row);
                selectedRows[row.index] = contactAddress[3];
                has = true;
                break;
            }
        }
        if (!has) {
            actionNeededRows.push(row);
            submitData[row.index] = {
                map : 'data['+row.index+']',
                data : ""
            }
        } else {
            submitData[row.index] = {
                map : 'data['+row.index+']',
                data : selectedRows[row.index].value
            }
        }
    });

    return {
        mappedRows : mappedRows,
        actionNeededRows : actionNeededRows,
        selectedRows : selectedRows,
        submitData : submitData
    }
}

const isPhoneNumber = (phone) => {
    return phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
}

const isEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const isZip = (zip) => {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
}


const MapcontactHelper = {
    mapData,
    getSelectOptions
}

export default MapcontactHelper;