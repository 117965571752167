import React, {useEffect} from 'react';
import { useState } from 'react';
import moment from 'moment';
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {markAsDone} from "../../../../api/calendarApi";
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivityCalendar, fetchTaskCount } from '../../../../actions/calendarAction';
import EditIcon from '@material-ui/icons/Edit';

const TASK_TO_ICON = {
    call : 'phone',
    meeting : 'groups',
    task : 'task',
    deadline : 'schedule',
    email : 'mail',
    followup : 'group_add'
}

const ActivityListItem = ({ item, handleTaskUpdate, updateTaskFlag }) => {

    const dispatch = useDispatch();
    const activeTab = useSelector(state => state.calendarReducer.activeTabActivityCalendar);
    const activeTaskId = useSelector(state => state.calendarReducer.activeTaskId);
    const { id, title, taskTitle, start, taskDuration,  taskDate, taskTime} = item;
    const [checked, setChecked] = useState(false);

    const [showEdit, setShowEdit] = useState(false)

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(()=>{
        if(checked){
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Do you want to mark this as done?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => doneActivity()
                    },
                    {
                        label: 'No',
                    }
                ]
            });
        }
    },[checked])


    const doneActivity = () => {
        setChecked(false)
        markAsDone({
            id : id
        }).then(res => {

            updateTaskFlag()
            // dispatch(fetchActivityCalendar({
            //     'task_id' : activeTaskId,
            //     'activeTab' : activeTab
            // }));
    
            // dispatch(fetchTaskCount({
            //     'task_id' : activeTaskId,
            //     'activeTab' : activeTab
            // }));
        });

    }

    /* for edit task */
    const onHoverActivity = () => {
        setShowEdit(true)
    }

    const onBlurActivity = () => {
        setShowEdit(false)
    }

    return (
        <tr style={{ background: checked ? 'rgba(60, 127, 243, 0.1)' : '#ffffff' }} onMouseOver={onHoverActivity} onMouseLeave={onBlurActivity}>
            <td>
                <label>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <span
                        className="d-flex justify-content-start align-items-center">
                    </span>
                </label>
            </td>
            <td>
                <div className="d-flex align-items-center d-flex">
                    <p className="m-0 text-dark-blue-65 truncate" style={{width: '180px'}}>{title}</p>
                    <div className="ml-2 cursor-pointer edit_icon_wrapper" onClick={() => handleTaskUpdate(item.id, item)}>
                        <EditIcon  />
                    </div>
                </div>
            </td>
            <td>
                <p className={`m-0 ${start ? 'text-red' : 'text-dark-blue-65'}`}>
                    {moment(taskDate).format('MM/DD/YYYY')+' at '}{moment(taskTime, 'hh:mm a').format('hh:mm a')}
                    {/* At {moment(taskDate).format('MMMM Do YYYY')+' '}{moment(taskTime, 'hh:mm a').format('hh:mm a')} */}
                </p>
            </td>
            <td>
                <p className="m-0 text-dark-blue-65">{taskDuration}</p>
            </td>
            <td>
                <label>
                    <div className="m-contact-task-title-alt">
                        <span className="d-flex justify-content-start align-items-center"><i
                                className="material-icons mr-2">
                                {TASK_TO_ICON[taskTitle]}
                            </i>
                            {taskTitle}
                        </span>
                    </div>
                </label>
            </td>
            
        </tr>
    );
};

export default ActivityListItem;