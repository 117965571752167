const Icons = {
    docFiles: <svg xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 63.63 90">
        <g transform="translate(-403.7499999999999 50)">
            <path d="M405.04-48.33h38.93v58.4h-38.94v-58.4z" stroke="#000" strokeOpacity="0" fill="#5ea0ef"/>
            <path d="M444 11.74h-38.96c-.7 0-1.3-.74-1.3-1.67v-58.4c0-.93.6-1.67 1.3-1.67H444c.7 0 1.3.74 1.3 1.67v58.4c0 .93-.6 1.67-1.3 1.67zM406.34 8.4h36.4v-55.07h-36.4z" stroke="#000" strokeOpacity="0" fill="#0078d4"/>
            <path d="M412.47-40.37h38.93v58.4h-38.93v-58.4z" stroke="#000" strokeOpacity="0" fill="#83b9f9"/>
            <path d="M451.45 19.66h-38.97c-.7 0-1.3-.74-1.3-1.67v-58.4c0-.94.6-1.68 1.3-1.68h38.97c.7 0 1.3.74 1.3 1.67V18c0 .92-.6 1.66-1.3 1.66zm-37.68-3.33h36.4v-55.08h-36.4z" stroke="#000" strokeOpacity="0" fill="#0078d4"/>
            <path d="M447.42-33.08H423.3c-1.06 0-1.93 1.16-1.93 2.47V37c0 1.3.87 2.46 1.92 2.46h41.78c1.05 0 1.9-1.16 1.9-2.47V-7.77c0-1.38-.85-2.47-1.9-2.47H451.2c-1.04-.07-1.86-1.16-1.86-2.54V-30.6c-.05-1.4-.87-2.48-1.92-2.48z" stroke="#000" strokeOpacity="0" fill="#fff"/>
            <path d="M446.58-29.94v17c0 3.47 2.13 6.26 4.77 6.26h13.2v43h-40.62v-66.26h22.65m.92-3.68H423c-1.06 0-1.94 1.1-1.94 2.5V37.5c0 1.4.88 2.5 1.94 2.5h42.43c1.06 0 1.94-1.1 1.94-2.5V-7.93c0-1.4-.88-2.5-1.94-2.5h-14.08c-1.07 0-1.9-1.1-1.9-2.5v-18.2c0-1.4-.88-2.5-1.95-2.5z" stroke="#000" strokeOpacity="0" fill="url(#a)"/>
            <path d="M466.6-9.95l-17.87-23.13v18.85c-.02 2.34 1.45 4.26 3.26 4.28z" stroke="#000" strokeOpacity="0" fill="#0078d4"/>
            <path d="M430.33-.05h27.7c1.27 0 1.9.82 1.9 2.46v.57c0 1.64-.63 2.46-1.9 2.46h-27.7c-1.26 0-1.9-.82-1.9-2.46V2.4c0-1.63.64-2.45 1.9-2.45zM430.33 9.76h27.7c1.27 0 1.9.82 1.9 2.46v.56c0 1.64-.63 2.46-1.9 2.46h-27.7c-1.26 0-1.9-.82-1.9-2.46v-.56c0-1.64.64-2.46 1.9-2.46zM430.34 19.57h16.04c1.27 0 1.9.82 1.9 2.46v.56c0 1.63-.63 2.45-1.9 2.45h-16.04c-1.27 0-1.9-.82-1.9-2.46v-.57c0-1.64.63-2.46 1.9-2.46z" stroke="#000" strokeOpacity="0" fill="#5ea0ef"/>
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" id="a" x1="435.57" y1="40" x2="435.57" y2="-50">
                <stop offset="0%" stopColor="#0078d4"/>
                    <stop offset="81.7%" stopColor="#5ea0ef"/>
                </linearGradient>
            </defs>
        </g>
    </svg>
};

export default Icons;