import React, {Fragment, useContext} from "react";
import Styles from "./FileUploader.module.css";
import {UploadFileIcon} from "./Icons";
import CsvParse from "./lib";
import {DataCleanContext} from "./StateProvider";
import ActionTypes from "./ActionTypes";
import {TAB_CLEAN_LIST} from "./Constants";

const FileUploader = () => {
    const {state, dispatch} = useContext(DataCleanContext);

    const DataUploaded = (data, file) => {
        let validateData = [];
        let checkedData = [];
        if (state.data.length > 0) {
            let dataLength = state.data[0].length;
            for (let i = 0; i < dataLength; i++) {
                validateData["id" + i] = false;
                validateData["skipId" + i] = false;
                checkedData[i]  = {};
            }
        }

        dispatch({
            type: ActionTypes.UPDATE_STATE,
            payload: {
                activeTab: TAB_CLEAN_LIST.UPLOAD_FILE,
                data: data,
                file: file,
                validateData: validateData,
                checkedData: checkedData,
                total: data.length,
                isFileSelected: true
            }
        });
    }

    return (
        <Fragment>
            <CsvParse
                onDataUploaded={DataUploaded}
                onError={()=>{

                }}
                render={(onChange)=>(
                    <div className={Styles.fileTrayWrapper}>
                        <div
                            className={Styles.fileTray}
                            onDragOver={(event)=>{
                                event.preventDefault();
                            }}
                            onDrop={(event)=>{
                                event.preventDefault();
                                onChange({
                                    target: {
                                        files: event.dataTransfer.files
                                    }
                                });
                            }}
                            onClick={() => {
                                const fileInput =
                                    document.querySelector('input[type="file"]');
                                if (fileInput) {
                                    fileInput.click();
                                }
                            }}>
                            <UploadFileIcon/>
                            <input type="file" className={Styles.uploadFileHide} onChange={onChange} accept=".csv"/>
                            <h5 className={Styles.uploadInstruction}>Drag and drop your file here or click here to import your list</h5>
                            <p className={Styles.uploadRule}>(CSV and TXT files are supported)</p>
                        </div>
                    </div>
                )}
            />
        </Fragment>
    );
};

export default FileUploader;
