import React, { useEffect, useState } from "react";
import axis from 'axis.js';
import { updateTimelineNote } from "../../../api/contactApi";
import Utils, { getAccountData } from "../../../helpers/Utils";
import If from 'if-else-react';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from "../leftSide/note/defaultStyle";
import TimezoneConversation from "../../globals/TimezoneConversion";

const mentionStyle = {
    backgroundColor: '#cee4e5',
};

const getHtmlText = (message) => {
    let regix = /\@.*?\)/g; // eslint-disable-line 
    let matchStrign = message.match(regix);
    if (!axis.isNull(matchStrign) && axis.isArray(matchStrign)) {
        matchStrign.map((str) => { // eslint-disable-line array-callback-return
            let eachStr = str.match(/@\[(.*)\]\(.*\)/);
            if(eachStr != null)
                message = message.replace(str, "<b>" + "@" + eachStr[1] + "</b>")
        });
    }
    return message.replace(/\n/g, "<br />");
}

const deletedCampaign = (
    <React.Fragment>
        <a onClick={(e) => e.preventDefault()} title="This campaign has been deleted" className="la la-exclamation-triangle text-danger"></a>
    </React.Fragment>
)

const Note = (props) => {
    const [state,setUseState] = useState({
        isEdit:false,
        noteText: '',
        htmlText: '',
    });
    const setState = (obj) => {setUseState({...state,...obj})}

    useEffect(() => {
        let text = '';
        if(props.message !== undefined && props.message != null && props.message.message!== undefined && props.message.message!= null){
            text = props.message.message
        }
        if(text!== undefined && text!= null && text!=='' && text[text.length-1] === ')'){
            text = text+' ';
        }
        setUseState({
            ...state,
            htmlText: getHtmlText(props.message.message),
            noteText: text
        });
    },[]);

    const changeText = (e) => {
        setState({noteText: e.target.value});
    }

    const updateText = () => {
        setState({
            htmlText:getHtmlText(state.noteText),
            isEdit:false,
        });
        updateTimelineNote(
        {
            id: props.message.id,
            note : state.noteText,
            contact_id: props.message.contact_id
        }).then(res => {
            if(res.data.status == 'success'){
                Utils.showNotification(res.data.html);
            }
        });
    }

    // let userTimezone = props.loggedOnUser != undefined ? (props.loggedOnUser.timezone != undefined && props.loggedOnUser.timezone != null && props.loggedOnUser.timezone != '') ? props.loggedOnUser.timezone : 'EST' : 'EST'
    const getUserTimezone = getAccountData('userTimezoneRaw')
    let userTimezone = (getUserTimezone && getUserTimezone != null) ? getUserTimezone : 'UTC'
    return (
        <React.Fragment>
            <div class="single_note p-3 bg-yellow-10 mt-3">
                <div class="note_header row px-2 pb-2">
                    <div class="d-flex align-items-start col s8">
                        <span class="single_note_icon bg-dark-blue-10 circle d-flex justify-content-center align-items-center">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="15" height="15" transform="translate(0.5 0.5)" fill="#E5E8EF"></rect>
                                <path d="M15.0965 3.17871H13.3555V13.3573C13.3555 13.6414 13.4684 13.9139 13.6693 14.1149C13.8702 14.3158 14.1427 14.4287 14.4269 14.4287C14.7111 14.4287 14.9836 14.3158 15.1845 14.1149C15.3854 13.9139 15.4983 13.6414 15.4983 13.3573V3.5805C15.4983 3.47394 15.456 3.37174 15.3806 3.29639C15.3053 3.22104 15.2031 3.17871 15.0965 3.17871Z" fill="#133159"></path>
                                <path d="M13.8644 15.4246C13.4112 15.3003 13.0114 15.0307 12.7262 14.6573C12.441 14.2838 12.2863 13.827 12.2857 13.3571V0.901785C12.2857 0.795225 12.2434 0.693029 12.168 0.61768C12.0927 0.542331 11.9905 0.5 11.8839 0.5H0.901786C0.795226 0.5 0.69303 0.542331 0.61768 0.61768C0.542331 0.693029 0.5 0.795225 0.5 0.901785V13.625C0.5 14.1223 0.697544 14.5992 1.04917 14.9508C1.40081 15.3024 1.87772 15.5 2.375 15.5H13.8544C13.8638 15.5003 13.8731 15.4971 13.8804 15.491C13.8877 15.4849 13.8924 15.4763 13.8937 15.4669C13.8949 15.4575 13.8926 15.4479 13.8872 15.4401C13.8818 15.4323 13.8737 15.4268 13.8644 15.4246ZM2.64286 6.39285V3.17857H5.85714V6.39285H2.64286ZM10.1429 12.8214H2.64286V11.75H10.1429V12.8214ZM10.1429 10.6786H2.64286V9.60713H10.1429V10.6786ZM10.1429 8.5357H2.64286V7.46427H10.1429V8.5357ZM10.1429 6.39285H6.92857V5.32142H10.1429V6.39285ZM10.1429 4.24999H6.92857V3.17857H10.1429V4.24999Z" fill="#133159"></path>
                            </svg>

                        </span>
                        <div>
                            <h5 class="d-flex align-items-center m-0 ml-2">
                                {props.message.user.full_name}
                            </h5>
                            <p class="m-0 ml-2">
                                {/* {props.create.local_created_at.for_human} */}
                                {TimezoneConversation(props.message.created_at, 'UTC', userTimezone, 'LLL')}
                                </p>
                        </div>
                    </div>
                    <div class="col s4 text-right pl-0 d-flex justify-content-end align-items-center">
                        {
                            Utils.getAccountData("note_edit_access") == "1" ? (<>
                                <If condition={!state.isEdit}>
                                    <svg class="mr-4" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setState({isEdit:true})}>
                                        <g clipPath="url(#clip0)">
                                            <path fill="#3C7EF3" d="M17.6437 1.88101L16.1187 0.356013C15.8897 0.127857 15.5796 -0.000244141 15.2563 -0.000244141C14.9329 -0.000244141 14.6228 0.127857 14.3938 0.356013L13.2875 1.46226C13.2305 1.51954 13.1985 1.59707 13.1985 1.67789C13.1985 1.75871 13.2305 1.83624 13.2875 1.89351L16.1063 4.71226C16.1635 4.76928 16.2411 4.8013 16.3219 4.8013C16.4027 4.8013 16.4802 4.76928 16.5375 4.71226L17.6437 3.60601C17.8719 3.37695 18 3.06682 18 2.74351C18 2.42021 17.8719 2.11008 17.6437 1.88101ZM13.3594 9.29976L12.1094 10.5498C12.04 10.6208 12.0008 10.716 12 10.8154V13.9966H2V3.99664H9.18125C9.2806 3.99588 9.3758 3.95668 9.44687 3.88726L10.6969 2.63726C10.7495 2.58481 10.7854 2.51791 10.7999 2.44505C10.8145 2.37219 10.807 2.29664 10.7786 2.22801C10.7501 2.15937 10.7019 2.10074 10.6401 2.05954C10.5782 2.01835 10.5056 1.99646 10.4312 1.99664H1.5C1.10218 1.99664 0.720644 2.15467 0.43934 2.43598C0.158035 2.71728 0 3.09881 0 3.49664L0 14.4966C0 14.8945 0.158035 15.276 0.43934 15.5573C0.720644 15.8386 1.10218 15.9966 1.5 15.9966H12.5C12.8978 15.9966 13.2794 15.8386 13.5607 15.5573C13.842 15.276 14 14.8945 14 14.4966V9.56539C14.0002 9.49109 13.9783 9.41841 13.9371 9.35657C13.8959 9.29473 13.8373 9.24652 13.7686 9.21806C13.7 9.1896 13.6245 9.18218 13.5516 9.19672C13.4787 9.21127 13.4118 9.24713 13.3594 9.29976Z"></path>
                                            <path fill="#3C7EF3" d="M15.4007 5.41559C15.4577 5.47287 15.4897 5.5504 15.4897 5.63122C15.4897 5.71204 15.4577 5.78957 15.4007 5.84685L8.57567 12.6718L5.67568 12.9937C5.58497 13.0039 5.49313 12.9935 5.40697 12.9634C5.32082 12.9332 5.24257 12.884 5.17802 12.8195C5.11348 12.755 5.0643 12.6767 5.03414 12.5905C5.00398 12.5044 4.99361 12.4126 5.0038 12.3218L5.32568 9.42185L12.1507 2.59684C12.208 2.53982 12.2855 2.50781 12.3663 2.50781C12.4471 2.50781 12.5246 2.53982 12.5819 2.59684L15.4007 5.41559Z"></path>
                                        </g>
                                        <defs>
                                            <clipPath>
                                                <rect width="18" height="16" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {/*<svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>removeNote()}>*/}
                                    {/*    <path d="M10.8462 1.84615H8.30769V1.38462C8.30769 0.619904 7.68779 0 6.92308 0H5.07692C4.31221 0 3.69231 0.619904 3.69231 1.38462V1.84615H1.15385C0.516606 1.84615 0 2.36276 0 3V3.92308C0 4.17799 0.206625 4.38461 0.461538 4.38461H11.5385C11.7934 4.38461 12 4.17799 12 3.92308V3C12 2.36276 11.4834 1.84615 10.8462 1.84615ZM4.61538 1.38462C4.61538 1.13019 4.8225 0.923077 5.07692 0.923077H6.92308C7.1775 0.923077 7.38462 1.13019 7.38462 1.38462V1.84615H4.61538V1.38462Z" fill="#FF264A"></path>*/}
                                    {/*    <path d="M0.874693 5.30859C0.792338 5.30859 0.726713 5.37742 0.730636 5.45969L1.11141 13.4513C1.1466 14.1909 1.7541 14.7701 2.49429 14.7701H9.50333C10.2435 14.7701 10.851 14.1909 10.8862 13.4513L11.267 5.45969C11.2709 5.37742 11.2053 5.30859 11.1229 5.30859H0.874693ZM7.84496 6.46244C7.84496 6.20744 8.0515 6.0009 8.3065 6.0009C8.5615 6.0009 8.76804 6.20744 8.76804 6.46244V12.4624C8.76804 12.7174 8.5615 12.924 8.3065 12.924C8.0515 12.924 7.84496 12.7174 7.84496 12.4624V6.46244ZM5.53727 6.46244C5.53727 6.20744 5.74381 6.0009 5.99881 6.0009C6.25381 6.0009 6.46035 6.20744 6.46035 6.46244V12.4624C6.46035 12.7174 6.25381 12.924 5.99881 12.924C5.74381 12.924 5.53727 12.7174 5.53727 12.4624V6.46244ZM3.22958 6.46244C3.22958 6.20744 3.43612 6.0009 3.69112 6.0009C3.94612 6.0009 4.15265 6.20744 4.15265 6.46244V12.4624C4.15265 12.7174 3.94612 12.924 3.69112 12.924C3.43612 12.924 3.22958 12.7174 3.22958 12.4624V6.46244Z" fill="#FF264A"></path>*/}
                                    {/*</svg>*/}
                                </If>
                                <If condition={state.isEdit}>
                                    <div className="text-right">
                                        <button className="btn btn-success btn-sm mr-1" onClick={() => setState({isEdit:false})}><i className="fa fa-times"></i> Cancel</button>
                                        <button className="btn btn-success btn-sm" onClick={() => updateText()}><i className="fa fa-save"></i> Save</button>
                                    </div>
                                </If>
                            </>) : ""
                        }
                    </div>
                </div>
                <div class="message_body pt-0 pl-5 pr-1 pb-3">
                    {state.isEdit?
                        <MentionsInput
                            cols={30}
                            rows={5}
                            value={state.noteText}
                            onChange={changeText}
                            style={defaultStyle}
                            markup="@[__display__](__id__) "
                            placeholder={"Add notes and @mentions here"}
                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={props.users}
                                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                    <div className="user">{highlightedDisplay}</div>
                                )}
                                style={mentionStyle}
                            />
                        </MentionsInput>:
                        <p class="text-gray m-0 mb-2" dangerouslySetInnerHTML={{__html: state.htmlText}}/>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default Note;