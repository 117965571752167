import * as ACTION from "../constants/contactActionTypes";
import { addDays } from 'date-fns';

const initialState = {
    contact: null,
    showNoteModal: false,
    showTaskModal: false,
    videoLoader: true,
    videoFolders: null,
    videos: null,
    s3ServerVideoUrl: '',
    selectedVideo: null,
    folderId: null,
    messageTemplates: null,
    allContactList: [],
    userInfo: [],
    allActivityList : [],
    subUserList: null,
    userTagList:null,
    sourceList: null,
    pipelineList: null,
    campaignList: null,
    reloadNoteList: false,
    reloadConversation: false,
    storedContactListGroup: null,
    //Contact List
    contactListFirstLoading: true,
    contactListSelectedSource : '',
    contactListSelectedOwner : '',
    contactListSearchTextInTop : '',
    contactListSelectedTag : '',
    contactListSelectedStage : '',
    contactListSelectedFile : '',
    contactListSelectedSubUser : [-1],
    contactListSelectedCampaign : '',
    contactListSelectedOperationList: [
        {
            "filter":
                {},
            "sortField": "id",
            "sortOrder": "desc"

        }
    ],
    contactListSelectedOmitList: ['name','address', 'city', 'state', 'country', 'campaigns'],
    contactListSelectedContactIds: [],
    contactListSelectedDateRangeInfo: [
        {
            startDate: addDays(new Date(),-30),
            endDate: new Date(),
            key: 'selection',
        }
    ],
    contactListSelectedFromDate : '',
    contactListSelectedEndDate : '',
    contactListSelectedColumnFilterData : {},
    contactListPage: 1,
    contactListpageSize: 50,
    contactListDatePickerActive: false,
    contactListColumnFilterMultipleSelectedData: [[], []],
    contactListColumnFilterMultiSelectedDatalabel: [[], []],
    contactListStageListForPipeline: [],
    contactListSelectedSavedContactList: { 'status': false },

    /* Custom Fields */
    isLoadedCustomFields : false,
    userCustomFields: null,

    //For next previous in contact details
    assignedContactListInfo:null,
    contactListParams: null,
    tagListInfo:{per_page:20,current_page:1,data:[],fetch:true,payload: {paginate: "true"},columns: ["id", "name"]},


    // Reduce contact table query
    userListInObject: {},
    contactSourceInObject: {},
    pipelineListInObject: {},
    carrierLookupPrices: null
};

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_CONTACT_DETAILS:
            return { ...state, contact: action.payload }
        case ACTION.SET_SHOW_NOTE_MODAL:
            return { ...state, showNoteModal: action.payload }
        case ACTION.SET_SHOW_TASK_MODAL:
            return { ...state, showTaskModal: action.payload }
        case ACTION.UPDATE_CONTACT_COLUMN:
            return {
                ...state, contact: {
                    ...state.contact,
                    ...action.payload
                }
            }
        /* Video Email Start */
        case ACTION.STORE_VIDEOS:
            return { ...state, ...action.payload }

        case ACTION.STORE_VIDEO_FOLDERS:
            return { ...state, videoFolders: action.payload, videoLoader: false }

        case ACTION.UPDATE_FOLDER_ID:
            return { ...state, folderId: action.payload }
        /* Video Email End */

        /* Message Template Start */
        case ACTION.STORE_MESSAGE_TEMPLATE:
            return { ...state, messageTemplates: action.payload }
        /* Message Template End */

        /*Get All Contacts */
        case ACTION.SET_ALL_CONTACTS:
            return { ...state, allContactList: action.payload }
        /* GET All Contact End */

        /*Set User Info */
        case ACTION.SET_USER_INFO:
            return { ...state, userInfo: action.payload }

        
        case ACTION.SET_ALL_TASKS:
            return { ...state, allActivityList: action.payload }
        case ACTION.SET_SUBUSER_LIST:
            return { ...state, subUserList: action.payload }

        case ACTION.SET_USERTAG_LIST:
            return { ...state, userTagList: action.payload }

        case ACTION.SET_USERSOURCE_LIST:
            return { ...state, sourceList: action.payload }    

        case ACTION.SET_USERCUSTOMFIELD_LIST:
            return { ...state, userCustomFields: action.payload, isLoadedCustomFields: true }  

        case ACTION.SET_PIPELINE_LIST:
            return { ...state, pipelineList: action.payload }
        case ACTION.SET_CAMPAIGN_LIST:
            return { ...state, campaignList: action.payload }   
        case ACTION.RELOAD_NOTE_LIST:
                return { ...state, reloadNoteList: action.payload }      
        case ACTION.RELOAD_CONVERSATION:
            return { ...state, reloadConversation: action.payload }  
        case ACTION.SET_ALL_CONTACTLISTGROUP:
            return { ...state, storedContactListGroup: action.payload } 
        case ACTION.CONTACT_LIST_FIRSTLOADING:
            return { ...state, contactListFirstLoading: action.payload } 
        case ACTION.CONTACT_LIST_SELECTEDSOURCE:
            return { ...state, contactListSelectedSource: action.payload } 
        case ACTION.CONTACT_LIST_SELECTEDOWNER:
            return { ...state, contactListSelectedOwner: action.payload }     
        case ACTION.CONTACT_LIST_SEARCHTEXTINTOP:
            return { ...state, contactListSearchTextInTop: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDTAG:
            return { ...state, contactListSelectedTag: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDSTAGE:
            return { ...state, contactListSelectedStage: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDFILE:
            return { ...state, contactListSelectedFile: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDSUBUSER:
            return { ...state, contactListSelectedSubUser: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDCAMPAIGN:
            return { ...state, contactListSelectedCampaign: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDOPERATIONLIST:
            return { ...state, contactListSelectedOperationList: action.payload }   
        case ACTION.CONTACT_LIST_SELECTEDOMITLIST:
            return { ...state, contactListSelectedOmitList: action.payload } 
        case ACTION.CONTACT_LIST_SELECTEDCONTACTIDS:
            return { ...state, contactListSelectedContactIds: action.payload }    
        case ACTION.CONTACT_LIST_SELECTEDDATERANGEINFO:
            return { ...state, contactListSelectedDateRangeInfo: action.payload }      
        case ACTION.CONTACT_LIST_SELECTEDFROMDATE:
            return { ...state, contactListSelectedFromDate: action.payload }   
        case ACTION.CONTACT_LIST_SELECTEDENDDATE:
            return { ...state, contactListSelectedEndDate: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDCOLUMNFILTERDATA:
            return { ...state, contactListSelectedColumnFilterData: action.payload }    
        case ACTION.CONTACT_LIST_SELECTEDPAGE:
            return { ...state, contactListPage: action.payload }     
        case ACTION.CONTACT_LIST_SELECTEDPAGESIZE:
            return { ...state, contactListpageSize: action.payload }    
        case ACTION.CONTACT_LIST_SELECTEDDATEPICKERACTIVE:
            return { ...state, contactListDatePickerActive: action.payload }   
        case ACTION.CONTACT_LIST_COLUMNFILTERMULTIPLESELECTEDDATA:
            return { ...state, contactListColumnFilterMultipleSelectedData: action.payload }       
        case ACTION.CONTACT_LIST_COLUMNFILTERMULTIPLESELECTEDDATALABEL:
            return { ...state, contactListColumnFilterMultiSelectedDatalabel: action.payload }      
        case ACTION.CONTACT_LIST_STAGELIST_FOR_PIPELINE:
            return { ...state, contactListStageListForPipeline: action.payload }     
        case ACTION.CONTACT_LIST_SELECTED_SAVED_CONTACT_LIST:
            return { ...state, contactListSelectedSavedContactList: action.payload }  

        case ACTION.UPDATE_MULTIPLE_DATA:

            return {...state, ...action.payload}
            
        default:
            return state;
    }
}


export default contactReducer;
