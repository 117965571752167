import * as ACTION from "../constants/dripBatchActionTypes";

const initialState = {
    show_edit_modal: false,
    edit_data: null,
    search_text: '',
    need_to_reload: false,
    total_records: 0,
    total_pages: 0,
    current_page: 1,
    per_page: 10,

    show_notification: false,
    notification_text: '',
    notification_type: 'success',

    drip_batch_details: null
};

export const RELOAD_DRIP_BATCH_REPORT_FLAG = 'RELOAD_DRIP_BATCH_REPORT_FLAG'
export const DRIP_BATCH_REPORT_SEARCH_TEXT = 'DRIP_BATCH_REPORT_SEARCH_TEXT'
export const DRIP_BATCH_REPORT_PAGINATION = 'DRIP_BATCH_REPORT_PAGINATION'
export const DRIP_BATCH_REPORT_EDIT_TITLE = 'DRIP_BATCH_REPORT_EDIT_TITLE'

const dripBatchReducer = (state = initialState, action) => {
    const {type, payload} = action 
    switch (type) {
        case ACTION.DRIP_BATCH_REPORT_EDIT_TITLE:
            return {...state, show_edit_modal: payload.modalShowFlag, edit_data: payload.editData}

        case ACTION.DRIP_BATCH_REPORT_SEARCH_TEXT:
            return {...state, search_text: payload}

        case ACTION.RELOAD_DRIP_BATCH_REPORT_FLAG:
            return {...state, need_to_reload: payload}

        case ACTION.DRIP_BATCH_REPORT_PAGINATION:
            return {...state, 
                total_records: payload.totalRecords !== undefined ? payload.totalRecords : state.total_records, 
                current_page: payload.currentPage !== undefined ? payload.currentPage : state.current_page, 
                per_page: payload.perPage !== undefined ? payload.perPage :state.per_page,
                total_pages: payload.totalPages !== undefined ? payload.totalPages :state.total_pages
            }

        case ACTION.DRIP_REPORT_SHOW_NOTIFICATION:
            return {...state, 
                show_notification: payload.flag,
                notification_text: payload.text,
                notification_type: payload.type
            }

        default:
            return state;
    }
}
export default dripBatchReducer;