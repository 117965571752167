import React, {useCallback, useContext, useEffect, useState} from "react";
import BottomButtons from "./BottomButtons";
import {cleanDataFileImportApi, getCarrierLookupCreditSettingsApi} from "../../../api/contactApi";
import {DataCleanContext} from "../StateProvider";
import ActionTypes from "../ActionTypes";
import {CONTACT_VALIDATION_TYPE} from "../../../constants/CoreConstants";
import {FILE_IMPORT_STEP, TAB_CLEAN_LIST} from "../Constants";

const ContactValidation = (props) => {
    const {state, dispatch} = useContext(DataCleanContext);
    const creditSettingType = {"CALLER_NAME" : 63, "CARRIER" : 64, "VERIFY_EMAIL":65};

    const handleChangeCheckbox = (field,value) => {
        dispatch({
            type: ActionTypes.UPDATE_STATE,
            payload: {
                carrierInfos: {
                    ...state.carrierInfos,
                    [field]: !state.carrierInfos[field] ? value : null
                }
            }
        });
    };
    const getValidationPrice = (validationType) => {
        let price;
        if(state.carrierLookupPrices){
            price = state.carrierLookupPrices.find(({ type }) => type === creditSettingType[validationType]);
            if(price){
                price = price.value;
            }
        }else if(!state.carrierLookupPrices){
            getCarrierLookupCreditSettingsApi()
                .then((response) => {
                    if (response.data && response.data.status === "success") {
                        if (response.data && response.data.data) {
                            dispatch({
                                type: ActionTypes.UPDATE_STATE,
                                payload: {
                                    carrierLookupPrices: response.data.data
                                }
                            })
                            price = response.data.data;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return price;
    };

    const checkboxValueHandler = (key) => {
        return state.carrierInfos && state.carrierInfos[key];
    };

    const prevStep = (e) => {
        e.preventDefault();
        dispatch({
            type: ActionTypes.UPDATE_STATE,
            payload: {
                activeStep: state.activeStep - 1
            }
        });
    };

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData();

        const data = state.submitData;
        for (const [key, value] of Object.entries(data)) {
            formData.append(value.map, value.data);
        }

        formData.append("carrier_infos", JSON.stringify(state.carrierInfos));
        formData.append("file", state.file);

        dispatch({
            type: ActionTypes.UPDATE_STATE,
            payload: {
                activeStep: FILE_IMPORT_STEP.STEP_SUCCESS
            }
        });

        cleanDataFileImportApi(formData)
            .then((res)=>{
                res = res.data;
                if(res && res.success){
                    dispatch({
                        type: ActionTypes.UPDATE_STATE,
                        payload: {
                            activeTab: TAB_CLEAN_LIST.REPORTS,
                            isFileSelected: false
                        }
                    });
                }else {
                    window.showNotification("error", res.message);
                    dispatch({
                        type: ActionTypes.UPDATE_STATE,
                        payload: {
                            activeStep: FILE_IMPORT_STEP.STEP_UPLOAD
                        }
                    });
                }
            }).catch((err)=>{
            window.showNotification("error", "Something went wrong try again later!");
            dispatch({
                type: ActionTypes.UPDATE_STATE,
                payload: {
                    activeStep: FILE_IMPORT_STEP.STEP_UPLOAD
                }
            });
        });
    };

    return (
        <div className="import_content_container white pb-2">
            <div className="file_upload_section_wrapper">
                <div className="main_section_title_section">
                    <div>
                        <h6 className="center-align">Which type of lookup do you want to use to clean your list data before uploading?</h6>
                    </div>
                </div>

                <div className="duplicate_rule_content_section">
                    <div className="duplicate_rule_content d-flex align-items-center flex-column">
                        <p className="duplicate_rule_content_title">Select your desired options below:</p>
                        <form action="#" className="duplicate_rule_form">
                            <div>
                                <p className={`options ${(checkboxValueHandler("emailVerificationType") === CONTACT_VALIDATION_TYPE.VERIFY_EMAIL) ? "duplicate_rule_checked" : ""}`}>
                                    <label>
                                        <input type="checkbox" checked={(checkboxValueHandler("emailVerificationType") === CONTACT_VALIDATION_TYPE.VERIFY_EMAIL)} onChange={()=>{
                                            handleChangeCheckbox("emailVerificationType", CONTACT_VALIDATION_TYPE.VERIFY_EMAIL);
                                        }} className="filled-in" />
                                        <span>Validate my emails (${getValidationPrice("VERIFY_EMAIL")} per email address)</span>
                                    </label>
                                </p>
                                <p className={`options ${(checkboxValueHandler("phoneVerificationType") === CONTACT_VALIDATION_TYPE.CARRIER) ? "duplicate_rule_checked" : ""}`}>
                                    <label>
                                        <input type="checkbox" onChange={()=>{
                                            handleChangeCheckbox("phoneVerificationType", CONTACT_VALIDATION_TYPE.CARRIER);
                                        }} checked={(checkboxValueHandler("phoneVerificationType") === CONTACT_VALIDATION_TYPE.CARRIER)} className="filled-in"/>
                                        <span>Validate my phone numbers (${getValidationPrice("CARRIER")} per phone number)</span>
                                    </label>
                                </p>
                                <p className={`options ${(checkboxValueHandler("doNotCall") === CONTACT_VALIDATION_TYPE.DO_NOT_CALL) ? "duplicate_rule_checked" : ""}`}>
                                    <label>
                                        <input type="checkbox" onChange={()=>{
                                            handleChangeCheckbox("doNotCall", CONTACT_VALIDATION_TYPE.DO_NOT_CALL);
                                        }} checked={(checkboxValueHandler("doNotCall") === CONTACT_VALIDATION_TYPE.DO_NOT_CALL)} className="filled-in"/>
                                        <span>Check "DO NOT CALL" my phone numbers (${getValidationPrice("CARRIER")} per phone number)</span>
                                    </label>
                                </p>
                            </div>
                        </form>
                    </div>

                    <BottomButtons
                        onBackClick={prevStep}
                        onContinueClick={submitForm}
                        continueButtonText="Upload"/>
                </div>
            </div>
        </div>
    );
}

export default ContactValidation;
