const NotFoundActivity = () => {
    return ( <tr>
        <td colSpan="5">
            <div class="p-3 message__container contact__middle__inbox__msg__container">
                <div class="notItemsFound">No Activities Found</div>
            </div>
        </td>
    </tr> );
}
 
export default NotFoundActivity;