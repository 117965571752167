import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import StepProgressBar from './StepProgressBar';
import { CONTACT_VALIDATION_TYPE, FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import BottomButtons from './BottomButtons';
import { updateMultipleData } from "../../actions/contactAction";
import { getCarrierLookupCreditSettingsApi } from "../../api/contactApi";
const HeaderRight = () => {
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}

const validationActionConst = {
    UPLOAD_ALL: "UPLOAD_ALL",
    SKIP_INVALID: "SKIP_INVALID"
};

const ContactValidation = props => {
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [carrier, setCarrier] = useState(false);
    const [callerName, setCallerName] = useState(false);
    const [validationAction, setValidationAction] = useState("");
    const creditSettingType = { "CALLER_NAME": 63, "CARRIER": 64, "VERIFY_EMAIL": 65 };

    useEffect(() => {
        setVerifyEmail(props.state?.validate_contacts?.emailVerificationType === CONTACT_VALIDATION_TYPE.VERIFY_EMAIL);
        setCarrier(props.state?.validate_contacts?.phoneVerificationType === CONTACT_VALIDATION_TYPE.CARRIER);
        setCallerName(props.state?.validate_contacts?.phoneVerificationType === CONTACT_VALIDATION_TYPE.CALLER_NAME);
        setValidationAction(props.state?.validate_contacts?.validationAction);
    }, []);

    const saveAndContinue = useCallback((e) => {
        e.preventDefault()
        if ((carrier || callerName || verifyEmail) && !validationAction) {
            window.showNotification("error", "Please select a validation action");
            return;
        }

        props.handleChange("validate_contacts", {
            phoneVerificationType: carrier ? CONTACT_VALIDATION_TYPE.CARRIER : (callerName ? CONTACT_VALIDATION_TYPE.CALLER_NAME : null),
            emailVerificationType: verifyEmail ? CONTACT_VALIDATION_TYPE.VERIFY_EMAIL : null,
            validationAction: validationAction
        });
        props.nextStep()
    }, [verifyEmail, carrier, callerName, validationAction]);

    const getValidationPrice = (validationType) => {
        let price;
        if (props.carrierLookupPrices) {
            price = props.carrierLookupPrices.find(({ type }) => type === creditSettingType[validationType]);
            if (price) {
                price = price.value;
            }
        } else if (!props.carrierLookupPrices) {
            getCarrierLookupCreditSettingsApi()
                .then((response) => {
                    if (response.data && response.data.status === 'success') {
                        if (response.data && response.data.data) {
                            props.updateMultipleData({ carrierLookupPrices: response.data.data });
                            price = response.data.data;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return price;
    }

    const prevStep = useCallback((e) => {
        e.preventDefault();
        props.prevStep();
    }, []);

    return (
        <>
            <div className="import_content_container">
                <div className="main_content_inner_container white">
                    <div className="main_content_topbar">
                        <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                            <HeaderRight />
                        </div>
                    </div>

                    <div className="divider main_content_container_divider"></div>

                    <div className="main_content_main_section">
                        <StepProgressBar fromCampaign={props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_VALIDATION} />
                    </div>

                    <div className="content_divider"><div class="content_divider_inner"></div></div>

                    <div className="main_section_title_section">
                        <div>
                            <h6 className="center-align">Would you like to clean your list data prior to uploading?</h6>
                            <h6 className="center-align">This will improve deliverability of your messages. <span className="highlight color_blue">(Recommended)</span></h6>
                        </div>
                    </div>

                    <div className="divider main_content_main_section_divider"></div>

                    <div className="duplicate_rule_content_section">
                        <div className="duplicate_rule_content">
                            <p className="duplicate_rule_content_title">Select your desired options below (leave unchecked to skip):</p>
                            <form action="#" className="duplicate_rule_form">
                                <div>
                                    <p className={`options ${verifyEmail ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" checked={verifyEmail} onChange={() => {
                                                setVerifyEmail(!verifyEmail)
                                                if (verifyEmail && !carrier) {
                                                    setValidationAction("");
                                                }
                                            }} className="filled-in" />
                                            <span>Validate my emails (${getValidationPrice("VERIFY_EMAIL")} per email address)</span>
                                        </label>
                                    </p>
                                    <p className={`options ${carrier ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" onChange={() => {
                                                setCarrier(!carrier)
                                                if (carrier && !verifyEmail) {
                                                    setValidationAction("");
                                                }
                                            }} checked={carrier} className="filled-in" />
                                            <span>Validate my phone numbers (${getValidationPrice("CARRIER")} per phone number)</span>
                                        </label>
                                    </p>
                                    {/*<p className={`options ${callerName ? 'duplicate_rule_checked' : ''}`}>*/}
                                    {/*    <label>*/}
                                    {/*        <input type="checkbox" onChange={()=>{ setCallerName(!callerName) }} checked={callerName} className="filled-in"/>*/}
                                    {/*        <span>Caller-Name verification: ${getValidationPrice("CALLER_NAME")} per number. Check the box to proceed.</span>*/}
                                    {/*    </label>*/}
                                    {/*</p>*/}
                                </div>
                            </form >
                        </div >

                        {
                            (verifyEmail || carrier) &&
                            <div className="duplicate_rule_content">
                                <p className="duplicate_rule_content_title">What action should take after validation:</p>
                                <form action="#" className="duplicate_rule_form">
                                    <div>
                                        <p className={`options ${(validationAction === validationActionConst.SKIP_INVALID) ? 'duplicate_rule_checked' : ''}`}>
                                            <label>
                                                <input type="checkbox" checked={(validationAction === validationActionConst.SKIP_INVALID)} onChange={() => { setValidationAction(validationActionConst.SKIP_INVALID) }} className="filled-in" />
                                                <span>Continue uploading my contacts, but remove the ones with invalid data</span>
                                            </label>
                                        </p>
                                        <p className={`options ${(validationAction === validationActionConst.UPLOAD_ALL) ? 'duplicate_rule_checked' : ''}`}>
                                            <label>
                                                <input type="checkbox" onChange={() => { setValidationAction(validationActionConst.UPLOAD_ALL) }} checked={(validationAction === validationActionConst.UPLOAD_ALL)} className="filled-in" />
                                                <span>Upload all contacts</span>
                                            </label>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        }

                        <BottomButtons
                            onBackClick={prevStep}
                            onContinueClick={saveAndContinue}
                            continueButtonText={'Save & Continue'} />
                    </div >
                </div >
            </div >
        </>
    );
}

const mapStateToProps = state => {
    return {
        carrierLookupPrices: state.contactReducer.carrierLookupPrices,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactValidation);
