import * as ACTION from "../constants/contactActionTypes";

const initialState = {
    virtualNumberList : []
};

const virtualNumberReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.SET_VIRTUAL_NUMBER_LIST:
            return {...state,virtualNumberList:action.payload}
        default:
            return state;
    }
}

export default virtualNumberReducer;
