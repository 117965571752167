import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const CompleteStep = () => {
    return (
        <div className="import_content_container white pb-2">
            <div className="file_upload_section_wrapper">
                <div className="main_section_title_section loader-wrapper">
                    <h6 className="center-align">Please wait while uploading the file....</h6>
                    <CircularProgress size={80}/>
                </div>
            </div>
        </div>
    );
}

export default CompleteStep;