import { sendRequest, sendRequestBackEnd } from "./rootApi";
import Api from "../constants/Api";

export const carrierLookupCleanDataSingleApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.carrierLookupCleanDataSingle, payload);
}
export const carrierLookupCleanDataBulkApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.carrierLookupCleanDataBulk, payload);
}

export const cleanContactsActionsApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.cleanContactsActions, payload);
}


export const cleanDataFileListApi = async (payload) => {
    return sendRequest("post", Api.cleanDataFileList, payload);
}

export const cleanDataFilesDetailsApi = async (payload) => {
    return sendRequest("post", Api.cleanDataFilesDetails, payload);
}

export const addToDoNotCallListApi = async (payload) => {
    return sendRequest("post", Api.addToDoNotCallList, payload);
}