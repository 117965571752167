import { Skeleton } from "@material-ui/lab";

const ActivityListLoading = () => {
    return ( 
        <>
        <tr>
            <td><Skeleton variant="rect" width={20}/></td>
            <td><Skeleton variant="rect" width={100}/></td>
            <td><Skeleton variant="rect" width={100}/></td>
            <td><Skeleton variant="rect" width={100}/></td> 
            <td><Skeleton variant="rect" width={100}/></td>
        </tr> 
        <tr>
            <td><Skeleton variant="rect" width={20}/></td>
            <td><Skeleton variant="rect" width={100}/></td>
            <td><Skeleton variant="rect" width={100}/></td>
            <td><Skeleton variant="rect" width={100}/></td> 
            <td><Skeleton variant="rect" width={100}/></td>
        </tr> 
        <tr>
            <td><Skeleton variant="rect" width={20}/></td>
            <td><Skeleton variant="rect" width={100}/></td>
            <td><Skeleton variant="rect" width={100}/></td>
            <td><Skeleton variant="rect" width={100}/></td> 
            <td><Skeleton variant="rect" width={100}/></td>
        </tr>      
        </>
    );
}
 
export default ActivityListLoading;