import { useState, useEffect } from "react";
import './contact-more-info.css'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import LinkIcon from '@material-ui/icons/Link';
import CakeIcon from '@material-ui/icons/Cake';
import UpdateMoreInfo from "./UpdateMoreInfo";
import PublicIcon from '@material-ui/icons/Public';
import { formatDateWithoutZone } from "../../../globals/TimezoneConversion";
import moment from "moment";
import { Link } from "react-router-dom";

const MoreInfo = (props) => {
    const [isShowUpdateModal, setIsShowUpdateModal] = useState(false)
    const [contact, setContact] = useState(null)

    useEffect(() => {
        setContact(props.contact)
    }, [props.contact])

    const handleUpdateModal = ( updated = false, updateData = null) => {
        if(updated){
            /* update all state field */
            props.onChange({ ...contact, ...updateData })
            // setContact({ ...contact, ...updateData })
        }
        setIsShowUpdateModal(false)
    }

    if(contact === null){
        return null;
    }

    return (
        <div className="contact-single-details-more-info-tab">
            <div className="contact-single-details-more-info-tab-body">
                <div className="contact-single-details-more-info-header">
                    <span>More Info</span>
                    <div className="accent--bg--text--color accent--bg--color" onClick={() => setIsShowUpdateModal(true)}>Edit</div>
                </div>
                <div className="contact-single-details-more-info-body">
                    <EachItem 
                        icon={<LocationOnIcon fontSize="small" />} 
                        title="Address" 
                        value={(contact.address !== undefined && contact.address !== null) ? contact.address : ''} 
                        noData="No address added" 
                    />
                    <EachItem
                        icon={''}
                        title="City"
                        value={(contact.city !== undefined && contact.city !== null) ? contact.city : ''}
                        noData="No city added"
                    />
                    <EachItem 
                        icon={''} 
                        title="State" 
                        value={(contact.state !== undefined && contact.state !== null) ? contact.state : ''} 
                        noData="No state added" 
                    />
                    <EachItem 
                        icon={''} 
                        title="Zip code" 
                        value={(contact.zip !== undefined && contact.zip !== null) ? contact.zip : ''} 
                        noData="No zip code added" 
                    />
                    <EachItem 
                        icon={<PublicIcon fontSize="small" />} 
                        title="Country" 
                        value={(contact.country !== undefined && contact.country !== null) ? contact.country : ''} 
                        noData="No country added" 
                    />
                    <EachItem 
                        icon={<AttachMoneyIcon fontSize="small" />} 
                        title="Deal value" 
                        value={(contact.deal_value !== undefined && contact.deal_value !== null) ? contact.deal_value : ''} 
                        noData="No deal value added" 
                    />
                    <EachItem 
                        icon={<BusinessIcon fontSize="small" />} 
                        title="Company name" 
                        value={(contact.company_name !== undefined && contact.company_name !== null) ? contact.company_name : ''} 
                        noData="No company name added" 
                    />
                    <EachItem 
                        icon={<LinkIcon fontSize="small" />} 
                        title="URL" 
                        value={(contact.url !== undefined && contact.url !== null) ? contact.url : ''} 
                        noData="No url added" 
                        clickable={true}
                    />
                    <EachItem 
                        icon={<CakeIcon fontSize="small" />} 
                        title="Birth Date" 
                        value={(contact.birth_date !== undefined && contact.birth_date !== null && contact.birth_date !== '') ? formatDateWithoutZone(contact.birth_date,'MM/DD/YYYY') : ''} 
                        noData="No birth date added" 
                        extraData = { () => {
                            let ageText = "";
                            if(contact.birth_date !== undefined && contact.birth_date !== null){
                                try{
                                    let current = moment();
                                    let birthDate = moment(contact.birth_date, 'YYYY-MM-DD');  
                                    let age = moment.duration(current.diff(birthDate));
                                    let years = age.years();
                                    let months = age.months();
                                    let days = age.days();
 
                                    if(years < 0 || months < 0 || days < 0 || (years <= 0 && months <= 0  && days <= 0)){
                                        ageText = "0";
                                    } else {
                                        ageText = `${years} years ${months} months ${days} days`;
                                    }
                                }
                                catch(error){
                                    console.log("age calculate: contact.birth_date", contact.birth_date, error)
                                }
                            }
                            if(ageText === ""){
                                return "";
                            }
                            return <span className="age__calculate"><span classname="age__text">Age</span> {ageText}</span>
                        }}
                    />
                    <EachItem 
                        icon={<CakeIcon fontSize="small" />} 
                        title="Anniversary Date" 
                        value={(contact.anniversary_date !== undefined && contact.anniversary_date !== null && contact.anniversary_date !== '') ? formatDateWithoutZone(contact.anniversary_date,'MM/DD/YYYY') : ''} 
                        noData="No anniversary date added"
                    />
                </div>  
            </div>
        
            {isShowUpdateModal &&
                <UpdateMoreInfo
                    open={isShowUpdateModal}
                    data={contact}
                    onClose={handleUpdateModal}
                />
            }
        </div>
    )
}
export default MoreInfo;

const EachItem = ({title, value, icon, noData, extraData, clickable =false}) => {
    return (
        <div className="___more_info__each_info___item">
            <div className={`___more_info___icon`}>
                {icon}
            </div>
            <div className="___more_info__content">
                <div className="___more_info__title">{title}</div>
                {(!clickable || value==='') ?
                <div className={`___more_info__value ${value === '' ? 'no____data' : ''}`}>{value === '' ? noData : value}</div>
                : <div className={`___more_info__value ${value === '' ? 'no____data' : ''}`}>
                    <Link to={`//${value.replace(/^https?:\/\//, '')}`} target="_blank">{value === '' ? noData : value}</Link></div>
                }
                {extraData !== undefined && extraData !== "" && extraData()}
            </div>
        </div>
    )
}