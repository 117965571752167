import React from "react";
import Styles from "./Pagination.module.css";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";

const Pagination = ({ page, totalPage, callback }) => {

    const onChangePage = (newPage) => {
        newPage = newPage.replace(/[a-z|A-Z|!@#$%^&* ]/g,"");
        if (newPage !== "" && newPage > 0) {
            if (totalPage >= newPage) {
                callback(parseInt(newPage));
            }
        }else{
            callback("");
        }
    }

    let previousPage = null;
    let nextPage = null;

    if (page > 1) {
        previousPage = page-1;
    }

    if (page !== totalPage) {
        if (typeof page === "string" && page !== ""){
            callback(parseInt(page));
        }
        nextPage = page+1;
    }

    return(
        totalPage !== 0 ?
            <div className={Styles.paginationWrapper}>
                <div className={Styles.paginationInnerWrapper}>
                <span className={`${Styles.paginationPrev} ${previousPage === null ? Styles.disabled : ""}`} onClick={()=>{
                    if (previousPage !== null){
                        callback(page-1)
                    }
                }}>
                    <KeyboardArrowLeft/>
                </span>
                    <input type="text" className={Styles.paginationInput} value={page} onChange={(e)=>{onChangePage(e.target.value)}}/>
                    <span className={Styles.paginationDivider}></span>
                    <input className={Styles.paginationTotalPage} readOnly value={totalPage}/>
                    <span className={`${Styles.paginationNext} ${nextPage === null ? Styles.disabled : ""}`} onClick={()=>{
                        if (nextPage !== null){
                            callback(page+1)
                        }
                    }}>
                    <KeyboardArrowRight/>
                </span>
                </div>
            </div> :
            <div/>
    );
};

export default Pagination;