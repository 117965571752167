import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { IconList } from './IconList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { confirmAlert } from 'react-confirm-alert';
import { removeContactListApi } from '../../api/contactApi';
import Helper from './Helper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

function PopperHelperNormal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const submit=(event,id,data = null)=>{
    handleClose(event)
    props.onSubmit(id,data);

  }

  const removeListButton=(data, index)=>{


    return <span className="align-items-center remove_list_Button cursor-pointer d-flex" onClick={()=>removeListConfirm(data, index)}>{IconList.removeFilterIcon}</span>
    
}

const removeListConfirm=(data,index)=>{

  confirmAlert({
    title: 'Confirm to delete',
    message: `Are you sure to delete ${data.title} permanently ?`,
    buttons: [
        {
            label: 'Yes',
            onClick: async () => {
                await removeContactListApi({ 'id': data.id })
                    .then(response => {
                      
                      if(response.data.status === 'success'){
                        window.showNotification('SUCCESSS',`${data.title} list removed successfully`)
                        props.removeSuccess(data,index)
                      }
                        
                    })
                    .catch(error => {
                        window.showNotification('ERROR',' Something went wrong! ')
                    });
            }
        },
        {
            label: 'No',
            onClick: () => {
                return;
            }
        }
    ]
})
}

  return (
    <div className="colums-filter">

        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={`accent--bg--text--color ${(props.buttonClassName !== undefined && props.buttonClassName != null) ? props.buttonClassName : ''}`}

        >
        {/* {(props.buttonIcon != undefined && props.IconList.myListIcon != null) ? IconList[props.buttonIcon]: ''}&nbsp; */}
        {(props.buttonColumnIcon !== undefined && props.buttonColumnIcon != null) ? IconList[props.buttonColumnIcon]: ''}&nbsp;

          {props.buttonText !== undefined ? props.buttonText: "Toggle menu Grow"}
        </Button>
        <Popper className="colums-filter" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
            placement='bottom'>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem className="dropdownhelper-menuitem-class" onClick={(event)=>submit(event,'')}>{props.checkBoxNeeded ? props.selectedList === '' ? <CheckBoxIcon /> : '' : ''} Show All</MenuItem>
                  {(props.from !== undefined && props.from != null && props.from === 'my-lists' && props.datalist !== undefined && props.datalist.length > 0) &&
                    props.datalist.map((data,index)=>{
                      if(data.filter_params !== undefined && data.filter_params != null && data.filter_params.operation !== undefined &&
                        data.filter_params.operation != null && data.filter_params.operation !== ""){
                      return  (<MenuItem className={`dropdownhelper-menuitem-class ${props.menuItemClassName !== undefined ? props.menuItemClassName : ''}`} key={`contact-list-${data.id}`}><span onClick={(event)=>submit(event,data.id,data)}>{props.checkBoxNeeded ? props.selectedList == data.id ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : ''} {Helper.limitMessage(data[props.mapping.title],15)} </span>{removeListButton(data, index)}</MenuItem>)
                    }})
                    
                  }
                  {(props.from === undefined || props.from == null || props.from !== 'my-lists') && 
                                    (props.datalist !== undefined && props.datalist.length > 0) &&
                                      props.datalist.map((data,index)=>(
              
                                          <MenuItem className={`dropdownhelper-menuitem-class ${props.menuItemClassName !== undefined ? props.menuItemClassName : ''}`} key={`contact-list-${data.id}`} onClick={(event)=>submit(event,data.id,data)}>{props.checkBoxNeeded ? props.selectedList == data.id ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : ''} {data[props.mapping.title]}({data.count})</MenuItem>
                                      ))
                                      
                                    
                   }

              </MenuList>

                  {/* {(props.from === undefined || props.from == null || props.from !== 'my-lists') && 
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={(event)=>submit(event,'')}>{props.checkBoxNeeded ? props.selectedList == '' ? <CheckBoxIcon /> : '' : ''} Show All</MenuItem>
                                    {(props.datalist !== undefined && props.datalist.length > 0) &&
                                      props.datalist.map((data,index)=>(
              
                                          <MenuItem key={`contact-list-${data.id}`} onClick={(event)=>submit(event,data.id,data)}>{props.checkBoxNeeded ? props.selectedList == data.id ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : ''} {data[props.mapping.title]}({data.count})</MenuItem>
                                      ))
                                      
                                    }
                                </MenuList>} */}
                  
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      {/* </div> */}
    </div>
  );
}

export default React.memo(PopperHelperNormal)