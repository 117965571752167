import BootstrapTooltip from "../../globals/BootstrapTooltip";

const DialingListTopBar = props => {
    return (
        <div className="auto__dialer_top_section">
            <div className="mb-0 row">
                <div className="col s12 m6 auto__dialer_category_section">
                    <ul>
                        <li className="auto__dialer_title_wrapper"><span className="list-title">Dialing List</span></li>
                        <li className="total-lists-number">
                            <span>Total List: {props.totalRecords} </span>
                        </li>
                    </ul>
                </div>
                <div className="col s12 m6 auto__dialer_utility_buttons">
                    <ul className="right">
                        <li className="mr10pxSpace">
                            <BootstrapTooltip title="New List" arrow placement="top">
                                <a className="rounded-icons" href="#!" onClick={e => { e.preventDefault(); props.showAddNewModal()}}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 7C14 7.64517 13.944 8.16667 13.2988 8.16667H8.16667V13.2988C8.16667 13.9428 7.64517 14 7 14C6.35483 14 5.83333 13.9428 5.83333 13.2988V8.16667H0.701166C0.0571664 8.16667 0 7.64517 0 7C0 6.35483 0.0571664 5.83333 0.701166 5.83333H5.83333V0.701166C5.83333 0.0559998 6.35483 0 7 0C7.64517 0 8.16667 0.0559998 8.16667 0.701166V5.83333H13.2988C13.944 5.83333 14 6.35483 14 7Z" fill="#546376"></path>
                                    </svg>
                                </a>
                            </BootstrapTooltip>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default DialingListTopBar;