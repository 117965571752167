import React from "react";
import * as ACTION from "../constants/dripBatchActionTypes";

/* middleware */
export const getDripBatchReportByPagination = (payload,callback)=>({
    type: ACTION.GET_DRIP_BATCH_REPORT_PAGINATION,
    payload: {payload,callback}
});

export const submitDripBatchTitleEdit = (payload,callback)=>({
    type: ACTION.SUBMIT_DRIP_BATCH_REPORT_TITLE_EDIT,
    payload: {payload,callback}
});

export const getDripBatchContactsByPagination = (payload,callback)=>({
    type: ACTION.GET_DRIP_BATCH_CONTACT_PAGINATION,
    payload: {payload,callback}
});

/* reducer */
export const _dripBatchReportReload = (payload)=>({
    type: ACTION.RELOAD_DRIP_BATCH_REPORT_FLAG,
    payload: payload
});

export const _dripBatchReportSearchText = (payload)=>({
    type: ACTION.DRIP_BATCH_REPORT_SEARCH_TEXT,
    payload: payload
});

export const _dripBatchReportPagination = (payload)=>({
    type: ACTION.DRIP_BATCH_REPORT_PAGINATION,
    payload: payload
});

export const _dripBatchReportTitleEditModal = (payload)=>({
    type: ACTION.DRIP_BATCH_REPORT_EDIT_TITLE,
    payload: payload
});

export const _dripReportShowNotification = (payload)=>({
    type: ACTION.DRIP_REPORT_SHOW_NOTIFICATION,
    payload: payload
});