import React, {Fragment, useContext, useEffect, useState} from "react";
import Styles from "./FileReport.module.css"
import Icons from "./Icons";
import {
    FormControl,
    Select,
    InputBase,
    withStyles,
    MenuItem,
    Button,
    CircularProgress
} from "@material-ui/core";
import ActionTypes from "../ActionTypes";
import {STATUS, TAB_CLEAN_LIST} from "../Constants";
import {DataCleanContext} from "../StateProvider";
import {cleanDataFileListApi} from "../../../api/cleanDataApi";
import Pagination from "./Pagination";
import BootstrapTooltip from "../../globals/BootstrapTooltip";

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        fontSize: "14px !important",
        borderRadius: "4px !important",
        position: "relative",
        color: "#3a414a !important",
        border: "1px solid #ced4da !important",
        padding: "10px 26px 10px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4
        },
        backgroundColor: "#ffffff"
    },
}))(InputBase);

const FileReport = () => {
    const {state, dispatch} = useContext(DataCleanContext);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loading,setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(()=>{
        if (page !== ""){
            setLoading(true);
            cleanDataFileListApi({
                page: page,
                limit: limit,
                totalPage: totalPage
            })
                .then((res)=>{
                    res = res.data;
                    if (res.success){
                        setFiles(res.data);
                        setTotalPage(res.totalPage);
                    }
                }).finally(()=>{
                setLoading(false);
            });
        }
    },[page, limit]);

    const renderFileUrl = (file) => {
        if (file && file.aws3_info){
            if(file.aws3_info && file.aws3_info ===  "\\"){
                return "";
            }else{
                let aws3Info = file.aws3_info.replace(/\\/g, '').replace(/&quot;/g, '\"');
                try{
                    aws3Info = JSON.parse(aws3Info).fileUrl
                }catch(error){
                    console.log(error)
                }
                return  aws3Info;
            }
        }
        return "";
    }

    const renderSearchType = (file) => {
        if (file && file.carrier_infos){
            if(file.carrier_infos && file.carrier_infos ===  "\\"){
                return "[]";
            }else{
                try{
                    let carrierInfos = file.carrier_infos.replace(/\\/g, '').replace(/&quot;/g, '\"');

                    carrierInfos = JSON.parse(carrierInfos);

                    return `[${Object.values(carrierInfos).join(",")}]`;
                }catch(error){
                    console.log(error);
                }
                return "[]";
            }
        }
        return "[]";
    }

    const getChipColor = (file) => {
        switch (file.status){
            case STATUS.ACTIVE:
                return Styles.active;
            case STATUS.PROCESSING:
                return Styles.processing;
            case STATUS.QUEUED:
                return Styles.queued;
            case STATUS.COMPLETE:
                return Styles.complete;
            case STATUS.FAILED:
                return Styles.failed;
            default:
                return Styles.default;
        }
    }

    return(
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <div className={Styles.pageHeaderTitle}>
                    <div>
                        {Icons.docFiles}
                    </div>
                    <div>
                        <h1 className={Styles.pageMainTitle}>Files</h1>
                        <p className={Styles.pageMainSubtitle}>Check the status of files you've uploaded for verifications</p>
                    </div>
                </div>
                <div className={Styles.pageHeaderAction}>
                    <FormControl className={Styles.formControl}>
                        <Select
                            labelId="per-page-select-label"
                            id="per-page-select"
                            name="perPage"
                            value={limit}
                            displayEmpty
                            onChange={(e)=>{
                                setLimit(e.target.value);
                            }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value={""} disabled># of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={5}>5 of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={10}>10 of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={25}>25 of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={50}>50 of Results</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className={Styles.pageBody}>
                <table>
                    <thead className={Styles.tableHead}>
                        <tr>
                            <th style={{ width: "30%" }}>
                                File Name
                            </th>
                            <th className={Styles.textAlignCenter}>
                                Date Uploaded
                            </th>
                            <th className={Styles.textAlignCenter}>
                                Total Records
                            </th>
                            <th className={Styles.textAlignCenter}>
                                Total Cost
                            </th>
                            <th className={Styles.textAlignCenter}>
                                Status
                            </th>
                            <th className={Styles.textAlignCenter}>
                                Failed Reason
                            </th>
                            <th>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        loading ? (
                            <tr>
                                <td colSpan={7}>
                                    <div className={Styles.loaderWrapper}>
                                        <CircularProgress />
                                        <h5 className={Styles.marginTop}>Getting file list....</h5>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            files[0] ? (
                                files.map((file)=>(
                                    <tr>
                                        <td style={{ width: "30%" }}>
                                            <ul>
                                                <li className={Styles.fileTitle}>{file.file_name_original}</li>
                                                <li className={Styles.validationType}><span className={Styles.validationTypeLabel}>Search type:</span> {renderSearchType(file)}</li>
                                                <li className={Styles.fileId}>ID: {file.file_name ? file.file_name.split(".csv")[0] : ""}</li>
                                            </ul>
                                        </td>
                                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                                            {file.created_at}
                                        </td>
                                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                                            {file.total_record}
                                        </td>
                                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                                            ${file.total_cost}
                                        </td>
                                        <td className={Styles.textAlignCenter}>
                                            <span className={`${Styles.statusChip} ${getChipColor(file)}`}>{file.status}</span>
                                        </td>
                                        <td className={Styles.textAlignCenter}>
                                            {
                                                file.failed_reason &&
                                                <BootstrapTooltip arrow title={<span>{file.failed_reason}</span>}>
                                                    <div className={`${Styles.truncate} ${Styles.failedReason}`}>
                                                        {file.failed_reason}
                                                    </div>
                                                </BootstrapTooltip>
                                            }
                                        </td>
                                        <td>
                                            <div className={Styles.fileActions}>
                                                <span className={`${Styles.viewDetails} ${Styles.cursorPointer}`} onClick={()=>{
                                                    dispatch({
                                                        type: ActionTypes.UPDATE_STATE,
                                                        payload: {
                                                            activeTab: TAB_CLEAN_LIST.REPORTS_DETAILS,
                                                            cleanDataRequestId: file.id,
                                                            cleanDataRequest: file
                                                        }
                                                    });
                                                }}>View Details Report</span>
                                                {
                                                    renderFileUrl(file) !== "" ? <a className={Styles.downloadButton} target="_blank" href={renderFileUrl(file)}>Download</a> : <div/>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className={Styles.loaderWrapper}>
                                            <h5 className={Styles.marginTop}>No file found!</h5>
                                        </div>
                                    </td>
                                </tr>
                            )
                        )
                    }
                    </tbody>
                </table>
                <Pagination page={page} totalPage={totalPage} callback={(uPage)=>{setPage(uPage)}}/>
            </div>
        </div>
    );
}

export default FileReport;