import React, { useEffect, useState ,useRef} from 'react';
import { connect } from 'react-redux';
//moved to cdn
// import '../contact/contact-list.css'
// import '../contact/contact-list-responsive.css'


import { IconList } from '../globals/IconList';
import { DropdownHelper } from '../globals/DropdownHelper';
import { GlobalTable } from '../globals/GlobalTable';
import Utils from '../../helpers/Utils';
import Helper from '../globals/helpers/Helper';
import '../contact/contactList/theme/default.css'; // main css file
import '../contact/contactList/styles.css'; // theme css file
import "../contact/contactList/custom-styles.css";
import { getDripBatchContactsByPagination } from '../../actions/dripBatchAction';
import { icons } from './icon';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    getDripBatchContactsByPagination: (params, callback) => dispatch(getDripBatchContactsByPagination(params, callback)),
})


const DripBatchContactList = (props) => {

    //Pagination
    const [contactlist, setContactList] = useState([])
    const [totalContacts, setTotalContacts] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')

    useEffect(() => {
        document.title = `Batches | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('report');
        }
    },[]);

    useEffect(() => {
        props.getDripBatchContactsByPagination({
            perPage : itemPerPage, query: search, currentPage: page, batchId: props.match.params.id
        }, (res) => {
            setContactList(res.data)
            setTotalContacts(res.total)
            setLastPage(res.last_page)
            setLoading(false)
        })
    }, [itemPerPage, page, search])

    const columns = [
        {
            name: 'First name',
            selector: row => row['first_name'],
            minWidth: '250px',
            /*
            please do not remove those code
            cell: row => <button row={row} onClick={(event) => {
                console.log(event)
            }} >hello</button>,
            allowOverflow: true,
            button: true,
            width: '56px',
            grow: 2, //do not know this props
            ignoreRowClick: true
            right: true,
            center: true
            */
        },
        {
            name: 'Last name',
            sortable: false,
            minWidth: '250px',
            selector: row => row['last_name']
        },
        {
            name: 'Address ',
            sortable: false,
            minWidth: '250px',
            selector: row => row['address']
        },
        {
            name: 'Email',
            sortable: false,
            minWidth: '250px',
            selector: row => row['email']
        },
        {
            name: 'Number',
            sortable: false,
            minWidth: '250px',
            selector: row => row['number']
        },
        {
            name: 'Add to batch',
            sortable: false,
            minWidth: '250px',
            selector: row => row['added_to_batch']
        },
        {
            name: 'Assigned to campaign',
            sortable: false,
            minWidth: '250px',
            selector: row => row['assigned_time']
        }
    ];

    // pagination
    const handlePaginationChange = (event, page) => {
		event.preventDefault()
		if(page != null){
			setPage(page)
		}
	}

	let previous_page = null, next_page = null; 
	if(page > 1){
		previous_page = page - 1
	}

	if(page != lastPage){
		next_page = page + 1
	}

    return (
        <React.Fragment>
            <div className="container-fulid">
                <div className="dropdown-main-contact-row row">
                    <div className="col l12">
                        <div className="contact-top-filter-main">
                            <div className="contact-top-filter-left">
                                <ul>
                                    <li className="add-contact">
                                        <a className="dropdown-trigger btn">
                                            <span className="contact-right-drop">Drip bridge-{props.match.params.title} </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="contact-top-filter-right">
                                <ul>
                                    <li className="filter-onwer input-field">
                                        <div className="select-wrapper">
                                            <DropdownHelper
                                                datalist={[
                                                    {value: 10, id: 10},
                                                    {value: 25, id: 25},
                                                    {value: 50, id: 50},
                                                    {value: 100, id: 100},
                                                ]}
                                                className="second__step__area__select"
                                                mapping={{ title: "value" }}
                                                menuItemClassName=""
                                                selectedValue={itemPerPage}
                                                dropdownHeader={'ownerChange'}
                                                parentDivClassName="ppc__select_owner"
                                                updateSelectedData={(value) => setItemPerPage(value)}
                                            />
                                        </div>
                                    </li>

                                    <li className="search-data-table">
                                        <div className="body-search">
                                            <form action="#">
                                                <input type="search" value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search" />
                                                <button>{icons.search}</button>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            
            
                <div className="datatable-area">
                    <div className="row datatable-scroll-row">
                        <div className="col s12">
                            <div className="Drip_table">
                                <GlobalTable
                                    table_unique_id={'drip_batch_contact_list'}
                                    columns={columns}
                                    data={contactlist}
                                    className="highlight"
                                    keyField={'id'}
                                    noDataComponent={(
                                        <span style={{ padding: 10, color: 'red' }}>No contact in list!</span>
                                    )}
                                    progressPending={loading}
                                    defaultSortField="first_name"
                                    selectableRows={false}
                                    pagination={false}
                                    subHeader={false}
                                    noHeader={true}
                                />

                            </div>
                            {totalContacts > 0 &&
                                        <div className="pagination_bar">
                                        <ul>
                                            <li>
                                                <a 
                                                    href="#" onClick={(event) => handlePaginationChange(event, previous_page)}
                                                    style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                                                >
                                                    {icons.previous}
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <span>{page}</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="d-flex align-items-center"> 
                                                    <span>
                                                        {icons.backwardSlash}
                                                    </span>	
                                                    {lastPage}
                                                </a>
                                            </li>
                                            <li>
                                                <a 
                                                    href="#" onClick={(event) => handlePaginationChange(event, next_page)}
                                                    style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                                                >
                                                    {icons.next}	
                                                </a>
                                            </li>
                                            <li style={{color: '#9daac1', marginLeft: 20}}><small className="paginations_text">Total entries : {totalContacts}</small></li>
                                        </ul>
                                    </div>
                                    }
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment >
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(DripBatchContactList);



// try{
//     payload.append('api-key', "W6GSG6PQ6K5ZCQ1UQT5E"/* getCookie(process.env.REACT_APP_ACCESS_TOKEN) */)
//     data = payload;
// } catch (error) {
//     data = {...payload, ...{'api-key': "W6GSG6PQ6K5ZCQ1UQT5E" /* getCookie(process.env.REACT_APP_ACCESS_TOKEN) */}};
// }