import React, { Component } from 'react';
import { connect } from "react-redux";
// import {updateContactInfo} from "../../../actions/contactAction";
import DoneIcon from '@material-ui/icons/Done';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CreateIcon from '@material-ui/icons/Create';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { updateContactInfoWithCallback, updateMultipleData } from '../../../../../actions/contactAction';
import BootstrapTooltip from '../../../../globals/BootstrapTooltip';
import Utils from '../../../../../helpers/Utils';

const initialStates = {
    validation: false,
    validationText: '',
    edit: false,
    saving: false,
    success: false,
    field_value: '',
    mouseOver: false,
    copy: false,
}
class SingleInputField extends Component {
    constructor(props) {
        super(props);
        this.state = initialStates;
        this.inputField = React.createRef();
    }

    componentDidMount() {
        this.setState({ field_value: this.props.value })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.loadingData !== this.props.loadingData) {
            if (this.props.loadingData) {
                this.setState(initialStates)
            } else {
                this.setState({ field_value: this.props.value })

            }
        }
    }

    handleCrossPress = () => {
        this.setState({ edit: false, field_value: this.props.value, validation: false, validationText: '' })
    }

    handleCopy = (value = '') => {
        try {
            navigator.clipboard.writeText(value);
            this.setState({ copy: true })
            setTimeout(() => {
                this.setState({ copy: false })
            }, 700);
        } catch (err) {
            console.log('Failed to copy!');
        }
    }

    handleOnChange = (event) => {
        if (this.props.name === 'number') {

            if (!Utils.checkLetterExistInText(event.target.value) || event.target.value === '') {
                let value = event.target.value
                if (value.length > 0 && value[0] !== '1') {
                    value = 1 + value
                }
                this.setState({ field_value: value })
            } else {
                try {
                    let value = event.target.value.replace(/[^0-9]+/g, '')
                    if (value.length > 0 && value[0] !== '1') {
                        value = 1 + value;
                    }
                    this.setState({ field_value: value })

                }
                catch (e) {
                    console.log(e)
                }

            }

        } else {
            this.setState({ field_value: event.target.value })
        }
    }

    handleEnterPress = (event) => {
        var code = (event.keyCode ? event.keyCode : event.which);
        if (code == 13) {
            this.handleSubmit()
        }
    }

    handleCheckPress = () => {
        this.handleSubmit()
    }

    handleSubmit = () => {

        if (this.state.field_value !== this.props.value) {
            if (this.props.checkValidation) {
                let validation = this.props.checkValidation(this.state.field_value)
                if (validation != '') {
                    this.setState({ validation: true, validationText: validation })
                    return
                }
                else {
                    this.setState({ validation: false, validationText: '' })
                }
            }
            let self = this
            this.setState({ edit: false, saving: true })
            this.props.updateContactInfoWithCallback({
                contactId: this.props.contact.id,
                key: this.props.name,
                value: this.state.field_value
            }, (res) => {
                let response = res.original
                if (response.success == true) {
                    self.setState({ saving: false, success: true, validation: false, validationText: '' }, () => {
                        setTimeout(() => {
                            self.setState({ success: false })
                        }, 2000);
                    })
                    if (this.props.name === 'number') {
                        let tmpContact = self.props.contact;
                        if (tmpContact.contact_additional_informations) {
                            if (tmpContact.contact_additional_informations.number_validation_status) {
                                tmpContact.contact_additional_informations.number_validation_status = "NOT_VERIFIED";
                                tmpContact.contact_additional_informations.number_validation_infos = null;
                            }
                            if (tmpContact.contact_additional_informations.dnc_status) {
                                tmpContact.contact_additional_informations.dnc_status = "NOT_VERIFIED";
                                tmpContact.contact_additional_informations.dnc_validation_infos = null;
                            }
                            self.props.updateMultipleData({ contact: tmpContact })
                        }

                    } else if (this.props.name === 'email') {
                        let tmpContact = self.props.contact;
                        if (tmpContact.contact_additional_informations) {
                            if (tmpContact.contact_additional_informations.email_validation_status) {
                                tmpContact.contact_additional_informations.email_validation_status = "NOT_VERIFIED";
                                tmpContact.contact_additional_informations.email_validation_infos = null;
                            }
                            self.props.updateMultipleData({ contact: tmpContact })
                        }

                    }


                }
                else {
                    self.setState({ saving: false, edit: true, validation: true, validationText: response.message })
                }
            })
        } else {
            this.setState({ edit: false, validation: false })

        }

    }

    render() {
        const { label, placeholder, name, isDisabled, showSpan } = this.props
        const { validation, validationText, edit, saving, success, field_value, mouseOver, copy } = this.state

        const isDisabledFlag = isDisabled != undefined ? true : (edit ? false : true)

        return (
            <React.Fragment>
                <div className="d-flex align-items-center justify-content-between"
                >
                    <h6>{label}</h6>
                </div>

                <div className="text-field-item mb-2" onMouseEnter={() => this.setState({ mouseOver: true })} onMouseLeave={() => this.setState({ mouseOver: false })}>
                    {(showSpan !== undefined && showSpan && field_value !== undefined && field_value !== "" && field_value !== null && !edit
                    ) ?
                        <div className="contact-text-field-label text-field text-gray">
                            <BootstrapTooltip arrow title={field_value}>
                                <span className="truncate label-text">{field_value}</span>
                            </BootstrapTooltip>
                        </div>
                        :
                        <BootstrapTooltip arrow title={`${(field_value !== undefined && field_value !== "" && field_value !== null) ? field_value : ""}`}>
                            <input ref={this.inputField} className="text-field text-gray" value={field_value}
                                onKeyPress={this.handleEnterPress} onChange={this.handleOnChange} name={name} type="text"
                                disabled={isDisabledFlag} placeholder={placeholder} />
                        </BootstrapTooltip>
                    }

                    {(copy && !edit && !saving && mouseOver) &&
                        <span className="small-round-icon mr-4" >{<DoneAllIcon fontSize="small" />}</span>
                    }
                    {(!copy && !edit && !saving && mouseOver) &&
                        <BootstrapTooltip arrow title={`Copy ${this.props.label}`}>
                            <span className="small-round-icon mr-4" onClick={() => { this.handleCopy(field_value) }}>
                                <FileCopyIcon fontSize="small" />
                            </span>
                        </BootstrapTooltip>
                    }

                    {success &&
                        <span className="small-round-icon" >{<DoneAllIcon fontSize="small" />}</span>
                    }

                    {!success &&
                        <>
                            {(!edit && !saving && mouseOver) &&
                                <BootstrapTooltip arrow title={`Update ${this.props.label}`}>

                                    <span className="small-round-icon" onClick={() => this.setState({ edit: true, mouseOver: false }, () => { this.inputField.current.focus() })}>{<CreateIcon fontSize="small" />}</span>
                                </BootstrapTooltip>

                            }

                            {(!edit && saving) &&
                                <BootstrapTooltip arrow title={`Update ${this.props.label}`}>

                                    <span className="small-round-icon" >{<MoreHorizIcon fontSize="small" />}</span>
                                </BootstrapTooltip>

                            }

                            {(edit && !saving) &&
                                <div>
                                    <BootstrapTooltip arrow title="confirm">

                                        <span className="small-round-icon checkIcon" onClick={this.handleCheckPress}>{<DoneIcon fontSize="small" />}</span>
                                    </BootstrapTooltip>
                                    {/* <BootstrapTooltip arrow title="cancel">

                        <span className="small-round-icon" onClick={this.handleCrossPress}>{<ClearIcon fontSize="small" />}</span>
                        </BootstrapTooltip> */}

                                </div>
                            }
                        </>
                    }
                    {validation &&
                        <small className="text-danger">{validationText}</small>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contact: state.contactReducer.contact,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateContactInfoWithCallback: (params, callback) => dispatch(updateContactInfoWithCallback(params, callback)),
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleInputField);