import { useEffect, useState, useRef } from "react";
import Popover from '@material-ui/core/Popover';
import CheckIcon from '@material-ui/icons/Check';
const downSingleArrow = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7636 6.56341C5.11508 6.21194 5.68492 6.21194 6.0364 6.56341L9 9.52701L11.9636 6.56341C12.3151 6.21194 12.8849 6.21194 13.2364 6.56341C13.5879 6.91488 13.5879 7.48473 13.2364 7.8362L9.6364 11.4362C9.28492 11.7877 8.71508 11.7877 8.3636 11.4362L4.7636 7.8362C4.41213 7.48473 4.41213 6.91488 4.7636 6.56341Z" fill="#111827"/>
                    </svg>

const CustomPopupMenu = ({
    list, handleClick, placeholder, value, selectedId, reorganized = false, valueField, labelField, 
    textLength, height
}) => {
    const [anchorActionMenu, setAnchorActionMenu] = useState(null);
    const [open, setOpen] = useState(false);
    const dropDownLabel = useRef(null);
    const [itemData, setItemData] = useState([])
    const [fullItemData, setFullItemData] = useState([])

    useEffect(() => {
        if(reorganized){
            let organized_data = [];
            organized_data.push({value: '__all__', label: 'All users'})
            list.forEach((item, index) => {
                organized_data.push({
                    value: item[valueField], 
                    label: item[labelField]
                })
            });
            setItemData(organized_data)
            setFullItemData(organized_data)
        }else{
            let item = [{value: '__all__', label: 'All users'}]
            let old_data = [...list]
            old_data = [...item, ...old_data];
            setItemData(old_data)
            setFullItemData(old_data)
        }
    }, [list])

    const handleMenuLabel = (e) => {
        setAnchorActionMenu(e.currentTarget);
        setOpen(true)
    }

    const handleClose = (e) => {
        setAnchorActionMenu(null);
        setOpen(false)
    }

    const renderLabel = () => {
        return (
            <div style={{textTransform: 'capitalize', height: height}} className={`label_class_wrapper`} onClick={(e) => handleMenuLabel(e)} ref={dropDownLabel}>
                {/* {value.length === 0 && placeholder }
                {(value.length !== 0 && value.length === 1) && value[0].label}
                {(value.length !== 0 && value.length > 1) && (value.length + 'users selected' )} */}
                <div className="list___item__iv2">
                    {getSelectedList()}
                </div>
                <span>{downSingleArrow}</span>
            </div>
        )
    }

    const getSelectedList = () => {
        if(value.length === 1){
            return getSmallText(value[0].label)
        }

        if(value.length > 1 && value.length < 3){
            let view = [];
            value.forEach((item, index) => {
                view.push(
                    <span key={index} className="avater__name__sub__user__list">
                        {getContactAvaterText(item.label, "")}
                    </span>
                );
            });
            return view;
        }

        if(value.length > 2){
            let view = [];
            let item = 1;
            for(item; item < 3; item++){
                view.push(
                    <span key={item} className="avater__name__sub__user__list">
                        {getContactAvaterText(value[item].label, "")}
                    </span>
                );
            }
            view.push(
                <span key={item} className="avater__name__sub__user__list">+{value.length-2} more</span>
            );
            return view;
        }
        return placeholder;
    }

    const getSmallText = (text) => {
        if(text.length > textLength){
            return (text.substring(0, (textLength-3))+'...');
        }
        return text;
    }

    const renderItem = () => {
        let view = [];
        itemData.forEach((item, index) => {
            let remove = itemExist(selectedId, item.value)
            view.push(
                <div className="pgwptc__group__each__item" key={index} onClick={() => {handleClick(item, remove); /* setOpen(false) */}}>
                    <span className="pgwptc__gei_title">{item.label}</span>
                    {remove && <CheckIcon fontSize="small" />}
                </div>
            )
        });
        return view;
    }

    const itemExist = (arrayList, item) => {
        if(arrayList.length > 0 && arrayList[0] === '__all__'){
            return true;
        }
        if(arrayList.indexOf(item) !== -1){
            return true
        }
        return false;
    }

    const searchUser = (value) => {
        if(value === ''){
            setItemData(fullItemData)
            return
        }
        let filter = itemData.filter(item => {
            if(item.value === '__all__'){
                return false;
            }
            if(item.label.toLowerCase().includes(value.toLowerCase())){
                return true;
            }
            return false;
        })
        setItemData(filter)
    }

    const getContactAvaterText = (firstName, lastName, email = "", number = "") => {
        if (firstName !== "" && lastName !== "") {
            return firstName.substring(0, 1).toUpperCase() + "" + lastName.substring(0, 1).toUpperCase();
        }
        else if (firstName !== "") {
            return firstName.substring(0, 2).toUpperCase();
        } else if (lastName !== "") {
            return lastName.substring(0, 2).toUpperCase()
        } else if(email !== ""){
            return email.substring(0, 2).toUpperCase()
        }
        else if(number !== ""){
            return number.substring(0, 2).toUpperCase()
        }
        return "N/A"
    }

    return (
        <div className={`custom_menu_option_container`} onClick={(e) => e.stopPropagation()}>
            {renderLabel()}

            <Popover
                id={"pype_g_u_l_c_dd_w_id"}
                open={open}
                anchorEl={anchorActionMenu}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        boxShadow: "0px 2px 3px rgba(0,0,0,0.5)",
                        width: "300px",
                        maxHeight: "400px"
                    }
                }}
            >
                <div className="p_g_u_l_c_d_main_w awesome__scroll_bar">
                    <input 
                        className="search__input"
                        placeholder="Search user"
                        onChange={(event) => searchUser(event.target.value)}
                    />
                    {list.length === 0 &&
                        <div className="empty__containner">
                            <span>No data found !</span>
                        </div>
                    }
                    {list.length > 0 &&
                    <div className="pype_g_u_l_c_dd">
                        {renderItem()}
                        {/* <div 
                            className="pgwptc__group__each__item pgwptc__group__each__item__clear_all" 
                            onClick={() => {
                                handleClick({value: '__clear_all__', label: 'Clear All'}, true); 
                                // setOpen(false)
                            }}
                        >
                            <span className="pgwptc__gei_title"><b>{'Clear All'}</b></span>
                        </div> */}
                    </div>
                    }
                </div>
            </Popover>
        </div>
    )
}
export default CustomPopupMenu;