import React, { useEffect, useRef, useState, useContext } from 'react';
import { Button, FormControl, LinearProgress } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { addDays } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import { DateRangePicker } from 'react-date-range';
import { connect } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import '../../../../src/react-confirm-alert.css';
import { contactListFirstLoading, fetchCampaignList, getAllContacts, getPipelineList, getSubUserList, getUserSourceList, getUserTagList, setSubUserListFromAction, setUserSourceList, setUserTagList, storeContactListGroup, storeContactListMultipleSelectedData, storeContactListMultipleSelectedDataLabel, storeContactListSearchTextInTop, storeContactListSelectedCampaign, storeContactListSelectedColumnFilterData, storeContactListSelectedContactIds, storeContactListSelectedDatePickerActive, storeContactListSelectedDateRangeInfo, storeContactListSelectedEndDate, storeContactListSelectedFile, storeContactListSelectedFromDate, storeContactListSelectedOmitList, storeContactListSelectedOperationList, storeContactListSelectedPage, storeContactListSelectedPageSize, storeContactListSelectedSavedContactList, storeContactListSelectedSource, storeContactListSelectedStage, storeContactListSelectedSubUser, storeContactListSelectedTag, storeContactListStageListForPipeline, updateMultipleData } from '../../../actions/contactAction';
import {
    exportCSVFromContactlist,
    getContactListForDropdown,
    getFileListForDropdown,
    getStageListWithPagination,
    getSubUserListForContactlist,
    removeBulkContact,
    getCampaignListForDropdownWithPaginate,
    getUserTagListNew,
    getCarrierLookupCreditSettingsApi,
    carrierLookupVerifyContactsApi
} from '../../../api/contactApi';
import Utils from '../../../helpers/Utils';
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from '../../globals/Dropdown/GlobalDropdown';
import { DropdownHelper } from '../../globals/DropdownHelper';
import { GlobalTable } from '../../globals/GlobalTable';
import { IconList } from '../../globals/IconList';
import PopperHelper from '../../globals/Popperhelper';
import PopperHelperNormal from '../../globals/PopperhelperWithoutCheckbox';
// import { formatDateWithoutZone } from '../../globals/TimezoneConversion';
//moved to cdn
// import '../contact-list.css'
// import '../contact-list-responsive.css';
import ModalAssignToCampaign from './ModalAssignToCampaign';
import ModalAssignToList from './ModalAssignToList';
import ModalAssignToTag from './ModalAssignToTag';
import ModalCreateList from './ModalCreateList';
import ModalListForStageAdd from './ModalListForStageAdd';
import { HistoryProvider } from "../../../App";
import GlobalSearchBox from '../../globals/GlobalSearchBox';
import InsertFileName from './InsertFileName';
import ModalAddToAutoDialer from "./ModalAddToAutoDialer";
import WithoutQueryCampaignColumns from './WithoutQueryCampaignColumns';
import WithQueryCampaignColumns from './WithQueryCampaignColumns';
import { withTransaction } from '@elastic/apm-rum-react'
import DropdownWithScroll from '../../globals/DropdownWithScroll';
import ModalAssignToTagNew from './ModalAssignToTagNew';
import GlobalUserListDropdown from '../../common/UserListDropdown/Index';
import "./customStyles.css";
import CustomCircleLoader from "../../common/Loader/CustomCircleLoader";
import LeadOwnerModal from "./leadOwner/LeadOwnerModal";
import AddCollaboratorModal from "./addCollaborator/AddCollaboratorModal";
import NewConfirmAlert from "../../common/new-alert/NewConfirmAlert";
import {
    CONTACT_VALIDATION_TYPE,
    LOOKUP_PROVIDER_TYPE_EMAIL,
    LOOKUP_PROVIDER_TYPE_NUMBER, LOOKUP_TYPE_CARRIER_LOOKUP, LOOKUP_TYPE_DNC_LOOKUP, LOOKUP_TYPE_EMAIL_LOOKUP
} from "../../../constants/CoreConstants";

const queryType1 = [
    'contains', 'does not contain', 'is_empty', 'not empty']

const queryType2 = [
    'is_greater_or_equal_to', 'is_less_than', 'is_not_empty']

const queryType3 = [
    'was_less_than', 'was_more_than']

const queryType4 = [
    'minutes', 'hours', 'days', 'weeks', 'months', 'years']

const queryType5 = ['equal_to','not_equal_to']

const queryType6 = ['age_more_than','age_less_than','age_equal_to']

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const columnList = [
    'name_email', 'name', 'option', 'owner', 'pipelines', 'stages', 'campaigns', 'active_campaign','zip','age', 'address', 'city', 'state', 'country', 'source',
    'tags', 'created_at', 'updated_at', 'price', 'last_activity', 'upcoming_task', 'last_communication',
    'last_email_sent', 'last_email_received', 'email_sent', 'email_received', 'last_call_made', 'last_call_received',
    'calls_made', 'calls_received', 'last_text_sent', 'last_text_received', 'texts_sent', 'texts_received'
    // , 'form'
]

const userTimezoneRaw = Utils.getAccountData('userTimezoneRaw');

const defaultColumnFilterData = [
    {
        "filter":
            {},
        "sortField": "id",
        "sortOrder": "desc"

    }
];

var emptyMessage = 'No Contact Found';

const CONSTANTS = {
    CHANGE_LEAD_OWNER: '__change_lead_owner',
    ADD_COLLABORATOR: '__add_collaborator'
}


const Contactlist = (props) => {

    let exportFileName = ''
    let windowHeight= window.innerHeight
    const history = useContext(HistoryProvider);
    const search = useLocation().search;
    const queryCampaignId = new URLSearchParams(search).get('campaignId');
    const queryContactedCampaign = new URLSearchParams(search).get('contacted');
    const queryRespondedCampaign = new URLSearchParams(search).get('responded');

    const [componentLoading, setComponentLoading] = useState(true);

    //Filter-Show
    const [filterShow, setFilterShow] = useState(false)
    //Pagination
    const [totalContacts, setTotalContacts] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [lastPage, setLastPage] = useState(0)
    //campaign search

    const [campaignList, setCampaignList] = useState([])
    //stage search

    // const [stageList, setStageList] = useState([])

    //source seaerch

    const [sourceList, setSourceList] = useState([])

    //tag search
    //file search

    const [fileList, setFileList] = useState([])

    //Owner Search

    const [subUserList, setSubUserList] = useState([])

    //List Search
    const [savedContactList, setSavedContactList] = useState([])
    const [openCreateListModal, setOpenCreateListModal] = useState(false)
    const [selectedContactList, setSelectedContactList] = useState('')
    const [selectedContactListTitle, setSelectedContactListTitle] = useState('')

    // contact list

    const [contactlist, setContactList] = useState([])
    const [updateTable, setUpdateTable] = useState(false)

    // const [props.contactListSearchTextInTop, props.storeContactListSearchTextInTop] = useState('')
    const [searchTimer, setSearchTimer] = useState('')
    //Column Filter
    // const [props.contactListSelectedOperationList, props.storeContactListSelectedOperationList] = useState([
    //     {
    //         "filter":
    //             {},
    //         "sortField": "created_at",
    //         "sortOrder": "desc"

    //     }
    // ])


    // {"first_name":{"filterVal":{"text":"as","comparator":"contains","relational":false},
    // "filterType":"TEXT","comparator":"LIKE"}},

    //Daterange
    const [showDateRange, setShowDateRange] = useState(false)

    //Tag Assign
    const [showTagModal, setShowTagModal] = useState(false)
    const [showListModal, setShowListModal] = useState(false)
    const [showAddToCampignModal, setShowAddToCampaignModal] = useState(false)
    const [showAddToAutoDialerModal, setShowAddToAutoDialerModal] = useState(false)

    const [searchByDateRange, setSearchByDateRange] = useState(false)

    // Pop Hover Start 
    const classes = useStyles();

    //update column fiilter
    const [updateColumnArray, setUpdateColumnArray] = useState(false)

    //Pipeline list
    const [pipelineList, setPipelineList] = useState([])

    //Stage Add
    const [openAddStageToContactModal, setOpenAddStageToContactModal] = useState(false)
    const [stageListForAction, setStageListForAction] = useState([])

    const [loadDropdownItems, setLoadDropdownItems] = useState(false)

    //StageChange
    const [stageChangeInfo, setStageChangeInfo] = useState(null)

    const [openfileNameModal, setOpenfileNameModal] = useState(false)

    const [contactListSelectedOmitData, setContactListSelectedOmitData] = useState(['name', 'address', 'city', 'state', 'country', 'active_campaign'])

    const [pageNoSearch, setPageNoSearch] = useState(null)
    const bottomScrollRef = useRef();
    const upperScrollRef = useRef();
    const filterHeaderRef = useRef();
    const extraFilterOptionRef = useRef();
    // pop hover End

    /* for multiple sub user list select */
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedIds, setSelectedIds] = useState([])

    const [scrollViewHeight, setScrollViewHeight] = useState(windowHeight-210+'px');
    const [startingSession, setStartingSession] = useState(false);

    const [openLeadOwnerModal, setOpenLeadOwnerModal] = useState(false);
    const [openAddCollaboratorModal, setOpenAddCollaboratorModal] = useState(false);
    const creditSettingType = {CALLER_NAME : 63, CARRIER : 64, VERIFY_EMAIL:65, DNC: 66};
    const defaultCategoryName = {VERIFY_EMAIL : "verify-email", CALLER_NAME : "caller-name",CARRIER: "carrier", DNC: "dnc" }

    useEffect(()=>{
        if(Utils.getAccountData("carrierLookup") && !props.carrierLookupPrices){
            getCarrierLookupCreditSettingsApi()
                .then((response) => {
                    if (response.data && response.data.status === 'success') {
                        if (response.data && response.data.data) {
                            props.updateMultipleData({ carrierLookupPrices: response.data.data});
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },[]);

    useEffect(() => {

        document.title = `Contacts | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('contact');
        }
        if (props.allContactList !== undefined && props.allContactList != null) {
            setContactList(props.allContactList.data)
            setTotalContacts(props.allContactList.total)
            localStorage.setItem('contactListInfo',JSON.stringify({ from: 'contactListPage',totalContacts: props.allContactList?.data?.length,
            contactList: props.allContactList.data}));
            setLastPage(props.allContactList.last_page)
        }

        fetchAllContacts('', '', '', true);

    }, [])

    useEffect(() => {
        
        let locallyStoredOmitList = localStorage.getItem('pypepro-contactlist-omitinfo');

        let tmpOmitList = contactListSelectedOmitData
        if (locallyStoredOmitList && locallyStoredOmitList !== JSON.stringify(contactListSelectedOmitData)) {
            tmpOmitList = JSON.parse(locallyStoredOmitList)
            setContactListSelectedOmitData(tmpOmitList)
        }

        let length = queryCampaignId ? columnList.length+4 : columnList.length
        let width = (length - tmpOmitList.length) *250+128;
            width = tmpOmitList.includes('option') ? width+60 : width
            console.log(width)
            document.querySelector('#contact_table_search').style.width= width+'px';
        
    }, [])

    const afterLoad = () => {
        // getUserCustomFields()
        getAllTagsForDropdown(true)
        getAllContactList()
        getPipelineListForColumnFilter()
    }

    const preLoad=() => {
        getSubUsersList()
        getSourceListForDropdown()
    }
    useEffect(() => {
        try{
            upperScrollRef.current.children[0].style.width = document.querySelector('.ContactModule-container-parent-class .dataTable__wrapper.updateDataTable__wrapper .rdt_Table')?.offsetWidth + "px";
            setShowDateRange(false);
        }
        catch(e){
            console.log('scroll problem')
        }
    }, [contactListSelectedOmitData.length])

    useEffect(() => {
        try{
            upperScrollRef.current.children[0].style.width = document.querySelector('.ContactModule-container-parent-class .dataTable__wrapper.updateDataTable__wrapper .rdt_Table')?.offsetWidth + "px";

        }
        catch(e){
            console.log('scroll problem')
        }
    }, [])

    useEffect(() => {
        try{
            if (!componentLoading) {
                // upperScrollRef.current.children[0].style.width = bottomScrollRef.current.children[0].scrollWidth + "px";
                upperScrollRef.current.children[0].style.width = document.querySelector('.ContactModule-container-parent-class .dataTable__wrapper.updateDataTable__wrapper .rdt_Table')?.offsetWidth + "px";
                let width = document.querySelector('.rdt_Table') ? document.querySelector('.rdt_Table').offsetWidth : '1000';
                document.querySelector('#contact_table_search').style.width= width+'px';
            }
        }

        catch(e){
            console.log('scroll problem')
        }
    }, [componentLoading])

    const handleRoute = (routeLink) => {
        history.push(routeLink);
    }

    const onBottomScroll = (e) => {
        upperScrollRef.current.scrollLeft = e.target.scrollLeft;

    }

    const onUpperScroll = (e) => {
        bottomScrollRef.current.scrollLeft = e.target.scrollLeft;
    }

    const exportCSV = () => {

        setOpenfileNameModal(true);
    }

    // useEffect(() => {

    //     setUpdateColumnArray(!updateColumnArray)
    // }, [subUserList])

    const clearDropdown = (header) => {

        let reload = false

        switch (header) {
            case "fileChange":
                if (props.contactListSelectedFile !== '') {
                    props.storeContactListSelectedFile('');
                    reload = true
                }
                break;
            case "campaignChange":

                if (props.contactListSelectedCampaign !== '') {
                    props.storeContactListSelectedCampaign('');
                    reload = true
                }
                break;

            case "tagChange":

                if (props.contactListSelectedTag !== '') {
                    props.storeContactListSelectedTag('');
                    reload = true
                }
                break;

            case "stageChange":

                if (props.contactListSelectedStage !== '') {
                    props.storeContactListSelectedStage('');
                    reload = true
                }
                break;

            case "sourceChange":

                if (props.contactListSelectedSource !== '') {
                    props.storeContactListSelectedSource('');
                    reload = true
                }
                break;

            case "ownerChange":

                if (props.contactListSelectedSubUser.length !== 0) {
                    props.storeContactListSelectedSubUser([-1]);
                    reload = true
                }
                break;
            case "dateRange":

                if (props.contactListSelectedFromDate !== '' || props.contactListSelectedEndDate !== '') {
                    props.storeContactListSelectedFromDate('')
                    props.storeContactListSelectedEndDate('')
                    props.storeContactListSelectedDateRangeInfo([
                        {
                            startDate: new Date(),
                            endDate: addDays(new Date(), 7),
                            key: 'selection',
                        }])
                    reload = true
                }
                props.storeContactListSelectedDatePickerActive(false)
                setShowDateRange(false);
                // fetchAllContacts()
                break;
            default:
                props.storeContactListSelectedFile('');
                props.storeContactListSelectedCampaign('');
                props.storeContactListSelectedTag('');
                props.storeContactListSelectedStage('');
                props.storeContactListSelectedSource('');
                props.storeContactListSelectedSubUser([-1]);
        }
        if (reload) {
            fetchAllContacts(header, '')
        }
    }

    const handleChangeSearchText = (event) => {
        props.storeContactListSearchTextInTop(event.target.value)
        clearTimeout(searchTimer)
        let searchTokenTimer = setTimeout(() => {

            fetchAllContacts('searchToken', event.target.value)
        }, 1000);

        setSearchTimer(searchTokenTimer)
        setShowDateRange(false);

    }

    const updateOmitList = (omitListFromPopper, omitId, reason) => {
        // props.storeContactListSelectedOmitList(omitListFromPopper)
        let width = document.querySelector('.rdt_Table') ? document.querySelector('.rdt_Table').offsetWidth : '5179';
        let updateWidth = (omitId === 'option') ? 310 : 250
        if(reason === 'pop'){
            // console.log(document.querySelector('[data-column-id='+parseInt(omitId)+1+']').offsetWidth)
            document.querySelector('#contact_table_search').style.width= width-updateWidth+'px';
        }else{
            document.querySelector('#contact_table_search').style.width= width+updateWidth+'px';
        }

        setContactListSelectedOmitData(omitListFromPopper)
        setUpdateTable(!updateTable)
        setShowDateRange(false);
        localStorage.setItem('pypepro-contactlist-omitinfo', JSON.stringify(omitListFromPopper))
    }

    // dropdown data
    const updateDatatable = (dropdownHeader, value) => {
        fetchAllContacts(dropdownHeader, value)
        setShowDateRange(false);

    }

    const submitColumnFilterData = (selectedData, filterName, multipleSelectData, multiSelectedDatalabel) => {


        // [{"filter":
        // {"first_name":
        // {"filterVal":
        // {"text":"asd","comparator":"contains","relational":false},
        // "filterType":"TEXT","comparator":"LIKE"}
        // },"sortField":"id","sortOrder":"desc"}]

        let tempOperationList = [...props.contactListSelectedOperationList];

        // tempOperationList = ([
        //     {"filter":{"first_name":{"filterVal":{"text":"as","comparator":"contains","relational":false},
        //     "filterType":"TEXT","comparator":"LIKE"}},

        //             "sortField":"id",
        //             "sortOrder":"desc"

        //     }
        // ])
        let tempColumnFilterData = props.contactListSelectedColumnFilterData;

        console.log(selectedData);
        console.log(filterName);

        if (filterName === 'first_name' || filterName === 'address' || filterName === 'city' || filterName === 'state'
            || filterName === 'country') {

            if (selectedData[1] === '' && filterName !== "first_name") {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': { 'text': selectedData[1], 'comparator': selectedData[0], 'relational': false },
                    'filterType': "TEXT", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }

        }

        if (filterName === 'deal_value') {

            if (selectedData[1] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]

                }
            } else {
                let tempQueryField = {
                    'filterVal': { 'text': selectedData[1], 'comparator': selectedData[0], 'relational': false },
                    'filterType': "NUMBER", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData

                tempOperationList[0].filter[filterName] = tempQueryField;
            }


        }
        if (filterName === 'emails_sent' || filterName === 'emails_received' || filterName === 'calls_made' || filterName === 'texts_sent'
            || filterName === 'texts_received') {

            if (selectedData[1] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]

                }
            } else {
                let tempQueryField = {
                    'filterVal': { 'text': selectedData[1], 'comparator': selectedData[0], 'relational': true },
                    'filterType': "NUMBER", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData

                tempOperationList[0].filter[filterName] = tempQueryField;
            }


        }

        if (filterName === 'created_at' || filterName === 'updated_at') {

            if (selectedData[1] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': { 'text': selectedData[1], 'comparator': selectedData[0], "timeComparator": selectedData[2], 'relational': false },
                    'filterType': "DATE", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }


        }

        if (filterName === 'last_activity' ||
            filterName === 'last_communication' || filterName === 'last_email_sent' || filterName === 'last_email_received' ||
            filterName === 'last_call_made' || filterName === 'last_call_received' || filterName === 'last_text_sent' ||
            filterName === 'last_text_received') {


            if (selectedData[1] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': { 'text': selectedData[1], 'comparator': selectedData[0], "timeComparator": selectedData[2], 'relational': true },
                    'filterType': "DATE", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }


        }

        if (filterName === "user") {

            if (selectedData[0] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': {
                        'text': selectedData[0], 'user_id': selectedData[0], "comparator": "owner_filter",
                        'relational': true, "stageComparator": true
                    },
                    'filterType': "TEXT", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }


        }

        if (filterName === "source") {

            if (selectedData[0] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': {
                        'text': selectedData[0], "comparator": "source_filter",
                        'relational': false, "sourceComparator": true
                    },
                    'filterType': "TEXT", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }


        }

        if (filterName === "tags") {

            if (multipleSelectData[0].length === 0 && multipleSelectData[1].length === 0) {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': {
                        'text': 'multiple', 'include': multipleSelectData[0], 'exclude': multipleSelectData[1],
                        "comparator": "multiple",
                        'relational': true
                    },
                    'filterType': "TEXT", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;

                props.storeContactListMultipleSelectedData(multipleSelectData)
                props.storeContactListMultipleSelectedDataLabel(multiSelectedDatalabel)
            }


        }

        if (filterName === "stage") {

            if (selectedData[1] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': {
                        'text': selectedData[1], "pipelineId": selectedData[0], "stageId": selectedData[1],
                        "comparator": "stage_filter",
                        'relational': true, "stageComparator": true
                    },
                    'filterType': "NUMBER", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }


        }

        if(filterName === 'zip'){

            if (selectedData[1] === '') {
                if (tempOperationList[0].filter[filterName] !== undefined && tempOperationList[0].filter[filterName] != null) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': { 'text': selectedData[1], 'comparator': selectedData[0], 'relational': false },
                    'filterType': "TEXT", 'comparator': 'EQUAL'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }
        }

        if(filterName === 'birth_date'){
            if (selectedData[1] === '') {
                
                if (tempOperationList[0].filter[filterName]) {
                    delete tempOperationList[0].filter[filterName]
                    delete tempColumnFilterData[filterName]
                }
            } else {
                let tempQueryField = {
                    'filterVal': { 'text': selectedData[1], 'comparator': selectedData[0], "timeComparator": 'years', 'relational': false },
                    'filterType': "DATE", 'comparator': 'LIKE'
                }
                tempColumnFilterData[filterName] = selectedData
                tempOperationList[0].filter[filterName] = tempQueryField;
            }

        }
        props.storeContactListSelectedOperationList(tempOperationList);


        props.storeContactListSelectedColumnFilterData(tempColumnFilterData)

        fetchAllContacts('operationChange', tempOperationList)

    }

    const contactAddToCampaign = () => {

        if (props.contactListSelectedContactIds.length > 0) {
            setShowAddToCampaignModal(true)
        } else {
            window.showNotification('ERROR','No contact selected')
        }
    }

    const updateStageListForColumnFilter = async (pipelineId,) => {

        let tempColumnFilterData = props.contactListSelectedColumnFilterData;

        tempColumnFilterData.stage = [pipelineId, '']
        props.storeContactListSelectedColumnFilterData(tempColumnFilterData)
        await getStageListForDropdown(pipelineId, '')

    }

    const updatePipelineAction = (pipelineId) => {
        setStageListForAction([])
        getStageListForDropdown(pipelineId, "action")

    }

    const openStageChangeModal = (stageInfo, stageIndex, row, index) => {

        setStageChangeInfo({ stageInfo: stageInfo, stageIndex: stageIndex, index: index, row: row })
        setOpenAddStageToContactModal(true)

    }

    const addColumnInOmitList = (columnName) => {

        let tempOmitlist = contactListSelectedOmitData
        tempOmitlist.push(columnName)
        console.log(columnName)
        let width = document.querySelector('.rdt_Table') ? document.querySelector('.rdt_Table').offsetWidth : '5179';
        let updateWidth = (columnName === 'option') ? 310 : 250
            // console.log(document.querySelector('[data-column-id='+parseInt(omitId)+1+']').offsetWidth)
        document.querySelector('#contact_table_search').style.width= width-updateWidth+'px';

        // props.storeContactListSelectedOmitList(tempOmitlist)
        setContactListSelectedOmitData(tempOmitlist)
        setUpdateTable(!updateTable)
        localStorage.setItem('pypepro-contactlist-omitinfo', JSON.stringify(tempOmitlist))

    }


    const datatableRerender = (row, index) => {

        let datatableLists = [...contactlist]

        datatableLists[index] = row

        setContactList(datatableLists)
        localStorage.setItem('contactListInfo',JSON.stringify({ from: 'contactListPage',totalContacts: datatableLists.length,
            contactList: datatableLists}));
        setUpdateTable(!updateTable)
    }


    const goToContactDetails=(contactId, index)=>{

        history.push({ pathname: `/contacts/${contactId}`, state: { from: 'contactListPage',contactList: contactlist, totalContacts: totalContacts ,currentIndex : index,
        queryParam: {}} })
    }

    const openDialer=(contactDetails, index)=>{

        let tmpParams = { from: 'contactListPage',contactList: contactlist, totalContacts: totalContacts ,currentIndex : index}
        window.initiateGlobalDialer(contactDetails, tmpParams);
    }

    const updateSorting=(field)=>{

        let operationData = props.contactListSelectedOperationList;

        if(operationData[0].sortField === field){
            if(operationData[0].sortOrder === 'desc'){
                operationData[0].sortOrder = 'asc'
            }else{
                operationData[0].sortOrder = 'desc'
    
            }
        }else{
            operationData[0].sortField = field;
            operationData[0].sortOrder = 'desc'

        }

        props.updateMultipleData({contactListSelectedOperationList : operationData});
        updateDatatable('operationChange', operationData);
    }

    const getAllTagsForDropdown = async (firstLoad = false) => {

        
        if(firstLoad){
            getUserTagListNew(props.tagListInfo)
            .then((res)=>{
                props.updateMultipleData({tagListInfo : {...props.tagListInfo,...res.data}})
            })

        }else{
            getUserTagListNew({...props.tagListInfo,...{current_page: props.tagListInfo.current_page+1}})
            .then(res=>{
                let response = res.data;
                let tmpData = [...props.tagListInfo.data,...response.data];
                response.data = tmpData;

                if(response.current_page >= response.last_page){
                    response.fetch = false;
                }
                props.updateMultipleData({tagListInfo : {...props.tagListInfo,...response}})
            })
        }
        
    }


    let columns;

    if (queryCampaignId) {
        columns = WithQueryCampaignColumns(
            addColumnInOmitList, queryType1, submitColumnFilterData, props.contactListSelectedColumnFilterData, updateTable,
            contactListSelectedOmitData, setShowDateRange, subUserList, pipelineList, handleRoute, openStageChangeModal,
            props.contactListStageListForPipeline, updateStageListForColumnFilter, sourceList, props.tagListInfo, props.contactListColumnFilterMultipleSelectedData,
            props.contactListColumnFilterMultiSelectedDatalabel, queryType2, queryType3, queryType4, queryCampaignId,datatableRerender,
            openDialer,updateSorting,queryType5,queryType6,getAllTagsForDropdown,props.userListInObject,props.contactSourceInObject
        )
    } else {

        columns = WithoutQueryCampaignColumns(
            addColumnInOmitList, queryType1, submitColumnFilterData, props.contactListSelectedColumnFilterData, updateTable,
            contactListSelectedOmitData, setShowDateRange, subUserList, pipelineList, handleRoute, openStageChangeModal,
            props.contactListStageListForPipeline, updateStageListForColumnFilter, sourceList, props.tagListInfo, props.contactListColumnFilterMultipleSelectedData,
            props.contactListColumnFilterMultiSelectedDatalabel, queryType2, queryType3, queryType4,datatableRerender,
            openDialer,updateSorting,queryType5,queryType6,getAllTagsForDropdown,props.userListInObject,props.contactSourceInObject
        )
    }

    const onChangePage = (pageNo, totalrows) => {
        if (props.contactListPage <= lastPage) {
            props.storeContactListSelectedPage(props.contactListPage + 1)
            fetchAllContacts('pageNo', props.contactListPage + 1)
        }
    }

    const handlePaginationChange = (event, pageNo) => {

        event.preventDefault()
        if (pageNo != null && pageNo !=='') {
            props.storeContactListSelectedPage(pageNo)
            fetchAllContacts('pageNo', pageNo)
        }else if(pageNo === '' && event.target.value.trim() !== '' && !Utils.checkLetterExistInText(event.target.value)){
            let value = event.target.value;
            if(value <=lastPage && value >= 1){
                value = Math.floor(value);
                props.storeContactListSelectedPage(value)
                clearTimeout(pageNoSearch)
                let tablePageInput = setTimeout(()=>{
                    fetchAllContacts('pageNo', value)
                },1500);

                setPageNoSearch(tablePageInput)

            }
        }else if(pageNo === '' && (event.target.value.trim() === '' || Utils.checkLetterExistInText(event.target.value))){
            props.storeContactListSelectedPage( event.target.value)

        }


    }

    const handlePageSizeChange = (event) => {

        let newPageSize = event.target.value
        if (newPageSize !== props.contactListpageSize) {
            props.storeContactListSelectedPageSize(newPageSize)
            fetchAllContacts('pageSize', newPageSize)

        }
    }

    const onChangeRowsPerPage = (page_no) => {
        setItemPerPage(page_no)
        fetchAllContacts()
    }

    const actionOnList = (listId, listData) => {


        if (listId === '') {
            clearList()
        }
        else if (selectedContactList !== listId) {

            setSelectedContactList(listId)
            setSelectedContactListTitle(listData?.title);
            // fetchAllContacts('listChange', listId, '')

            if (listData.contact_ids !== undefined && listData.contact_ids != null && Array.isArray(listData.contact_ids) && listData.contact_ids.length > 0) {

                props.storeContactListSelectedSavedContactList({ 'status': true, "savelistId": listId })
                fetchAllContacts('listChange', { 'status': true, "savelistId": listId })
            }
            else if (listData.filter_params !== undefined && listData.filter_params != null && listData.filter_params.operation !== undefined &&
                listData.filter_params.operation != null && listData.filter_params.operation !== "") {

                if ((listData.filter_params.operation !== JSON.stringify(defaultColumnFilterData) && listData.filter_params.operation !== JSON.stringify('{}'))) {
                    props.storeContactListSelectedSavedContactList({ 'status': false })
                    let parsedValue = JSON.parse(listData.filter_params.operation);
                    props.storeContactListSelectedOperationList(parsedValue)

                    if (listData.filter_params.columnFilterData !== undefined &&
                        listData.filter_params.columnFilterData != null && listData.filter_params.columnFilterData !== "") {
                        // console.log(listData.filter_params.columnFilterData)
                        // console.log(listData.filter_params.operation)

                        props.storeContactListSelectedColumnFilterData(JSON.parse(listData.filter_params.columnFilterData))

                    } else {

                        if (parsedValue[0] !== undefined && parsedValue[0] != null && parsedValue[0].hasOwnProperty('filter')) {
                            getColumnFilters(parsedValue[0].filter)

                        }
                    }
                    fetchAllContacts('operationChange', JSON.parse(listData.filter_params.operation), { 'status': false })


                } else {
                    props.storeContactListSelectedSavedContactList({ 'status': true, "savelistId": listId })
                    fetchAllContacts('listChange', { 'status': true, "savelistId": listId })
                }

            }
            else {
                props.storeContactListMultipleSelectedData([[], []])
                props.storeContactListMultipleSelectedDataLabel([[], []])
                props.storeContactListSelectedOperationList(defaultColumnFilterData)
                props.storeContactListSelectedSavedContactList({ 'status': true, "savelistId": listId })
                fetchAllContacts('listChange', { 'status': true, "savelistId": listId })

            }

        }
    }

    const getColumnFilters = (operationData) => {

        console.log('column Filter not available')

    }

    const fetchAllContacts = async (dropdownHeader = '', value = '', value2 = '', firstLoad = false) => {

        setShowDateRange(false);

        if (dropdownHeader !== "export") {
            setComponentLoading(true)

        }
        if (firstLoad) {
            preLoad()
        }

        let QueryItem;

        if (queryCampaignId != null) {
            QueryItem = {}
            QueryItem["campaignid"] = queryCampaignId
            if (queryContactedCampaign != null) {
                QueryItem["contacted"] = "contacted"
            }
            if (queryRespondedCampaign != null) {
                QueryItem["responded"] = "responded"

            }
        } else {
            QueryItem = []
        }

        let params = {

            itemPerPage: dropdownHeader === 'pageSize' ? value : dropdownHeader === 'clearAll' ? 50 : props.contactListpageSize,
            operation: (dropdownHeader === 'operationChange') ? JSON.stringify(value) : (dropdownHeader === 'clearColumnFilter' || dropdownHeader === 'clearAll' || dropdownHeader === 'removeList' || dropdownHeader === 'listChange') ? JSON.stringify(defaultColumnFilterData) : dropdownHeader === 'removeList' ? JSON.stringify(defaultColumnFilterData) : JSON.stringify(props.contactListSelectedOperationList),
            queryTerm: JSON.stringify(QueryItem),
            searchToken: (dropdownHeader === 'searchToken') ? value : dropdownHeader === 'clearAll' ? '' : props.contactListSearchTextInTop !== '' ? String(props.contactListSearchTextInTop) : '',
            file_list_id: (dropdownHeader === 'fileChange') ? value : dropdownHeader === 'clearAll' ? '' : props.contactListSelectedFile !== '' ? String(props.contactListSelectedFile) : '',
            campaignId: (dropdownHeader === 'campaignChange') ? value : dropdownHeader === 'clearAll' ? '' : props.contactListSelectedCampaign !== '' ? String(props.contactListSelectedCampaign) : '',
            tagId: (dropdownHeader === 'tagChange') ? value : dropdownHeader === 'clearAll' ? '' : props.contactListSelectedTag !== '' ? String(props.contactListSelectedTag) : '',
            stageId: (dropdownHeader === 'stageChange') ? value : dropdownHeader === 'clearAll' ? '' : props.contactListSelectedStage !== '' ? String(props.contactListSelectedStage) : '',
            sourceId: (dropdownHeader === 'sourceChange') ? value : dropdownHeader === 'clearAll' ? '' : props.contactListSelectedSource !== '' ? String(props.contactListSelectedSource) : '',

            saveList: (dropdownHeader === 'listChange' && value !== '') ? value : (dropdownHeader === 'operationChange' && value2 !== '') ? value2 : (dropdownHeader === 'clearAll' || dropdownHeader === 'removeList') ? { status: false } : dropdownHeader === 'removeList' ? { status: false } : props.contactListSelectedSavedContactList,
            response_type: null,
            owner_id: (dropdownHeader === 'ownerChange') ? value : dropdownHeader === 'clearAll' ? [-1] : props.contactListSelectedSubUser.length !== 0 ? props.contactListSelectedSubUser : [-1],
            page_no: (dropdownHeader === 'pageNo') ? value : dropdownHeader === 'clearAll' ? 1 : props.contactListPage !== undefined ? props.contactListPage : 1,
            userTimezoneRaw: userTimezoneRaw
        }

        if ((props.contactListDatePickerActive || dropdownHeader === 'dateRange') && dropdownHeader !== 'clearAll') {
            params['startDate'] = (dropdownHeader === 'dateRange') ? value : props.contactListSelectedFromDate + ' 00:00:01'
            params['endDate'] = (dropdownHeader === 'dateRange') ? value2 : props.contactListSelectedEndDate + ' 23:59:59'

        }
        params['searchColumns'] = 'all'
        if(dropdownHeader === 'searchToken'){
            params['searchColumns'] = Utils.checkLetterExistInText(value) ? 'text' :  value.length >3 ? 'number' : 'numberandemail' 
        } else if(props.contactListSearchTextInTop !== ''){
            params['searchColumns'] = Utils.checkLetterExistInText(props.contactListSearchTextInTop) ? 'text' :  props.contactListSearchTextInTop.length >3 ? 'number' : 'numberandemail' 
        }


        if (dropdownHeader === "export") {
            if (props.contactListSelectedContactIds.length > 0) {
                params['contactIds'] = props.contactListSelectedContactIds
            }
            params['fileName'] = exportFileName
            params['fileListId'] = params['file_list_id']
            params['ownerId'] = params['owner_id']
            params['responseType'] = params['response_type']
            params['mappings'] = contactListSelectedOmitData
            params['roleId'] = Utils.getUserRole();
            params['isoDateFormat'] = "MM/DD/YYYY HH:mm:ss";

            try {
                exportCSVFromContactlist('POST', { filterData: params }).then(response => {

                    if (!response) {
                        window.showNotification('ERROR', 'Something went wrong')

                    }
                    else if (response.status === 'SUCCESS') {
                        window.showNotification('SUCCESS', <p className="cursor-pointer" onClick={() => handleRoute('/data-export/csv-file')}>{response.message}. Click to see processed files</p>)
                    } else {
                        window.showNotification('ERROR', response.message)

                    }
                });
            } catch (e) {
                window.showNotification('ERROR', 'Something went wrong')
            }

        }
        else {
            props.updateMultipleData({contactListParams : {...params}})
            try {
                await props.getAllContactList({ params }, (response) => {


                    if (response !== undefined && response != null && response.html !== undefined && response.html != null) {
                        setContactList(response.html.data)
                        setTotalContacts(response.html.total)

                        localStorage.setItem('contactListInfo',JSON.stringify({ from: 'contactListPage',totalContacts: response.html.data.length,
                        contactList: response.html.data}));
                        if (props.contactListFirstLoading) {
                            props.storeContactListFirstLoading(false)
                        }
                        else {
                            emptyMessage = 'No Contact found!'
                        }
                        setLastPage(response.html.last_page)
                        setUpdateTable(!updateTable)
                        setComponentLoading(false)
                        //not required now
                        // if(dropdownHeader === 'pageNo' && scroller){
                        //     // scroller.scrollTop = scroller.scrollHeight
                        //     scroller.scrollTo({ top: scroller.scrollHeight, behavior: 'smooth' });

                        // }
                        try{
                            if (bottomScrollRef && bottomScrollRef.current) {
                                bottomScrollRef.current.scrollLeft = 0
                            }
                            //not required as contactlist now scrollable
                            // window.scrollTo({ top: 50, behavior: 'smooth' });
                        }
                        catch(e){
                            console.log('slide problem')
                        }
                    }


                })
                if (firstLoad) {
                    afterLoad()
                }
            }
            catch (error) {
                console.log(error)
                if (firstLoad) {
                    afterLoad()
                }
            } finally {

            }

        }

    }

    const getDropdownItems = () => {

        // getCampaignListForDropdown();
        // getStageListForDropdown('all','',20,1, true)
        // getFileList()

        setLoadDropdownItems(true);
    }

    const getPipelineListForColumnFilter = async () => {

        let tmpDataForPipeline={};

        if (props.storedPipelineList) {
            setPipelineList(props.storedPipelineList)
            
            if(Array.isArray(props.storedPipelineList) && props.storedPipelineList.length > 0){
                
                for(let j=0;j< props.storedPipelineList.length;j++){

                    tmpDataForPipeline[props.storedPipelineList[j].id] = props.storedPipelineList[j].title
                }
                props.updateMultipleData({pipelineListInObject: tmpDataForPipeline})
            }
        } else {
            await props.fetchPipelineList({}, (callback) => {

                setPipelineList(callback.data)
                props.updateMultipleData({pipelineList: callback.data})

                if(callback.data && Array.isArray(callback.data) && callback.data.length > 0){
                
                    for(let j=0;j< callback.data.length;j++){
    
                        tmpDataForPipeline[callback.data[j].id] = callback.data[j].title
                    }

                    props.updateMultipleData({pipelineListInObject: tmpDataForPipeline})
                }
            })
        }


    }
    const getFileList = async () => {
        await getFileListForDropdown()
            .then(response => {

                if (response.data.success) {
                    setFileList(response.data.data.list)
                }

            })
            .catch(error => {
                // alert('something is wrong' + error)
                console.log(error)
            });
    }

    const getSubUsersList = async () => {


        let tmpDataForUser = {}
        if (props.subUserList != null) {

            if(props.subUserList && Array.isArray(props.subUserList) && props.subUserList.length > 0){
                for(let j=0;j< props.subUserList.length;j++){

                    tmpDataForUser[props.subUserList[j].id] = props.subUserList[j].full_name
                }
    
            }
            setSubUserList(props.subUserList)
            props.updateMultipleData({userListInObject : tmpDataForUser});

        } else {
            await getSubUserListForContactlist()
                .then(response => {

                    // props.setSubUserList(response.data.data)

                    if(response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0){

                        for(let j=0;j< response.data.data.length;j++){

                            tmpDataForUser[response.data.data[j].id] = response.data.data[j].full_name
                        }
            
                    }

                    setSubUserList(response.data.data)

                    setUpdateColumnArray(!updateColumnArray)

                    props.updateMultipleData({subUserList: response.data.data, userListInObject : tmpDataForUser});

                })
                .catch(error => {
                    console.log('something is wrong' + error)
                });
        }
    }

    // const getUserCustomFields = async () => {

    //     props.fetchUserCustomFields({}, (callback) => {
    //     })
    // }

    const getCampaignListForDropdown = async (perPage = 20, pageNo = 1, scroll= false) => {

        if (props.storedCampaignList == null) {
            props.fetchStoreCampaignList({}, (callback) => {

                setCampaignList(callback.data)
            })
        } else {
            setCampaignList(props.storedCampaignList)

        }
    }

    const getStageListForDropdown = async (pipelineId, type = '' , perPage = 20, pageNo = 1, scroll= false) => {
        // await getStageList({ "pipeline_id": pipelineId })

        let params = {"pipeline_id": pipelineId };
        if(scroll){
            params['perPage']= perPage
            params['pageNo'] = pageNo
        }
        await getStageListWithPagination(params)
            .then(response => {

                if (type === 'action') {
                    setStageListForAction(response.data.stages)
                } else {

                    props.storeContactListStageListForPipeline(response.data.stages)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }



    const getSourceListForDropdown = async () => {

        let tmpDataForUser={};
        if (props.storedSourceList != null) {

            if(props.storedSourceList && Array.isArray(props.storedSourceList) && props.storedSourceList.length > 0){
                for(let j=0;j< props.storedSourceList.length;j++){

                    tmpDataForUser[props.storedSourceList[j].id] = props.storedSourceList[j].source_title
                }
    
            }
            setSourceList(props.storedSourceList)
            props.updateMultipleData({contactSourceInObject : tmpDataForUser});
        } else {
            await props.fetchUserSourceList({}, (callback) => {
                if(callback && Array.isArray(callback) && callback.length > 0){
                    for(let j=0;j< callback.length;j++){
    
                        tmpDataForUser[callback[j].id] = callback[j].source_title
                    }
        
                }
                props.updateMultipleData({contactSourceInObject : tmpDataForUser});
                setSourceList(callback)
                setUpdateColumnArray(!updateColumnArray)
            })

        }

    }



    const getAllContactList = async () => {
        await getContactListForDropdown()
            .then(response => {
                setSavedContactList(response.data.data.list)
                props.storeContactListGroup(response.data.data.list)
            })
            .catch(error => {
                // alert('something is wrong' + error)
                console.log(error)
            });
    }

    const setDateRange = (selectedDateRange) => {

        clearTimeout(searchByDateRange)

        import('../../globals/TimezoneConversion').then((data)=>{
            
            
            let startDate = data.formatDateWithoutZone(selectedDateRange[0].startDate, 'MM/DD/YYYY')
            let lastDate = data.formatDateWithoutZone(selectedDateRange[0].endDate, 'MM/DD/YYYY')

        // let convertedDate1 = convertStringIntoTime(startDate+' 00:00:00',userTimezoneRaw,'YYYY/MM/DD HH:MM:SS')

        props.storeContactListSelectedFromDate(startDate)
        props.storeContactListSelectedEndDate(lastDate)
        props.storeContactListSelectedDateRangeInfo(selectedDateRange)
        let searchTokenDateRangeTimer = setTimeout(() => {


            props.storeContactListSelectedDatePickerActive(true)
            setShowDateRange(false);
            fetchAllContacts('dateRange',
                startDate + ' 00:00:01',
                lastDate + ' 23:59:59'
            )
        }, 1000);

        setSearchByDateRange(searchTokenDateRangeTimer)

    })


    }

    const removeBulkContacts = async () => {

        if (props.contactListSelectedContactIds.length > 0) {

            confirmAlert({
                title: 'Confirm to delete',
                message: 'Are you sure to delete selected contacts?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            await removeBulkContact({ 'ids': props.contactListSelectedContactIds })
                                .then(response => {

                                    props.storeContactListSelectedContactIds([])
                                    if (response.data.success) {
                                        fetchAllContacts()
                                        window.showNotification('SUCCESS','Contact deleted successfully')

                                    } else {
                                        window.showNotification('ERROR','Something went wrong')
                                    }
                                })
                                .catch(error => {
                                    window.showNotification('ERROR','Something went wrong')
                                });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })



        } else {

            window.showNotification('ERROR','No Contact Selected')
        }
    }

    const handleTagModalClose = (update = false, newTag = false) => {

        setShowTagModal(false);

        if (update) {
            fetchAllContacts()
        }

        if (newTag) {
            getAllTagsForDropdown()
        }
    };

    const handleListModalClose = (update = false, newList = false) => {

        setShowListModal(false);

        if (newList) {
            getAllContactList()
        }
    };

    const openAddContactModal = (e) => {
        e.preventDefault()
        // alert('hello')
        // setViewAddContactModal(true)
        setShowDateRange(false);
        window.showAddContactModal(props.storedSourceList);

    }

    let previous_page = null, next_page = null;
    if (props.contactListPage > 1) {
        previous_page = props.contactListPage - 1
    }
        // eslint-disable-next-line eqeqeq
    if (props.contactListPage != lastPage) {
        next_page = props.contactListPage + 1
    }

    const popupList = (event, boolean) => {
        event.preventDefault();
        setOpenCreateListModal(boolean)
    }

    const listAddCallback = (response) => {

        let tempContactListData = [...savedContactList]
        tempContactListData.push(response);

        setSavedContactList(tempContactListData)
    }

    const openActionAssignStageModal = () => {
        if (props.contactListSelectedContactIds.length > 0) {
            setOpenAddStageToContactModal(true)
        } else {
            window.showNotification('ERROR','No contact selected')
        }
    }


    const openActionAssignTagModal = () => {
        if (props.contactListSelectedContactIds.length > 0) {
            setShowTagModal(true)
        } else {
            window.showNotification('ERROR','No contact selected')
        }
    }

    const openAddToAutoDialerModal = () => {
        if (props.contactListSelectedContactIds.length > 0) {
            setShowAddToAutoDialerModal(true)
            
        } else {
            window.showNotification('ERROR', 'No contact selected');
        }
    }

    const openActionAssignListModal = () => {
        if (props.contactListSelectedContactIds.length > 0) {
            setShowListModal(true)
        } else {
            window.showNotification('ERROR','No contact selected')
        }
    }

    const showFilterOptions = () => {
        return (
            <div className="all-select-area" ref={extraFilterOptionRef}>
                <div className="row">
                    <div className="col s6 m6 l6 xl6">
                        <div className="margin-none-row row">
                            <div className="dash-bord-select col s12">
                                <div className="custom-input custom-select-arrow col s11">
                                    <div className="select-wrapper deal-select-labe">
                                        <DropdownWithScroll
                                            fetchApi={{'action': getCampaignListForDropdownWithPaginate,'initialParams':{},
                                            perPage:'perPage',pageNo:'pageNo', columns:['id','title']}}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select Campaign"
                                            className=""
                                            mapping={{ title: "title" }}
                                            menuItemClassName=""
                                            updateSelectedData={props.storeContactListSelectedCampaign}
                                            selectedValue={props.contactListSelectedCampaign}
                                            updateDatatable={updateDatatable}
                                            dropdownHeader={'campaignChange'}
                                            textColor={false}
                                        />
                                    </div>
                                </div>
                                <div className="modal-close custom-cursor-pointer" onClick={() => clearDropdown('campaignChange', '')}>
                                    {IconList.modalCloseIcon}
                                </div>
                            </div>
                        </div>

                        <div className="margin-none-row row">
                            <div className="dash-bord-select col s12">
                                <div className="custom-input custom-select-arrow col s11">
                                    <div className="select-wrapper">
                                        <DropdownWithScroll
                                            fetchApi={{'action': getStageListWithPagination,'initialParams':{"pipeline_id": 'all' },
                                            perPage:'perPage',pageNo:'pageNo', columns:['id','stage','text_color','pipeline_id']}}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select Stage"
                                            className=""
                                            mapping={{ title: "stage",pipeline_id: "pipeline_id" }}
                                            menuItemClassName=""
                                            updateSelectedData={props.storeContactListSelectedStage}
                                            selectedValue={props.contactListSelectedStage}
                                            updateDatatable={updateDatatable}
                                            dropdownHeader={'stageChange'}
                                            textColor={true}
                                        />
                                    </div>
                                </div>
                                <div className="modal-close" onClick={() => clearDropdown('stageChange', '')}>
                                    {IconList.modalCloseIcon}
                                </div>
                            </div>
                        </div>

                        <div className="margin-none-row row">
                            <div className="dash-bord-select col s12">
                                <div className="custom-input custom-select-arrow col s11">
                                    <div className="select-wrapper">
                                        <DropdownHelper
                                            datalist={sourceList}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select Source"
                                            className=""
                                            mapping={{ title: "source_title" }}
                                            menuItemClassName=""
                                            updateSelectedData={props.storeContactListSelectedSource}
                                            selectedValue={props.contactListSelectedSource}
                                            updateDatatable={updateDatatable}
                                            dropdownHeader={'sourceChange'}
                                        />
                                        {/* } */}
                                    </div>
                                </div>
                                <div className="modal-close custom-cursor-pointer" onClick={() => clearDropdown('sourceChange', '')}>
                                    {IconList.modalCloseIcon}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s6 m6 l6 xl6">
                        <div className="margin-none-row row">
                            <div className="dash-bord-select col s12">
                                <div className="custom-input custom-select-arrow col s11">
                                    <div className="select-wrapper">
                                    <DropdownWithScroll
                                            fetchApi={{'action': getUserTagListNew,'initialParams':{"paginate": 'true' },
                                            perPage:'per_page',pageNo:'current_page', columns:['id','name']}}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select Tag"
                                            className=""
                                            mapping={{ title: "name" }}
                                            menuItemClassName=""
                                            updateSelectedData={props.storeContactListSelectedTag}
                                            selectedValue={props.contactListSelectedTag}
                                            updateDatatable={updateDatatable}
                                            dropdownHeader={'tagChange'}
                                            textColor={true}
                                        />
                                    </div>
                                </div>
                                <div className="modal-close custom-cursor-pointer" onClick={() => clearDropdown('tagChange', '')}>
                                    {IconList.modalCloseIcon}

                                </div>
                            </div>
                        </div>

                        <div className="margin-none-row row">
                            <div className="dash-bord-select col s12">
                                <div className="custom-input custom-select-arrow col s11">
                                    <div className="select-wrapper">
                                        <DropdownWithScroll
                                            fetchApi={{'action': getFileListForDropdown,'initialParams':{},
                                            perPage:'perPage',pageNo:'pageNo', columns:['id','name_original']}}
                                            noneFieldRequired={true}
                                            noneFieldTitle="Select File"
                                            className=""
                                            mapping={{ title: "name_original" }}
                                            menuItemClassName=""
                                            updateSelectedData={props.storeContactListSelectedFile}
                                            selectedValue={props.contactListSelectedFile}
                                            updateDatatable={updateDatatable}
                                            dropdownHeader={'fileChange'}
                                            textColor={false}
                                        />
                                    </div>
                                </div>
                                <div className="modal-close custom-cursor-pointer" onClick={() => clearDropdown('fileChange', '')}>
                                    {IconList.modalCloseIcon}

                                </div>
                            </div>
                        </div>

                        <div className="margin-none-row row">
                            <div className="dash-bord-select col s12 custom-time-range-contact-list">
                                <div className="custom-input custom-select-arrow col s11">
                                    <div className="select-wrapper">
                                        {!showDateRange &&
                                            <FormControl onClick={() => setShowDateRange(true)}>
                                                <input className="select-dropdown dropdown-trigger" type="text" readOnly={true} value={props.contactListDatePickerActive ? props.contactListSelectedFromDate.replaceAll('-', '/') + '-' + props.contactListSelectedEndDate.replaceAll('-', '/') : 'Select Date range'} />
                                            </FormControl>
                                        }
                                        {showDateRange &&
                                            <DateRangePicker
                                                editableDateInputs={true}
                                                onChange={item => setDateRange([item.selection])}
                                                // showMonthAndYearPickers={true}
                                                moveRangeOnFirstSelection={true}
                                                ranges={props.contactListSelectedDateRangeInfo}
                                                // showSelectionPreview={true}
                                                minDate={addDays(new Date(), -600)}
                                                maxDate={addDays(new Date(), 1)}
                                                months={1}
                                                direction="vertical"
                                                dateDisplayFormat={'MM/dd/yyyy'}
                                                calendarFocus={'backwards'}
                                                ariaLabels={{
                                                    dateInput: {
                                                        selection1: { startDate: "start date input of selction 1", endDate: "end date input of selction 1" },
                                                        selection2: { startDate: "start date input of selction 2", endDate: "end date input of selction 2" }
                                                    },
                                                    monthPicker: "month picker",
                                                    yearPicker: "year picker",
                                                    prevButton: "previous month button",
                                                    nextButton: "next month button",
                                                }}
                                                scroll={{ enabled: true }}

                                            />
                                        }
                                    </div>
                                </div>
                                <div className="modal-close custom-cursor-pointer" onClick={() => clearDropdown('dateRange', '')}>
                                    {IconList.modalCloseIcon}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const handleClickSessionStart = () => {
        if (startingSession) {
            return;
        }
        setStartingSession(true);
        window.globalPowerDialerModal(true, {
            type: "contact-list",
            contactIds: props.contactListSelectedContactIds,
            filter: props.contactListParams,
            callback: (response, formData) => {
                if (response.success) {
                    const win = window.open(
                        `/power-dialer?listId=${response?.data}&listName=${
                            formData.name
                        }&from=contactList&for=startSession`,
                        "_blank"
                    );
                    win.focus();
                    setStartingSession(false);
                } else {
                    setStartingSession(false);
                }
            },
        });
    };

    const clearListButton = () => {
        if (props.contactListSelectedSource !== '' ||
            props.contactListSearchTextInTop !== '' ||
            props.contactListSelectedTag !== '' ||
            props.contactListSelectedStage !== '' ||
            props.contactListSelectedFile !== '' ||
            props.contactListSelectedSubUser.length > 1 ||
            props.contactListSelectedCampaign !== '' ||
            JSON.stringify(props.contactListSelectedOperationList) !== JSON.stringify(defaultColumnFilterData) ||
            JSON.stringify(props.contactListSelectedColumnFilterData) !== "{}" ||
            props.contactListSelectedFromDate !== '' ||
            props.contactListSelectedEndDate !== '' ||
            props.contactListpageSize !== 50 ||
            props.contactListDatePickerActive !== false ||
            JSON.stringify(props.contactListSelectedSavedContactList) !== JSON.stringify({ status: false })) {
            return ( 
            <>
                <div
                    onClick={() => handleClickSessionStart()}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 7px",
                        cursor: "pointer",
                        color: "#fff",
                        backgroundColor: "#6070ed",
                        float: "left",
                        borderRadius: "4px",
                        marginRight: "5px",
                    }}
                >
                    {startingSession && (
                        <CustomCircleLoader
                            style={{ display: "inline-flex", marginRight: "5px" }}
                            fill='#fff'
                            size='extra-small'
                        />
                    )}
                    {startingSession
                        ? "Starting Dialing Session"
                        : "Start Dialing Session"}
                </div>
                <span className="align-items-center clear_list_contact_Button cursor-pointer d-flex" onClick={() => clearAllFilter()}>{IconList.removeFilterIcon}{` remove Filter`}</span>
            </>
            )
        }else if(props.contactListSelectedContactIds && props.contactListSelectedContactIds.length > 0) {
            return ( 
            <>
                <div
                    onClick={() => handleClickSessionStart()}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 7px",
                        cursor: "pointer",
                        color: "#fff",
                        backgroundColor: "#6070ed",
                        float: "left",
                        borderRadius: "4px",
                        marginRight: "5px",
                    }}
                >
                    {startingSession && (
                        <CustomCircleLoader
                            style={{ display: "inline-flex", marginRight: "5px" }}
                            fill='#fff'
                            size='extra-small'
                        />
                    )}
                    {startingSession
                        ? "Starting Dialing Session"
                        : "Start Dialing Session"}
                </div>
            </>
            )
        
        }

    }

    const clearAllFilter = () => {

        props.storeContactListSelectedSource('')
        props.storeContactListSearchTextInTop('')
        props.storeContactListSelectedTag('')
        props.storeContactListSelectedStage('')
        props.storeContactListSelectedFile('')
        props.storeContactListSelectedSubUser([-1])
        props.storeContactListSelectedCampaign('')
        props.storeContactListSelectedOperationList(defaultColumnFilterData)
        // props.storeContactListSelectedOmitList
        props.storeContactListSelectedContactIds([])
        props.storeContactListSelectedDateRangeInfo([
            {
                startDate: addDays(new Date(), -30),
                endDate: new Date(),
                key: 'selection',
            }
        ])
        props.storeContactListSelectedFromDate('')
        props.storeContactListSelectedEndDate('')
        // props.storeContactListSelectedColumnFilterData
        props.storeContactListSelectedPage(1)
        props.storeContactListSelectedPageSize(50)
        props.storeContactListSelectedDatePickerActive(false)
        props.storeContactListMultipleSelectedData([[], []])
        props.storeContactListMultipleSelectedDataLabel([[], []])
        props.storeContactListSelectedSavedContactList({ 'status': false })
        props.storeContactListSelectedPage(1)
        setSearchByDateRange(false)
        setShowDateRange(false)
        setSelectedContactList('')
        setSelectedContactListTitle('');
        clearColumnFilter('clearAll')
    }

    const clearList = () => {
        setSelectedContactList('')
        setSelectedContactListTitle('');
        clearColumnFilter('removeList')
    }

    const clearColumnFilter = (clearCommand) => {

        props.storeContactListSelectedOperationList(defaultColumnFilterData)
        props.storeContactListSelectedColumnFilterData({})
        props.storeContactListSelectedSavedContactList({ 'status': false })
        fetchAllContacts(clearCommand)
    }

    const removeListCallBack = (data, index) => {

        // eslint-disable-next-line eqeqeq
        if (selectedContactList == data.id) {
            clearList();
        }
        let tempContactListData = [...savedContactList]
        tempContactListData.splice(index, 1);

        setSavedContactList(tempContactListData)
    }

    /* for multiple sub user list */
    const handleSelectUser = (item, remove) => {
        if(item.value === '__clear_all__'){
            setSelectedUsers([])
            setSelectedIds([])
            props.storeContactListSelectedSubUser([])
            updateDatatable('ownerChange', [])
            return
        }
        if(item.value === '__all__'){
            if(remove){
                setSelectedUsers([])
                setSelectedIds([])
                props.storeContactListSelectedSubUser([])
                updateDatatable('ownerChange', [])
            }
            else{
                setSelectedUsers([item])
                setSelectedIds(['__all__'])
                props.storeContactListSelectedSubUser([-1])
                updateDatatable('ownerChange', [-1])
            }
        }else{
            if(selectedIds.length > 0 && selectedIds[0] === '__all__'){
                setSelectedUsers([item])
                setSelectedIds([item.value])
                props.storeContactListSelectedSubUser([item.value])
                updateDatatable('ownerChange', [item.value])
            }
            else{
                let old_data_ids = [...selectedIds]
                let old_data_full = [...selectedUsers]
                if(remove){
                    let filter_old_data_ids = old_data_ids.filter(list => item.value !== list)
                    setSelectedIds(filter_old_data_ids)
    
                    let filter_old_data_full = old_data_full.filter(list => item.value !== list.value)
                    setSelectedUsers(filter_old_data_full)
                    props.storeContactListSelectedSubUser(filter_old_data_ids)
                    updateDatatable('ownerChange', filter_old_data_ids)
                }
                else{
                    old_data_ids.push(item.value)
                    old_data_full.push(item)
                    setSelectedUsers(old_data_full)
                    setSelectedIds(old_data_ids)
                    props.storeContactListSelectedSubUser(old_data_ids)
                    updateDatatable('ownerChange', old_data_ids)
                }
            }
        }
    }

    const handleBulkLeadOwnerAction = (action) => {

        if((
            props.contactListSelectedSource !== '' ||
            props.contactListSearchTextInTop !== '' ||
            props.contactListSelectedTag !== '' ||
            props.contactListSelectedStage !== '' ||
            props.contactListSelectedFile !== '' ||
            props.contactListSelectedSubUser.length > 1 ||
            props.contactListSelectedCampaign !== '' ||
            JSON.stringify(props.contactListSelectedOperationList) !== JSON.stringify(defaultColumnFilterData) ||
            JSON.stringify(props.contactListSelectedColumnFilterData) !== "{}" ||
            props.contactListSelectedFromDate !== '' ||
            props.contactListSelectedEndDate !== '' ||
            props.contactListpageSize !== 50 ||
            props.contactListDatePickerActive !== false ||
            JSON.stringify(props.contactListSelectedSavedContactList) !== JSON.stringify({ status: false })
        ) || (
            props.contactListSelectedContactIds && props.contactListSelectedContactIds.length > 0
        )){
            if(CONSTANTS.CHANGE_LEAD_OWNER === action){
                setOpenLeadOwnerModal(true);
            }else if(CONSTANTS.ADD_COLLABORATOR === action){
                setOpenAddCollaboratorModal(true);
            }
        }else {
            window.showNotification('ERROR','No contact selected or no filter is active');
        }

    }

    const carrierLookupVerifyContact = (validateType,lookupType) => {
        if (props.contactListSelectedContactIds && props.contactListSelectedContactIds.length === 0){
            window.showNotification('ERROR','No contact selected');
            return;
        }
        let price;

        if(props.carrierLookupPrices){
            price = props.carrierLookupPrices.find(({ type }) => type === creditSettingType[validateType]);
            if(price){
                price = price.value;
            }
        }

        let validateData = {
            "contactIds": props.contactListSelectedContactIds,
            "categoryName": defaultCategoryName[validateType],
            "lookupType": lookupType
        };

        NewConfirmAlert({
            onSubmit : () => {
                carrierLookupVerifyContactsApi(validateData)
                    .then((response)=>{
                        if (response.success){
                            window.showNotification('SUCCESS',`${(validateData ? (validateData.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP ? 'Email' : 'Number') : '')} validation request accepted`);
                        }else {
                            window.showNotification('ERROR',`Something went wrong while validate ${(validateData ? (validateData.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP ? 'email' : 'number') : '')}`);
                        }
                    })
                    .catch((err)=>{
                        window.showNotification('ERROR',`Something went wrong while validate ${(validateData ? (validateData.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP ? 'email' : 'number') : '')}`)
                    });
            },
            title: `Validating each ${(validateData ? (validateData.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP ? 'email' : 'number') : '')} will cost: $${price}`,
            description: 'These credits will be deducted from your balance.',
            cancelText: 'Cancel',
            submitText: 'Validate',
            width: '480px'
        });
    };

    return (

        <React.Fragment>

            {showTagModal &&
                <ModalAssignToTagNew
                    open={showTagModal}
                    contact_id={props.contactListSelectedContactIds}
                    title={"Add Tag"}
                    callBack={handleTagModalClose}
                    onClose={() => setShowTagModal(false)}
                />
            }

            {showListModal &&
                <ModalAssignToList
                    open={showListModal}
                    selectedContactIds={props.contactListSelectedContactIds}
                    title={"Assign List"}
                    callBack={handleListModalClose}
                    onClose={() => setShowListModal(false)}
                    contactListGroup={savedContactList}
                />
            }
            {showAddToCampignModal &&
                <ModalAssignToCampaign
                    open={showAddToCampignModal}
                    onClose={() => setShowAddToCampaignModal(false)}
                    contactIds={props.contactListSelectedContactIds}
                // onSubmit={submitAddToCampaign}
                />
            }
            {showAddToAutoDialerModal &&
                <ModalAddToAutoDialer
                    open={showAddToAutoDialerModal}
                    onClose={() => setShowAddToAutoDialerModal(false)}
                    contactIds={props.contactListSelectedContactIds}
                    contactList={contactlist}
                    // onSubmit={submitAddToCampaign}
                />
            }

            {/* <If condition={isOpenMMSModal}> */}
            {openCreateListModal &&
                <ModalCreateList
                    open={openCreateListModal}
                    onClose={() => setOpenCreateListModal(false)}
                    selectedContactIds={props.contactListSelectedContactIds}
                    operation={props.contactListSelectedOperationList}
                    columnFilterData={props.contactListSelectedColumnFilterData}
                    callback={listAddCallback}
                />
            }
            {openfileNameModal &&
                <InsertFileName
                    open={openfileNameModal}
                    onClose={() => setOpenfileNameModal(false)}
                    callback={(name) => {
                        setOpenfileNameModal(false); exportFileName = name; fetchAllContacts("export")
                    }}
                />
            }

            {openAddStageToContactModal &&
                <ModalListForStageAdd
                    open={openAddStageToContactModal}
                    onClose={() => setOpenAddStageToContactModal(false)}
                    updatePipelineAction={updatePipelineAction}
                    pipelineList={pipelineList}
                    stageListForAction={stageListForAction}
                    contactIds={props.contactListSelectedContactIds}
                    // submitContactInStage={submitContactInStage}
                    stageChangeInfo={stageChangeInfo}
                    setStageChangeInfo={setStageChangeInfo}
                    datatableRerender={datatableRerender}
                />
            }

            {
                openLeadOwnerModal &&
                <LeadOwnerModal
                    isOpen={openLeadOwnerModal}
                    onClose={()=>{
                        setOpenLeadOwnerModal(false);
                    }}
                    data={{
                        callback: () => {

                        },
                        subUserList: subUserList,
                        contactIds: props.contactListSelectedContactIds,
                        filter: props.contactListParams,
                        userId: Utils.getAccountData('userId')
                    }}
                />
            }

            {
                openAddCollaboratorModal &&
                <AddCollaboratorModal
                    isOpen={openAddCollaboratorModal}
                    onClose={()=>{
                        setOpenAddCollaboratorModal(false);
                    }}
                    data={{
                        callback: () => {

                        },
                        subUserList: subUserList,
                        contactIds: props.contactListSelectedContactIds,
                        filter: props.contactListParams,
                        userId: Utils.getAccountData('userId')
                    }}
                />
            }
            <div className="container-fulid">
                <div className="dropdown-main-contact-row row">
                    <div className="col l12">
                        <div className="contact-top-filter-main" ref={filterHeaderRef}>
                            <div className="contact-top-filter-left firstOne">
                                <ul>
                                    <li className="add-action firstItem">
                                        <GlobalDropdown className="add__action_dropdown" placement={"bottomRight"}>
                                            <GlobalDropDownButton>
                                                <Button
                                                    className="list__settings_btn"
                                                    variant="contained"
                                                    color="secondary"
                                                    endIcon={<i className="material-icons">keyboard_arrow_down</i>}
                                                >
                                                    Actions
                                                </Button>
                                            </GlobalDropDownButton>
                                            <GlobalDropDownItem onClick={() => contactAddToCampaign()} title='Add To Campaign' onClickHide={true} icon={IconList.addToContactSVG} />
                                            <GlobalDropDownItem title='Add To List' onClick={openActionAssignListModal} onClickHide={true} icon={IconList.addToListSVG} />
                                            <GlobalDropDownItem onClick={openActionAssignStageModal} onClickHide={true} title='Add to Pipeline Stage' icon={IconList.addToPipelineSVG} />
                                            <GlobalDropDownItem onClick={openActionAssignTagModal} onClickHide={true} title='Assign Tag' icon={IconList.addTagSVG} />
                                            { (Utils.getAccountData('userId') === 1 || Utils.getAccountData('userId') === 2 || Utils.getAccountData('userId') === 446 || Utils.getAccountData('userId') === 12) &&
                                                <GlobalDropDownItem onClick={openAddToAutoDialerModal} onClickHide={true} title='Add to auto dialer' icon={IconList.callIconv3} />
                                            }
                                            { (Utils.getAccountData('userId') === 1 || Utils.getAccountData('userId') === 2 || Utils.getAccountData('userId') === 446 || Utils.getAccountData('userId') === 12) &&
                                                <GlobalDropDownItem onClick={openAddToAutoDialerModal} onClickHide={true} title='Add to auto dialer' icon={IconList.callIconv3} />
                                            }
                                            <GlobalDropDownItem title='Manage Tags' onClickHide={true} onClick={() => history.push({ pathname: '/user/tags', state: { from: 'contactListPage' } })} icon={IconList.manageTagsSVG} />
                                            <GlobalDropDownItem title='Export Contacts' onClick={() => exportCSV()} icon={IconList.exportContactSVG} />
                                            <GlobalDropDownItem title='Exported Files' onClick={() => handleRoute('/data-export/csv-file')} icon={IconList.exportContactSVG} />
                                            <GlobalDropDownItem title='Inactive Contact List' onClick={() => handleRoute('/inactive-contact-list')} icon={IconList.inactiveContactListImage} />
                                            <GlobalDropDownItem title='Unsubscribed Contact List' onClick={() => handleRoute('/unsubscribe-contact-list')} icon={IconList.inactiveContactListImage} />
                                            <GlobalDropDownItem onClickHide={true} onClick={() => handleBulkLeadOwnerAction(CONSTANTS.CHANGE_LEAD_OWNER)} title={<span>Change Lead Owner</span>} icon={IconList.ownerChangeCircle} />
                                            <GlobalDropDownItem onClickHide={true} onClick={() => handleBulkLeadOwnerAction(CONSTANTS.ADD_COLLABORATOR)} title={<span>Add Collaborator(s)</span>} icon={IconList.collaboratorCircle} />
                                            { (Utils.getAccountData('carrierLookup')) &&
                                                <GlobalDropDownItem onClick={()=>{carrierLookupVerifyContact(CONTACT_VALIDATION_TYPE.CARRIER,LOOKUP_TYPE_CARRIER_LOOKUP)}} onClickHide={true} title={<span>Validate Numbers <sup><span style={{ background: "red", padding: "4px", borderRadius: "12px", color: "#fff", fontSize: "9px" }}>New</span></sup></span>} icon={IconList.callIconv3} />
                                            }
                                            { (Utils.getAccountData('carrierLookup')) &&
                                                <GlobalDropDownItem onClick={()=>{carrierLookupVerifyContact(CONTACT_VALIDATION_TYPE.VERIFY_EMAIL,LOOKUP_TYPE_EMAIL_LOOKUP)}} onClickHide={true} title={<span>Validate Emails <sup><span style={{ background: "red", padding: "4px", borderRadius: "12px", color: "#fff", fontSize: "9px" }}>New</span></sup></span>} icon={IconList.callIconv3} />
                                            }
                                            { (Utils.getAccountData('carrierLookup')) &&
                                                <GlobalDropDownItem onClick={()=>{carrierLookupVerifyContact(CONTACT_VALIDATION_TYPE.CARRIER,LOOKUP_TYPE_DNC_LOOKUP)}} onClickHide={true} title={<span>Check DNC Status <sup><span style={{ background: "red", padding: "4px", borderRadius: "12px", color: "#fff", fontSize: "9px" }}>New</span></sup></span>} icon={IconList.callIconv3} />
                                            }
                                            <GlobalDropDownItem onClickHide={true} onClick={() => removeBulkContacts()} title='Delete Selected Contacts' icon={IconList.deleteSelectedContactSVG} />
                                        </GlobalDropdown>
                                        {/* <!-- Dropdown Structure --> */}

                                    </li>


                                    <li className="add-contact contactlist-path-svg">
                                        <a className="dropdown-trigger btn accent--bg--text--color" href="#!" onClick={(e) => openAddContactModal(e)}>
                                            {IconList.plusIconContact}
                                            <span className="contact-right-drop">Contact</span>
                                        </a>
                                    </li>
                                    <li className="add-contact">
                                        <Link className="dropdown-trigger btn accent--bg--text--color" to={'/contacts-import'} >
                                            {IconList.importIcon}
                                            <span className="contact-right-drop">Import Contacts</span>
                                        </Link>
                                    </li>

                                </ul>

                            </div>
                            <div className="contact-top-filter-right firstOne">
                                <ul>
                                    {!Utils.getAccountData('userIsAgent') && 
                                    <li className="filter-onwer input-field secondItem">
                                        <div className="select-wrapper">
                                            {subUserList !== undefined &&
                                                // <DropdownHelper
                                                //     datalist={subUserList}
                                                //     noneFieldRequired={false}
                                                //     noneFieldTitle="Select Owner"
                                                //     className=""
                                                //     mapping={{ title: "full_name" }}
                                                //     menuItemClassName=""
                                                //     updateSelectedData={props.storeContactListSelectedSubUser}
                                                //     selectedValue={props.contactListSelectedSubUser}
                                                //     updateDatatable={updateDatatable}
                                                //     dropdownHeader={'ownerChange'}
                                                //     parentDivClassName="ppc__select_owner"
                                                //     multipleSelect={true}
                                                //     selectAllFieldRequired={true}
                                                // />
                                                <GlobalUserListDropdown
                                                    userList={subUserList}
                                                    handleSelect = {(value, remove) => handleSelectUser(value, remove)}
                                                    selected = {selectedUsers}
                                                    selectedId = {selectedIds}
                                                    reorganized={true}
                                                    valueField={'id'}
                                                    labelField={'full_name'}
                                                    textLength={16}
                                                    height={'42px'}
                                                    placeholder={'Filter by lead owner'}
                                                />
                                            }
                                        </div>
                                    </li>
                                    }

                                    <li className="search-data-table secondItem">
                                        <div className="body-search">
                                            {/* <!-- Topbar Search --> */}
                                            <form action="#">
                                                <GlobalSearchBox
                                                    type="search"
                                                    value={props.contactListSearchTextInTop}
                                                    placeholder="Search contacts"
                                                    onChange={handleChangeSearchText}
                                                />
                                            </form>
                                        </div>
                                    </li>


                                    <li className="create-list contactlist-path-svg">
                                        <a className="dropdown-trigger btn accent--bg--text--color " href="#!" data-target="dropdown3" onClick={(event) => popupList(event, true)}>
                                            {IconList.createListIcon}
                                            <span className="contact-right-drop">Create List</span>
                                        </a>
                                    </li>


                                    <li className="my-list create-list">
                                        <PopperHelperNormal
                                            buttonText={"My Lists"}
                                            mapping={{ id: "id", title: "title" }}
                                            datalist={savedContactList}
                                            onSubmit={actionOnList}
                                            selectedList={selectedContactList}
                                            checkBoxNeeded={true}
                                            buttonColumnIcon="menuIconMyList"
                                            from="my-lists"
                                            removeOption={true}
                                            removeSuccess={removeListCallBack}
                                            menuItemClassName={'my-list-menuitem'}
                                        />
                                        {/* <!-- Dropdown Structure --> */}
                                    </li>
                                    <li className="colums-filter omitlist-filter">
                                        <PopperHelper
                                            buttonText="Columns"
                                            buttonColumnIcon="columnsIcon"
                                            datalist={columnList}
                                            setOmitList={updateOmitList}
                                            omitList={contactListSelectedOmitData}
                                            buttonClassName="accent--bg--text--color" />
                                    </li>
                                    {/* } */}
                                    <li className="colums-filter" onClick={() => { if (!loadDropdownItems) { getDropdownItems(); } setFilterShow(!filterShow) }}>
                                        <div className="filter-icon-contact-list filter-icon-contact-list___alt">
                                            <button className="MuiButtonBase-root MuiButton-root MuiButton-text accent--bg--text--color" tabIndex="0" type="button" aria-haspopup="true"><span className="MuiButton-label">
                                                &nbsp;
                                                {filterShow && IconList.filterAltFilledSVG}
                                                {!filterShow && IconList.filterAltOutlineSVG}
                                            </span>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End contact top row --> */}

                {/* <!-- all select tags --> */}
                {filterShow &&

                    showFilterOptions()

                }
                {/* <!-- all select tags --> */}

                {/* <!-- start datatable row --> */}
                {/* <div className="datatable-area" style={{maxWidth:calcWidth + 'px'}} > */}
                <div className="datatable-area contact-datatable-area" style={{ maxWidth: '100%' }} >
                    <div className="row datatable-scroll-row">
                        <div className="col s12 contact__list_table_wrapper">
                            <div className={`top-scroll-bar awesome__scroll_bar `} ref={upperScrollRef} onScroll={onUpperScroll} id="TopScroll">
                                <div className="scroll-div1">
                                </div>
                            </div>
                            <div className={`bottom-scroll-bar awesome__scroll_bar dataTable__wrapper updateDataTable__wrapper ${!props.contactListFirstLoading ? 'first-load-table' : ''}`} ref={bottomScrollRef} onScroll={onBottomScroll} id="BottomScroll">
                                {/* <div className="scroll-div2"> */}
                                {componentLoading ? !props.contactListFirstLoading ? <LinearProgress /> : ''
                                    : <p className="lead-count-in-contact-list justify-content-between">
                                        {selectedContactListTitle ? `List : ${selectedContactListTitle} - ` : ''}
                                        {(totalContacts <= props.contactListpageSize) ? `${totalContacts} Contacts  ` : `Showing ${contactlist.length} of ${totalContacts} contacts  `}
                                        <div className="d-flex">
                                            {clearListButton()}
                                        </div>
                                </p>}
                                <GlobalTable
                                    table_unique_id={'contact_table_search'}
                                    columns={updateTable ? columns : columns}
                                    data={contactlist}
                                    className="highlight block-ui-loader second-div-table"
                                    keyField={'id'}
                                    noDataComponent={(
                                        <span style={{ padding: 10, color: 'red' }} className="contact-list-empty-message-div">{emptyMessage}</span>
                                    )}
                                    progressPending={(componentLoading && props.contactListFirstLoading) ? true : false}
                                    defaultSortField="title"

                                    selectableRows={true}
                                    selectableRowsData={{
                                        selectableRowsNoSelectAll: componentLoading ? true : (contactlist.length === 0 ? true : false),
                                        selectableRowsHighlight: true,
                                        onSelectedRowsChange: (state) => {
                                            let contactIds = []
                                            state.selectedRows.forEach(element => {
                                                contactIds.push(element.id)
                                            });
                                            props.storeContactListSelectedContactIds(contactIds)
                                        }
                                    }}

                                    pagination={false}
                                    paginationData={{
                                        paginationTotalRows: totalContacts,
                                        paginationPerPage: itemPerPage,
                                        currentPage: props.contactListPage,
                                        totalPage: lastPage,
                                        onChangePage: onChangePage, //for pagination change
                                        onChangeRowsPerPage: onChangeRowsPerPage, //for per props.contactListPage change
                                        // currentPage: props.contactListPage,
                                        // paginationComponentOptions: {
                                        //     rowsPerPageText: 'Pages of '
                                        // }
                                    }}

                                    subHeader={false}
                                    subHeaderData={{
                                        subHeaderComponent: (
                                            <div className="top_search_field d-flex align-items-center" style={{ width: '100%' }}>
                                                <input className="" type="text" placeholder="Search Existing Contacts Name" />
                                                <span>
                                                    {IconList.search}
                                                </span>
                                            </div>
                                        )
                                    }}

                                    noHeader={false}
                                    noHeaderData={{
                                        fixedHeaderScrollHeight: scrollViewHeight
                                    }}
                                />
                                {componentLoading ? !props.contactListFirstLoading ? <LinearProgress /> : ''
                                    : <p className="lead-count-in-contact-list">
                                        {(totalContacts <= props.contactListpageSize) ? `${totalContacts} Contacts` : `Showing ${contactlist.length} of ${totalContacts} contacts`}
                                    </p>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End datatable row --> */}

                {/* <!-- pagenation row --> */}
                {totalContacts > 0 &&

                    <div className="row">
                        <div className="col l12">
                            <div className="pagenation-main-area">
                                <div className="pagenation-top">
                                    <form action="">

                                        <FormControl variant="filled" className={classes.formControl}>
                                            {/* <InputLabel id="demo-simple-select-filled-label">Size</InputLabel> */}
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                value={props.contactListpageSize}
                                                onChange={handlePageSizeChange}
                                            //   label="PageSize"
                                            >
                                                <MenuItem value={25}>25</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                                <MenuItem value={100}>100</MenuItem>
                                                <MenuItem value={250}>250</MenuItem>
                                                <MenuItem value={500}>500</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </form>
                                    <span className="contact-pagenat">Contact</span>
                                </div>
                                <div className="pagenation-middle">
                                    <ul className="pagination">
                                        <li className="arrow-left">
                                            <a href="#!" onClick={(event) => handlePaginationChange(event, previous_page)}>
                                                <i className="material-icons" style={previous_page == null ? { cursor: 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1' } : {}}>chevron_left</i>
                                            </a>
                                        </li>

                                        {/* <a 
                                                    href="#" onClick={(event) => handlePaginationChange(event, previous_page)}
                                                    style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                                                >
                                                    {icons.previous}
                                                </a> */}
                                        <li className="active">
                                            <div className="pagination--text" >
                                            <input className="contact-pagination-no" type="text" placeholder="Search Existing Contacts Name" 
                                                onChange={(event)=> handlePaginationChange(event,'')} value={props.contactListPage}/>
                                                
                                            </div>
                                        </li>
                                        <li className="waves-effect"><div className="pagination--text">/</div></li>
                                        <li className="waves-effect"><div className="pagination--text" >{lastPage}</div></li>
                                        <li className="arrow-left waves-effect">
                                            <a href="#!" onClick={(event) => handlePaginationChange(event, next_page)}>
                                                <i className="material-icons"
                                                    style={next_page == null ? { cursor: 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1' } : {}}>chevron_right</i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pagenation-bottom"></div>
                            </div>
                        </div>
                    </div>
                }
                {/* <!-- pagenation row --> */}
            </div>

            {/* </main> */}
        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        contactListParams: state.contactReducer.contactListParams,
        allContactList: state.contactReducer.allContactList,
        subUserList: state.contactReducer.subUserList,
        userTagList: state.contactReducer.userTagList,
        storedSourceList: state.contactReducer.sourceList,
        storedPipelineList: state.contactReducer.pipelineList,
        storedCampaignList: state.contactReducer.campaignList,
        contactListFirstLoading: state.contactReducer.contactListFirstLoading,
        contactListSelectedSource: state.contactReducer.contactListSelectedSource,
        contactListSearchTextInTop: state.contactReducer.contactListSearchTextInTop,
        contactListSelectedTag: state.contactReducer.contactListSelectedTag,
        contactListSelectedStage: state.contactReducer.contactListSelectedStage,
        contactListSelectedFile: state.contactReducer.contactListSelectedFile,
        contactListSelectedSubUser: state.contactReducer.contactListSelectedSubUser,
        contactListSelectedCampaign: state.contactReducer.contactListSelectedCampaign,
        contactListSelectedOperationList: state.contactReducer.contactListSelectedOperationList,
        contactListSelectedOmitList: state.contactReducer.contactListSelectedOmitList,
        contactListSelectedContactIds: state.contactReducer.contactListSelectedContactIds,
        contactListSelectedDateRangeInfo: state.contactReducer.contactListSelectedDateRangeInfo,
        contactListSelectedFromDate: state.contactReducer.contactListSelectedFromDate,
        contactListSelectedEndDate: state.contactReducer.contactListSelectedEndDate,
        contactListSelectedColumnFilterData: state.contactReducer.contactListSelectedColumnFilterData,
        contactListPage: state.contactReducer.contactListPage,
        contactListpageSize: state.contactReducer.contactListpageSize,
        contactListDatePickerActive: state.contactReducer.contactListDatePickerActive,
        contactListColumnFilterMultipleSelectedData: state.contactReducer.contactListColumnFilterMultipleSelectedData,
        contactListColumnFilterMultiSelectedDatalabel: state.contactReducer.contactListColumnFilterMultiSelectedDatalabel,
        contactListSelectedSavedContactList: state.contactReducer.contactListSelectedSavedContactList,
        contactListStageListForPipeline: state.contactReducer.contactListStageListForPipeline,
        tagListInfo: state.contactReducer.tagListInfo,
        userListInObject: state.contactReducer.userListInObject,
        contactSourceInObject: state.contactReducer.contactSourceInObject,
        pipelineListInObject: state.contactReducer.pipelineListInObject,
        carrierLookupPrices: state.contactReducer.carrierLookupPrices,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllContactList: (params, callback) => dispatch(getAllContacts(params, callback)),
        setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
        getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback)),
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
        storeUserTagList: (params, callback) => dispatch(setUserTagList(params, callback)),
        fetchUserSourceList: (params, callback) => dispatch(getUserSourceList(params, callback)),
        storeUserSourceList: (params, callback) => dispatch(setUserSourceList(params)),
        // fetchUserCustomFields: (params, callback) => dispatch(getUserCustomFieldList(params, callback)),
        fetchPipelineList: (params, callback) => dispatch(getPipelineList(params, callback)),
        fetchStoreCampaignList: (params, callback) => dispatch(fetchCampaignList(params, callback)),
        storeContactListGroup: (params, callback) => dispatch(storeContactListGroup(params, callback)),
        storeContactListFirstLoading: (params) => dispatch(contactListFirstLoading(params)),
        storeContactListSelectedSource: (params) => dispatch(storeContactListSelectedSource(params)),
        storeContactListSearchTextInTop: (params) => dispatch(storeContactListSearchTextInTop(params)),
        storeContactListSelectedTag: (params) => dispatch(storeContactListSelectedTag(params)),
        storeContactListSelectedStage: (params) => dispatch(storeContactListSelectedStage(params)),
        storeContactListSelectedFile: (params) => dispatch(storeContactListSelectedFile(params)),
        storeContactListSelectedSubUser: (params) => dispatch(storeContactListSelectedSubUser(params)),
        storeContactListSelectedCampaign: (params) => dispatch(storeContactListSelectedCampaign(params)),
        storeContactListSelectedOperationList: (params) => dispatch(storeContactListSelectedOperationList(params)),
        storeContactListSelectedOmitList: (params) => dispatch(storeContactListSelectedOmitList(params)),
        storeContactListSelectedContactIds: (params) => dispatch(storeContactListSelectedContactIds(params)),
        storeContactListSelectedDateRangeInfo: (params) => dispatch(storeContactListSelectedDateRangeInfo(params)),
        storeContactListSelectedFromDate: (params) => dispatch(storeContactListSelectedFromDate(params)),
        storeContactListSelectedEndDate: (params) => dispatch(storeContactListSelectedEndDate(params)),
        storeContactListSelectedColumnFilterData: (params) => dispatch(storeContactListSelectedColumnFilterData(params)),
        storeContactListSelectedPage: (params) => dispatch(storeContactListSelectedPage(params)),
        storeContactListSelectedPageSize: (params) => dispatch(storeContactListSelectedPageSize(params)),
        storeContactListSelectedDatePickerActive: (params) => dispatch(storeContactListSelectedDatePickerActive(params)),
        storeContactListMultipleSelectedData: (params) => dispatch(storeContactListMultipleSelectedData(params)),
        storeContactListMultipleSelectedDataLabel: (params) => dispatch(storeContactListMultipleSelectedDataLabel(params)),
        storeContactListStageListForPipeline: (params) => dispatch(storeContactListStageListForPipeline(params)),
        storeContactListSelectedSavedContactList: (params) => dispatch(storeContactListSelectedSavedContactList(params)),
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    };
}

export default withTransaction('contactmodule-contactlist','component')(connect(mapStateToProps, mapDispatchToProps)(Contactlist));