import {IconList} from "../../../constants/IconList";
import Loading from "../../../components/common/Loading";
import GlobalModal from "../../globals/Modal/GlobalModal";
import SimpleReactValidator from 'simple-react-validator';
import If, {Else} from 'if-else-react';
import {useEffect, useReducer, useRef, useState} from "react";
import {FormHelperText, NativeSelect} from "@material-ui/core";
import CustomSwitch from "../../globals/Switch/CustomSwitch";
import './modal_add_new_list.css';
import {callingSettingSubmit} from '../../../api/autoDialerApi'
import {getUserVirtualNumberList} from "../../../actions/contactAction";
import {connect, useSelector} from "react-redux";
import Utils from '../../../helpers/Utils';

const FIRST_WORLD = {
    title: '',
    actionIfNotAnswer: '0',
    fromNumberType: '1',
    fromNumberSelect: '',
    startFrom: '1',
    maxCallTime: 3600,
    ringTime: 20,
    voicemailMessage: '',
    textMessage: '',
    willRecordCall: false,
}
const ModalAddNewList = props => {
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate: {forceUpdate: () => forceUpdate(1)},
        className: 'text-danger'
    }));


    const [state, setState] = useState(FIRST_WORLD);
    const [loading, setLoading] = useState(false);
    const [virtualNumbers, setVirtualNumbers] = useState(props.virtualNumberList);

    useEffect(() => {
        if (props.virtualNumberList.length === 0) {
            props.getUserVirtualNumberList();
        }
    }, []);

    useEffect(() => {
        if (props.virtualNumberList.length) {
            setVirtualNumbers(props.virtualNumberList);
            let defaultNumber = props.virtualNumberList.find(number => number.is_default == 1);
            setState({...state, fromNumberSelect: defaultNumber.id});
        }
    }, [props.virtualNumberList])

    useEffect(() => {
        if (typeof props.editListData.id !== 'undefined') {
            setState({
                title: props.editListData.title,
                actionIfNotAnswer: props.editListData.action_if_not_answer,
                fromNumberType: props.editListData.from_number_type,
                fromNumberSelect: props.editListData.virtual_numbers,
                startFrom: props.editListData.call_from,
                maxCallTime: props.editListData.max_call_time,
                ringTime: props.editListData.ringing_time,
                voicemailMessage: props.editListData.voice_mail,
                textMessage: props.editListData.text_message,
                willRecordCall: props.editListData.will_record,
            });
        }
    }, [props.editListData]);

    const listAddSubmit = () => {
        if (validator.current.allValid()) {
            setLoading(true);
            callingSettingSubmit({
                'id': typeof props.editListData.id !== 'undefined' ? props.editListData.id : null,
                'title': state.title,
                'actionIfNotAnswer': state.actionIfNotAnswer,
                'fromNumberType': state.fromNumberType,
                'fromNumberSelect': state.fromNumberSelect,
                'startFrom': state.startFrom,
                'maxCallTime': state.maxCallTime,
                'ringTime': state.ringTime,
                'voicemailMessage': state.voicemailMessage,
                'textMessage': state.textMessage,
                'willRecordCall': state.willRecordCall,
            }).then(response => {
                setLoading(false);
                if (response.data.status) {
                    props.fetchDialingList();
                    props.onClose();
                    window.showNotification('SUCCESS', response.data.message);
                } else {
                    window.showNotification('ERROR', response.data.message);
                }
            }).catch(error => {
                setLoading(false);
                Utils.handleException(error);
            });
        } else {
            console.log('not valid');
            validator.current.showMessages();
        }
    }

    const renderVirtualNumber = () => {
        return virtualNumbers.map(virtualNumber => {
            return (<option value={virtualNumber.id} selected={virtualNumber.is_default == 1}>{virtualNumber.virtual_number}</option>);
        })
    }

    return (
        <GlobalModal open={props.isOpen} onClose={() => props.onClose()} title={'Add new list'} buttonText={"Save List"} buttonIcon={IconList.copyIcon} onSubmit={listAddSubmit}>
            <div className='list__setting_container'>
                <If condition={loading}>
                    <Loading size={50}/>
                <Else />
                    <form action="/">
                        <div className="row mb-0">
                            <div className="input-field col s12">
                                <label htmlFor="list_title">List Title</label>
                                <input placeholder="Enter Title" id="list_title" type="text" value={state.title} onChange={e => setState({...state, title: e.target.value})}/>
                                {validator.current.message("list_title", state.title, 'required|string')}
                            </div>
                        </div>
                        <div className="row mb-0">
                            <div className="input-field col s6">
                                <label htmlFor="virtual_number">Call from which number?</label>
                                <NativeSelect
                                    className="input-select"
                                    value={state.fromNumberType}
                                    inputProps={{ 'aria-label': 'selectName' }}
                                    onChange={(e) => setState({...state, fromNumberType: e.target.value})}
                                >
                                    <option value="1">Previous Contacted</option>
                                    <option value="2">Select a number</option>
                                    <option value="3">Random</option>
                                </NativeSelect>
                            </div>
                            <div className="input-field col s6">
                                <label htmlFor="campaign-link">Call Start from </label>
                                <NativeSelect
                                    defaultValue={'1'}
                                    className="input-select"
                                    value={state.startFrom}
                                    inputProps={{ 'aria-label': 'selectName' }}
                                    onChange={e => setState({...state, startFrom: e.target.value})}
                                >
                                    <option value="1">Call where left</option>
                                    <option value="2">Top to bottom</option>
                                </NativeSelect>
                            </div>
                        </div>
                        {state.fromNumberType == 2 &&
                        <div className="row mb-0">
                            <div className="input-field col s6">
                                <label htmlFor="virtual_number">Select a virtual number</label>
                                <NativeSelect
                                    className="input-select"
                                    value={state.fromNumberSelect}
                                    inputProps={{ 'aria-label': 'selectName' }}
                                    onChange={(e) => setState({...state, fromNumberSelect: e.target.value})}
                                >
                                    { renderVirtualNumber() }
                                </NativeSelect>
                            </div>
                        </div>
                        }
                        <div className="row mb-0">
                            <div className="input-field col s6">
                                <label htmlFor="from-title">Max Call Time</label>
                                <input placeholder="Enter time in second" type="number" value={state.maxCallTime} onChange={e => setState({...state, maxCallTime:e.target.value})}/>
                                <FormHelperText id="standard-weight-helper-text">Time in second</FormHelperText>
                                {validator.current.message("call_time", state.maxCallTime, 'required|integer')}
                            </div>
                            <div className="input-field col s6">
                                <label htmlFor="from-title">Ringing Time</label>
                                <input placeholder="Time in second" type="number" value={state.ringTime} onChange={e => setState({...state, ringTime: e.target.value})}/>
                                <FormHelperText id="standard-weight-helper-text">Hangup call if not received after this time</FormHelperText>
                                {validator.current.message("ringing_time", state.ringTime, 'required|integer')}
                            </div>
                        </div>
                        <div className="row mb-0">
                            <div className="input-field col s6">
                                <label htmlFor="virtual_number">Action if not pickup</label>
                                <NativeSelect
                                    value={state.actionIfNotAnswer}
                                    onChange={e => setState({...state, actionIfNotAnswer:e.target.value})}
                                    className="input-select"
                                    inputProps={{ 'aria-label': 'selectName' }}
                                >
                                    <option value="0">Do Nothing</option>
                                    {/*<option value="1">Drop Voicemail</option>*/}
                                    <option value="2">Send SMS</option>
                                    {/*<option value="4">Both</option>*/}
                                </NativeSelect>
                            </div>
                            <div className="input-field col s6">
                                <label className="d-flex align-items-center mt-4 pt-2">
                                    <span className="pr-2">Record call</span>
                                    <CustomSwitch status={state.willRecordCall} brandColor={"#f94f72"} onChange={() => setState({...state, willRecordCall: !state.willRecordCall})}/>
                                </label>
                            </div>
                        </div>
                        <div className="row mb-0">
                            {(state.actionIfNotAnswer == 1 || state.actionIfNotAnswer == 4) &&
                                <div className='input-field col s12'>
                                    <label htmlFor="voice-mail">Voice Mail Message</label>
                                    <textarea id="" cols="30" rows="10" value={state.voicemailMessage} onChange={e => setState({...state, voicemailMessage: e.target.value})} placeholder="Enter voicemail message"/>
                                </div>
                            }
                            {(state.actionIfNotAnswer == 2 || state.actionIfNotAnswer == 4) &&
                                <div className='input-field col s12'>
                                    <label htmlFor="voice-mail">Text message</label>
                                    <textarea id="" cols="30" rows="10" placeholder="Enter text message" value={state.textMessage} onChange={e => setState({...state, textMessage: e.target.value})}/>
                                </div>
                            }
                        </div>
                    </form>
                </If>
            </div>
        </GlobalModal>
    );
}

const mapStateToProps = state => {
    return {
        virtualNumberList: state.virtualNumberReducer.virtualNumberList,
    };
};

const mapDispatchToProps = dispatch => ({
    getUserVirtualNumberList: () => dispatch(getUserVirtualNumberList())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddNewList);