import React, {useContext, useEffect, useState} from "react";
import Styles from "./Verify.module.css";
import Utils from "../../helpers/Utils";
import FileUploader from "./FileUploader";
import {carrierLookupCleanDataBulkApi, carrierLookupCleanDataSingleApi} from "../../api/cleanDataApi";
import NewConfirmAlert from "../common/new-alert/NewConfirmAlert";
import {getCarrierLookupCreditSettingsApi} from "../../api/contactApi";
import {
    CONTACT_VALIDATION_TYPE,
    LOOKUP_PROVIDER_TYPE_EMAIL,
    LOOKUP_PROVIDER_TYPE_NUMBER, LOOKUP_TYPE_CARRIER_LOOKUP, LOOKUP_TYPE_EMAIL_LOOKUP
} from "../../constants/CoreConstants";
import {DataCleanContext} from "./StateProvider";
import ActionTypes from "./ActionTypes";
import ValidationResponse from "./ValidationResponse";
import {makeStyles} from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const Verify = () => {
    const classes = useStyles();
    const {state, dispatch} = useContext(DataCleanContext);
    const creditSettingType = {"CALLER_NAME" : 63, "CARRIER" : 64, "VERIFY_EMAIL":65};
    const defaultCategoryName = {VERIFY_EMAIL : "verify-email", CALLER_NAME : "caller-name",CARRIER: "carrier" };
    const [emailAddress, setEmailAddress] = useState("");
    const [emailAddresses, setEmailAddresses] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(!state.carrierLookupPrices){
            getCarrierLookupCreditSettingsApi()
                .then((response) => {
                    if (response.data && response.data.status === "success") {
                        if (response.data && response.data.data) {
                            dispatch({
                                type: ActionTypes.UPDATE_STATE,
                                payload: {
                                    carrierLookupPrices: response.data.data
                                }
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    });
    const submitSingleVerification = (validateType,providerType) => {

        let validationData = {};

        if (providerType === LOOKUP_PROVIDER_TYPE_EMAIL){
            if (!emailAddress){
                window.showNotification("error", "Email address is required");
                return;
            }

            if (!Utils.validateEmail(emailAddress)){
                window.showNotification("error", "Invalid email address");
                return;
            }

            validationData = {
                "lookupType": LOOKUP_TYPE_EMAIL_LOOKUP,
                "contact":{
                    "email": emailAddress
                },
                "categoryName": CONTACT_VALIDATION_TYPE.VERIFY_EMAIL
            }

        }else if (providerType === LOOKUP_PROVIDER_TYPE_NUMBER){
            if (!phoneNumber){
                window.showNotification("error", "Phone number is required");
                return;
            }

            if (!Utils.validateMobileNumber(phoneNumber)){
                window.showNotification("error", "Invalid phone number");
                return;
            }

            validationData = {
                "lookupType": LOOKUP_TYPE_CARRIER_LOOKUP,
                "contact":{
                    "number": phoneNumber
                },
                "categoryName": CONTACT_VALIDATION_TYPE.CARRIER
            }
        }

        let price = state.carrierLookupPrices ? state.carrierLookupPrices.find(({ type }) => type === creditSettingType[validateType]) : null;

        NewConfirmAlert({
            onSubmit : () => {
                setLoading(true);
                carrierLookupCleanDataSingleApi(validationData)
                    .then((response)=>{
                        if (response){
                            if (response.success || (response.message && response.message.includes("Invalid number :"))){
                                ValidationResponse({
                                    response: response,
                                    validationData: validationData
                                });
                            }else {
                                window.showNotification("error", response.message ?? "Failed to validate");
                            }
                        }else {
                            window.showNotification("error", "Failed to validate");
                        }
                    }).catch((err)=>{
                    console.log(err.message)
                    window.showNotification("error", "Failed to validate");
                }).finally(()=>{
                    setLoading(false);
                });
            },
            title: `Validating each ${(providerType === LOOKUP_PROVIDER_TYPE_EMAIL ? "email" : "number")} will cost: $${(price ? price.value : 0)}`,
            description: "These credits will be deducted from your balance",
            cancelText: "Cancel",
            submitText: "Validate",
            width: "480px"
        });
    };

    const submitBulkVerification = (validateType,providerType) => {

        let validationData = {};

        if (providerType === LOOKUP_PROVIDER_TYPE_EMAIL){
            if (!emailAddresses && !emailAddresses.split("\n")[0]){
                window.showNotification("error", "Email address is required");
                return;
            }

            let contacts = emailAddresses.split("\n").map((email)=>({ email: email }));

            validationData = {
                "lookupType": LOOKUP_TYPE_EMAIL_LOOKUP,
                "contacts": contacts,
                "categoryName": CONTACT_VALIDATION_TYPE.VERIFY_EMAIL
            }

        }else if (providerType === LOOKUP_PROVIDER_TYPE_NUMBER){
            if (!phoneNumber && !phoneNumbers.split("\n")[0]){
                window.showNotification("error", "Phone number is required");
                return;
            }

            let contacts = phoneNumbers.split("\n").map((number)=>({ number: number }));

            validationData = {
                "lookupType": LOOKUP_TYPE_CARRIER_LOOKUP,
                "contacts": contacts,
                "categoryName": CONTACT_VALIDATION_TYPE.CARRIER
            }
        }

        let price = state.carrierLookupPrices ? state.carrierLookupPrices.find(({ type }) => type === creditSettingType[validateType]) : null;

        NewConfirmAlert({
            onSubmit : () => {
                try{
                    setLoading(true);
                    carrierLookupCleanDataBulkApi(validationData)
                        .then((response)=>{
                            if (response){
                                if (response.success) {
                                    if (response.status) {
                                        window.showNotification("success", "Request has been received. Check Reports(Single/Multiple) for results");
                                    }else {
                                        window.showNotification("error", "Failed to validate");
                                    }
                                }else {
                                    window.showNotification("error", response.message ?? "Failed to validate");
                                }
                            }else {
                                window.showNotification("error", "Failed to validate");
                            }
                        }).catch((err)=>{
                        console.log(err.message)
                        window.showNotification("error", "Failed to validate");
                    }).finally(()=>{
                        setLoading(false);
                    });
                }catch (err){
                    window.showNotification("error", "Failed to validate");
                    console.log(err);
                }
            },
            title: `Validating each ${(providerType === LOOKUP_PROVIDER_TYPE_EMAIL ? "email" : "number")} will cost: $${(price ? price.value : 0)}`,
            description: "These credits will be deducted from your balance",
            cancelText: "Cancel",
            submitText: "Validate",
            width: "480px"
        });
    };

    return (
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <h1 className={Styles.pageTitle}>Hey {Utils.getAccountData("fullName")}, your data is dirty. Let's clean it up!</h1>
                <p className={Styles.pageSubTitle}>Bad data leads to bad results. Don't worry, we've got you covered.</p>
            </div>
            <div className={Styles.pageBody}>
                <div className={Styles.pageMainRow}>
                    <div className={`${Styles.pageMainCol} ${Styles.emailSection}`}>
                        <div className={Styles.form}>
                            <div className={Styles.formGroup}>
                                <label>Verify Single Email Address</label>
                                <div className={Styles.formControl}>
                                    <input className={Styles.formInput} value={emailAddress} onChange={(e)=>{
                                        setEmailAddress(e.target.value);
                                    }} placeholder="Email Address"/>
                                    <button className={Styles.verifyButton} onClick={()=>{
                                        submitSingleVerification(CONTACT_VALIDATION_TYPE.VERIFY_EMAIL,LOOKUP_PROVIDER_TYPE_EMAIL)
                                    }}>Verify</button>
                                </div>
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Verify Multiple Email Addresses</label>
                                <div className={`${Styles.formControl} ${Styles.flexAlignEnd}`}>
                                    <textarea value={emailAddresses} onChange={(e)=>{
                                        setEmailAddresses(e.target.value);
                                    }} className={Styles.formInputTextArea} placeholder={
                                        "Copy/Paste your emails here."
                                        + "\n"
                                        + "1 email address per line."
                                    }/>
                                    <button className={Styles.verifyButton} onClick={()=>{
                                        submitBulkVerification(CONTACT_VALIDATION_TYPE.VERIFY_EMAIL,LOOKUP_PROVIDER_TYPE_EMAIL)
                                    }}>Verify</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.vl}></div>
                    <div className={`${Styles.pageMainCol}  ${Styles.phoneSection}`}>
                        <div className={Styles.form}>
                            <div className={Styles.formGroup}>
                                <label>Verify Single Phone Number</label>
                                <div className={Styles.formControl}>
                                    <input className={Styles.formInput} value={phoneNumber} onChange={(e)=>{
                                        setPhoneNumber(e.target.value);
                                    }} placeholder="Phone Number"/>
                                    <button className={Styles.verifyButton} onClick={()=>{
                                        submitSingleVerification(CONTACT_VALIDATION_TYPE.CARRIER,LOOKUP_PROVIDER_TYPE_NUMBER)
                                    }}>Verify</button>
                                </div>
                            </div>
                            <div className={Styles.formGroup}>
                                <label>Verify Multiple Phone Numbers</label>
                                <div className={`${Styles.formControl} ${Styles.flexAlignEnd}`}>
                                    <textarea value={phoneNumbers} onChange={(e)=>{
                                        setPhoneNumbers(e.target.value)
                                    }} className={Styles.formInputTextArea} placeholder={
                                        "Copy/Paste your phone numbers here."
                                        + "\n"
                                        + "1 phone number per line."
                                    }/>
                                    <button className={Styles.verifyButton} onClick={()=>{
                                        submitBulkVerification(CONTACT_VALIDATION_TYPE.CARRIER,LOOKUP_PROVIDER_TYPE_NUMBER)
                                    }}>Verify</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FileUploader />
            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default Verify;