import { Skeleton } from '@material-ui/lab'
import React from 'react'

const listStyle = {
    root : {
        width : "100%",
        minHeight : "142px",
        backgroundColor : "rgb(250, 250, 250)",
        color : "rgba(0, 0, 0, 0.87)",
        border: "1px solid rgba(60,126,243,.1)",
        borderRadius: "10px",
        display : "flex",
        alignItems : "center",
        marginBottom : "10px"
    }
}

const SingleSkeleton = () => {
    return (
        <div style={listStyle.root}>
            <div>
                <Skeleton className="ml-3" width={20}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={140}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5 d-inline-flex">
                <Skeleton className="ml-3" width={30} height={30} variant="circle"/>
                <Skeleton className="ml-3" width={30} height={30} variant="circle"/>
                <Skeleton className="ml-3" width={30} height={30} variant="circle"/>
                <Skeleton className="ml-3" width={30} height={30} variant="circle"/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
            <div className="ml-5">
                <Skeleton className="ml-3" width={120}/>
                <Skeleton className="ml-3" width={150}/>
            </div>
        </div>
    )
}

export const ContactListSkeleton = () => {
    return (
        <div className="d-flex flex-column w-100">
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
            <SingleSkeleton/>
        </div>
    )
}
