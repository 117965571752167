import React, {useContext, useEffect, useState} from "react";
import Styles from "./FileReportDetails.module.css"
import Icons from "./Icons";
import {
    FormControl,
    Select,
    InputBase,
    withStyles,
    MenuItem,
    Checkbox,
    Button, CircularProgress
} from "@material-ui/core";
import {ArrowBack, InfoRounded, KeyboardArrowDown} from "@material-ui/icons";
import ActionTypes from "../ActionTypes";
import {STATUS, TAB_CLEAN_LIST} from "../Constants";
import {DataCleanContext} from "../StateProvider";
import GlobalDropdown, {GlobalDropDownButton, GlobalDropDownItem} from "../../globals/Dropdown/GlobalDropdown";
import {IconList} from "../../globals/IconList";
import Pagination from "./Pagination";
import {cleanContactsActionsApi, cleanDataFilesDetailsApi} from "../../../api/cleanDataApi";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import ModalAssignToList from "./actions/ModalAssignToList";
import ModalAssignToCampaign from "./actions/ModalAssignToCampaign";
import ModalAssignToTag from "./actions/ModalAssignToTag";
import ModalListForStageAdd from "./actions/ModalListForStageAdd";
import NewConfirmAlert from "../../common/new-alert/NewConfirmAlert";
import ValidationResponse from "./ValidationResponse";
import ModalExportFile from "./actions/ModalExportFile";

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        fontSize: "14px !important",
        borderRadius: "4px !important",
        position: "relative",
        color: "#3a414a !important",
        border: "1px solid #ced4da !important",
        padding: "10px 26px 10px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4
        },
        backgroundColor: "#ffffff"
    },
}))(InputBase);

const FileReportDetails = () => {
    const {state, dispatch} = useContext(DataCleanContext);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loading,setLoading] = useState(false);
    const [fileContacts, setFileContacts] = useState([]);
    const [filter, setFilter] = useState({
        invalidNumbers: false,
        validNumbers: false,
        invalidEmails: false,
        validEmails: false
    });
    const [selectedFileContactIds, setSelectedFileContactIds] = useState([]);
    const [showListModal, setShowListModal] = useState(false);
    const [showAddToCampaignModal, setShowAddToCampaignModal] = useState(false);
    const [showTagModal, setShowTagModal] = useState(false);
    const [openAddStageToContactModal, setOpenAddStageToContactModal] = useState(false);
    const [openExportFileModal, setOpenExportFileModal] = useState(false);

    useEffect(()=>{
        if (!state.cleanDataRequestId && !state.cleanDataRequest){
            window.showNotification("error", "No clean data file found");
            dispatch({
                type: ActionTypes.UPDATE_STATE,
                payload: {
                    activeTab: TAB_CLEAN_LIST.REPORTS
                }
            });
            return;
        }

        if (page !== ""){
            setLoading(true);
            cleanDataFilesDetailsApi({
                page: page,
                limit: limit,
                totalPage: totalPage,
                cleanDataRequestId: state.cleanDataRequestId,
                filter: (Object.values(filter).every(value => value === false) ? null : filter)
            })
                .then((res)=>{
                    res = res.data;
                    if (res.success){
                        setFileContacts(res.data);
                        setTotalPage(res.totalPage);
                    }
                }).finally(()=>{
                setLoading(false);
            });
        }
    },[page, limit, state.cleanDataRequestId, filter]);

    const handleFilterChange = (name,value) => {
        setFilter({ ...filter, [name]: value });
    }

    const renderFileUrl = (file) => {
        if (file && file.aws3_info){
            if(file.aws3_info && file.aws3_info ===  "\\"){
                return "";
            }else{
                let aws3Info = file.aws3_info.replace(/\\/g, '').replace(/&quot;/g, '\"');
                try{
                    aws3Info = JSON.parse(aws3Info).fileUrl
                }catch(error){
                    console.log(error)
                }
                return  aws3Info;
            }
        }
        return "";
    }

    const renderSearchType = (file) => {
        if (file && file.carrier_infos){
            if(file.carrier_infos && file.carrier_infos ===  "\\"){
                return "[]";
            }else{
                try{
                    let carrierInfos = file.carrier_infos.replace(/\\/g, '').replace(/&quot;/g, '\"');

                    carrierInfos = JSON.parse(carrierInfos);

                    return `[${Object.values(carrierInfos).join(",")}]`;
                }catch(error){
                    console.log(error);
                }
                return "[]";
            }
        }
        return "[]";
    }

    const getChipColor = (file) => {
        switch (file.status){
            case STATUS.ACTIVE:
                return Styles.active;
            case STATUS.PROCESSING:
                return Styles.processing;
            case STATUS.QUEUED:
                return Styles.queued;
            case STATUS.COMPLETE:
                return Styles.complete;
            case STATUS.FAILED:
                return Styles.failed;
            default:
                return Styles.default;
        }
    }

    const handleAddAllContactToCRM = () => {
        NewConfirmAlert({
            onSubmit : () => {
                const payload = {
                    cleanContactIds: [],
                    filterType: "ALL",
                    action: "ADD_TO_CRM",
                };

                if (filter){
                    if (filter.invalidNumbers){
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (filter.validNumbers){
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (filter.invalidEmails){
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (filter.validEmails){
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload).then((res)=>{
                    if (res && res.success){
                        window.showNotification("success", res.message);
                    }else {
                        window.showNotification("error", "Something went wrong try again later");
                    }
                }).finally(()=>{

                });
            },
            title: "Are your sure?",
            description: "You are about to add all contacts to CRM",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px"
        });
    }

    const handleAddSelectedContactToCRM = () => {
        NewConfirmAlert({
            onSubmit : () => {
                const payload = {
                    cleanContactIds: selectedFileContactIds,
                    filterType: "SEGMENT",
                    action: "ADD_TO_CRM",
                };

                if (filter){
                    if (filter.invalidNumbers){
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (filter.validNumbers){
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (filter.invalidEmails){
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (filter.validEmails){
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload).then((res)=>{
                    if (res && res.success){
                        window.showNotification("success", res.message);
                    }else {
                        window.showNotification("error", "Something went wrong try again later");
                    }
                }).finally(()=>{

                });
            },
            title: "Are your sure?",
            description: "You are about to add selected contacts to CRM",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px"
        });
    }

    const handleDeleteSelectedContactToCRM = () => {
        NewConfirmAlert({
            onSubmit : () => {
                const payload = {
                    cleanContactIds: selectedFileContactIds,
                    filterType: "SEGMENT",
                    action: "DELETE_CONTACT",
                };

                if (filter){
                    if (filter.invalidNumbers){
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (filter.validNumbers){
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (filter.invalidEmails){
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (filter.validEmails){
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload).then((res)=>{
                    if (res && res.success){
                        window.showNotification("success", res.message);
                    }else {
                        window.showNotification("error", "Something went wrong try again later");
                    }
                }).finally(()=>{

                });
            },
            title: "Are your sure?",
            description: "You are about to delete selected contacts",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px"
        });
    }

    return(
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <div className={Styles.pageHeaderTitle}>
                    <div>
                        {Icons.docFiles}
                    </div>
                    <div>
                        <h1 className={Styles.pageMainTitle}>File Validation Details</h1>
                        <p className={Styles.pageMainSubtitle}>Check the status of contacts of the files you've uploaded for verifications</p>
                    </div>
                </div>
                <div className={Styles.pageHeaderAction}>
                    <FormControl className={Styles.formControl}>
                        <Select
                            labelId="per-page-select-label"
                            id="per-page-select"
                            name="perPage"
                            value={limit}
                            displayEmpty
                            onChange={(e)=>{
                                setLimit(e.target.value);
                            }}
                            input={<BootstrapInput/>}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value={""} disabled># of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={5}>5 of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={10}>10 of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={25}>25 of Results</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={50}>50 of Results</MenuItem>
                        </Select>
                    </FormControl>
                    <Button
                        className={Styles.backButton}
                        startIcon={<ArrowBack/>}
                        variant="contained"
                        color="primary"
                        onClick={()=>{
                        dispatch({
                            type: ActionTypes.UPDATE_STATE,
                            payload: {
                                activeTab: TAB_CLEAN_LIST.REPORTS
                            }
                        });
                    }}>Back</Button>
                </div>
            </div>
            <div className={Styles.pageBody}>
                <table>
                    <thead className={Styles.tableHead}>
                    <tr>
                        <th style={{ width: "30%" }}>
                            File Name
                        </th>
                        <th className={Styles.textAlignCenter}>
                            Date Uploaded
                        </th>
                        <th className={Styles.textAlignCenter}>
                            Total Records
                        </th>
                        <th className={Styles.textAlignCenter}>
                            Total Cost
                        </th>
                        <th className={Styles.textAlignCenter}>
                            Status
                        </th>
                        <th className={Styles.textAlignCenter}>
                            Failed Reason
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ width: "30%" }}>
                            <ul>
                                <ul>
                                    <li className={Styles.fileTitle}>{state.cleanDataRequest.file_name_original}</li>
                                    <li className={Styles.validationType}><span className={Styles.validationTypeLabel}>Search type:</span> {renderSearchType(state.cleanDataRequest)}</li>
                                    <li className={Styles.fileId}>ID: {state.cleanDataRequest.file_name ? state.cleanDataRequest.file_name.split(".csv")[0] : ""}</li>
                                </ul>
                            </ul>
                        </td>
                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                            {state.cleanDataRequest.created_at}
                        </td>
                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                            {state.cleanDataRequest.total_record}
                        </td>
                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                            ${state.cleanDataRequest.total_cost}
                        </td>
                        <td className={Styles.textAlignCenter}>
                            <span className={`${Styles.statusChip} ${getChipColor(state.cleanDataRequest)}`}>{state.cleanDataRequest.status}</span>
                        </td>
                        <td className={Styles.textAlignCenter}>
                            {
                                state.cleanDataRequest.failed_reason &&
                                <BootstrapTooltip arrow title={<span>{state.cleanDataRequest.failed_reason}</span>}>
                                    <div className={`${Styles.truncate} ${Styles.failedReason}`}>
                                        {state.cleanDataRequest.failed_reason}
                                    </div>
                                </BootstrapTooltip>
                            }
                        </td>
                        <td>
                            <div className={Styles.fileActions}>
                                <span className={`${Styles.viewDetails} ${Styles.cursorPointer}`}
                                      onClick={()=>{
                                          dispatch({
                                              type: ActionTypes.UPDATE_STATE,
                                              payload: {
                                                  activeTab: TAB_CLEAN_LIST.REPORTS
                                              }
                                          });
                                      }}>Hide Details Report</span>
                                {
                                    renderFileUrl(state.cleanDataRequest) !== "" ? <a className={Styles.downloadButton} target="_blank" href={renderFileUrl(state.cleanDataRequest)}>Download</a> : <div/>
                                }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={7}>
                            <div className={Styles.detailsPane}>
                                <div className={Styles.detailsPaneHeader}>
                                    <div className={Styles.detailsPaneHeaderLeft}>
                                        <span className={Styles.fontBold}>Validation Results:</span>
                                        <span>Total Contacts - {state.cleanDataRequest.total_record}</span>
                                    </div>
                                    <div className={Styles.detailsPaneHeaderRight}>
                                        <div className={Styles.filterWrapper}>
                                            <span className={Styles.fontBold}>Filter Results: </span>
                                            <div className={Styles.dFlexAlignCenter}>
                                                <Checkbox
                                                    checked={filter.validEmails}
                                                    onClick={()=>{
                                                        handleFilterChange("validEmails",!filter.validEmails);
                                                    }}
                                                    color="primary"
                                                    inputProps={{
                                                        "aria-label": "secondary checkbox"
                                                    }}
                                                />
                                                Valid Emails
                                            </div>
                                            <div className={Styles.dFlexAlignCenter}>
                                                <Checkbox
                                                    checked={filter.invalidEmails}
                                                    onClick={()=>{
                                                        handleFilterChange("invalidEmails",!filter.invalidEmails);
                                                    }}
                                                    color="primary"
                                                    inputProps={{
                                                        "aria-label": "secondary checkbox"
                                                    }}
                                                />
                                                Invalid Emails
                                            </div>
                                            <div className={Styles.dFlexAlignCenter}>
                                                <Checkbox
                                                    checked={filter.validNumbers}
                                                    onClick={()=>{
                                                        handleFilterChange("validNumbers",!filter.validNumbers);
                                                    }}
                                                    color="primary"
                                                    inputProps={{
                                                        "aria-label": "secondary checkbox"
                                                    }}
                                                />
                                                Valid Numbers
                                            </div>
                                            <div className={Styles.dFlexAlignCenter}>
                                                <Checkbox
                                                    checked={filter.invalidNumbers}
                                                    onClick={()=>{
                                                        handleFilterChange("invalidNumbers",!filter.invalidNumbers);
                                                    }}
                                                    color="primary"
                                                    inputProps={{
                                                        "aria-label": "secondary checkbox"
                                                    }}
                                                />
                                                Invalid Numbers
                                            </div>
                                        </div>
                                        <div>
                                            <GlobalDropdown className="add__action_dropdown" placement="bottomLeft">
                                                <GlobalDropDownButton>
                                                    <Button
                                                        className="list__settings_btn"
                                                        variant="contained"
                                                        color="primary"
                                                        endIcon={<KeyboardArrowDown/>}
                                                    >
                                                        Actions
                                                    </Button>
                                                </GlobalDropDownButton>
                                                <GlobalDropDownItem onClick={handleAddAllContactToCRM} title="Add All Contact to CRM" onClickHide={true} icon={IconList.addToContactSVG} />
                                                <GlobalDropDownItem onClick={handleAddSelectedContactToCRM} title="Add Selected Contact to CRM" onClickHide={true} icon={IconList.addToContactSVG} />
                                                <GlobalDropDownItem onClick={() => {setShowListModal(true)}} title="Add To List" onClickHide={true} icon={IconList.addToListSVG} />
                                                <GlobalDropDownItem onClick={() => {setShowAddToCampaignModal(true)}} title="Add To Campaign" onClickHide={true} icon={IconList.addToContactSVG} />
                                                <GlobalDropDownItem onClick={() => {setShowTagModal(true)}} title="Assign Tag" onClickHide={true} icon={IconList.addTagSVG} />
                                                <GlobalDropDownItem onClick={() => {setOpenAddStageToContactModal(true)}} title="Add to Pipeline Stage" onClickHide={true} icon={IconList.addToPipelineSVG} />
                                                <GlobalDropDownItem onClick={() => {setOpenExportFileModal(true)}} title="Export All Contacts" onClickHide={true} icon={IconList.exportContactSVG} />
                                                <GlobalDropDownItem onClick={() => {setOpenExportFileModal(true)}} title="Export Selected Contacts" onClickHide={true} icon={IconList.exportContactSVG} />
                                                <GlobalDropDownItem onClick={handleDeleteSelectedContactToCRM} title="Delete Selected Contacts" onClickHide={true} icon={IconList.deleteSelectedContactSVG} />
                                            </GlobalDropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className={Styles.detailsPaneBody}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className={Styles.dFlexAlignCenter}>
                                                        <Checkbox
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label": "secondary checkbox"
                                                            }}
                                                            checked={(fileContacts.length === selectedFileContactIds.length && selectedFileContactIds.length !== 0)}
                                                            indeterminate={(fileContacts.length > selectedFileContactIds.length && selectedFileContactIds.length !== 0)}
                                                            onClick={()=>{
                                                                if (fileContacts.length === selectedFileContactIds.length){
                                                                    setSelectedFileContactIds([]);
                                                                }else {
                                                                    setSelectedFileContactIds(fileContacts.map((fileContact)=>(fileContact.id)));
                                                                }
                                                            }}
                                                        />
                                                        Name
                                                    </div>
                                                </th>
                                                <th className={Styles.textAlignCenter}>Email</th>
                                                <th className={Styles.textAlignCenter}>Email Validation Status</th>
                                                <th className={Styles.textAlignCenter}>Number</th>
                                                <th className={Styles.textAlignCenter}>Number Validation Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            loading ? (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <div className={Styles.loaderWrapper}>
                                                            <CircularProgress />
                                                            <h5 className={Styles.marginTop}>Getting file contact list....</h5>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                fileContacts[0] ? (
                                                    fileContacts.map((fileContact,index)=>(
                                                        <tr>
                                                            <td>
                                                                <div className={Styles.dFlexAlignCenter}>
                                                                    <Checkbox
                                                                        color="primary"
                                                                        inputProps={{
                                                                            "aria-label": "secondary checkbox"
                                                                        }}
                                                                        checked={selectedFileContactIds.includes(fileContact.id)}
                                                                        onClick={()=>{
                                                                            if (selectedFileContactIds.includes(fileContact.id)){
                                                                                setSelectedFileContactIds(selectedFileContactIds.filter((selectedFileContactId)=>(selectedFileContactId !== fileContact.id)));
                                                                            }else {
                                                                                setSelectedFileContactIds([...selectedFileContactIds, fileContact.id]);
                                                                            }
                                                                        }}
                                                                    />
                                                                    {`${fileContact.first_name ?? ''} ${fileContact.last_name ?? ''}`}
                                                                </div>
                                                            </td>
                                                            <td className={Styles.textAlignCenter}>{fileContact.email}</td>
                                                            <td className={Styles.textAlignCenter}>
                                                                {fileContact.email_valid_status}
                                                                {
                                                                    fileContact.email_validation_infos &&
                                                                    <BootstrapTooltip arrow title="Click for details">
                                                                        <span onClick={()=>{
                                                                            ValidationResponse({
                                                                                response: fileContact.email_validation_infos
                                                                            });
                                                                        }}>
                                                                        <InfoRounded style={{ color: "green", fontSize: "14px", marginLeft: "6px" }}/>
                                                                    </span>
                                                                    </BootstrapTooltip>
                                                                }
                                                            </td>
                                                            <td className={Styles.textAlignCenter}>{fileContact.number}</td>
                                                            <td className={Styles.textAlignCenter}>
                                                                {fileContact.number_valid_status}
                                                                {
                                                                    fileContact.number_validation_infos &&
                                                                    <BootstrapTooltip arrow title="Click for details">
                                                                        <span onClick={()=>{
                                                                            ValidationResponse({
                                                                                response: fileContact.number_validation_infos
                                                                            });
                                                                        }}>
                                                                            <InfoRounded style={{ color: "green", fontSize: "14px", marginLeft: "6px" }}/>
                                                                        </span>
                                                                    </BootstrapTooltip>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <div className={Styles.loaderWrapper}>
                                                                <h5 className={Styles.marginTop}>No contact found!</h5>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    <Pagination page={page} totalPage={totalPage} callback={(uPage)=>{setPage(uPage)}}/>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {showListModal &&
                <ModalAssignToList
                    open={showListModal}
                    selectedFileContactIds={selectedFileContactIds}
                    onClose={() => setShowListModal(false)}
                    filter={filter}
                />
            }

            {showAddToCampaignModal &&
                <ModalAssignToCampaign
                    open={showAddToCampaignModal}
                    onClose={() => setShowAddToCampaignModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filter={filter}
                />
            }

            {showTagModal &&
                <ModalAssignToTag
                    open={showTagModal}
                    selectedFileContactIds={selectedFileContactIds}
                    filter={filter}
                    onClose={() => setShowTagModal(false)}
                />
            }

            {openAddStageToContactModal &&
                <ModalListForStageAdd
                    open={openAddStageToContactModal}
                    onClose={() => setOpenAddStageToContactModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filter={filter}
                />
            }

            {openExportFileModal &&
                <ModalExportFile
                    open={openExportFileModal}
                    onClose={() => setOpenExportFileModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filter={filter}
                />
            }
        </div>
    );
}

export default FileReportDetails;