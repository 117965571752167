import { all, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from '../constants/dripBatchActionTypes'
import * as API from '../api/dripBatchApi'
import Utils from '../helpers/Utils'

function* dripBatchMiddlewareWatcher() {
    yield takeEvery(ACTION.GET_DRIP_BATCH_REPORT_PAGINATION, getDripBatchReportByPaginationMiddleware)
    yield takeEvery(ACTION.SUBMIT_DRIP_BATCH_REPORT_TITLE_EDIT, submitDripBatchTitleEditMiddleware)
    yield takeEvery(ACTION.GET_DRIP_BATCH_CONTACT_PAGINATION, getDripBatchContactsByPaginationMiddleware)
}

function* getDripBatchReportByPaginationMiddleware(action) {
    const {payload} = action
    try {
        const response = yield call(API.getDripBatchReportByPaginationApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }

}

function* submitDripBatchTitleEditMiddleware(action) {
    const {payload} = action
    try {
        const response = yield call(API.submitDripBatchTitleEditApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

function* getDripBatchContactsByPaginationMiddleware(action) {
    const {payload} = action
    try {
        const response = yield call(API.getDripBatchContactsByPaginationApi, payload.payload);
        if (payload.callback) payload.callback(response.data);
    } catch (error) {
        Utils.handleException(error);
    }
}

export default function* dripBatchMiddleware() {
    yield all([
        dripBatchMiddlewareWatcher()
    ])
}