import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/contactActionTypes'
import {getUserVirtualNumberList} from "../api/contactApi";


function* getUserVirtualNumberListWatcher() {
    yield takeEvery(ACTION.GET_VIRTUAL_NUMBER_LIST, attemptUserVirtualNumberList)
}

function* attemptUserVirtualNumberList(action) {
    try {
        const response = yield call(getUserVirtualNumberList, action.payload);

        let responseData = response.data.data;
        yield put({type: ACTION.SET_VIRTUAL_NUMBER_LIST, payload: responseData});
    } catch (error) {
        console.log(error);
    }

}

export default function* virtualNumberMiddleware() {
    yield all([
        getUserVirtualNumberListWatcher()
    ])
}