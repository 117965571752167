import React ,{createContext} from "react";
import './App.css';
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import Utils from "./helpers/Utils";
import { init as initApm } from '@elastic/apm-rum'

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});
const generateClassName = createGenerateClassName({
    productionPrefix: 'cntct_' + + Utils.generateRandomString(5),
});

initApm({

    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: process.env.REACT_APP_APM_SERVICENAME,
  
    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: process.env.REACT_APP_APM_SERVERURL,
  
    // Set service version (required for sourcemap feature)
    serviceVersion: process.env.REACT_APP_APM_SERVICEVERSION,
  
      // Set the service environment
    environment: process.env.REACT_APP_APM_ENVIRONMENT,
  
    Type: ['page-load','history','eventtarget', 'xmlhttprequest','fetch','error']
  
  })

function App({ history = defaultHistory }) {
    return (
        <HistoryProvider.Provider value={history}>
            <StylesProvider generateClassName={generateClassName}>
                <Router history={history}>
                    <Routes history={history} />
                </Router>
            </StylesProvider>
        </HistoryProvider.Provider>

    );
}

export default App;
