import "date-fns";
import React, { useEffect, useState, useRef} from "react";
import { updateMoreInfoContact } from "../../../../api/contactApi";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import EditIcon from '@material-ui/icons/Edit';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from "moment";
import { formatDateWithoutZone } from "../../../globals/TimezoneConversion";
import "./update-more-info.css";
import { IconList } from "../../../../constants/IconList";

const initialState = {
    address: "",
    state: "",
    city: "",
    zip: null,
    country: "",
    deal_value: 0,
    company_name: "",
    url: "",
    birth_date: '',
    anniversary_date: ''
}

const UpdateMoreInfo = (props) => {

    const birthDateRef = useRef(null);
    const anniversaryDateRef = useRef(null);

    const [contact, setContact] = useState(initialState);
    const [submitUpdating, setSubmitUpdating] = useState(false);
    const [openDateField, setOpenDateField] = useState({isBirthdateOpen: false, isAnniversaryDateOpen: false});

    useEffect(() => {
        if(props.open){
            setContact({
                ...contact,
                ...props.data,
                birth_date: ( props.data.birth_date !== undefined && props.data.birth_date !== null && props.data.birth_date !== '' && props.data.birth_date !== "0000-00-00") ? formatDateWithoutZone(props.data.birth_date, 'MM/DD/YYYY') : '',
                anniversary_date:( props.data.anniversary_date !== undefined && props.data.anniversary_date !== null && props.data.anniversary_date !== '' && props.data.anniversary_date !== "0000-00-00") ? formatDateWithoutZone(props.data.anniversary_date, 'MM/DD/YYYY'): ''
            })
        }
        return () => setContact(initialState)
    }, [props.open]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        if(submitUpdating) return;
        setSubmitUpdating(true)
        let formData = {
            'id': props.data.id,
            'field' : 'more-info',
            address: contact['address'],
            state: contact['state'],
            city: contact['city'],
            zip: contact['zip'],
            country: contact['country'],
            deal_value: contact['deal_value'],
            company_name: contact['company_name'],
            url: contact['url'],
            // birth_date: contact['birth_date'],
            // anniversary_date: contact['anniversary_date']
        }

        if(moment(contact['birth_date']).isValid()){
            let birth = moment(contact['birth_date']).format('YYYY-MM-DD')
            formData['birth_date'] = birth
        }
        else{
            formData['birth_date'] = contact['birth_date']
        }
        if(moment(contact['anniversary_date']).isValid()){
            let anniversary = moment(contact['anniversary_date']).format('YYYY-MM-DD')
            formData['anniversary_date'] = anniversary
        }
        else{
            formData['anniversary_date'] = contact['anniversary_date']
        }
        
        if(contact.zip === null){
            formData['zip'] = ''
        }

        updateMoreInfoContact(formData).then(res => {
            let response = res.data;
            if(response.status === 'success'){
                /* call a method to update contact info */
                if(window.showNotification !== undefined)
                    window.showNotification("SUCCESS", "Contact information updated successfully.");
                props.onClose(true,formData);
            }
            else{
                if(window.showNotification !== undefined)
                    window.showNotification("ERROR", response.html);
            }
            setSubmitUpdating(false)
        }).catch(error => {
            setSubmitUpdating(false)
            if(window.showNotification !== undefined)
                window.showNotification("ERROR", "Something went wrong!");
        })
    }
  
    const onChangeField = (value) => {
        setContact({ ...contact, ...value })
    }

    const changeDealValue = (event) => {
      var regExpInt = /^[0-9]*$/g;
      const regExpDecimal = /^[-+]?[0-9]+\.[0-9]+$/;
      if(regExpInt.test(event.target.value)){
        onChangeField({deal_value : event.target.value})
      }
      else{
        if(event.target.value.slice(-1) === '.'){
          let value =  event.target.value
          value = value.replace(/\./g, '');
          value = value + '.'
          onChangeField({deal_value : value})
        }
        else{
          if(regExpDecimal.test(event.target.value)){
            onChangeField({deal_value : event.target.value})
          }
        }
        // const value_array = event.target.value.split('.')
      }
    }

    const changeDealValueOnBlur = (event) => {
      if(event.target.value.slice(-1) === '.'){
        let value =  event.target.value
        value = value.replace(/\.$/g, '');
        onChangeField({deal_value : value})
      }
    }

    const changeZipValue = (event) => {
        if(event.target.value.length <= 10){
            onChangeField({zip : event.target.value})
        }
    }

    return(
        <GlobalModal
            open={props.open} 
            title="Update Contact" 
            onClose={props.onClose}
            buttonText={`${submitUpdating ? 'Updating ' : 'Update '} Contact`}
            buttonIcon={<EditIcon fontSize="small" />}
            onSubmit={handleSubmit}
            className="contact__update__more__info"
        >             
            <h3 className="Update__contact__modal_title">Address</h3>
            <input className="Update__contact__modal_input dealValue " value={contact.address} onChange={(event) => onChangeField({address : event.target.value})} placeholder="Enter address" />

            <h3 className="Update__contact__modal_title">State</h3>
            <input className="dealValue Update__contact__modal_input" value={contact.state} onChange={(event) => onChangeField({state : event.target.value})} placeholder="Enter state" />

            <h3 className="Update__contact__modal_title">City</h3>
            <input className="Update__contact__modal_input dealValue "value={contact.city} onChange={(event) => onChangeField({city : event.target.value})} placeholder="Enter city" />

            <h3 className="Update__contact__modal_title">Country</h3>
            <input className="Update__contact__modal_input dealValue " value={contact.country} onChange={(event) => onChangeField({country : event.target.value})} placeholder="Enter country" />

            <h3 className="Update__contact__modal_title">Zip code</h3>
            <input className="Update__contact__modal_input dealValue " min="0" value={contact.zip} onChange={changeZipValue} placeholder="Enter zip code" />

            <h3 className="Update__contact__modal_title">Company Name</h3>
            <input className="Update__contact__modal_input dealValue" value={contact.company_name} onChange={(event) => onChangeField({company_name : event.target.value})} placeholder="Enter company name" />

            <h3 className="Update__contact__modal_title">Deal Value</h3>
            <input className="Update__contact__modal_input dealValue " value={contact.deal_value} onChange={changeDealValue} placeholder="Enter deal value" onBlur={changeDealValueOnBlur} />

            <h3 className="Update__contact__modal_title">URL</h3>
            <input className="Update__contact__modal_input dealValue" value={contact.url} onChange={(event) => onChangeField({url : event.target.value})} placeholder="Enter url" />
            <div className="update__date__wrapper__extra">
                <div className="update__date__wrapper__extra__left">
                <h3 className="Update__contact__modal_title">Birth Date</h3>
                <div className="preview__date__field__wr">
                    <div className={`preview__date__field ${!contact.birth_date ? 'd-none' : ""}`}>
                        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                inputRef={birthDateRef}
                                onClick={() => setOpenDateField({isBirthdateOpen: true, isAnniversaryDateOpen: false})}
                                onClose={() => setOpenDateField({isBirthdateOpen: false, isAnniversaryDateOpen: false})}
                                id="birth-date"
                                open={openDateField.isBirthdateOpen}
                                inputProps={{readOnly : true}}
                                value={contact.birth_date}
                                onChange={(date) => onChangeField({birth_date : moment(date).format('MM/DD/YYYY')})}
                                margin="normal"
                                format="MM/dd/yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={`no__date__field__section ${contact.birth_date ? 'd-none' : ""}`}>
                        <span>No Birthdate Added Yet</span>
                        <div className="add__new__date__icon" onClick={e => birthDateRef.current.click()}>{IconList.customPlusIcon}</div>
                    </div>
                </div>

                </div>
                <div className="update__date__wrapper__extra__right">
                <h3 className="Update__contact__modal_title">Anniversary Date</h3>
                    <div className="preview__date__field__wr">
                        <div className={`preview__date__field ${!contact.anniversary_date ? 'd-none' : ""}`}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    inputRef={anniversaryDateRef}
                                    onClick={() => setOpenDateField({isAnniversaryDateOpen: true, isBirthdateOpen: false})}
                                    onClose={() => setOpenDateField({isAnniversaryDateOpen: false, isBirthdateOpen: false})}
                                    id="anniversary-date"
                                    open={openDateField.isAnniversaryDateOpen}
                                    inputProps={{readOnly : true}}
                                    value={contact.anniversary_date}
                                    onChange={(date) => onChangeField({anniversary_date : moment(date).format('MM/DD/YYYY')})}
                                    margin="normal"
                                    format="MM/dd/yyyy"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        
                        <div className={`no__date__field__section ${contact.anniversary_date ? 'd-none' : ""}`}>
                            <span>No Anniversary Data Added Yet</span>
                            <div className="add__new__date__icon" onClick={e => anniversaryDateRef.current.click()}>{IconList.customPlusIcon}</div>
                        </div>
                    </div>
                </div>
           </div>

        </GlobalModal>
    )
}
export default UpdateMoreInfo;