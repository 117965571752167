import React, {useEffect} from "react";
import UtilsHelper from "../../../../helpers/Utils";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import {confirmAlert} from "react-confirm-alert";
import { deleteContactTag } from "../../../../api/contactApi";
import ModalAssignToTag from "../../contactList/ModalAssignToTag";

const ShowTagsMoreInfo = (props) => {

  let TagListArray = [];

  useEffect(()=>{
    props.getTagList();
    },[]) // eslint-disable-line

  const handleRemoveTag=(contactId, tagId, index)=>{
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to remove this tag from this contact?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteContactTag({
                            contact_id: contactId,
                            tag_id: tagId
                        }).then((res)=> {
                            if (res.data.success) {
                              
                              props.updateTags(index)
                                window.showNotification('SUCCESS', res.data.message);
                                
                            } else {
                                window.showNotification('ERROR', res.data.message);
                            }
                        }).catch(error => {
                            console.error(error);
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

  return (
    <>
      <GlobalModal
        open={props.open}
        onClose={props.close}
        title={`Show All Tags`}
        className={"show__all__campaigns global-small-modal"}
        hideFooter={true}
      >
        <div className="contact__all__campaigns__wr">
          {props.allContactTags && props.allContactTags.length > 0 &&
              props.allContactTags.forEach((eachTag, index) => {
                if (eachTag && eachTag.tag && eachTag.tag.name) {
                  TagListArray.push(
                    <tr key={`tagList${eachTag.tag.id}${index}`}>
                      <td
                        className="link-text-design campaign-list-in-contact-list contact__all__campaigns"
                      >
                        <BootstrapTooltip
                          title={eachTag.tag.name}
                          arrow
                          placement="top"
                        >
                          <span>
                              {UtilsHelper.reduceTextLength(
                                  eachTag.tag.name,
                                  20
                              )}
                          </span>

                        </BootstrapTooltip>
                      </td>
                      <td>
                        <button className="remove__tag" onClick={() => handleRemoveTag(eachTag.contact_id, eachTag.tag.id, index)}>Remove</button>
                      </td>
                    </tr>
                  )
                }
            })

          }

          <div className="campaign__show__all__table__header show__more__tag__table__wr">
            <table>
              <thead>
                <tr>
                  <th>Tag Name <button className="add__tag__top" onClick={() => props.setIsOpenAssignTagModal(true)}>+</button></th>
                  <th>Action</th>
                  
                </tr>
              </thead>
              {props.isLoading ? <div>Please wait...</div> : TagListArray}
              {
                TagListArray.length === 0 && !props.isLoading &&
                  <div className="no__tag__added">
                    <span>No Tag added in this contact yet</span>
                    <button className="add__tag" onClick={() => props.setIsOpenAssignTagModal(true)}>Add Tag</button>
                  </div>
              }
                
            </table>
          </div>

          {
            props.isOpenAssignTagModal && (
              <ModalAssignToTag
                open={props.isOpenAssignTagModal}
                contact_id={[props.contactId]}
                title={"Add Tag"}
                callBack={props.handleAddTag}
                onClose={()=> props.setIsOpenAssignTagModal(false)}
                contactTags={props.contactTags}
              />
            )
          }
        </div>
      </GlobalModal>
    </>
  );
};

export default ShowTagsMoreInfo;
