import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import LeadOwner from './LeadOwner';
import AssignToStage from './deal/AssignToStage';
import CampaignList from './campaignList/CampaignList';
import FormEntryList from './formEntryList/FormEntryList';
import './Contact.css';
import './ContactResponsive.css';
import './right-side-alt.css';
import Collaborators from './collaborator/Collaborators';
import { fetchTasks, setContactDetails } from './redux/contactAction';
import TaskList from './task/TaskList';
import {getActivityListContactDetails, getContactSmartFormEntries} from '../../../api/contactApi';
import Source from './Source';
import { storeContactActivityList } from '../../../actions/contactAction';
import { RightSideSkeleton } from '../../common/RightSideSkeleton';
import Unsubscribed from './Unsubscribed';
import LeadRequest from "./LeadRequest";
import Utils from "../../../helpers/Utils";
import DNC from "./dnc/DNC";

const RightSide = (props) => {
    const [formEntries, setFormEntries] = useState([]);

    useEffect(() => {
        getActivityList();
        getContactSmartFormEntries(props.contactId)
            .then((res)=>{
                if (res && res.success){
                    setFormEntries(res.data);
                }else {
                    setFormEntries([]);
                }
            }).catch((err)=>{
                setFormEntries([]);
        });

    }, [props.contactId]) // eslint-disable-line react-hooks/exhaustive-deps

    const getActivityList=()=>{
        getActivityListContactDetails({contact_id : props.contactId})
        .then(res=>{
            if(res.data.success){
                if(res.data.data.length > 0){
                    res.data.data.forEach((item, index)=>{
                        res.data.data[index].contact = props.contact
                    })

                    props.storeContactActivityList([...res.data.data])
                }else if(res.data.data.length === 0){
                    props.storeContactActivityList([])

                }
            }
        })
        .catch(e=>{
            console.log(e)
        })
    }

    const getMargedEntriesAndCustomEntries = () => {

        let entries = [];
        
        if(Array.isArray(props.contact.form_entries)) {
            entries = props.contact.form_entries;
        }

        if(Array.isArray(props.contact.custom_form_entries)) {
            entries = [...entries,...props.contact.custom_form_entries];
        }

        return entries;
    }
    return (
        <React.Fragment>

            <div class="col s12 m12 l4 xl3 lead_owner_collapsible white inbox-right-side v2">
                {
                    ( props.contactDetails==null || props.contact == null || props.loadingData)? (
                        // <NewLoader loading={true} onlyLoader={true} size={25} message={""} />
                        <RightSideSkeleton />
                        ) : (
                        <React.Fragment>
                            <LeadRequest />
                            <Unsubscribed 
                                contact_id = {props.contactId}
                            />

                            <LeadOwner contactDetails={props.contactDetails}/>
                            <ul className="collapsible">
                                {
                                    Utils.getAccountData("carrierLookup") && props.contact && props.contact.number &&
                                    props.contact.number.trim() !== "" &&
                                    <li className="collaborat__list common__list">
                                        <DNC/>
                                    </li>
                                }
                                <li className="collaborat__list common__list">
                                    <Collaborators
                                        refreshCollaborator={() => {}}
                                        people={props.contact ? props.contact : null} 
                                        contact_id={props.contact ? props.contact.id : null}
                                        refreshData={props.refreshData}
                                        collaborators={props.collaboratorsListRedecuer}
                                    />
                                </li>
                                <li className="collaborat__list common__list">
                                    <AssignToStage 
                                        contactId={props.contact ? props.contact.id : null}
                                        reloadTopSection={() => {}}
                                        people={props.contact ? props.contact : null} 
                                        loadDataOnCommunication={() => {}}
                                        collaborators={props.collaboratorsListRedecuer}
                                        contactInfo={props.contact}
                                    />
                                </li>
                                <li className="collaborat__list common__list">
                                    <TaskList
                                        people={props.contact ? props.contact : null} 
                                        contact_id={props.contact ? props.contact.id : null} 
                                        refreshData={() => getActivityList()} 
                                        activity={props.allActivityList}
                                        from={"Task"}/>
                                </li>
                                <li className="collaborat__list common__list">
                                    <TaskList 
                                        people={props.contact ? props.contact : null} 
                                        contact_id={props.contact ? props.contact.id : null} 
                                        refreshData={() => getActivityList()} 
                                        activity={props.allActivityList}
                                        from={"Appointment"}/>
                                        
                                </li>
                                <li className="collaborat__list common__list v2_single__contact_campaigns">
                                    <CampaignList
                                        people={props.contact ? props.contact : null} 
                                        contact_id={props.contact ? props.contact.id : null}
                                        refreshData={props.refreshData} 
                                    />
                                </li>
                                <li className="collaborat__list common__list">
                                    <FormEntryList
                                        smartFormEntries={formEntries}
                                        entries={getMargedEntriesAndCustomEntries()} />
                                </li>
                                <li className="collaborat__list">
                                    <Source
                                        reloadTopSection={() => {}}
                                        // sourceId={props.contact.source}
                                        // contact_id={props.contact.id}
                                        sourceList={(props.sourceList !== undefined && props.sourceList != null) ? props.sourceList : null}
                                    />
                                </li>
                            </ul>

                        </React.Fragment>
                        )
                }
                
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        contact: state.rightsideReducer.contact,
        allActivityList: state.rightsideReducer.allActivityList,
        collaboratorsListRedecuer: state.rightsideReducer.collaborators,
        subUserList: state.contactReducer.subUserList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTasks: (params,callBack) => dispatch(fetchTasks(params,callBack)),
        setContactDetails: (params) => dispatch(setContactDetails(params)),
        storeContactActivityList: (params) => dispatch(storeContactActivityList(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSide);