import React, { Component } from 'react';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import StepProgressBar from './StepProgressBar';

const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Completed</small></h6>
        </div>
    );
}

class Success extends Component{

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    render(){
        return(
            <React.Fragment>
                <div className="import_content_container">
                    <div className="main_content_inner_container white">
                        <div className="main_content_topbar">
                            <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                                <HeaderRight />
                            </div>
                        </div>

                        <div class="divider main_content_container_divider"></div>

                        <div className="main_content_main_section">
                            <div className="import-complete m-auto text-center pt-3 pb-3">

                                <StepProgressBar fromCampaign={this.props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_SUCCESS}/>

                                <div class="content_divider"><div class="content_divider_inner"></div></div>
                                
                                <div className="complete_section_box">
                                    <div className="complete_section_content center">
                                        <div class="complete_celebrate_icon">
                                            <svg width="159" height="166" viewBox="0 0 159 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M85.0332 113.912C101.314 113.912 114.512 100.714 114.512 84.4336C114.512 68.1531 101.314 54.9551 85.0332 54.9551C68.7527 54.9551 55.5547 68.1531 55.5547 84.4336C55.5547 100.714 68.7527 113.912 85.0332 113.912Z" fill="#3C7EF3"/>
                                                <path d="M77.5 97.7314L92.6577 112.889C105.212 109.541 114.511 98.1034 114.511 84.4335C114.511 84.1546 114.511 83.8756 114.511 83.5966L102.608 72.6235L77.5 97.7314Z" fill="#356DD0"/>
                                                <path d="M85.7778 91.0362C87.0797 92.3381 87.0797 94.5699 85.7778 95.8718L83.081 98.5685C81.7791 99.8704 79.5473 99.8704 78.2454 98.5685L66.4354 86.6655C65.1335 85.3636 65.1335 83.1318 66.4354 81.8299L69.1322 79.1332C70.4341 77.8313 72.6659 77.8313 73.9678 79.1332L85.7778 91.0362Z" fill="white"/>
                                                <path d="M96.1007 70.4847C97.4026 69.1828 99.6344 69.1828 100.936 70.4847L103.633 73.1815C104.935 74.4834 104.935 76.7152 103.633 78.0171L83.1748 98.3824C81.8729 99.6843 79.6411 99.6843 78.3392 98.3824L75.6424 95.6856C74.3405 94.3837 74.3405 92.1519 75.6424 90.85L96.1007 70.4847Z" fill="white"/>
                                                <path d="M102.578 48.0113C102.578 47.4225 102.807 46.8348 103.262 46.3925L128.205 22.1811C129.099 21.3133 130.527 21.3336 131.394 22.2276C132.262 23.1216 132.24 24.5497 131.348 25.4175L106.404 49.6301C105.511 50.4979 104.082 50.4765 103.216 49.5824C102.79 49.1438 102.578 48.5775 102.578 48.0113Z" fill="#FF4E61"/>
                                                <path d="M106.302 119.578C106.423 119.025 106.751 118.517 107.261 118.181C108.301 117.495 109.7 117.781 110.386 118.821L129.539 147.829C130.225 148.869 129.939 150.268 128.899 150.955C127.859 151.641 126.46 151.355 125.774 150.316L106.622 121.306C106.272 120.776 106.175 120.153 106.302 119.578Z" fill="#FFAA00"/>
                                                <path d="M91.6957 133.028C91.6957 132.444 91.921 131.861 92.3704 131.42L92.3955 131.395C93.2847 130.521 94.7128 130.534 95.5854 131.422C96.4591 132.311 96.446 133.739 95.5579 134.612L95.5329 134.637C94.6437 135.51 93.2156 135.498 92.343 134.608C91.9115 134.17 91.6957 133.599 91.6957 133.028Z" fill="#FF4E61"/>
                                                <path d="M118.439 102.542C118.439 102.163 118.534 101.779 118.735 101.428C119.353 100.345 120.731 99.9685 121.812 100.586L152.222 117.938C153.303 118.556 153.68 119.933 153.062 121.015C152.445 122.097 151.067 122.474 149.986 121.857L119.576 104.504C118.847 104.088 118.439 103.326 118.439 102.542Z" fill="#62D38F"/>
                                                <path d="M123.639 70.1276C123.639 69.3432 124.048 68.5815 124.778 68.1655L155.187 50.8131C156.269 50.1956 157.646 50.5723 158.263 51.6535C158.881 52.7358 158.505 54.1138 157.423 54.7313L127.014 72.0837C125.932 72.7012 124.554 72.3245 123.936 71.2434C123.735 70.8905 123.639 70.5067 123.639 70.1276Z" fill="#5C73BC"/>
                                                <path d="M127.354 93.6327C127.354 92.8674 127.743 92.12 128.447 91.6968C129.515 91.0555 130.901 91.4012 131.542 92.4681L131.56 92.499C132.202 93.5671 131.857 94.9523 130.789 95.5936C129.721 96.2361 128.336 95.8904 127.693 94.8223L127.675 94.7925C127.457 94.429 127.354 94.0284 127.354 93.6327Z" fill="#FF4E61"/>
                                                <path d="M59.6371 48.0113C59.6371 47.4225 59.4082 46.8348 58.9528 46.3926L34.0095 22.1811C33.1155 21.3133 31.6874 21.3336 30.8208 22.2276C29.953 23.1216 29.9745 24.5497 30.8673 25.4175L55.8106 49.6301C56.7035 50.4979 58.1327 50.4765 58.9993 49.5824C59.4249 49.1438 59.6371 48.5776 59.6371 48.0113Z" fill="#FF4E61"/>
                                                <path d="M83.5555 39.5966C83.9894 39.1984 84.2677 38.6325 84.2857 37.998L85.2613 3.25016C85.2963 2.00471 84.3159 0.966186 83.0713 0.932088C81.8258 0.897112 80.7881 1.87841 80.7523 3.12298L79.7758 37.8716C79.74 39.1162 80.7221 40.1548 81.9667 40.1889C82.5777 40.2058 83.1383 39.9794 83.5555 39.5966Z" fill="#27AE60"/>
                                                <path d="M68.0621 56.165C68.0621 55.5964 67.8488 55.0278 67.4196 54.5891C66.5494 53.6975 65.1214 53.6808 64.2297 54.551L64.2047 54.576C63.3143 55.4462 63.2964 56.8742 64.1678 57.7659C65.0379 58.6575 66.466 58.6742 67.3576 57.8028L67.3827 57.779C67.8345 57.3367 68.0621 56.7514 68.0621 56.165Z" fill="#FFAA00"/>
                                                <path d="M67.0872 122.509C66.9264 121.966 66.5632 121.483 66.0309 121.184C64.9449 120.574 63.5696 120.959 62.9589 122.045L45.9232 152.345C45.3126 153.431 45.6977 154.806 46.7838 155.417C47.8698 156.027 49.2451 155.642 49.8558 154.556L66.8911 124.255C67.2021 123.702 67.2545 123.073 67.0872 122.509Z" fill="#FFAA00"/>
                                                <path d="M33.0875 83.9974C33.0875 83.4133 32.8622 82.8304 32.4128 82.3894L32.3877 82.3643C31.4985 81.4906 30.0704 81.5037 29.1978 82.3918C28.3241 83.281 28.3372 84.7091 29.2253 85.5817L29.2503 85.6067C30.1396 86.4793 31.5676 86.4673 32.4402 85.5781C32.8717 85.1394 33.0875 84.5684 33.0875 83.9974Z" fill="#FF4E61"/>
                                                <path d="M47.87 102.542C47.87 102.163 47.7746 101.779 47.5732 101.428C46.9557 100.345 45.5777 99.9685 44.4966 100.586L14.0866 117.938C13.0054 118.556 12.6287 119.933 13.2462 121.015C13.8637 122.097 15.2416 122.474 16.3228 121.857L46.7328 104.504C47.4611 104.088 47.87 103.326 47.87 102.542Z" fill="#62D38F"/>
                                                <path d="M37.5374 124.117C37.5374 123.72 37.4325 123.318 37.2132 122.954L37.1953 122.924C36.5516 121.858 35.1653 121.514 34.0984 122.157C33.0315 122.8 32.6894 124.187 33.3319 125.254L33.3498 125.284C33.9935 126.35 35.3798 126.694 36.4467 126.05C37.1488 125.627 37.5374 124.881 37.5374 124.117Z" fill="#2DC471"/>
                                                <path d="M46.3859 74.5846C46.3859 73.8003 45.977 73.0386 45.2475 72.6226L14.8387 55.2701C13.7563 54.6526 12.3795 55.0293 11.7621 56.1105C11.1446 57.1929 11.5201 58.5708 12.6024 59.1883L43.0112 76.5408C44.0936 77.1582 45.4716 76.7816 46.0891 75.7004C46.2905 75.3475 46.3859 74.9637 46.3859 74.5846Z" fill="#5C73BC"/>
                                                <path d="M85.6071 164.07C86.3081 163.718 86.8053 163.01 86.8497 162.172L88.7115 127.21C88.7776 125.966 87.8231 124.904 86.5799 124.838C85.3355 124.771 84.2725 125.725 84.2064 126.97L82.3447 161.932C82.2786 163.176 83.2335 164.238 84.4768 164.305C84.8826 164.327 85.2684 164.24 85.6071 164.07Z" fill="#5C73BC"/>
                                                <path d="M35.3086 55.746C35.3086 54.9807 34.9188 54.2333 34.2155 53.8101C33.1474 53.1688 31.7611 53.5145 31.1197 54.5813L31.1019 54.6123C30.4606 55.6804 30.8051 57.0655 31.8731 57.7069C32.9412 58.3494 34.3263 58.0037 34.9688 56.9356L34.9867 56.9058C35.2048 56.5422 35.3086 56.1417 35.3086 55.746Z" fill="#FF4E61"/>
                                                <path d="M6.23553 103.456C2.79174 103.456 -4.10695e-07 100.746 -2.64592e-07 97.4032C-1.18436e-07 94.0596 2.79174 91.3501 6.23553 91.3501C9.67932 91.3501 12.4723 94.0596 12.4723 97.4032C12.4723 100.746 9.67932 103.456 6.23553 103.456Z" fill="#FFD02F"/>
                                                <path d="M109.073 27.7069C105.63 27.7069 102.838 24.9962 102.838 21.6537C102.838 18.3101 105.63 15.6006 109.073 15.6006C112.517 15.6006 115.31 18.3101 115.31 21.6537C115.31 24.9962 112.517 27.7069 109.073 27.7069Z" fill="#3C7EF3"/>
                                                <path d="M142.21 134.462C140.159 134.462 138.496 136.125 138.496 138.176C138.496 140.228 140.159 141.891 142.21 141.891C144.261 141.891 145.925 140.228 145.925 138.176C145.925 136.125 144.261 134.462 142.21 134.462Z" fill="#FF4E61"/>
                                            </svg>                                    
                                        </div>

                                        <div>
                                            <h4>Congratulation!</h4>
                                        </div>
            
                                        <div>
                                            <p>Contact has beed uploaded successfully</p>
                                        </div>

                                        <div className="title">
                                            { this.props.successMessageForCampaignUpload &&
                                                <div>
                                                    <strong>{this.props.successMessageForCampaignUpload}</strong>
                                                </div> }
                                        </div>
                                        <div className="import-complete-list d-inline-block pt-3">
                                            <ul >
                                                <li>Added Leads: <span><strong>{ this.props.state.totalUpload }</strong></span></li>
                                                <li>Updated Leads: <span><strong>{ this.props.state.isExistFailed}</strong></span></li>
                                                <li>Duplicate Leads: <span><strong> { (this.props.state.validateFailed <= 0) ? 0 : this.props.state.validateFailed - 1   }</strong></span></li>
                                            </ul>
                                        </div>

                                        <div class="all_contact_button">
                                            <a href="javascript:void(0)" onClick={this.saveAndContinue} class="main_content_container_button white-text d-flex justify-content-center align-items-center back_next_buttons">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="main_content_container_button_icon">
                                                    <circle cx="10" cy="10" r="10" fill="white"/>
                                                    <g clipPath="url(#clip0)">
                                                    <path d="M9.99955 11.9312C8.93407 11.9312 8.06836 11.0654 8.06836 9.99996C8.06836 8.93448 8.93407 8.06877 9.99955 8.06877C11.065 8.06877 11.9307 8.93448 11.9307 9.99996C11.9307 11.0654 11.065 11.9312 9.99955 11.9312ZM9.85305 10.5727C9.61331 10.5727 9.41353 10.3729 9.41353 10.1331H8.77424C8.78756 10.7325 9.26703 11.212 9.85305 11.212V10.5727Z" fill="#3C7EF3"/>
                                                    <path d="M15.8602 10.3995C15.2075 11.2119 12.8768 13.8623 10 13.8623C7.1232 13.8623 4.79245 11.2119 4.13984 10.3995C3.95339 10.1731 3.95339 9.84011 4.13984 9.60037C4.79245 8.78794 7.1232 6.13755 10 6.13755C12.8768 6.13755 15.2075 8.78794 15.8602 9.60037C16.0466 9.82679 16.0466 10.1598 15.8602 10.3995ZM10 7.20304C8.45505 7.20304 7.20311 8.45498 7.20311 9.99993C7.20311 11.5449 8.45505 12.7968 10 12.7968C11.545 12.7968 12.7969 11.5449 12.7969 9.99993C12.7969 8.45498 11.545 7.20304 10 7.20304Z" fill="#3C7EF3"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0">
                                                    <rect width="12" height="12" fill="white" transform="matrix(1 0 0 -1 4 16)"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>                                        
                                                <span>View Imported Leads</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </React.Fragment>
        )
    }
}

export default Success;
