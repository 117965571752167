import React, { useState, useEffect, useRef } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CreateIcon from '@material-ui/icons/Create';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { connect } from "react-redux";
import AvatarComponet from "./avatar"
import TextInputField from "./TextInputField"
import SelectField from "./SelectField";
import TagsComponent from "./tag/TagsComponent"
import { reloadConversationList, setContactDetails, toggleNoteModal, addExtraEmailContact } from "../../../actions/contactAction"
import NoteComponent from "./note/NoteComponent";
import CustomFields from './customFields/CustomFields';
import './leftside.css'
import SingleInputField from './Tabs/ContactTab/SingleInputField';
import Utils from '../../../helpers/Utils';
import { confirmAlert } from 'react-confirm-alert';
import {
	removeBulkContact,
	getEmailTypeApi,
	addExtraEmailApi,
	getExtraEmailApi,
	editExtraEmailApi,
	removeExtraEmail,
	getUserCampaignRegistrationStatusApi
} from '../../../api/contactApi';
import { useHistory } from "react-router-dom";
import { IconList } from '../../globals/IconList';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import MoreInfo from './moreInfo/MoreInfo';
import SelectContactEmail from './SelectContactEmail';
import Helper from '../../globals/Helper'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CarrierLookup from "../../carrierLookup";
import dncNoCall from "../../common/dncNoCall";
const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;

const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63']
	&& Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true : false

const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61']
	&& Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true : false
const TabPanel = (props) => {
	const { children, value, index, ...other } = props;


	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
}

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}


const LeftSide = (props) => {
	const [mailOptionVisible, setMailOptionVisible] = useState(false);
	const [lastCommunicationMessage, setLastCommunicationMessage] = useState('');

	// for video send
	const [activeTab, setActiveTab] = useState(0);
	const history = useHistory();

	const [inputList, setInputList] = useState([]);
	const [validationText, setValidationText] = useState("")
	const [extraEmailList, setExtraEmailList] = useState([]);

	const [emailTypeList, setEmailTypeList] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [editItemId, setEditItemid] = useState(null);
	const [validation, setValidation] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setIsErrors] = useState({ email: '' });
	const extraEmailId = useRef(null);

	useEffect(() => {
		getEmailTypeList();
		getExtraEmailList();
		getLastCommunicationinfo();
	}, [isSuccess])

	useEffect(() => {
		getLastCommunicationinfo();
		getExtraEmailList();
	}, [props.contact]);

	const getLastCommunicationinfo = () => {
		setLastCommunicationMessage(props.contact?.contact_activity?.local_last_communication?.for_human
			? props.contact.contact_activity.local_last_communication.for_human : "No conversation yet!")
	}

	const getExtraEmailList = () => {
		getExtraEmailApi({ contact_id: parseInt(props.contact.id) })
			.then((response) => {

				if (response.data.status === 'success') {

					if (response.data.data !== undefined && response.data.data != null) {
						setExtraEmailList(response.data.data);
					} else {
						window.showNotification('ERROR', "You don't have access to this contact")
						history.push('/contacts');
					}


				} else {
					window.showNotification('ERROR', response.data.message)
					history.push('/contacts');

				}
			})
			.finally(() => {
			})

	}

	const getEmailTypeList = () => {
		getEmailTypeApi()
			.then((response) => {

				if (response.data.status === 'success') {

					if (response.data.data !== undefined && response.data.data != null) {
						setEmailTypeList(response.data.data);
					} else {
						window.showNotification('ERROR', "You don't have access to this contact")
						history.push('/contacts');
					}


				} else {
					window.showNotification('ERROR', response.data.message)
					history.push('/contacts');

				}
			})
			.finally(() => {
			})

	}


	const selectActiveMailType = (type) => {

		setMailOptionVisible(false)
		window.openGlobalDirectMailModal({
			messageType: type, contactId: props.contact.id, contactDetails: props.contact,
			callBack: submitDirectMail, from: 'inbox'
		})

	}

	const submitDirectMail = () => {
		console.log('submit Direct Mail')
	}

	const showNotificationStart = (type, message) => {
		window.showNotification(type, message)
	}

	const removeBulkContacts = async () => {

		confirmAlert({
			title: 'Confirm to delete',
			message: 'Are you sure to delete contact?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						await removeBulkContact({ 'ids': [props.contact.id] })
							.then(response => {

								if (response.data.success) {
									window.showNotification('SUCCESS', 'Contact deleted successfully')
									history.push("/contacts");

								} else {
									window.showNotification('ERROR', 'Something went wrong')
								}
							})
							.catch(error => {
								window.showNotification('ERROR', 'Something went wrong')
							});
					}
				},
				{
					label: 'No',
					onClick: () => {
						return;
					}
				}
			]
		})

	}


	const removeExtraEmails = async (itemId, dataIndex) => {
		extraEmailId.current = itemId
		confirmAlert({
			title: 'Confirm to delete',
			message: 'Are you sure to delete contact email?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						await removeExtraEmail({ 'contact_id': props.contact.id, 'id': itemId })
							.then(response => {
								if (response.data.status === 'error') {
									window.showNotification('ERROR', 'Something went wrong')
								}
								else {
									let oldData = [...extraEmailList]
									let data = oldData.filter((item, index) => {
										return index !== dataIndex
									})
									setExtraEmailList(data)
								}
							})
							.catch(error => {
								window.showNotification('ERROR', 'Something went wrong')
							});
					}
				},
				{
					label: 'No',
					onClick: () => {
						return;
					}
				}
			]
		})

	}



	const getContactEmail = () => {
		let contact = props.contact
		if (contact.email !== undefined && contact.email !== null) {
			return contact.email
		}
		return ""
	}

	const editMoreInfo = (changedInfo) => {
		if (props.updateStoredContactDetails !== undefined) {
			props.updateStoredContactDetails(changedInfo)
		}
	}

	const openContactInfoModal = () => {
		window.handleGlobalContactDetailsModal({
			open: true,
			from: 'contact-details',
			title: () => {
				let title = "Contact details";
				let data = props.contact
				if (data !== undefined && data !== null) {
					if ((data.first_name !== null && data.first_name !== "") && (data.last_name !== null && data.last_name !== "")) {
						title = data.first_name + ' ' + data.last_name
					}
					else if (data.first_name !== null && data.first_name !== "") {
						title = data.first_name
					}
					else if (data.last_name !== null && data.last_name !== "") {
						title = data.last_name
					}
					else if (data.email !== null && data.email !== "") {
						title = data.email
					}
					else if (data.number !== null && data.number !== "") {
						title = data.number
					}
				}
				return title;
			},
			id: props.contact.id,
			closeContactDetails: () => console.log('info modal close')
		})
	}

	// handle input change
	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		if (name === "email") {
			let invalid = "";
			if (!Utils.validateEmail(value) && value !== "") {
				invalid = "Invalid email";
			}
			setValidationText(invalid)
			setValidation("")
		}
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
		setIsErrors({
			...errors, [name]: ''
		})
	};

	// handle click event of the Remove button
	const handleRemoveClick = index => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
		setEditItemid(null)
		setIsEdit(false)
		setIsErrors({ first_name: '', email: '', contact_relation_id: '' })
		setValidationText("")
	};

	// handle click event of the Add button
	const handleAddClick = () => {
		if (validation != null) {
			setValidation(null)
		}
		setInputList([...inputList, { first_name: "", last_name: "", email: "", contact_relation_id: emailTypeList.length > 0 ? emailTypeList[0]['id'] : "", label: "" }]);
	};

	const handleSubmitForm = (e) => {
		e.preventDefault()
		if (handleValidation() && validationText === "") {
			setIsLoading(true)
			let obj = {
				contact_id: parseInt(props.contact.id),
			}
			let inputData = [...inputList]
			obj.first_name = inputData[0]['first_name']
			obj.last_name = inputData[0]['last_name']
			obj.email = inputData[0]['email']
			obj.label = inputData[0]['label'];
			obj.contact_relation_id = inputData[0]['contact_relation_id']
			if (inputData[0]['id'] !== undefined) {
				obj.id = inputData[0]['id']
			}
			if (!isEdit) {
				addExtraEmailApi(obj).then(response => {
					if (response.data.status === 'success') {
						setIsLoading(false)
						setValidation(null)
						setIsSuccess(!isSuccess)
						setIsEdit(false)
						setInputList([])
						//setInputList([{ first_name: "", last_name: "", email: "" ,contact_relation_id: emailTypeList.length>0 ? emailTypeList[0]['id'] : ""}])
					} else {
						setIsLoading(false)
						setValidation("The email has already been taken")
						// setInputList([{ first_name: "", last_name: "", email: "" ,contact_relation_id: emailTypeList.length>0 ? emailTypeList[0]['id'] : ""}])
					}
				}).catch(error => {

				})
			} else {
				editExtraEmailApi(obj).then(response => {
					if (response.data.status === 'success') {
						setIsLoading(false)
						setIsSuccess(!isSuccess)
						setIsEdit(false)
						setEditItemid(null)
						setValidation(null)
						setInputList([])
					} else {
						setIsLoading(false)
						setValidation("The email has already been taken")
					}
				}).catch(error => {

				})
			}
		}

	}

	const handleValidation = () => {
		let formInputList = [...inputList]
		let errors = {}
		if (formInputList.length > 0) {

			if (formInputList[0]["email"] === "") {
				errors.email = "email is required";
			}

			if (formInputList[0]["label"] && formInputList[0]["label"].length > 50) {
				errors.label = "label must be less than or equal 50 character";
			}

		}
		if (Object.keys(errors).length > 0) {
			setIsErrors(errors)
			return false
		} else {
			return true
		}
	}


	// Edit Extra Email

	const editExtraEmail = (oldItem) => {
		let obj = {
			first_name: oldItem.first_name,
			last_name: oldItem.last_name,
			email: oldItem.email,
			label: oldItem.label,
			contact_relation_id: oldItem.contact_relation.id,
			id: oldItem.id
		}

		setEditItemid(oldItem.id)
		setIsEdit(true)
		setInputList([{ ...obj }])
		setValidation(null)
	}

	const renderName = (first_name, last_name) => {
		let firstName = Utils.isEmpty(first_name)
		let lastName = Utils.isEmpty(last_name)

		if (!firstName && !lastName) {
			return first_name += " " + last_name
		}
		else if (!firstName) {
			return first_name
		}
		else if (!lastName) {
			return last_name
		}
		else {
			return null
		}


	}

	const callAll = () => {

		let allContacts = (props.allContactList && props.allContactList.data) ? props.allContactList.data : []
		let totalContacts = (props.allContactList && props.allContactList.total) ? props.allContactList.total : 0
		let currentIndex = props.assignedContactListInfo && props.assignedContactListInfo.currentIndex ? props.assignedContactListInfo.currentIndex : null

		if (allContacts[1]) {
			let tmpParams = { from: 'contactListPage', contactList: allContacts, totalContacts: totalContacts, currentIndex: currentIndex }
			window.initiateGlobalDialer(props.contact, tmpParams)
		} else {
			window.initiateGlobalDialer(props.contact)
		}


	}

	const sendVoiceMail = () => {
		if (window.sendCommunication !== undefined) {
			window.sendCommunication({
				open: true,
				onClose: () => console.log('RVM close'),
				id: props.contact.id,
				from: "contact-details",
				fetchContactDetails: false,
				sendSuccessCallBack: () => props.reloadNeedForConversation(true)
			}, 'voice')
		}
	}

	const handleNewPostcard = (type = 'postcard') => {
		setMailOptionVisible(false)
		window.sendGlobalDirectMailV2({
			contactId: props.contact.id,
			from: '/contacts/' + props.contact.id,
			card_type: type,
			for: 'direct-mail-send' //campaign-setting
		})
	}

	const sendSMSModal = () => {
		if (window.sendCommunication !== undefined) {
			window.sendCommunication({
				open: true,
				onClose: () => console.log('sms close'),
				id: props.contact.id,
				from: "deal-details",
				fetchContactDetails: false,
				contactInfo: props.contact,
				sendSuccessCallBack: () => {
					setLastCommunicationMessage("A few seconds ago");
					props.reloadNeedForConversation(true);
				}
			}, 'sms')
		}
	}

	const getUserCampaignRegistrationStatus = () => {
		try {
			getUserCampaignRegistrationStatusApi()
				.then((res) => {
					res = res.data;
					if (res.success) {
						if (res.campaignRegistrationStatus === "TRUE") {
							sendSMSModal();
						} else {
							window.globalSmsRestriction(true, {
								callback: (res) => {
									if (res.sendWithRisk) {
										sendSMSModal();
									}
								}
							});
						}
					}
				})
				.catch((err) => {
					sendSMSModal();
					console.log(err);
				});
		} catch (err) {
			sendSMSModal();
			console.log(err);
		}
	}

	return (
		<React.Fragment>


			{props.showNoteModal && (
				<NoteComponent
					open={props.showNoteModal}
					onClose={() => props.toggleNoteModal(false)}
				/>
			)}
			<div className="col s12 m12 l3 xl3 contact_details white">

				<div className="d-flex justify-content-between align-items-center contact_details_top">
					<div className="px-2 py-3 d-flex align-items-center contact_details_top__for__pagination ">
						<div className="btn-floating box-shadow-none contact_person_icon d-flex justify-content-center align-items-center">
							<i className="material-icons">person</i>
						</div>
						{!props.assignedContactListInfo ?

							<span className="ml-2 contact_details_top__for__pagination_info">Contact Information</span>
							:
							<span className="ml-2 contact_details_top__for__info1">Information
								<span className='contact_details_top__for__pagin'>{props.assignedContactListInfo.currentIndex !== 0 &&
									<span onClick={() => {
										props.goToContactDetails('previous')
										setActiveTab(0)
									}}><NavigateBeforeIcon /></span>
								}
									{props.assignedContactListInfo.currentIndex + 1} of {props.assignedContactListInfo.totalContacts} contacts
									{props.assignedContactListInfo.currentIndex + 1 < props.assignedContactListInfo.totalContacts &&
										<span onClick={() => {
											props.goToContactDetails('next')
											setActiveTab(0)
										}}><NavigateNextIcon /></span>
									}
								</span>
							</span>

						}

					</div>
				</div>
				<div className="mt-3 ContactCustomClass">
					<div className="text-center">
						<AvatarComponet
							showNotificationStart={showNotificationStart}
						/>
						<div className="user_profile_icons d-flex justify-content-center v3">
							{props.contact.number &&
								props.contact.number !== "" && (
									<div
										id="text_message_btn"
										className="single_icon"
									>

										<span
											className="email_btn_icon"
											// onClick={(e) =>
											// 	setIsOpenSMSModal(true)
											// }
											onClick={(e) => {
												e.preventDefault();
												if (Utils.getAccountData("campaignRegistrationAvailable") === "TRUE") {
													if (window.sendCommunication !== undefined) {
														window.sendCommunication({
															open: true,
															onClose: () => console.log('sms close'),
															id: props.contact.id,
															from: "deal-details",
															fetchContactDetails: false,
															contactInfo: props.contact,
															sendSuccessCallBack: () => {
																setLastCommunicationMessage("A few seconds ago");
																props.reloadNeedForConversation(true);
															}
														}, 'sms')
													}
												} else {
													getUserCampaignRegistrationStatus();
												}
											}}
										>
											{IconList.massageIcon}
										</span>
										<p className="text-gray m-0">Text</p>
									</div>
								)}
							{((props.contact.email && props.contact.email !== "") || (extraEmailList && extraEmailList.length > 0)) && (
								<div
									id="email_btn"
									className="single_icon"
								>
									<span
										className="email_btn_icon"
										onClick={(e) => {
											e.preventDefault();
											if (window.sendCommunication !== undefined) {
												window.sendCommunication({
													open: true,
													onClose: () => console.log('Email Close'),
													id: props.contact.id,
													from: "contact-details",
													primaryEmail: getContactEmail(),
													fetchContactDetails: false,
													sendSuccessCallBack: () => {
														setLastCommunicationMessage("A few seconds ago");
														props.reloadNeedForConversation(true);
													}
												}, 'email')
											}
										}}
									>
										{IconList.emailIconV2}
									</span>
									<p className="text-gray m-0">Email</p>
								</div>
							)}

							{props.contact.number &&
								props.contact.number !== "" && (
									<div className="single_icon" onClick={() => {
										{
											(Utils.getAccountData("carrierLookup")
												&& props.contact && props.contact.number) ?
												(
													(
														props.contact.contact_additional_informations &&
														props.contact.contact_additional_informations.dnc_status === "DO_NOT_CALL"
													) ? (
														dncNoCall({
															takeRisk: () => {
																callAll(props.contact)
															},
															cancelButtonText: "Don't Call"
														})
													) : (
														callAll(props.contact)
													)
												) : (
													callAll(props.contact)
												)
										}
									}}>
										<i className="material-icons waves-effect m-2 text-gray modal-trigger d-none">
											phone_in_talk
										</i>
										<span className="email_btn_icon phone-icon"

										>
											{IconList.callIconv2}
										</span>
										<p className="text-gray m-0">Call</p>
									</div>
								)}

							{props.contact.number &&
								props.contact.number !== "" && (
									<div
										className="single_icon"
									>
										<i
											data-target="voice_mail_modal"
											className="modal-trigger material-icons waves-effect m-2 text-gray d-none">
											mic
										</i>
										<span
											className="email_btn_icon micBtn"
											onClick={(e) => {
												e.preventDefault();
												{
													(Utils.getAccountData("carrierLookup")
														&& props.contact && props.contact.number) ?
														(
															(
																props.contact.contact_additional_informations &&
																props.contact.contact_additional_informations.dnc_status === "DO_NOT_CALL"
															) ? (
																dncNoCall({
																	takeRisk: () => {
																		sendVoiceMail()
																	},
																	cancelButtonText: "Don't Send"
																})
															) : (
																sendVoiceMail()
															)
														) : (
															sendVoiceMail()
														)
												}
											}}
										>
											{IconList.micIcon}
										</span>
										<p className="text-gray m-0">Voice</p>
									</div>
								)}

							{props.contact.email && props.contact.email !== "" && (
								<div className="single_icon"
									// onClick={() => setOpenVideoMailModal(true)}>
									onClick={() => window.sendCommunication({
										open: true,
										contactId: props.contact.id,
										contactDetails: props.contact,
										from: 'contact-details',
										responseCallback: () => props.reloadNeedForConversation(true)
									}, 'videoEmail')}
								>
									<VideoCallIcon className="modal-trigger material-icons waves-effect m-2 text-gray d-none" />
									<span className="email_btn_icon micBtn videoIcon">
										{IconList.videoIconWithoutStroke}
									</span>
									<p className="text-gray m-0">Video</p>
								</div>
							)}


							{(thanksIoActive || zenDirectActive) && !Helper.isEmpty(props.contact.address) && !Helper.isEmpty(props.contact.state) &&

								<div
									className="single_icon"
									onClick={(e) => setMailOptionVisible(e.currentTarget)}
								>
									<span className="email_btn_icon  micBtn videoIcon">
										{IconList.directMailiconInContactDetailsWithoutStroke}
									</span>
									<p className="text-gray m-0">DMail</p>
								</div>
							}
							<Menu
								id="more-menu"
								anchorEl={mailOptionVisible}
								keepMounted
								open={Boolean(mailOptionVisible)}
								onClose={() => setMailOptionVisible(false)}
							>
								{/* {thanksIoActive && */}
								{/* <> */}
								{/* <MenuItem onClick={() => selectActiveMailType(POSTCARD)}
									style={{
										paddingTop: 6,
										paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
									}}>
									<span>
										{IconList.postcardIcon}
									</span>
									&nbsp;      Post Card

								</MenuItem> */}
								{/* <MenuItem onClick={() => selectActiveMailType(GREETING_CARD)}
									style={{
										paddingTop: 6,
										paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
									}}>

									<span>
										{IconList.greetingcardIcon}

									</span>
									&nbsp;    Greeting Card

								</MenuItem> */}

								{/* <MenuItem onClick={() => selectActiveMailType(THANKSIO_GREETING_CARD)}
									style={{
										paddingTop: 6,
										paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
									}}>

									<span>
										{IconList.greetingcardIcon}

									</span>
									&nbsp;  Greeting Card

								</MenuItem> */}

								{/* <MenuItem onClick={() => selectActiveMailType(LETTER)}
									style={{
										paddingTop: 6,
										paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
									}}>

									<span>
										{IconList.lettericon}

									</span>
									&nbsp;Letter</MenuItem>
								</>
								} */}
								{Utils.getAccountData('userId') == 1 &&
									<MenuItem onClick={() => selectActiveMailType(GIFT)}
										style={{
											paddingTop: 6,
											paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
										}}>

										<span>
											{IconList.giftIcon}

										</span>
										&nbsp; Old Gift</MenuItem>
								}

								{/* {Utils.getAccountData('userId') == 1 && */}
								<>
									{thanksIoActive &&
										<>
											<MenuItem onClick={() => handleNewPostcard('postcard')}
												style={{
													paddingTop: 6,
													paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
												}}
											>
												<span>{IconList.giftIcon}</span>&nbsp; Postcard
											</MenuItem>
											<MenuItem onClick={() => handleNewPostcard('letter')}
												style={{
													paddingTop: 6,
													paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
												}}
											>
												<span>{IconList.giftIcon}</span>&nbsp; Letter
											</MenuItem>
											<MenuItem onClick={() => handleNewPostcard('notecard')}
												style={{
													paddingTop: 6,
													paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
												}}
											>
												<span>{IconList.giftIcon}</span>&nbsp; Notecard
											</MenuItem>
										</>
									}
									{zenDirectActive &&
										<MenuItem onClick={() => handleNewPostcard('zendirect_gift')}
											style={{
												paddingTop: 6,
												paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
											}}
										>
											<span>{IconList.giftIcon}</span>&nbsp; Gift
										</MenuItem>
									}
								</>
							</Menu>
						</div>
					</div>
					<div className="row mt-4 userprofile__tabs">
						<div className="col s12">
							<Tabs
								className="user__tabs__content"
								value={activeTab}
								onChange={(event, newValue) =>
									setActiveTab(newValue)
								}
								aria-label="simple tabs example">
								<Tab
									className={`user__tabs__title ${activeTab === 0
										? "accent--bg--text--color"
										: ""
										}`}
									label="contact"
									{...a11yProps(0)}
								/>
								<Tab
									className={`user__tabs__title ${activeTab === 1
										? "accent--bg--text--color"
										: ""
										}`}
									label="more info"
									{...a11yProps(1)}
								/>
								<Tab
									className={`user__tabs__title ${activeTab === 2
										? "accent--bg--text--color"
										: ""
										}`}
									label="tags"
									{...a11yProps(2)}
								/>
							</Tabs>
						</div>
						<TabPanel
							className="use__tabs__panel"
							value={activeTab}
							index={0}>
							<div id="contact" className="col s12">
								<div className="bg-gray-light p-3 comon__title__name addtional__eamail__btn__wr">

									<div className="user-name-group-leftside d-flex">
										<div className="first-name-leftside">
											<SingleInputField
												label={"First Name"}
												name={"first_name"}
												contactId={
													props.contact &&
													props.contact.id
												}
												value={
													props.contact &&
													props.contact.first_name
												}
												placeholder="Enter first name"
												showSpan={false}
												loadingData={props.loadingData}
											/>
										</div>
										<div className="last-name-leftside">
											<SingleInputField
												label={"Last Name"}
												name={"last_name"}
												contactId={
													props.contact &&
													props.contact.id
												}
												value={
													props.contact &&
													props.contact.last_name
												}
												placeholder="Enter last name"
												loadingData={props.loadingData}
											/>
										</div>
									</div>
									<div style={{ position: "relative" }}>
										{Utils.getAccountData("carrierLookup") && props.contact && props.contact.email &&
											props.contact.email.trim() !== "" &&
											<div style={{ position: "absolute", top: "6px", right: "10px" }}>
												<CarrierLookup
													validationType={'EMAIL'}
												/>
											</div>
										}
										<SingleInputField
											label={"Email"}
											name={"email"}
											contactId={
												props.contact && props.contact.id
											}
											value={
												props.contact && props.contact.email
											}
											placeholder="Enter email address"
											checkValidation={(value) => {
												let invalid = "";
												if (value === '' && props.contact.number && props.contact.number !== '') {

												} else if (value.trim() === '') {
													invalid = "Number or Email is required"
												}
												else if (!Utils.validateEmail(value)) {
													invalid = "Invalid email";
												}

												return invalid;
											}}
											loadingData={props.loadingData}
										/>
									</div>
									{!props.loadingData &&
										extraEmailList.length > 0 && extraEmailList.map((item, index) => {
											if (item.first_name !== undefined && item.last_name !== undefined && item.email !== undefined && item.email !== "") {
												return (
													<>
														<div key={`${item.first_name} ${index}`} className="additional__email__field__wr" style={item.id === editItemId ? { display: 'none' } : { display: '' }}  >
															<Card>
																<CardContent className='custom__card__content'>
																	<div className='custom__card__content__left'>

																		{
																			<h6 className="additional__email__field__title"> <span> Name : {renderName(item.first_name, item.last_name) ?? "N/A"} </span></h6>
																		}
																		{
																			item.contact_relation !== undefined && item.contact_relation != null &&
																			<p className='additional__email__field__type'>Type: <span> {item.contact_relation.relation_type}</span></p>
																		}

																		<p className='additional__email__field__content'>Email : <span>{item.email}</span></p>
																		{
																			item.label ? <p className='additional__email__field__content'>Label : <span>{item.label}</span></p> : <div />
																		}
																	</div>
																	<div className='custom__card__content__right'>
																		{/*<span className="custom__card__content__right__msg"*/}
																		{/*onClick={(e) => {*/}
																		{/*	e.preventDefault();*/}
																		{/*	if (window.sendCommunication !== undefined) {*/}
																		{/*		window.sendCommunication({*/}
																		{/*			open: true,*/}
																		{/*			onClose: () => console.log('Email Close'),*/}
																		{/*			id: props.contact.id,*/}
																		{/*			from: "contact-details",*/}
																		{/*			primaryEmail: getContactEmail(),*/}
																		{/*			emailId : item.email,*/}
																		{/*			fetchContactDetails: false,*/}
																		{/*			sendSuccessCallBack: () => {*/}
																		{/*				setLastCommunicationMessage("A few seconds ago"); */}
																		{/*				props.reloadNeedForConversation(true);*/}
																		{/*			}*/}
																		{/*		},'email')*/}
																		{/*	}*/}
																		{/*}}*/}

																		{/*>{IconList.emailIconv3}</span>*/}
																		<span className="small-round-icon" onClick={() => editExtraEmail(item)}>{<CreateIcon fontSize="small" />}</span>
																		<span onClick={() => removeExtraEmails(item.id, index)} style={{ float: "right", cursor: "pointer" }}>
																			{IconList.redDeleteIcon}
																		</span>

																	</div>
																</CardContent>
															</Card>
														</div>
														{item.id === editItemId && isEdit && inputList.map((item, i) => {
															return (
																<div key={i} className="email-box addtional__email__input" >
																	<form className="additional__email__input__form" onSubmit={handleSubmitForm}>
																		<SelectContactEmail
																			// className="addtional__email__select__type"
																			label="Type"
																			name="contact_relation_id"
																			value={item.contact_relation_id}
																			data={emailTypeList}
																			onTypeChange={(e) => handleInputChange(e, i)}
																			formType="edit"

																		/>
																		<input
																			name="first_name"
																			placeholder="Enter First Name"
																			value={item.first_name}
																			onChange={e => handleInputChange(e, i)}
																		/>

																		<input
																			name="last_name"
																			placeholder="Enter Last Name"
																			value={item.last_name}
																			onChange={e => handleInputChange(e, i)}
																		/>

																		<input
																			className="ml10"
																			name="email"
																			placeholder="Enter Email"
																			value={item.email}
																			onChange={e => handleInputChange(e, i)}
																			style={{ marginBottom: 0 }}
																		/>
																		{errors.email !== '' && <span style={{ color: "red", marginBottom: 2 }}>{errors.email}</span>}

																		{
																			validation &&
																			<p style={{ color: "red", marginBottom: 2 }}>{validation}</p>
																		}
																		{
																			errors.email === '' && validationText !== "" &&
																			<small className="text-danger" style={{ marginBottom: 2 }}>{validationText}</small>
																		}

																		<input
																			className="ml10 mt-2"
																			name="label"
																			placeholder="Enter Label"
																			value={item.label}
																			onChange={e => handleInputChange(e, i)}
																		/>
																		{
																			errors.label !== "" &&
																			<small className="text-danger" style={{ marginBottom: 2 }}>{errors.label}</small>
																		}
																		<div className="btn-box addtional__email__sent__btn__group mb-1">
																			{!isLoading && inputList.length !== 0 && <button
																				className="mr10 addtional__email__sent__btn__remove"
																				onClick={() => handleRemoveClick(i)}>Cancel</button>}

																			{
																				!isLoading &&
																				<button
																					className="mr10 addtional__email__sent__btn__save" type='submit'
																				>Update
																				</button>
																			}
																			{
																				isLoading && <p>Updating...</p>
																			}
																		</div>
																	</form>
																</div>
															);
														})}
													</>
												)
											}
											else return null
										})
									}
									{editItemId == null && !isEdit && !props.loadingData && inputList.map((item, i) => {
										return (
											<div key={i} className="email-box addtional__email__input" >
												<form className="additional__email__input__form" onSubmit={handleSubmitForm}>
													<SelectContactEmail
														// className="addtional__email__select__type"
														label="Type"
														name="contact_relation_id"
														value={item.contact_relation_id}
														data={emailTypeList}
														onTypeChange={(e) => handleInputChange(e, i)}
														formType="add"

													/>
													<input
														name="first_name"
														placeholder="Enter First Name"
														value={item.first_name}
														onChange={e => handleInputChange(e, i)}
													/>

													<input
														name="last_name"
														placeholder="Enter Last Name"
														value={item.last_name}
														onChange={e => handleInputChange(e, i)}
													/>

													<input
														className="ml10"
														name="email"
														placeholder="Enter Email"
														value={item.email}
														onChange={e => handleInputChange(e, i)}
														style={{ marginBottom: 0 }}
													/>
													{errors.email !== '' && <span style={{ color: "red", marginBottom: 2 }}>{errors.email}</span>}

													{
														validation &&
														<p style={{ color: "red", marginBottom: 2 }}>{validation}</p>
													}
													{
														errors.email === '' && validationText !== "" &&
														<small className="text-danger" style={{ marginBottom: 2 }}>{validationText}</small>
													}

													<input
														className="ml10 mt-2"
														name="label"
														placeholder="Enter Label"
														value={item.label}
														onChange={e => handleInputChange(e, i)}
													/>
													{
														errors.label !== "" &&
														<small className="text-danger" style={{ marginBottom: 2 }}>{errors.label}</small>
													}
													<div className="btn-box addtional__email__sent__btn__group">
														{!isLoading && inputList.length !== 0 && <button
															className="mr10 addtional__email__sent__btn__remove"
															onClick={() => handleRemoveClick(i)}>Cancel</button>}


														{!isLoading && inputList.length - 1 === i &&
															<button
																className="mr10 addtional__email__sent__btn__save" type='submit'
															>Save
															</button>}
														{
															isLoading && <p>Submitting...</p>
														}
													</div>
												</form>
											</div>
										);
									})}

									{
										inputList.length === 0 &&
										<div className="addtional__email__btn__container">
											<button className="addtional__email__btn" onClick={handleAddClick}>+ Add More Email</button>
										</div>
									}
									<div style={{ position: "relative" }}>
										{Utils.getAccountData("carrierLookup") && props.contact && props.contact.number &&
											props.contact.number.trim() !== "" &&
											<div className="d-flex align-content-center" style={{ gap: "10px", position: "absolute", top: "6px", right: "10px" }}>
												<CarrierLookup validationType={'NUMBER'}/>
												<CarrierLookup validationType={'DNC_STATUS'}/>
											</div>
										}
										<SingleInputField
											label={"Phone Number"}
											name={"number"}
											contactId={
												props.contact && props.contact.id
											}
											value={
												props.contact &&
												props.contact.number
											}
											placeholder="Enter number"
											checkValidation={(value) => {
												let invalid = "";
												if (/^\d+$/.test(value)) {
													if (
														!Utils.validateMobileNumber(
															value
														)
													) {
														invalid = "Invalid number";
													}
												} else if (value.trim() === '' && props.contact.email && props.contact.email !== '') {

												} else if (value.trim() === '') {
													invalid = "Number or Email is required"
												}
												else {
													invalid = "Invalid number";
												}
												return invalid;
											}}
											loadingData={props.loadingData}
										/>
									</div>
									<TextInputField
										label={"Last Contacted"}
										name={"last_contacted"}
										value={lastCommunicationMessage}
									/>
									<SelectField
										label={"Lead Status"}
										name={"status"}
										value={
											props.contact &&
											props.contact.status
										}
										redirect={() => {
											history.push('/contacts');
										}}
									/>
								</div>

								<button
									className="btn profile-outline-btn text-light-blue mt-3 modal-trigger"
									data-target="view_all_info_modal"
									onClick={(e) => {
										e.preventDefault();
										openContactInfoModal()
									}}>
									<span className="material-icons mr-2">
										visibility
									</span>
									View All Info
								</button>

								<button
									className="btn profile-outline-btn text-red mt-3"
									onClick={() => removeBulkContacts()}>
									<svg
										className="mr-2"
										width="16"
										height="20"
										viewBox="0 0 16 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M14.2356 2.42308H10.9038V1.81731C10.9038 0.813624 10.0902 0 9.08654 0H6.66346C5.65978 0 4.84615 0.813624 4.84615 1.81731V2.42308H1.51442C0.678045 2.42308 0 3.10112 0 3.9375V5.14904C0 5.48361 0.271195 5.75481 0.605769 5.75481H15.1442C15.4788 5.75481 15.75 5.48361 15.75 5.14904V3.9375C15.75 3.10112 15.072 2.42308 14.2356 2.42308ZM6.05769 1.81731C6.05769 1.48338 6.32953 1.21154 6.66346 1.21154H9.08654C9.42047 1.21154 9.69231 1.48338 9.69231 1.81731V2.42308H6.05769V1.81731Z"
											fill="#FF5858"
										/>
										<path
											d="M1.15023 6.96637C1.04214 6.96637 0.956008 7.05671 0.961157 7.16468L1.46092 17.6537C1.50711 18.6244 2.30445 19.3846 3.27595 19.3846H12.4753C13.4468 19.3846 14.2442 18.6244 14.2904 17.6537L14.7901 7.16468C14.7953 7.05671 14.7091 6.96637 14.601 6.96637H1.15023ZM10.2987 8.48079C10.2987 8.14611 10.5698 7.87502 10.9045 7.87502C11.2392 7.87502 11.5103 8.14611 11.5103 8.48079V16.3558C11.5103 16.6905 11.2392 16.9616 10.9045 16.9616C10.5698 16.9616 10.2987 16.6905 10.2987 16.3558V8.48079ZM7.26987 8.48079C7.26987 8.14611 7.54095 7.87502 7.87563 7.87502C8.21032 7.87502 8.4814 8.14611 8.4814 8.48079V16.3558C8.4814 16.6905 8.21032 16.9616 7.87563 16.9616C7.54095 16.9616 7.26987 16.6905 7.26987 16.3558V8.48079ZM4.24102 8.48079C4.24102 8.14611 4.5121 7.87502 4.84679 7.87502C5.18148 7.87502 5.45256 8.14611 5.45256 8.48079V16.3558C5.45256 16.6905 5.18148 16.9616 4.84679 16.9616C4.5121 16.9616 4.24102 16.6905 4.24102 16.3558V8.48079Z"
											fill="#FF5858"
										/>
									</svg>
									Delete Contact
								</button>
							</div>
						</TabPanel>
						<TabPanel
							className="use__tabs__panel"
							value={activeTab}
							index={1}
						>
							{/* show more cotact info */}
							<MoreInfo contact={props.contact} onChange={editMoreInfo} />
							<CustomFields
								data={props.data}
								// customTypes={props.data.customTypes}
								contact_id={props.contact.id}
								contactCustomFields={
									props.contact.all_contact_custom_fields
								}
								defaultFields={props.contact.user.custom_fields}
							/>
						</TabPanel>
						<TabPanel
							className="use__tabs__panel"
							value={activeTab}
							index={2}>
							<TagsComponent />
						</TabPanel>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

const mapStateToProps = state => {
	return {
		contact: state.contactReducer.contact,
		showNoteModal: state.contactReducer.showNoteModal,
		assignedContactListInfo: state.contactReducer.assignedContactListInfo,
		allContactList: state.contactReducer.allContactList,

	};
};

const mapDispatchToProps = dispatch => {
	return {
		toggleNoteModal: (params) => dispatch(toggleNoteModal(params)),
		reloadNeedForConversation: (params) => dispatch(reloadConversationList(params)),
		updateStoredContactDetails: (params) => dispatch(setContactDetails(params)),
		addExtraEmailContact: (params) => dispatch(addExtraEmailContact(params)),
		setContactDetails: (params) => dispatch(setContactDetails(params)),

	};
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftSide);