import React, {useState} from "react"
import TextField from '@material-ui/core/TextField';
import { NavigateNext } from "@material-ui/icons";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import {cleanContactsActionsApi} from "../../../../api/cleanDataApi";

    const ModalExportFile = (props)=> {
        const [exportFileName, setExportFileName] = useState("");
        const handleSubmit=()=>{
            if(exportFileName !== ""){
                const payload = {
                    cleanContactIds: [],
                    filterType: "ALL",
                    action: "EXPORT_CONTACT",
                    fileName: exportFileName
                };

                if (props.selectedFileContactIds && props.selectedFileContactIds[0]){
                    payload.cleanContactIds = props.selectedFileContactIds;
                    payload.filterType = "SEGMENT";
                }

                if (props.filter){
                    if (props.filter.invalidNumbers){
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (props.filter.validNumbers){
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (props.filter.invalidEmails){
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    }else if (props.filter.validEmails){
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload).then((res)=>{
                    if (res && res.success){
                        window.showNotification("success", res.message);
                        props.onClose();
                    }else {
                        window.showNotification("error", "Something went wrong try again later");
                    }
                }).finally(()=>{

                });
            }else{
                window.showNotification("ERROR","Please insert file name")
            }
        }
        return (
            <GlobalModal {...props}
                title="File Name"
                buttonText="Save"
                buttonIcon={<NavigateNext/>}
                onSubmit={()=> handleSubmit()}
                className="manage-tag-modal update create-new-list global-small-modal global-xs-modal">
                    <div className="cm__textField_wrapper d-flex align-items-center justify-content-between flex-column">
                        <p>Please provide a name for your exported File </p>
                        <TextField id="outlined-basic" placeholder="Please provide a name for your exported file" variant="outlined" onChange={(event)=>{
                            setExportFileName(event.target.value);
                        }}/>
                    </div>
            </GlobalModal>
        )
}

export default ModalExportFile;