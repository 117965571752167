const Icons = {
    valid: (
        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="16.76" viewBox="0 0 512 429.76" fill="#3B8BD7">
            <path d="M96.3 0h242.43c-15.45 14.62-30.92 29.65-46 44.92l-.78.79H96.3c-13.9 0-26.55 5.7-35.72 14.87-9.17 9.17-14.87 21.82-14.87 35.71v237.17c0 27.86 22.73 50.59 50.59 50.59h319.4c27.7 0 50.59-22.89 50.59-50.59V100.71c12.47-14.55 25-28.94 37.48-43.14A94.985 94.985 0 0 1 512 96.29v237.17c0 52.91-43.39 96.3-96.3 96.3H96.3c-52.84 0-96.3-43.47-96.3-96.3V96.29C0 69.8 10.83 45.71 28.27 28.27 45.71 10.83 69.8 0 96.3 0zm92.81 146.72c22.15 12.77 36.58 23.38 53.76 42.31 44.55-71.71 94.83-113.36 157.71-169.76l6.15-2.37h68.8C383.28 119.36 314.7 201.19 245.77 324.73c-41.43-69.82-69.31-114.77-129.55-161.54l72.89-16.47z"/>
        </svg>
    ),
    invalid: (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.38443 15.7486L9.04204 3.63848C9.28674 3.19355 9.93071 3.19355 10.1754 3.63848L16.833 15.7486C17.0679 16.1752 16.7563 16.6957 16.2663 16.6957H2.9511C2.46111 16.6957 2.15008 16.1752 2.38443 15.7486Z" fill="#F6E266"/>
            <path d="M16.2658 16.9994H2.95113C2.61088 16.9994 2.30473 16.8253 2.13246 16.5337C1.96141 16.244 1.95595 15.8959 2.11784 15.6013L8.77548 3.4918C8.94468 3.18383 9.25572 3 9.60875 3C9.96115 3 10.2728 3.18383 10.442 3.4918L17.0996 15.6019C17.2615 15.8959 17.2567 16.2446 17.0856 16.5331C16.9128 16.8253 16.6066 16.9994 16.2658 16.9994ZM2.65107 15.8953C2.56889 16.045 2.62732 16.1752 2.65654 16.2239C2.68575 16.2745 2.774 16.3907 2.95113 16.3907H16.2664C16.4435 16.3907 16.5324 16.2738 16.5616 16.2239C16.5902 16.1746 16.6486 16.045 16.5665 15.8953L9.90881 3.78518C9.7877 3.56545 9.42979 3.56545 9.30868 3.78518L2.65107 15.8953Z" fill="#DA4A54"/>
            <path opacity="0.24" d="M16.4544 15.1143C16.6029 15.0151 16.8044 15.0644 16.8902 15.2209L17.0996 15.6019C17.4459 16.2324 16.9864 17 16.2663 17H2.95108C2.23043 17 1.77147 16.2325 2.11781 15.6019L2.3272 15.2209C2.41304 15.0644 2.61449 15.0151 2.763 15.1143C2.82203 15.1539 2.88412 15.174 2.95108 15.174H16.2663C16.3333 15.174 16.3954 15.1539 16.4544 15.1143Z" fill="#DA4A54"/>
            <path d="M16.2658 16.9994H2.95113C2.61088 16.9994 2.30473 16.8253 2.13246 16.5337C1.96141 16.244 1.95595 15.8959 2.11784 15.6013L8.77548 3.4918C8.94468 3.18383 9.25572 3 9.60875 3C9.96115 3 10.2728 3.18383 10.442 3.4918L17.0996 15.6019C17.2615 15.8959 17.2567 16.2446 17.0856 16.5331C16.9128 16.8253 16.6066 16.9994 16.2658 16.9994ZM2.65107 15.8953C2.56889 16.045 2.62732 16.1752 2.65654 16.2239C2.68575 16.2745 2.774 16.3907 2.95113 16.3907H16.2664C16.4435 16.3907 16.5324 16.2738 16.5616 16.2239C16.5902 16.1746 16.6486 16.045 16.5665 15.8953L9.90881 3.78518C9.7877 3.56545 9.42979 3.56545 9.30868 3.78518L2.65107 15.8953Z" fill="#DA4A54"/>
            <path d="M16.2658 16.9994H2.95113C2.61088 16.9994 2.30473 16.8253 2.13246 16.5337C1.96141 16.244 1.95595 15.8959 2.11784 15.6013L8.77548 3.4918C8.94468 3.18383 9.25572 3 9.60875 3C9.96115 3 10.2728 3.18383 10.442 3.4918L17.0996 15.6019C17.2615 15.8959 17.2567 16.2446 17.0856 16.5331C16.9128 16.8253 16.6066 16.9994 16.2658 16.9994ZM2.65107 15.8953C2.56889 16.045 2.62732 16.1752 2.65654 16.2239C2.68575 16.2745 2.774 16.3907 2.95113 16.3907H16.2664C16.4435 16.3907 16.5324 16.2738 16.5616 16.2239C16.5902 16.1746 16.6486 16.045 16.5665 15.8953L9.90881 3.78518C9.7877 3.56545 9.42979 3.56545 9.30868 3.78518L2.65107 15.8953Z" fill="#DA4A54"/>
            <path d="M10.2174 14.2604C10.2174 14.597 9.94469 14.869 9.60872 14.869C9.27275 14.869 9.00006 14.597 9.00006 14.2604C9.00006 13.9238 9.27275 13.6517 9.60872 13.6517C9.94469 13.6517 10.2174 13.9238 10.2174 14.2604Z" fill="#DA4A54"/>
            <path d="M9.60808 15.1734C9.10472 15.1734 8.69507 14.7637 8.69507 14.2603C8.69507 13.757 9.10472 13.3473 9.60808 13.3473C10.1114 13.3473 10.5211 13.757 10.5211 14.2603C10.5211 14.7637 10.112 15.1734 9.60808 15.1734ZM9.60808 13.956C9.44009 13.956 9.30373 14.093 9.30373 14.2604C9.30373 14.4278 9.44006 14.5647 9.60808 14.5647C9.77606 14.5647 9.91243 14.4278 9.91243 14.2604C9.91243 14.093 9.77606 13.956 9.60808 13.956Z" fill="#DA4A54"/>
            <path d="M9.00796 8.87121C8.98178 8.71479 9.01831 8.55774 9.11023 8.4281C9.20457 8.29664 9.34578 8.20899 9.5077 8.18218C9.54179 8.17671 9.57588 8.17365 9.60934 8.17365C9.90819 8.17365 10.1602 8.38485 10.2089 8.67579C10.2192 8.7391 10.2192 8.80725 10.2089 8.87178L9.60872 12.4344L9.00796 8.87121Z" fill="#DA4A54"/>
            <path d="M9.60992 7.86934C9.56002 7.86934 9.5095 7.87359 9.45837 7.88212C8.96108 7.96367 8.62511 8.42932 8.70851 8.92171L9.30864 12.4867C9.32933 12.6102 9.42732 12.7131 9.5588 12.7344C9.57583 12.7375 9.59227 12.7387 9.60933 12.7387C9.75541 12.7387 9.88446 12.634 9.9088 12.4867L10.5096 8.92171C10.5248 8.82675 10.526 8.72451 10.5096 8.62468C10.4347 8.18281 10.0482 7.86934 9.60992 7.86934ZM9.60992 8.478C9.7566 8.478 9.88565 8.58452 9.9094 8.72573C9.91427 8.75554 9.91427 8.79085 9.9094 8.82007L9.60871 10.6047L9.30801 8.82007C9.29098 8.71842 9.33236 8.64233 9.35854 8.60521C9.40541 8.53947 9.476 8.49566 9.55759 8.48225C9.57524 8.47922 9.59289 8.478 9.60992 8.478Z" fill="#DA4A54"/>
        </svg>
    ),
    doNotCall: (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
            <path fill="#fff" d="M24,93.5,93.5,24A49.32,49.32,0,0,0,24,93.5Z"/>
            <path fill="#fff" d="M101.68,32.93,32.93,101.68A49.44,49.44,0,0,0,80.31,107,49.53,49.53,0,0,0,107,80.3a49,49,0,0,0,3.73-18.86h0a48.93,48.93,0,0,0-9.08-28.51Z"/>
            <path d="M68.42,36l-.22,0c-3.47-.63-2.66-5.88.85-5.45a38.07,38.07,0,0,1,5.43,1.09,30.12,30.12,0,0,1,4.81,1.81A22.49,22.49,0,0,1,91.36,47.62a29,29,0,0,1,1,5,37.14,37.14,0,0,1,.2,5.55v.09c-.23,3.55-5.64,3.3-5.51-.28a32.89,32.89,0,0,0-.16-4.61,24.27,24.27,0,0,0-.79-4.12,17,17,0,0,0-9.32-11,25,25,0,0,0-3.9-1.44A31.29,31.29,0,0,0,68.42,36ZM47.26,57.58A47.57,47.57,0,0,0,53.65,67.9a40.36,40.36,0,0,0,10.62,9,1.14,1.14,0,0,0,1,.11,4.09,4.09,0,0,0,1.4-.89A13.92,13.92,0,0,0,68,74.66c2-2.24,4.36-5,7.4-3.35l.18.11,10.06,6.69.09.08a4.7,4.7,0,0,1,1.7,4.19,11.1,11.1,0,0,1-1.87,5.08,10.59,10.59,0,0,1-5.32,4.11,22.78,22.78,0,0,1-6.3,1.28,21.1,21.1,0,0,1-9.41-1.4,42.44,42.44,0,0,1-9.22-5.22L55,86.06c-1.48-1.07-3.08-2.21-4.64-3.54a60.91,60.91,0,0,1-14.9-19.31c-2.94-6.26-4.34-12.9-2.94-19.05a13.51,13.51,0,0,1,5.12-8.25,12.29,12.29,0,0,1,9.25-1.84,1.31,1.31,0,0,1,1,.74l6,11.76a3.55,3.55,0,0,1,.32,3.83,7.41,7.41,0,0,1-2.75,2.7c-.38.29-.83.57-1.3.87-1.57,1-3.36,2.13-2.87,3.65v0Zm17.43-9.35c-3.53-.61-2.72-5.9.81-5.46l.18,0a23.17,23.17,0,0,1,3.11.77,18.74,18.74,0,0,1,2.82,1.17l0,0A15.91,15.91,0,0,1,80,56.34v0a22.77,22.77,0,0,1,.34,3.18c.15,3.61-5.36,3.85-5.51.21v0c-.19-5.37-2.73-9.28-7.9-10.95a15.35,15.35,0,0,0-2.29-.55Z"/>
            <path fill="#d92d27" d="M61.44,0A61.31,61.31,0,1,1,38,4.66,61.29,61.29,0,0,1,61.44,0Zm40.24,32.93L32.93,101.68A49.44,49.44,0,0,0,80.31,107,49.53,49.53,0,0,0,107,80.3a49,49,0,0,0,3.73-18.86h0a48.93,48.93,0,0,0-9.08-28.51ZM24,93.5,93.5,24A49.32,49.32,0,0,0,24,93.5Z"/>
        </svg>
    ),
    verifiedOk: (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path fill="green" d="M74.7,7.8c-11.2,2-24.4,7.7-33,14.2C25.5,34.3,14.5,52.2,11.1,72c-1.5,8.2-1.4,104,0,112.2c5.7,32.4,31.6,58.3,64,64c5.6,1,24.5,1.4,26.7,0.6c2.7-1.1,2.9-2.7,2.9-29.2c0-27.2-0.1-28.4-3.4-32.3c-3.6-4.4-5-4.7-23.3-4.7c-16,0-16.2,0-19.4-1.6c-2-1-3.9-2.6-5-4.3l-1.8-2.6V128V81.8l1.8-2.6c1.1-1.6,3-3.2,5-4.3c3.2-1.6,3.4-1.6,18.8-1.6c9.4,0,16.6-0.3,18.1-0.7c3.2-0.9,7.4-5.1,8.4-8.6c0.6-1.8,0.8-9.7,0.8-27.7c0-26.4-0.2-28.1-2.9-29.2C99.8,6.5,79.6,6.9,74.7,7.8z"/>
            <path fill="green" d="M228.3,83.9c-1.3,0.6-10.1,8.8-21.9,20.4c-10.8,10.7-24.3,24-30,29.6l-10.3,10.1L152,130c-7.7-7.7-15.1-14.5-16.3-15.1c-4.3-2.2-11.6-0.9-14.7,2.6c-3.5,3.9-4.3,10.1-2,14.5c0.8,1.5,10.8,12,24,25.2l22.8,22.7l38.9-38.3c21.4-21.1,39.4-39.3,40.1-40.5c1.3-2.4,1.6-7.8,0.5-10.5c-0.9-2.4-4.3-5.8-6.7-6.9C236,82.7,231.1,82.7,228.3,83.9z"/>
        </svg>
    )
};

export default Icons;