import React,{useEffect, useState} from "react";
import ShowTagsMoreInfo from './ShowTagsMoreInfo'
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { IconList } from "../../../globals/IconList";
import UtilsHelper from "../../../../helpers/Utils";
import { getContactTagList, getUserTagList } from "../../../../actions/contactAction";
import { connect } from "react-redux";
import ModalAssignToTag from "../ModalAssignToTag";

const ShowSpecificContactTags = (props) => {

  let TagListArray = [];
  const dataColumnInsideColumn = 3;
  
   //Show more Tags
  const [isContactTagModalOpen, setIsContactTagModalOpen] = useState(false);
  const [allContactTags, setAllContactTags] = useState([]);
  const [isOpenAssignTagModal, setIsOpenAssignTagModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    setAllContactTags(props.row?.tags)
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const getTagList = async() => {
    setIsLoading(true);
  
    await props.getContactTagList({
        contactId: props.row.id
    })
    
    setIsLoading(false);
  }


  const handleAddTag = (update = false, newTag = false, updatedTags) => {

    setIsOpenAssignTagModal(false);
    getTagList();
    window.showNotification('SUCCESS', "Tag Assigned Successfully.");

    let rowData = props.row;
    rowData.tags = updatedTags;
    setAllContactTags(updatedTags);
    props.datatableRerender(rowData, props.index);

    if(newTag){
      props.fetchUserTagList({});
    }
  };

  const updateTags=(index)=>{
    let tempAllContactTags = [...allContactTags];
    tempAllContactTags.splice(index,1);
    setAllContactTags(tempAllContactTags);

    let rowData = props.row;
    rowData.tags = tempAllContactTags;
    props.datatableRerender(rowData, props.index);
  }

  return (
    <>
    <td className="Campaigns-content">
      <ul>
        {(allContactTags && Array.isArray(allContactTags)) &&
          allContactTags.forEach((tagInfo,index) => {
            if (tagInfo?.tag && tagInfo?.tag?.name) {
             if (TagListArray.length < dataColumnInsideColumn) {
                TagListArray.push (
                    <li key={`tagDropdown${tagInfo.tag.id}${index}`} className="link-text-design campaign-list-in-contact-list contact__all__campaigns">
                        <BootstrapTooltip
                            title={tagInfo.tag.name}
                            arrow
                            placement="top"
                        >
                            <span>
                                {UtilsHelper.reduceTextLength(
                                    tagInfo.tag.name,
                                    20
                                )}
                            </span>

                        </BootstrapTooltip>
                    </li>
                )
              }
            }
          })}

          {TagListArray}
          {
            TagListArray.length > 0 ?
            <li>
              <div onClick={() => setIsContactTagModalOpen(true)} className="see__more__contact__campaigns">
                {IconList.seeMore}
                <span>For More Info</span>
              </div>
              <BootstrapTooltip title="Assign Tags" placement="top" arrow>
                <div className="add__tags__btn__from__contact__list" onClick={() => setIsOpenAssignTagModal(true)}>
                  {IconList.plusIcon}
                </div>
              </BootstrapTooltip>
            </li>
            :
            <BootstrapTooltip title="Assign Tags" placement="top" arrow>
              <div className="add__tags__btn__from__contact__list" onClick={() => setIsOpenAssignTagModal(true)}>
                {IconList.plusIcon}
              </div>
            </BootstrapTooltip>
          }
      </ul>
    </td>

    {
      isOpenAssignTagModal && !isContactTagModalOpen && (
        <ModalAssignToTag
          open={isOpenAssignTagModal}
          contact_id={[props.row.id]}
          title={"Add Tag"}
          callBack={handleAddTag}
          onClose={()=> setIsOpenAssignTagModal(false)}
          // contactTags={props.contactTags}
          allContactTags={allContactTags}
        />
      )
    }

    {isContactTagModalOpen && (
        <ShowTagsMoreInfo
          open={isContactTagModalOpen}
          close={() => setIsContactTagModalOpen(false)}
          contactId = {props.row.id}
          allContactTags={allContactTags}
          setAllContactTags={setAllContactTags}
          isOpenAssignTagModal={isOpenAssignTagModal}
          setIsOpenAssignTagModal={setIsOpenAssignTagModal}
          getContactTagList={props.getContactTagList}
          fetchUserTagList={props.fetchUserTagList}
          contactTags={props.contactTags}
          getTagList={getTagList}
          isLoading={isLoading}
          handleAddTag={handleAddTag}
          updateTags={updateTags}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
    return {
        contactTags: state.tagReducer.tags,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getContactTagList: (params) => dispatch(getContactTagList(params)),
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (ShowSpecificContactTags);