import React, { useEffect } from 'react';
import Loading from '../../../common/Loading';
import GlobalModal from "../../../globals/Modal/GlobalModal";
import { Grid } from '@material-ui/core';
import {connect} from "react-redux";
import Chip from '@material-ui/core/Chip';
import { getUserCustomFieldList } from '../../../../actions/contactAction';
import { InsertDriveFileOutlined } from "@material-ui/icons";
import "./form-entry-details.css";

const FormEntryDetails = (props) => {

    useEffect(() => {
        if (!props.isLoadedCustomFields) {
            props.getUserCustomFieldList();
        }
    }, [props.isLoadedCustomFields]);   // eslint-disable-line react-hooks/exhaustive-deps

    const populateStatus = (status) => {
        var text = 'Error';
        var badge = 'm-badge--danger';
        if (status === 1) {
            text = 'Inserted';
            badge = 'm-badge--success';
        } else if (status === 2){
            text = 'Duplicate';
            badge = 'm-badge--warning';
        }

        return <span className={`m-badge m-badge--wide ${badge}`}>{text}</span>;
    }

    const populateSubmission = (submissions,fullEntry) => {
        if(Array.isArray(submissions)) {
            return submissions.map((submission, index) => (
                <>
                    {
                        (submission[Object.getOwnPropertyNames(submission)] !== null) && (
                            <tr className="m-datatable__row" style={{left: '0px'}}>
                                <td className="m-datatable__cell" style={{width: '210px'}}><span><strong>{Object.getOwnPropertyNames(submission)}</strong></span></td>
                                <td className="m-datatable__cell"><span style={{width: '100%'}}>{submission[Object.getOwnPropertyNames(submission)]}</span></td>
                            </tr>
                        )
                    }
                </>
            ));
        }

        let customEntries = [];
        let fields = JSON.parse(fullEntry.form_builder.builder_json);
        let fileCountObj = {};    //This is for cotain the count of files under uploadedFiles obj

        fields.fields.forEach((field) => {
            let fileCount = 0;
            //File label can be multiple so fileupload object contains array.
            if(field.type === 'fileUpload') {
                let label = field.label !== null ? field.label : 'fileUpload';
                fileCount = fileCountObj[label] === undefined ? fileCountObj[label] = 0 : ++fileCountObj[label];
            }

            let obj = getProcessFieldValueForPreview(field,submissions,fileCount);
            if(obj) {
                customEntries.push(
                    <tr className="m-datatable__row" style={{left: '0px'}}>
                        <td className="m-datatable__cell" style={{width: '210px'}}>
                                <span><strong>{obj.label}</strong></span>
                                {
                                    obj.isCustomField &&
                                    <Chip variant="outlined" color="secondary" size="small" label="Custom Field" className="custom__field__chip__contact"/>
                                }
                                {
                                    obj.isFile &&
                                    <Chip variant="outlined" color="secondary" size="small" label="File" className="custom__field__chip__contact"/>
                                }
                        </td>
                        <td className="m-datatable__cell">
                            <span style={{width: '100%'}}>
                                {
                                    obj.isFile ? 
                                    <a className='form_uploaded__files' href={obj.url} target="_blank" rel="noreferrer"><InsertDriveFileOutlined/> {obj.name}</a> :
                                    obj.value
                                }
                            </span>
                        </td>
                    </tr>
                )
            }
        });

        return customEntries;

    }

    const getProcessFieldValueForPreview = (field,obj, fileCount = 0) => {
        if(field.type === "firstName" || field.type === "lastName" || field.type === "email" || field.type === "phoneNumber" || field.type === "birthDate" || field.type === "companyName" || field.type === "address" || field.type === "city" || field.type === "state" || field.type === "zip" || field.type === "note" || field.type === "ipAddress") {
            return { label : field.label, value : obj[field.name]}
        } else if(field.customFieldId) {
            let customField = obj['custom_fields'].find(objElem => objElem.id === field.customFieldId);
            return {label : field.label, value : customField?.value, isCustomField : true}
        } else if(field.type === "shortAnswer" || field.type === "textarea" || field.type === "phoneInput" || field.type === "number" ||  field.type === "link" || field.type === "date" || field.type === "time" || field.type === "radio" || field.type === "checkbox" || field.type === "choice" || field.type === "dropdown" || field.type === "checkbox") {
            return {label : field.label, value : obj[field.label]}
        } else if(field.type === 'fileUpload') {
            try {
                let label = field.label !== null ? field.label : 'fileUpload';
                let file = obj['uploaded_files'][label][fileCount];
                return {label : label, name : file.file_original_name, url : file.url, isFile : true}
            } catch(ex) {
                return null;
            }
        }
      
        return null;
    }

    return (
        <GlobalModal {...props}
             title={"Entry Details"}
             hideFooter={true}
             open={props.open}
             onClose={props.closeModal}
             className={'manage-tag-modal create__deal__modal create-collaborate-modal activity__modal_overflow update global-medium-modal' }>
            <Grid container>
                <Grid item xs={12}>
                    {
                        ((props.entry && props.entry !== null)) ? (
                            <React.Fragment>
                                <div className="m-datatable m-datatable--default m-datatable--brand m-datatable--loaded py-2">
                                    <table className="m-datatable__table" id="html_table" width="100%">
                                        <thead className="m-datatable__head">
                                            <tr className="m-datatable__row" style={{left: '0px'}}>
                                                <th className="m-datatable__cell m-datatable__cell--sort"><span
                                                        style={{width: '100%'}}>{props.entry.form_builder.title}</span></th>

                                            </tr>
                                        </thead>
                                        <tbody className="m-datatable__body">
                                            <tr className="m-datatable__row" style={{left: '0px'}}>
                                                <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px'}}><span><strong>Submitted</strong></span></td>
                                                <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%'}}>{props.entry.created_at}</span></td>
                                            </tr>
                                            <tr className="m-datatable__row m-datatable__row--even" style={{left: '0px'}}>
                                                <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px'}}><span><strong>Status</strong></span></td>
                                                <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%'}}>{populateStatus(props.entry.status)}</span></td>
                                            </tr>
                                            <tr className="m-datatable__row m-datatable__row--even" style={{left: '0px'}}>
                                                <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px'}}><span><strong>Message</strong></span></td>
                                                <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%'}}>{props.entry.comment}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="m-datatable m-datatable--default m-datatable--brand m-datatable--loaded pt-5">
                                    <table className="m-datatable__table" id="html_table" width="100%">
                                        <thead className="m-datatable__head">
                                            <tr className="m-datatable__row" style={{left: '0px'}}>
                                                <th className="m-datatable__cell m-datatable__cell--sort"><span
                                                        style={{width: '100%'}}>Submission Details</span></th>

                                            </tr>
                                        </thead>
                                        <tbody className="m-datatable__body">
                                            {
                                                populateSubmission(JSON.parse(props.entry.entries),props.entry)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="text-center" style={{width: '400px'}}>
                                <Loading/>
                            </div>
                        )
                    }
                </Grid>
            </Grid>
        </GlobalModal>
    )
}


const mapStateToProps = state => {
    return {
        isLoadedCustomFields: state.contactReducer.isLoadedCustomFields,
        userCustomFields: state.contactReducer.userCustomFields
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserCustomFieldList: () => dispatch(getUserCustomFieldList()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEntryDetails);
