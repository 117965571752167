import React, { useState, useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import utils from "../../helpers/Utils";
import {contactLimitRequest, isUserPackageInTrailApi} from "../../api/contactApi";
import { HistoryProvider } from '../../App';

const HeaderRight = () =>{
    return (
        <div>
            <h6>Notice <small>Limit reached</small></h6>
        </div>
    );
}

const Upgrade = () => {
    const history = useContext(HistoryProvider);
    const [state, setState] = useState({
        loading: false,
        requestIsSubmitted: false
    });
    const [isPackageInTrial, setIsPackageInTrial] = useState(false)

    useEffect(() => {
        isUserPackageInTrailApi().then(res => {
            let response = res.data;
            if(response.status !== undefined && response.status === 'success'){
                setIsPackageInTrial(response.message)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    // const saveAndContinue = (e) => {
    //     e.preventDefault();
    //     props.nextStep()
    // };

    const submitContactLimitRequest = () => {
        setState({...state, loading: true});
        contactLimitRequest()
            .then(response => {
                if(response.data.upgraded) {
                    window.showNotification('Success', 'Your contact limit is upgraded');
                    setTimeout(function() {
                        window.location.reload();
                    }, 2000);
                }
                else if (response.data.success) {
                    setState({...state, requestIsSubmitted: true});
                } else {
                    window.showNotification('Error', response.data.message)
                }
            })
            .catch(error => {
                utils.handleException(error);
            })

    }

    const goToLink = (e, link) => {
        e.preventDefault();
        history.push({
            pathname: link
        });
    }

    return(
        <React.Fragment>
            <div className="import_content_container v2">
                <div className="main_content_inner_container white">
                    <div className="main_content_topbar">
                        <div
                            className="main_content_topbar_content d-flex justify-content-between align-items-center">
                            <HeaderRight extraTitle={'Limit exist'} />
                        </div>
                    </div>

                    <div className="divider main_content_container_divider"></div>

                    <div className="main_content_main_section">
                        {state.requestIsSubmitted ?
                            <div>
                                <div className="main_content_main_section_content">
                                    <div className={"d-flex justify-content-center"}>
                                        <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="#6fcf97">
                                            <path d="m33.964 85.821-32.5-32.5a5 5 0 0 1 0-7.071l7.071-7.071a5 5 0 0 1 7.071 0L37.5 61.072l46.894-46.893a5 5 0 0 1 7.071 0l7.071 7.071a5 5 0 0 1 0 7.071l-57.5 57.5a5 5 0 0 1-7.071 0z"/>
                                        </svg>

                                    </div>
                                </div>
                                <div>
                                    <h3 className="justify-content-center main_content_main_section_heading center">
                                        Your request is submitted successfully
                                    </h3>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="main_content_main_section_content">
                                    <div className={"d-flex justify-content-center"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 0 24 24" width="100px" fill="#e8738a">
                                            <path d="M0 0h24v24H0V0z" fill="none"/>
                                            <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="justify-content-center main_content_main_section_heading center">
                                        You've exceeded your contact limit!
                                    </h3>
                                </div>
                                {isPackageInTrial &&
                                <div>
                                    <p className="center main_content_main_section_details">
                                        <b>During your free trial we limit your account to 500 contacts for testing purpose.</b>
                                    </p>
                                </div>
                                }

                                <div className={"mt-3"}>
                                    <p className="center main_content_main_section_details">
                                        <b>To request a contact limit increase from our team click submit. <br/>Our team will review your request and get back to you ASAP.</b>
                                        <a className={"btn radius-5 white-text text-center accent--bg--color ml-2"} onClick={submitContactLimitRequest}>{state.loading ? 'Submitting...' : 'Submit'}</a>
                                    </p>
                                </div>
                                {isPackageInTrial &&
                                <div className={"mt-1"}>
                                    <p className="center main_content_main_section_details">
                                        <b>To instantly increase your contact limit you can also upgrade to a paid plan.</b>
                                        <a className={"btn radius-5 white-text text-center ml-2"} onClick={e => goToLink(e, '/billing')}>Upgrade</a>
                                    </p>
                                </div>
                                }
                            </div>
                        }


                    </div>

                </div>
            </div>

        </React.Fragment>
    )
}

export default Upgrade;