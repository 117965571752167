import React from "react";
import { Link } from "react-router-dom";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import GlobalModal from "../../globals/Modal/GlobalModal";
import UtilsHelper from "../../../helpers/Utils";
import { CAMPAIGN_STATUS } from "../../../constants/CoreConstants";
import "./show-all-campaigns-of-contact.css";

const ShowAllCampaignsOfContact = (props) => {
  const campaignArray = [];

  return (
    <>
      <GlobalModal
        open={props.open}
        onClose={props.close}
        title={`Show All ${!props.isShowPausedCampaigns ? "Active" : ""} Campaigns`}
        className={"show__all__campaigns global-small-modal"}
        hideFooter={true}
      >
        <div className="contact__all__campaigns__wr">
          {props.allCampaignsOfContact && props.allCampaignsOfContact.length > 0 &&
            props.allCampaignsOfContact.forEach((eachCampaign) => {
              if (eachCampaign.campaign && eachCampaign.campaign.title) {
                if (eachCampaign.campaign.status === CAMPAIGN_STATUS.ACTIVE_CAMPAIGN && eachCampaign.is_unsubscribed === 0) {
                  campaignArray.push(
                    <>
                      <tr>
                        <td
                          className="link-text-design campaign-list-in-contact-list contact__all__campaigns"
                        >
                          <BootstrapTooltip
                            title={eachCampaign.campaign.title}
                            arrow
                            placement="top"
                          >
                            <Link to={`/campaign/details/${eachCampaign.campaign.id}`} target="_blank">
                              <span>
                                {UtilsHelper.reduceTextLength(
                                  eachCampaign.campaign.title,
                                  20
                                )}
                              </span>
                            </Link>
                            
                          </BootstrapTooltip>
                        </td>
                        <td>
                          <span className="campaign__contact__created__at">
                            {window.globalTimezoneConversionToDifferentTimezone(
                              eachCampaign.created_at,'UTC','',
                              "MM/DD/YYYY hh:mm a"
                            )}
                          </span>
                        </td>
                        <td>
                          <div className="running-campaigncontact-icon-contactlist"></div>
                        </td>
                      </tr>
                    </>
                  );
                } else if((eachCampaign.campaign.status !== CAMPAIGN_STATUS.ACTIVE_CAMPAIGN || eachCampaign.is_unsubscribed !== 0) && props.isShowPausedCampaigns) {
                  campaignArray.push(
                    <>
                      <tr>
                        <td
                          className="link-text-design campaign-list-in-contact-list contact__all__campaigns"
                        >
                          <BootstrapTooltip
                            title={eachCampaign.campaign.title}
                            arrow
                            placement="top"
                          >
                            <Link to={`/campaign/details/${eachCampaign.campaign.id}`} target="_blank">
                              <span>
                                {UtilsHelper.reduceTextLength(
                                  eachCampaign.campaign.title,
                                  20
                                )}
                              </span>
                            </Link>
                          </BootstrapTooltip>
                        </td>
                        <td>
                          <span className="campaign__contact__created__at">
                             {window.globalTimezoneConversionToDifferentTimezone(
                              eachCampaign.created_at,'UTC','',
                              "MM/DD/YYYY hh:mm a"
                            )}
                          </span>
                        </td>
                        <td>
                          <div className="pause-campaign-contact-icon-contactlist"></div>
                        </td>
                      </tr>
                    </>
                  );
                }
              }
            })}

          <div className="campaign__show__all__table__header">
            <table>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Added at</th>
                  <th>Status</th>
                </tr>
              </thead>
              {campaignArray}
            </table>
          </div>
        </div>
      </GlobalModal>
    </>
  );
};

export default ShowAllCampaignsOfContact;
