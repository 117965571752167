import { Box, makeStyles, Typography } from "@material-ui/core";

export const TabPanel = (props) => {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box p={3}>
			<Typography>{children}</Typography>
		  </Box>
		)}
	  </div>
	);
}

export const useImportListPaperStyle = makeStyles({
    root: {
      boxShadow : "none"
    },
});

export const useImportListTabsStyle = makeStyles({
    indicator: {
      display : "block",
      backgroundColor : "#3C7EF3"
    },
});

export const useImportListTabStyle = makeStyles({
    root: {
      fontSize : 24,
      maxWidth : 400,
      "&.Mui-selected": {
          backgroundColor : "inherit",
          color : "#3C7EF3"
      },
      "&:focus": {
        backgroundColor : "inherit",
        color : "#3C7EF3"
      }
    },
    wrapper : {
        lineHeight : "41px",
        fontFamily : "'Poppins', sans-serif",
        textTransform : "none",
        flexDirection : "row",
        "& > svg": {
            marginRight : 10
        }
    }
});