import React, { useState, useEffect } from "react"
import { Save } from "@material-ui/icons";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import {
    assignBulkStage,
    contactMoveStage,
    getPipelineList,
    getStageListWithPagination
} from "../../../../api/contactApi";
import NewLoader from "../../../common/NewLoader";
import { DropdownHelper } from "../../../globals/DropdownHelper";
import {cleanContactsActionsApi} from "../../../../api/cleanDataApi";


const ModalListForStageAdd = (props) => {
    const [selectedPipelineId, setSelectedPipelineId] = useState("");
    const [selectedStageId, setSelectedStageId] = useState("");
    const [submittingData, setSubmittingData] = useState(false);
    const [stageListForAction, setStageListForAction] = useState([]);
    const [pipelineList, setPipelineList] = useState([]);

    useEffect(() => {
        getPipelineList()
            .then((res)=>{
                if (res && res.data && res.data.data){
                    setPipelineList(res.data.data);
                }else {
                    setPipelineList([]);
                }
            }).catch((err)=>{
            setPipelineList([]);
        });
    }, []);

    const updatePipeline = async (pipelineId) => {
        setSelectedPipelineId(pipelineId)
        if (setSelectedStageId !== "") {
            setSelectedStageId("")
        }
        await getStageListForDropdown(pipelineId, "action");
    };

    const updateStage = (stageId) => {
        setSelectedStageId(stageId);
    };

    const submitContactInStage = async () => {
        if(selectedStageId === ""){
            window.showNotification("ERROR","Please select stage");
        }

        const payload = {
            cleanContactIds: props.selectedFileContactIds,
            filterType: props.selectedFileContactIds[0] ? "SEGMENT" : "ALL",
            action: "ADD_TO_PIPELINE_STAGE",
            pipelineStageIds: [parseInt(selectedStageId)]
        };

        if (props.filter){
            if (props.filter.invalidNumbers){
                payload.numberStatus = "INVALID";
                payload.filterType = "SEGMENT";
            }else if (props.filter.validNumbers){
                payload.numberStatus = "VALID";
                payload.filterType = "SEGMENT";
            }

            if (props.filter.invalidEmails){
                payload.emailStatus = "INVALID";
                payload.filterType = "SEGMENT";
            }else if (props.filter.validEmails){
                payload.emailStatus = "VALID";
                payload.filterType = "SEGMENT";
            }
        }

        setSubmittingData(true)
        cleanContactsActionsApi(payload).then((res)=>{
            if (res && res.success){
                window.showNotification("success", res.message);
                props.onClose();
            }else {
                window.showNotification("error", "Something went wrong try again later");
            }
        }).finally(()=>{
            setSubmittingData(false)
        });
    };

    const getStageListForDropdown = async (pipelineId, type = "" , perPage = 20, pageNo = 1, scroll= false) => {
        let params = {"pipeline_id": pipelineId };
        if(scroll){
            params["perPage"]= perPage
            params["pageNo"] = pageNo
        }
        try{
            const response = await getStageListWithPagination(params);
            if (response && response.data && response.data.stages && type === "action") {
                setStageListForAction(response.data.stages);
            }
        }catch (err){
            setStageListForAction([]);
        }
    }

    return (
        <GlobalModal {...props}
            title="Assign To Stage"
            buttonText="Add"
            buttonIcon={<Save />}
            onSubmit={()=>submitContactInStage()}
            hideFooter={submittingData}
            className="manage-tag-modal update create-new-list global-medium-modal global-xs-modal">
            {
                submittingData &&
                <NewLoader loading={submittingData} onlyLoader={true} size={26} message={""} />
            }
            <DropdownHelper
                datalist={pipelineList}
                noneFieldRequired={true}
                noneFieldTitle="Select Pipleine"
                className=""
                mapping={{ title: "title" }}
                menuItemClassName="menuitem-li-hover-color-with-border"
                updateSelectedData={updatePipeline}
                selectedValue={selectedPipelineId}
                dropdownHeader="pipleineChange"
                parentDivClassName="ppc__select_owner"
            />
            {selectedPipelineId !== "" &&
                <DropdownHelper
                    datalist={stageListForAction}
                    noneFieldRequired={true}
                    noneFieldTitle="Select Stage"
                    className=""
                    mapping={{ title: "stage" }}
                    menuItemClassName="menuitem-li-hover-color-with-border"
                    updateSelectedData={updateStage}
                    selectedValue={selectedStageId}
                    dropdownHeader="stageChange-contact"
                    parentDivClassName="ppc__select_owner"
                />
            }
        </GlobalModal>
    )
}

export default ModalListForStageAdd