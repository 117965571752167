import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import { getCollaboratorList, getSubUserListForContactlist, saveCollaborator } from '../redux/contactApi';
import axis from "axis.js";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
// import { getSubUserList, setSubUserListFromAction } from '../../../actions/contactAction';
import {connect} from 'react-redux'
import { getSubUserList, setSubUserListFromAction } from '../redux/contactAction';
import GlobalModal from '../Helpers/Modal/GlobalModal';
import NewLoader from '../Helpers/NewLoader';


const EachUser = (props) => {
    return (
        <li>
        <label>

        <input name="users" checked={props.isChecked} onChange={props.selectUser} type="checkbox" defaultValue={props.data.id} className="styled-checkbox filled-in custom__filled__in" />
        <span>{props.data.full_name} </span>
        </label>
        </li>
    )
};

class ModalAddCollaboratorForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            error: false,
            errorMsg: '',
            submitingFile: false,
            submitEnable: false,
            loading: false,
            submitingContact : false,
            allTeamUser: [],
            tempTeamUser: [],
            newSelectedUsers : [],
            selectedUsers : [],
            allCollaborator: [],
            tempCollaborator: [],
            selectUserError : false,
            company : false,

        };
    }

    componentDidMount(){
        let users = [];

        this.props.selected.map((data,index)=> {
            users.push(data.user.id);
        });

        this.setState({
            selectedUsers : users
        });

        this.loadTeamUserList();
        this.loadCollaboratorList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            isOpen: nextProps.isOpen
        });
        if(nextProps.selected){
            let users = [];

            nextProps.selected.map(data=> (
                users.push(data.user.id)
            ));

            this.setState({
                selectedUsers : users
            });
        }
    }

    removeApiError = () => {
        this.form.hideError(this.number);
    };

    selectedUser = (id,name) =>{
        this.setState({
            assigned_to: id,
            assigned_to_name : name,
        });
    };

    loadTeamUserList = () => {
        this.setState({
            loading: true
        });
        if(this.props.subUserList != null){
            this.setState({ allTeamUser:this.props.subUserList, tempTeamUser: this.props.subUserList })

                    this.setState({
                    loading: false
                })
        }else{
            getSubUserListForContactlist()
            .then(response => {

                this.props.setSubUserList(response.data.data)
                this.setState({
                    allTeamUser: response.data.data,
                    tempTeamUser: response.data.data,
                    loading: false,
                    company: false
                })
            })
            .catch(error => {
                console.log('something is wrong' + error)
                this.setState({
                    loading: false,
                    company: false
                })
            });
        }

    };

    loadCollaboratorList = () => {
        this.setState({
            loading: true
        });
        
        getCollaboratorList()
        .then(response => {
            // this.props.setSubUserList(response.data.data)
            this.setState({
                allCollaborator: response.data.data,
                tempCollaborator: response.data.data,
                loading: false
            })
        })
        .catch(error => {
            console.log('something is wrong' + error)
            this.setState({
                loading: false
            })
        });

    }

    handleSubmit = (e) => {
        e.preventDefault();

        var params = {
            contact_id : this.props.contact_id,
            selectedUsers : this.state.selectedUsers
        };

        this.setState({
            submitingContact : true
        });

        
        saveCollaborator(params).then(res => {
            this.setState({
                submitingContact : false
            });


            if (res.data.status === 'error') {
                this.setState({
                    error: true,
                    errorMsg: res.data.html
                });

                setTimeout(() => {
                    this.setState({
                        error: false,
                        errorMsg: ""
                    });
                }, 5000);
            } else if (res.data.status === 'validation-error') {
                // console.log(res.data.html);
            } else {
                this.props.callBack();
                // this.props.modalToggle()
            }
        });
    };

    toggle = () => {
        if(this.props.modalToggle){
            this.props.modalToggle();
        }
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    handelCheckBox = (e) => {
        let previousSelectedUsers = this.state.selectedUsers;

        if (e.target.checked) {
            if(!previousSelectedUsers.includes(parseInt(e.target.value)))
                previousSelectedUsers.push(parseInt(e.target.value));
        }
        else {
            if(previousSelectedUsers.includes(parseInt(e.target.value))){
                previousSelectedUsers.splice(previousSelectedUsers.indexOf(parseInt(e.target.value)),1)
            }
        }

        this.setState({
            newSelectedUsers : previousSelectedUsers,
            submitEnable : true
        },()=>{
            //console.log(this.state)
        });
    };

    changeSearch = (e) => {

        let searchKey = e.target.value.toUpperCase().trim();
        let userData ;
        if(searchKey !== ''){
            userData = this.state.tempCollaborator.filter((data)=> {
                if (data.full_name.toUpperCase().indexOf(searchKey) > -1) {
                    return data;
                }
                return null
            });
        }else{
            userData = this.state.tempCollaborator
        }
        
        this.setState({
            allCollaborator: userData,
            searchKey: searchKey
        })
    };
    isUserChecked  = (id) => {
        return this.state.selectedUsers.includes(parseInt(id));
    };

    render() {
        let runningList = '';
        if(!axis.isUndefined(this.state.allCollaborator)) {
            if(this.state.allCollaborator.length){
                runningList = this.state.allCollaborator.map((data, index) => {
                    return (<EachUser selectUser={this.handelCheckBox} isChecked={this.isUserChecked(data.id)} data={data}
                                      key={index}/>)
                });
            }

        }

        return (
            // <Drawer anchor={'right'} open={true} onClose={this.toggle} >
            <GlobalModal {...this.props} 
                    title={"Collaborator"}
                    buttonText={"Save"}
                    // buttonIcon={<Save/>}
                    onSubmit={()=>alert('asd')}
                    hideFooter={true}
                    className={'manage-tag-modal modal-assign-campaign global-medium-modal' }>
                {/* <Wrapper className="manage-tag-modal create-collaborate-modal"> */}
                <div className="search-bar-top-area2.search-bar-top-area">
                <Form ref={c => { this.form = c }} onSubmit={this.handleSubmit}>
                    <Grid container>
                    {
                        this.state.loading ? (
                            <Grid item xs={12}>
                                <NewLoader loading={ this.state.loading} onlyLoader={true} size={30} message={""} />

                            </Grid>
                        ) : (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <div class="search-bar-top-area2 search-bar-top-area">
                                        <form action="">
                                            <input type="search" name="" class="ad__custom__input custom-popup-search" placeholder="Type to search" onChange={this.changeSearch}/>
                                            <Button variant="contained" color="primary" className="collb__crate__button">
                                             <span ><SearchIcon/></span>
                                            </Button>
                                        </form>
                                    </div>
                                    <div class="campaigns-single-item-main44 upload-single-item-main2 upload-single-item-main">
                                        <ul class="awesome__scroll_bar">
                                            {runningList}
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div class="manage-tag-footer modal-footer">
                                        <button color="primary" type="submit" disabled={!this.state.submitEnable} className="custom__modal--btn accent--bg--color">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="10" cy="10" r="10" fill="white"/>
                                                <path d="M13.8447 9.99923C13.8447 10.3537 13.8139 10.6403 13.4594 10.6403H10.6395V13.4601C10.6395 13.814 10.353 13.8454 9.9985 13.8454C9.64401 13.8454 9.35747 13.814 9.35747 13.4601V10.6403H6.5376C6.18375 10.6403 6.15234 10.3537 6.15234 9.99923C6.15234 9.64474 6.18375 9.3582 6.5376 9.3582H9.35747V6.53833C9.35747 6.18385 9.64401 6.15308 9.9985 6.15308C10.353 6.15308 10.6395 6.18385 10.6395 6.53833V9.3582H13.4594C13.8139 9.3582 13.8447 9.64474 13.8447 9.99923Z" fill="#3C7EF3"/>
                                            </svg>
                                            Update Collaborators
                                        </button>
                                        <a href="#!" onClick={(e) => {
                                            e.preventDefault();
                                            this.toggle();
                                        }} class="manage-tag-footer-btn-left custom__modal--btn-close modal-close waves-effect waves-green btn-flat">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.1016 4.9001C15.2016 1.0001 8.80156 1.0001 4.90156 4.9001C1.00156 8.8001 1.00156 15.2001 4.90156 19.1001C8.80156 23.0001 15.1016 23.0001 19.0016 19.1001C22.9016 15.2001 23.0016 8.8001 19.1016 4.9001ZM14.8016 16.2001L12.0016 13.4001L9.20156 16.2001L7.80156 14.8001L10.6016 12.0001L7.80156 9.2001L9.20156 7.8001L12.0016 10.6001L14.8016 7.8001L16.2016 9.2001L13.4016 12.0001L16.2016 14.8001L14.8016 16.2001Z" fill="white"/>
                                            </svg>
                                            Cancel
                                        </a>
                                        </div>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    </Grid>
                </Form>
                </div>
                </GlobalModal>
        );
    }
}

const mapStateToProps = state => {
    return {
        allContactList: state.rightsideReducer.allContactList,
        userInfo: state.rightsideReducer.userInfo,
        subUserList: state.rightsideReducer.subUserList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
        getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalAddCollaboratorForm);
